import { ConsentMode } from 'features/globalSetup/types/workspaceBidder'

export const DEFAULT_CPM_ADJUSTMENT = {
  OWN: 100,
  YIELDBIRD: 85,
} as const

export const DEFAULT_CONSENT_MODE: ConsentMode = 'consentOnly'

export const SELECT_HINT_LEARN_MORE =
  'https://knowledge-base.yieldbird.com/prebid-stack/available-bidders'

import { ColumnDef } from '@tanstack/react-table'
import { constantCase } from 'change-case'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScreamingSnakeCase } from 'type-fest'

import { BoxWithLogo } from 'components/Box'
import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { prebidModuleLogos } from 'features/globalSetup'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModulesAttributesSchema } from '../../../../schema'
import { ActionsCell } from './components/ActionsCell'
import { KNOWLEDGE_BASE_URL } from './constants'
import { DomainPrebidModuleSlice } from './hooks/useGetDomainPrebidModules/types'

export const useColumns = (moduleType: PrebidModuleType): ColumnDef<DomainPrebidModuleSlice>[] => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.modules.list.header',
  })

  const { control } = useFormContext<DomainPrebidModulesAttributesSchema>()

  return [
    {
      accessorKey: 'prebidModule.name',
      cell: ({
        row: {
          original: {
            prebidModule: { name },
          },
        },
      }) => (
        <BoxWithLogo logo={{ size: 'small', svg: prebidModuleLogos[name] }}>{name}</BoxWithLogo>
      ),
      header: t(`name.${moduleType}.name`),
      meta: {
        tooltip: {
          content: t(`name.${moduleType}.tooltip`),
          link: {
            title: tC('learnMore'),
            url: KNOWLEDGE_BASE_URL[
              constantCase(moduleType) as ScreamingSnakeCase<PrebidModuleType>
            ],
          },
        },
      },
      size: COLUMN_SIZE.LG,
    },
    {
      accessorKey: 'enabled',
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <Switch<DomainPrebidModulesAttributesSchema>
          control={control}
          name={`domainPrebidModulesAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: t('enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: props => (
        <ActionsCell
          {...props}
          moduleType={moduleType}
        />
      ),
      header: t('actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}

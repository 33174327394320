import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { MatchType, matchTypeValues } from 'features/inventory/page/types/matchType'
import { UseMatchTypeRadioGroup } from './types'

export const useMatchTypeRadioGroup = (): UseMatchTypeRadioGroup => {
  const { t } = useTranslation('features/page')

  return matchTypeValues.map(
    (matchType: MatchType): RadioGroupOption => ({
      label: t(`form.general.basicAttributes.matchType.values.${matchType}`),
      value: matchType,
    }),
  )
}

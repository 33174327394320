import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspaceFormContext } from 'features/workspace/contexts/WorkspaceFormContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/edit'
import { UPDATE_WORKSPACE } from './api/mutation'
import { Data } from './api/types'
import { mapper } from './mapper'
import { UseUpdateWorkspace } from './types'

export const useUpdateWorkspace = (): UseUpdateWorkspace => {
  const { t } = useTranslation('features/workspace')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(WorkspaceFormContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    onCompleted: ({ updateWorkspace: { errors, workspace } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else if (workspace) {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: EditSchema) => {
    setLoading(true)

    return mutateUpdateWorkspace({ variables: { input: mapper(data) } })
  }

  return { errors, update }
}

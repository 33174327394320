import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { WorkspaceSliceDTO } from './api/types'
import { WorkspaceSlice } from './types'

export const mapFromDTO = (from: WorkspaceSliceDTO): SelectOption<WorkspaceSlice> =>
  mapDTO<WorkspaceSliceDTO, SelectOption<WorkspaceSlice>>({ from }).transform(workspace => ({
    label: workspace.name,
    value: workspace.id,
  }))

import { Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { DeleteItem } from 'features/globalSetup/pages/Prebid/Modules/components/DeleteItem'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ id, prebidModule }: ActionsCellProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, close, isOpen, open } = useMenu()

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={() =>
        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.VENDOR_SPECIFIC.EDIT, {
            id,
            tab: 'general',
            workspaceId,
          }),
        )
      }
      text={t('form.action.edit')}
    />
  )

  const MenuItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [<EditItem key='edit' />]

    if (ability.can('delete', 'GlobalSetupFeature-vendorSpecific')) {
      items = items.concat([
        <Divider
          key='divider'
          sx={{ my: 1 }}
        />,
        <DeleteItem
          id={id}
          key='delete'
          prebidModule={prebidModule}
        />,
      ])
    }

    return items
  }

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuItems />
      </Menu>
    </>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { PUBLISHER_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_PUBLISHERS_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${PUBLISHER_FOR_TABLE_FRAGMENT}

  query getPublishersForTable(
    $first: Int
    $last: Int
    $terms: [String!]
    $userGroup: UserGroupTypeEnum!
  ) {
    users(first: $first, last: $last, terms: $terms, userGroup: $userGroup) {
      nodes {
        ...PublisherForTableFragment
      }

      totalCount
    }
  }
`

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { UserManagementFormContext } from '../../../contexts/UserManagementFormContext'
import { UserAddPublisherForm } from './Form'
import { useCreatePublisherByEmployee } from './hooks/useCreatePublisherByEmployee'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(UserManagementFormContext)
  const { createPublisherByEmployee, errors: apiErrors } = useCreatePublisherByEmployee()

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={createPublisherByEmployee}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault}
    >
      {() => (loading ? <Loading /> : <UserAddPublisherForm />)}
    </Form>
  )
}

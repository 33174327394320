import { gql, TypedDocumentNode } from '@apollo/client'

import { AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/api/fragment'
import { PageDTO } from '../types/pageDTO'
import { DOMAIN_FRAGMENT } from './domain'

export const PAGE_FRAGMENT: TypedDocumentNode<PageDTO> = gql`
  ${AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT}
  ${DOMAIN_FRAGMENT}

  fragment PageFragment on Page {
    adUnits {
      ...AdUnitForPageTransferList
    }
    createdAt
    domain {
      ...DomainFragment
    }
    id
    matchType
    name
    path
    updatedAt
  }
`

import { z } from 'zod'

import { AdUnitForPageTransferList } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/adUnitForPageTransferList'
import { PageForGeneralForm } from '../hooks/useGetPage/types'
import { adUnitIdsField } from './adUnitIdsField'
import { commonSchema } from './commonSchema'

export const editSchema = z
  .object({
    id: z.string(),
  })
  .merge(adUnitIdsField)
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = ({
  adUnits,
  domain,
  id,
  matchType,
  name,
  path,
}: PageForGeneralForm): EditSchema => ({
  adUnitIds: adUnits
    .map((adUnit: AdUnitForPageTransferList): AdUnitForPageTransferList['value'] => adUnit.value)
    .sort(),
  domainId: domain.id,
  id,
  matchType,
  name,
  path,
})

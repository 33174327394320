import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_PERFORMANCE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACE_FOR_FORM: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_PERFORMANCE_FRAGMENT}

  query getWorkspaceForPerformance($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        ...WorkspacePerformanceFragment
      }
    }
  }
`

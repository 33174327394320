export const TOKEN_COOKIES = {
  AT: 'at', // accessToken
  RT: 'rt', // refreshToken
} as const

const COOKIE_EXPIRATION_DAYS = 30

export const AUTH_COOKIE_OPTIONS = {
  expires: COOKIE_EXPIRATION_DAYS,
  samesite: 'strict',
  secure: import.meta.env.VITE_COOKIE_SECURE === 'true',
} as const

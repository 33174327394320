import { z } from 'zod'

import { FilteringRuleSlice } from 'features/globalSetup/pages/Prebid/Protection/pages/hooks/useGetFilteringRule'
import { commonSchema } from './commonSchema'

export const editSchema = z
  .object({
    id: z.string(),
  })
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (filteringRule: FilteringRuleSlice): EditSchema => {
  const { applicableDomains, blocked, domainIds, id, name } = filteringRule

  return {
    applicableDomains,
    blocked,
    domainIds,
    id,
    name,
  }
}

import { z } from 'zod'

import { dataSamplingValues } from 'features/workspace/types/dataSampling'

export const schema = z.object({
  dataSampling: z.enum(dataSamplingValues),
})

export type Schema = z.infer<typeof schema>

export const defaultSchema = (data: Schema): Schema => {
  const { dataSampling } = data

  return {
    dataSampling,
  }
}

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useAbility } from 'providers/casl'
import { CommonForm } from './CommonForm'
import { useUpdateAdUnit } from './hooks/useUpdateAdUnit'
import { EditSchema, editSchema, editSchemaDefault } from './schemas/editSchema'

export const GeneralFormEdit = (): JSX.Element => {
  const ability = useAbility()
  const { adUnit, formId, loading, setTouched, setValid } = useContext(AddEditAdUnitContext)
  const { errors: apiErrors, updateAdUnit } = useUpdateAdUnit()

  if (!adUnit) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={data => updateAdUnit(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(adUnit, ability)}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='edit' />)}
    </Form>
  )
}

import { Box } from '@mui/material'

import { GLOBAL_BORDER_RADIUS } from 'providers/material-ui/theme/constants'
import { EmployeeBoxProps } from './types'

export const EmployeeBox = ({ children }: EmployeeBoxProps): JSX.Element => (
  <Box
    sx={theme => ({
      backgroundColor: theme.palette.cream.main,
      borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
      p: 2,
      width: 'fit-content',
    })}
  >
    {children}
  </Box>
)

import { createTheme } from '@mui/material/styles'

import { createComponents } from './components'
import { GLOBAL_BORDER_RADIUS } from './constants'
import { createPalette } from './palette'
import { createTypography } from './typography'
import { createZIndex } from './zIndex'

export let theme = createTheme({})

// Assigning separately and order is important for inheritance
theme = createTheme(theme, { zIndex: createZIndex(theme) })
theme = createTheme(theme, { shape: { borderRadius: GLOBAL_BORDER_RADIUS } })
theme = createTheme(theme, { typography: createTypography(theme) })
theme = createTheme(theme, { palette: createPalette(theme) })
theme = createTheme(theme, { components: createComponents(theme) })

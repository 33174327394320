import { Box } from '@mui/material'
import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Hint from 'components/Hint'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { TargetingSection } from './components/TargetingSection'
import { SHOW_HINT_BELOW, URL } from './constants'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  const { watch } = useFormContext<Schema>()

  if (domain === undefined) {
    throw Error('Domain should be accessible')
  }
  const { id } = domain
  const { adThreshold, refreshInterval } = watch()

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.refresher.enabled.description')}
          title={t('form.refresher.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              name='refresherEnabled'
            />
          }
        />
      </Paper>

      <Paper>
        <SectionHeader
          bottomSpacing
          subtitle={t('form.refresher.basicSettings.subtitle')}
          subtitleEndAdornment={
            <Link
              newTab
              to={URL.KNOWLEDGE_BASE.BASIC_SETTINGS}
            >
              {t('common:learnMore')}
            </Link>
          }
          title={t('form.refresher.basicSettings.title')}
        />

        <BoxForm>
          <Box>
            <NumberField
              control={control}
              label={t('form.refresher.basicSettings.refreshInterval.label')}
              name='refreshInterval'
              tooltip={{
                content: t('form.refresher.basicSettings.refreshInterval.tooltip'),
              }}
            />

            {refreshInterval < SHOW_HINT_BELOW.REFRESH_INTERVAL && (
              <Hint
                content={t('form.refresher.basicSettings.refreshInterval.hint.text')}
                contentEndAdornment={
                  <Link
                    newTab
                    to={URL.GOOGLE_REFRESH_POLICY}
                  >
                    {t('form.refresher.basicSettings.refreshInterval.hint.link')}
                  </Link>
                }
              />
            )}
          </Box>

          <Box>
            <NumberField
              control={control}
              label={t('form.refresher.basicSettings.adThreshold.label')}
              name='adThreshold'
              tooltip={{ content: t('form.refresher.basicSettings.adThreshold.tooltip') }}
            />

            {adThreshold < SHOW_HINT_BELOW.AD_THRESHOLD && (
              <Hint content={t('form.refresher.basicSettings.adThreshold.hint.text')} />
            )}
          </Box>

          <Switch
            bold
            control={control}
            label={t('form.refresher.basicSettings.monitorUserScrollEnabled.title')}
            labelPlacement='start'
            name='monitorUserScrollEnabled'
            tooltip={{
              content: t('form.refresher.basicSettings.monitorUserScrollEnabled.tooltip'),
            }}
          />
        </BoxForm>
      </Paper>

      <TargetingSection />

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={id}
            product='refresher'
          />
        )}
      />
    </>
  )
}

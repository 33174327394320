import { RouteObject } from 'react-router-dom'

import ProductsPage from 'features/product/pages/ProductsPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const productRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='ProductPage-list'
        page={<ProductsPage />}
      />
    ),
    path: ROUTES.PRODUCT.LIST,
  },
]

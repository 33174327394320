import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { FilteringRule } from 'features/globalSetup/types/filteringRule'
import { DELETE_FILTERING_RULE } from './api/mutation'
import { UseDeleteFilteringRule, UseDeleteFilteringRuleProps } from './types'

export const useDeleteFilteringRule = ({
  name,
}: UseDeleteFilteringRuleProps): UseDeleteFilteringRule => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.protection.delete.action',
  })

  const [mutateDeleteFilteringRule] = useMutation(DELETE_FILTERING_RULE, {
    onCompleted: ({ errors }): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('error', { filteringRule: name }), { variant: 'error' })
      } else {
        enqueueSnackbar(t('success', { filteringRule: name }), { variant: 'success' })
      }
    },
    refetchQueries: ['filteringRules'],
  })

  const deleteFilteringRule = (id: FilteringRule['id']): void => {
    mutateDeleteFilteringRule({ variables: { id } })
  }

  return {
    deleteFilteringRule,
  }
}

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { ROUTES } from 'routes'
import {
  UserManagementFormContext,
  UserManagementFormContextType,
} from '../../contexts/UserManagementFormContext'
import { FormContainer } from '../../forms/UserPublisherForm/UserAddPublisherForm'

export const UserAddPublisherPage = (): JSX.Element => {
  const { t } = useTranslation('features/user')

  const contextProps: UserManagementFormContextType = useCommonFormContextProps({
    formId: 'publisher-form-add',
  })

  return (
    <UserManagementFormContext.Provider value={contextProps}>
      <UserManagementFormContext.Consumer>
        {(ctxProps: UserManagementFormContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                text={t('form.common.action.sendInvitation')}
              />
            }
            backTo={generatePath(ROUTES.USER.LIST, { tab: 'publishers' })}
            title={t('form.publisher.add.title')}
          />
        )}
      </UserManagementFormContext.Consumer>

      <FormContainer />
    </UserManagementFormContext.Provider>
  )
}

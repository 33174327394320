import { FlexBox } from 'components/Box'
import UserSignLayout from 'images/backgrounds/user-sign-layout.svg'
import { BackgroundBoxProps } from './types'

export const BackgroundBox = ({ children }: BackgroundBoxProps): JSX.Element => (
  <FlexBox
    axis='x'
    sx={{
      backgroundImage: `url("${UserSignLayout}")`,
      backgroundSize: 'cover',
      height: '100vh',
    }}
  >
    {children}
  </FlexBox>
)

import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { AddYieldbirdSchema } from '../../schemas/addSchema'
import { useOnCompleted } from '../useOnCompleted'
import { toDTO } from './api/mapper'
import { CREATE_YIELDBIRD_WORKSPACE_BIDDER } from './api/mutation'
import { UseCreateYieldbirdWorkspaceBidder } from './types'

export const useCreateYieldbirdWorkspaceBidder = (): UseCreateYieldbirdWorkspaceBidder => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { onCompleted } = useOnCompleted()
  const [errors, setErrors] = useState<FieldErrors<AddYieldbirdSchema>>({})

  const [mutate] = useMutation(CREATE_YIELDBIRD_WORKSPACE_BIDDER)

  const createYieldbird = async (data: AddYieldbirdSchema): Promise<void> => {
    const variables = { input: toDTO({ ...data, workspaceId }, ability) }
    const { data: response } = await mutate({ variables })

    if (response) {
      const { bidderCode } = data

      setErrors(onCompleted(bidderCode, response))
    }
  }

  return { createYieldbird, yieldbirdErrors: errors }
}

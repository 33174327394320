import { Link } from 'components/Link'
import { TextLinkProps } from './types'

export const TextLink = ({ link }: { link: TextLinkProps }): JSX.Element => (
  <>
    <Link
      newTab
      sx={{ width: 'fit-content' }}
      to={link.url}
    >
      {link.title}
    </Link>
  </>
)

import { RouteObject } from 'react-router-dom'

import { SupplyChainAdd } from 'features/globalSetup/pages/SupplyChainAdd'
import { SupplyChainEdit } from 'features/globalSetup/pages/SupplyChainEdit'
import { SupplyChainList } from 'features/globalSetup/pages/SupplyChainList'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes/routePaths'

export const supplyChainRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-supplyChain-list'
        page={<SupplyChainList />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.SCHAIN.LIST,
  },
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-supplyChain-add'
        page={<SupplyChainAdd />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.SCHAIN.ADD,
  },
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-supplyChain-edit'
        page={<SupplyChainEdit />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.SCHAIN.EDIT,
  },
]

import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import { Select, SelectAutocomplete } from 'components/Form'
import Loading from 'components/Loading'
import { userWorkspaceRoleOptions } from 'features/user'
import { useGetWorkspaceOptions } from '../hooks/useGetWorkspaceOptions'
import { UserWorkspaceForForm } from '../types'
import { NextFormContext } from './contexts/NextFormContext'
import { UserWorkspacesContext } from './contexts/UserWorkspacesContext'
import { CommonSchema } from './schemas'

export const AddForm = (): JSX.Element => {
  const { control, reset } = useFormContext<CommonSchema>()
  const { userWorkspaces } = useContext(UserWorkspacesContext)
  const { setShowNextInputs } = useContext(NextFormContext)
  const { loading, workspaceOptions } = useGetWorkspaceOptions()

  if (loading) {
    return <Loading />
  }

  return (
    <Stack
      direction='row'
      gap={2}
    >
      <SelectAutocomplete
        control={control}
        disabledOptions={userWorkspaces.map(({ workspaceId }: UserWorkspaceForForm) => workspaceId)}
        loading={loading}
        name='workspaceId'
        options={workspaceOptions}
      />

      <Select
        control={control}
        name='role'
        options={userWorkspaceRoleOptions()}
      />

      <FlexBox axis='x'>
        <>
          <IconButton type='submit'>
            <SaveIcon />
          </IconButton>

          <IconButton
            onClick={(): void => {
              reset()
              setShowNextInputs(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      </FlexBox>
    </Stack>
  )
}

import { MenuBook } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Link } from 'components/Link'
import { DocumentationLinkProps } from './types'

export const DocumentationLink = ({ url }: DocumentationLinkProps): JSX.Element => {
  const { t } = useTranslation('components')

  return (
    <Link
      newTab
      to={url}
    >
      <Stack
        alignItems='center'
        direction='row'
        gap={1}
      >
        <MenuBook />
        {t('link.documentation.title')}
      </Stack>
    </Link>
  )
}

import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client'

import { authLink } from './authLink'
import defaultOptions from './defaultOptions'
import { errorLink } from './errorLink'
import { uploadLink } from './uploadLink'

export let client: ApolloClient<unknown> // eslint-disable-line react-refresh/only-export-components

export const GraphQLProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  client = new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions,
    link: from([errorLink(), authLink.concat(uploadLink)]),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

import { Editor as CodeEditor } from '@monaco-editor/react'
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { Controller, ControllerFieldState, FieldPath, FieldValues } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import Loading from 'components/Loading'
import Tooltip from 'components/Tooltip'
import { GLOBAL_BORDER_RADIUS } from 'providers/material-ui/theme/constants'
import { CodeFieldProps } from './types'

export const CodeField = <T extends FieldValues>({
  control,
  disabled,
  language,
  name,
  title,
  tooltip: tooltipProps,
}: CodeFieldProps<T>): JSX.Element => {
  const setCustomStyles = (theme: Theme, error: ControllerFieldState['error']): SxProps<Theme> => ({
    border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[400]}`,
    borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
    pb: 1,
  })

  const Header = (): JSX.Element => (
    <FlexBox axis='x'>
      <Typography sx={{ m: 1 }}>{title}</Typography>

      {tooltipProps && <Tooltip {...tooltipProps} />}
    </FlexBox>
  )

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
        <FormControl error={Boolean(error)}>
          <Box
            id={name}
            sx={(theme: Theme) => ({ ...setCustomStyles(theme, error) })}
          >
            <Header />

            <Divider />

            <CodeEditor
              height={300}
              language={language}
              loading={<Loading />}
              onChange={onChange}
              options={{
                minimap: { enabled: false },
                readOnly: disabled,
                renderLineHighlightOnlyWhenFocus: true,
                scrollbar: { alwaysConsumeMouseWheel: false },
                scrollBeyondLastLine: false,
              }}
              value={value}
            />
          </Box>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

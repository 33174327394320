import { ApolloLink, FetchResult, fromPromise, Observable, ServerError } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'

import { refreshToken, RefreshTokenResult, reset } from 'features/user'
import { store } from 'providers/redux'
import { browserRelativeRedirect } from 'providers/router/utils'
import { ROUTES } from 'routes'
import { GRAPHQL_ERROR_CODE } from './constants'
import {
  enqueueSnackbarForCodeError,
  enqueueSnackbarForGeneralError,
  redirectToPageNotFound,
} from './utils'

const switchGraphQLErrors = (
  graphQLError: GraphQLError,
  errorResponse: ErrorResponse,
): Observable<FetchResult> | void => {
  const { forward, operation } = errorResponse

  const code: unknown = graphQLError.extensions.code

  switch (code) {
    case GRAPHQL_ERROR_CODE.FORBIDDEN: {
      enqueueSnackbarForCodeError(code)
      redirectToPageNotFound()
      break
    }

    case GRAPHQL_ERROR_CODE.INTERNAL_SERVER_ERROR: {
      enqueueSnackbarForCodeError(code)
      store.dispatch(reset())
      browserRelativeRedirect(ROUTES.AUTH.LOGIN)
      break
    }

    case GRAPHQL_ERROR_CODE.INVALID_TOKEN: {
      enqueueSnackbarForCodeError(code)
      break
    }

    case GRAPHQL_ERROR_CODE.NOT_FOUND: {
      enqueueSnackbarForCodeError(code)
      redirectToPageNotFound()
      break
    }

    case GRAPHQL_ERROR_CODE.UNAUTHORIZED: {
      // Apollo doesn't expose `ObservableLike` type for `flatMap` return
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return fromPromise(refreshToken()).flatMap((result: RefreshTokenResult): any => {
        if (result) {
          const { accessToken } = result

          if (accessToken) {
            operation.setContext(({ headers }: { headers: Headers }) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
              },
            }))
          }

          return forward(operation)
        }
      })
    }

    default:
      enqueueSnackbarForGeneralError()
  }
}

export const errorLink = (): ApolloLink =>
  onError(({ forward, graphQLErrors, networkError, operation }): Observable<FetchResult> | void => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions) {
          return switchGraphQLErrors(err, { forward, operation })
        } else {
          graphQLErrors.forEach((): void => enqueueSnackbarForGeneralError())
        }
      }
    }

    if (networkError) {
      const message = t('error.networkError', { ns: 'providers/graphql' })
      const status = (networkError as ServerError).statusCode ?? ''
      const code = `(NET_ERR_${status})`

      enqueueSnackbar(`${message} ${code}`, { variant: 'error' })
    }
  })

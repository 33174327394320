import { Button } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { BoxForm, TextField } from 'components/Form'
import { PasswordIcon } from 'features/user/components/PasswordIcon'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { Schema } from './schema'

export const Form = () => {
  const { t } = useTranslation('features/user')
  const {
    control,
    formState: { isValid },
  } = useFormContext<Schema>()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const nameWidth: number = inputWidth['sm']

  return (
    <>
      <BoxForm>
        <FlexBox
          axis='x'
          gap={2}
        >
          <TextField
            control={control}
            label={t('completeSetup.form.firstName.label')}
            name='firstName'
            placeholder={t('completeSetup.form.firstName.placeholder')}
            sx={{ maxWidth: nameWidth, minWidth: nameWidth }}
          />

          <TextField
            control={control}
            label={t('completeSetup.form.lastName.label')}
            name='lastName'
            placeholder={t('completeSetup.form.lastName.placeholder')}
            sx={{ maxWidth: nameWidth, minWidth: nameWidth }}
          />
        </FlexBox>

        <TextField
          control={control}
          fullWidth
          InputProps={{ endAdornment: <PasswordIcon {...{ setShowPassword, showPassword }} /> }}
          label={t('completeSetup.form.password.label')}
          name='password'
          type={showPassword ? 'text' : 'password'}
        />

        <TextField
          control={control}
          fullWidth
          InputProps={{ endAdornment: <PasswordIcon {...{ setShowPassword, showPassword }} /> }}
          label={t('completeSetup.form.passwordConfirmation.label')}
          name='passwordConfirmation'
          type={showPassword ? 'text' : 'password'}
        />
      </BoxForm>

      <Button
        data-cy='button-submit-complete-setup-form'
        disabled={!isValid}
        sx={{ mt: 2 }}
        type='submit'
        variant='contained'
      >
        {t('completeSetup.form.button')}
      </Button>
    </>
  )
}

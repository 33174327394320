import _ from 'lodash'

import { AdUnitBidderSlice } from './hooks/useGetAdUnitBidders'
import { AdUnitBiddersAttributes } from './types'

export const adUnitBiddersToAttributes = (
  adUnitBidders: AdUnitBidderSlice[],
): AdUnitBiddersAttributes =>
  _.chain(adUnitBidders)
    .keyBy('id')
    .mapValues((adUnitBidder: AdUnitBidderSlice) => {
      const { csEnabled, s2sEnabled } = adUnitBidder

      return { csEnabled, s2sEnabled }
    })
    .value()

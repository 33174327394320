import Across33 from 'images/logos/workspaceBidder/33Across.svg?react'
import Adagio from 'images/logos/workspaceBidder/Adagio.svg?react'
import Adbite from 'images/logos/workspaceBidder/Adbite.svg?react'
import AdForm from 'images/logos/workspaceBidder/AdForm.svg?react'
import AdFusion from 'images/logos/workspaceBidder/AdFusion.svg?react'
import AdKernel from 'images/logos/workspaceBidder/AdKernel.svg?react'
import AdMixer from 'images/logos/workspaceBidder/AdMixer.svg?react'
import Adnuntius from 'images/logos/workspaceBidder/Adnuntius.svg?react'
import AdPone from 'images/logos/workspaceBidder/AdPone.svg?react'
import Adquery from 'images/logos/workspaceBidder/Adquery.svg?react'
import Adrino from 'images/logos/workspaceBidder/Adrino.svg?react'
import AdYouLike from 'images/logos/workspaceBidder/AdYouLike.svg?react'
import Amx from 'images/logos/workspaceBidder/AMX RTB.svg?react'
import AOL from 'images/logos/workspaceBidder/AOL.svg?react'
import AppNexus from 'images/logos/workspaceBidder/AppNexus.svg?react'
import Beop from 'images/logos/workspaceBidder/Beop.svg?react'
import Bizzclick from 'images/logos/workspaceBidder/Bizz click.svg?react'
import Brightcom from 'images/logos/workspaceBidder/Brightcom.svg?react'
import Businessclick from 'images/logos/workspaceBidder/Businessclick.svg?react'
import Caroda from 'images/logos/workspaceBidder/Caroda.svg?react'
import Clickonometrics from 'images/logos/workspaceBidder/Clickonometrics.svg?react'
import Colossusssp from 'images/logos/workspaceBidder/Colossusssp.svg?react'
import ConnectAd from 'images/logos/workspaceBidder/ConnectAd.svg?react'
import Conversant from 'images/logos/workspaceBidder/Conversant.svg?react'
import Criteo from 'images/logos/workspaceBidder/Criteo.svg?react'
import DistrictM from 'images/logos/workspaceBidder/DistrictM.svg?react'
import EmxDigital from 'images/logos/workspaceBidder/EMX Digital.svg?react'
import Etarget from 'images/logos/workspaceBidder/Etarget.svg?react'
import Grid from 'images/logos/workspaceBidder/Grid.svg?react'
import GumGum from 'images/logos/workspaceBidder/GumGum.svg?react'
import Imds from 'images/logos/workspaceBidder/iMedia Digital Services.svg?react'
import Improvedigital from 'images/logos/workspaceBidder/Improve Digital.svg?react'
import IncrementX from 'images/logos/workspaceBidder/IncrementX.svg?react'
import IX from 'images/logos/workspaceBidder/Index Exchange.svg?react'
import Insticator from 'images/logos/workspaceBidder/Insticator.svg?react'
import Invibes from 'images/logos/workspaceBidder/Invibes.svg?react'
import JustPremium from 'images/logos/workspaceBidder/JustPremium.svg?react'
import Livewrapped from 'images/logos/workspaceBidder/Livewrapped.svg?react'
import Luponmedia from 'images/logos/workspaceBidder/Lupon Media.svg?react'
import Magnite from 'images/logos/workspaceBidder/Magnite.svg?react'
import Mgid from 'images/logos/workspaceBidder/Mgid.svg?react'
import Mytarget from 'images/logos/workspaceBidder/Mytarget.svg?react'
import Nativo from 'images/logos/workspaceBidder/Nativo.svg?react'
import NextMillennium from 'images/logos/workspaceBidder/NextMillennium.svg?react'
import Nexx360 from 'images/logos/workspaceBidder/Nexx360.svg?react'
import Nobid from 'images/logos/workspaceBidder/Nobid.svg?react'
import Ogury from 'images/logos/workspaceBidder/Ogury.svg?react'
import Onetag from 'images/logos/workspaceBidder/Onetag.svg?react'
import OpenX from 'images/logos/workspaceBidder/OpenX.svg?react'
import Optidigital from 'images/logos/workspaceBidder/Optidigital.svg?react'
import OTM from 'images/logos/workspaceBidder/OTM.svg?react'
import Outbrain from 'images/logos/workspaceBidder/Outbrain.svg?react'
import Pubmatic from 'images/logos/workspaceBidder/Pubmatic.svg?react'
import PulsePoint from 'images/logos/workspaceBidder/PulsePoint.svg?react'
import Quantcast from 'images/logos/workspaceBidder/Quantcast.svg?react'
import R2B2 from 'images/logos/workspaceBidder/R2B2.svg?react'
import RhythmOne from 'images/logos/workspaceBidder/RhythmOne.svg?react'
import Richaudience from 'images/logos/workspaceBidder/Richaudience.svg?react'
import Rise from 'images/logos/workspaceBidder/Rise.svg?react'
import Rtbhouse from 'images/logos/workspaceBidder/RTB House.svg?react'
import Rubicon from 'images/logos/workspaceBidder/Rubicon.svg?react'
import Seedtag from 'images/logos/workspaceBidder/Seedtag.svg?react'
import Sharethrough from 'images/logos/workspaceBidder/Sharethrough.svg?react'
import Smart from 'images/logos/workspaceBidder/Smart.svg?react'
import SmartyAds from 'images/logos/workspaceBidder/SmartyAds.svg?react'
import Sonobi from 'images/logos/workspaceBidder/Sonobi.svg?react'
import Sovrn from 'images/logos/workspaceBidder/Sovrn.svg?react'
import Spotx from 'images/logos/workspaceBidder/Spotx.svg?react'
import Tappx from 'images/logos/workspaceBidder/Tappx.svg?react'
import Teads from 'images/logos/workspaceBidder/Teads.svg?react'
import TripleLift from 'images/logos/workspaceBidder/TripleLift.svg?react'
import Underdogmedia from 'images/logos/workspaceBidder/Underdog Media.svg?react'
import Unruly from 'images/logos/workspaceBidder/Unruly.svg?react'
import Verizonmedia from 'images/logos/workspaceBidder/Verizon media.svg?react'
import Vidoomy from 'images/logos/workspaceBidder/Vidoomy.svg?react'
import Widespace from 'images/logos/workspaceBidder/Widespace.svg?react'
import Xandr from 'images/logos/workspaceBidder/Xandr.svg?react'
import Yahoossp from 'images/logos/workspaceBidder/Yahoo SSP.svg?react'
import Yieldlab from 'images/logos/workspaceBidder/Yieldlab.svg?react'
import Yieldmo from 'images/logos/workspaceBidder/Yieldmo.svg?react'
import Yoc from 'images/logos/workspaceBidder/Yoc.svg?react'
import { Logos } from 'types/logos'

export const bidderLogos: Logos = {
  '33Across': Across33,
  Adagio,
  Adbite,
  AdForm,
  AdFusion,
  AdKernel,
  AdMixer,
  Adnuntius,
  AdPone,
  Adquery,
  Adrino,
  AdYouLike,
  'AMX RTB': Amx,
  AOL,
  AppNexus,
  Beop,
  'Bizz click': Bizzclick,
  Brightcom,
  Businessclick,
  Caroda,
  Clickonometrics,
  Colossusssp,
  ConnectAd,
  Conversant,
  Criteo,
  DistrictM,
  'EMX Digital': EmxDigital,
  Etarget,
  Grid,
  GumGum,
  'iMedia Digital Services': Imds,
  'Improve Digital': Improvedigital,
  IncrementX,
  'Index Exchange': IX,
  Insticator,
  Invibes,
  JustPremium,
  Livewrapped,
  'Lupon Media': Luponmedia,
  Magnite,
  Mgid,
  Mytarget,
  Nativo,
  NextMillennium,
  Nexx360,
  Nobid,
  Ogury,
  Onetag,
  OpenX,
  Optidigital,
  OTM,
  Outbrain,
  Pubmatic,
  PulsePoint,
  Quantcast,
  R2B2,
  RhythmOne,
  Richaudience,
  Rise,
  'RTB House': Rtbhouse,
  Rubicon,
  Seedtag,
  Sharethrough,
  Smart,
  SmartyAds,
  Sonobi,
  Sovrn,
  Spotx,
  Tappx,
  Teads,
  TripleLift,
  'Underdog Media': Underdogmedia,
  Unruly,
  'Verizon media': Verizonmedia,
  Vidoomy,
  Widespace,
  Xandr,
  'Yahoo SSP': Yahoossp,
  Yieldlab,
  Yieldmo,
  Yoc,
}

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { EditForm } from './EditForm'
import { useUpdatePublisherWorkspaceRole } from './hooks/useUpdatePublisherWorkspaceRole'
import { commonSchema, CommonSchema, editSchemaDefault } from './schemas'
import { EditFormContainerProps } from './types'

export const EditFormContainer = ({ id, userWorkspace }: EditFormContainerProps): JSX.Element => {
  const {
    errors: apiErrors,
    loading,
    updatePublisherWorkspaceRole,
  } = useUpdatePublisherWorkspaceRole()
  const formId = `${id}-${userWorkspace.userId}`

  return (
    <Form<CommonSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={updatePublisherWorkspaceRole}
      schema={commonSchema}
      schemaDefaults={editSchemaDefault(userWorkspace)}
    >
      {() => (loading ? <Loading /> : <EditForm />)}
    </Form>
  )
}

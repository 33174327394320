import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { DomainSupplyChainDTO } from 'features/globalSetup/api/types/domainSupplyChainDTO'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { DomainSupplyChainSortBy } from '../types'
import { DomainSupplyChainSortByDTO } from './types'

type From = DomainSupplyChainDTO
type To = DomainSupplyChain

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((domainSupplyChain: From): To => {
    const { customDomainSid, updatedAt } = domainSupplyChain

    return {
      ...domainSupplyChain,
      customDomainSid: customDomainSid || undefined,
      updatedAt: new Date(updatedAt),
    }
  })

export const sortToDTO = (
  sort: SortInput<DomainSupplyChainSortBy>,
): SortInput<DomainSupplyChainSortByDTO> => {
  const { by } = sort

  if (by === 'domain.name') {
    return { ...sort, by: 'domainName' }
  } else {
    return { ...sort, by }
  }
}

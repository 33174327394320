import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitForProductTableDTO } from './types'

export const AD_UNIT_FOR_PRODUCT_TABLE_FRAGMENT: TypedDocumentNode<AdUnitForProductTableDTO> = gql`
  fragment AdUnitForProductTableFragment on AdUnit {
    divId
    id
    name
    path
    prebidStackEnabled
    priceGeniusEnabled
    refresherEnabled
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
  }
`

export const clean = (str: string): string => str.toLowerCase().trim()

export const withoutOuterQuotes = (value: string): string => {
  const quote: string = '"'

  if (value.charAt(0) === quote) {
    value = value.slice(1)
  }
  if (value.charAt(value.length - 1) === quote) {
    value = value.slice(0, -1)
  }

  return value
}

export const isNumericString = (value: string): boolean => {
  return !isNaN(value as unknown as number)
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceUserDTO } from '../types/workspaceUser'

export const WORKSPACE_USER_FRAGMENT: TypedDocumentNode<WorkspaceUserDTO> = gql`
  fragment WorkspaceUserFragment on User {
    currentWorkspaceRole
    email
    id
  }
`

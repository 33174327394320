import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SIGN_IN } from 'features/user/api/mutations/signIn'
import { SignInVars } from 'features/user/api/types/signIn'
import { mapGraphQLErrors } from 'providers/graphql'
import { useAuthenticate } from '../useAuthenticate'
import { UseSignIn } from './types'

export const useSignIn = (): UseSignIn => {
  const { t } = useTranslation('features/user')
  const { authenticate } = useAuthenticate()
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<SignInVars>>({})

  const [mutateSignIn, { loading }] = useMutation(SIGN_IN, {
    onCompleted: ({ signIn: { accessToken, errors, refreshToken, user } }) => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
      } else if (accessToken && refreshToken && user) {
        setErrors({})
        authenticate({ accessToken, refreshToken, user })
        enqueueSnackbar(t('login.success'), { variant: 'success' })
      }
    },
  })

  return {
    errors,
    loading,
    signIn: variables => mutateSignIn({ variables }),
  }
}

import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Link } from 'components/Link'
import { UserSignLayoutPage } from 'features/layout/pages/UserSignLayoutPage'
import { BoxHeader } from 'features/user/components/BoxHeader'
import { UserLoginForm } from 'features/user/forms/UserLoginForm'
import { URL_CONTACT_US } from './constants'

export const UserLoginPage = (): JSX.Element => {
  const { t } = useTranslation('features/user')

  return (
    <UserSignLayoutPage>
      <BoxHeader
        description={
          <Stack
            direction='row'
            gap={1}
          >
            <Typography>{t('login.description')}</Typography>

            <Link
              newTab
              to={URL_CONTACT_US}
            >
              {t('login.contactUs')}
            </Link>
          </Stack>
        }
        title={t('login.title')}
      />

      <UserLoginForm />
    </UserSignLayoutPage>
  )
}

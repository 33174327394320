import { gql, TypedDocumentNode } from '@apollo/client'

import { PageForAdUnitTransferListDTO } from '../types/pageForAdUnitTransferListDTO'

export const PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT: TypedDocumentNode<PageForAdUnitTransferListDTO> = gql`
  fragment PageForAdUnitTransferList on Page {
    id
    name
    path
  }
`

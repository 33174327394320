import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import ErrorPage from '../ErrorPage'
import PageNotFound from '../PageNotFound'

export const RouterErrorPage = (): JSX.Element => {
  const error: unknown = useRouteError()
  const { t } = useTranslation('common')

  if (isRouteErrorResponse(error)) {
    const { status, statusText } = error

    if (status === 404) {
      return <PageNotFound />
    } else {
      return <ErrorPage cause={`${status}: ${statusText}`} />
    }
  } else {
    return <ErrorPage cause={t('form.error.unknown')} />
  }
}

import { Box, Button, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DocumentationLink } from 'components/Link'
import { Schema } from 'features/performance/forms/schema'
import { AdminOptionFormHeaderProps } from './types'

export const AdminOptionFormHeader = ({
  documentationUrl,
  loading,
}: AdminOptionFormHeaderProps): JSX.Element => {
  const { t } = useTranslation('features/performance')
  const { t: tCommon } = useTranslation('common')
  const {
    formState: { isDirty },
  } = useFormContext<Schema>()

  return (
    <Box sx={{ mb: 2 }}>
      <Stack
        direction='row'
        justifyContent='space-between'
      >
        <Stack
          alignItems='center'
          direction='row'
        >
          <Typography sx={{ mr: 2 }}>{t('form.header')}</Typography>
          <DocumentationLink url={documentationUrl} />
        </Stack>

        <Button
          color='primary'
          disabled={!isDirty || loading}
          type='submit'
          variant='contained'
        >
          {tCommon('form.action.save')}
        </Button>
      </Stack>
    </Box>
  )
}

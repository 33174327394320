import { gql, TypedDocumentNode } from '@apollo/client'

import { UserSliceDTO } from 'features/user/forms/UserEmployeeForm/types'

export const EMPLOYEE_FRAGMENT: TypedDocumentNode<UserSliceDTO> = gql`
  fragment EmployeeFragment on User {
    id
    email
    role
  }
`

import { z } from 'zod'

export const adUnitAmazonsAttributes = z.record(z.string(), z.boolean())

export const schema = z.object({
  adUnitAmazonsAttributes,
  enabled: z.boolean(),
  id: z.string().optional(),
})

export type Schema = z.infer<typeof schema>
export type AdUnitAmazons = z.infer<typeof adUnitAmazonsAttributes>

export const schemaDefaults = (): Schema => {
  return {
    adUnitAmazonsAttributes: {},
    enabled: false,
    id: undefined,
  }
}

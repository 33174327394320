import _ from 'lodash'

import { AdUnitBidderSlice } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/hooks/UseGetAdUnitBidders'
import { ParamsKind } from 'features/globalSetup/pages/Prebid/Bidders/types'
import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'

export const getParamTemplate = ({
  adUnitBidders,
  id,
  kind,
  paramName,
}: {
  adUnitBidders: AdUnitBidderSlice[]
  id: AdUnitBidderSlice['id']
  kind: ParamsKind
  paramName: AdUnitParameter['name']
}): AdUnitParameter | undefined => {
  const adUnitBidder: AdUnitBidderSlice | undefined = adUnitBidders.find(
    (adUnitBidder: AdUnitBidderSlice): boolean => adUnitBidder.id === id,
  )
  if (adUnitBidder === undefined) return undefined

  return _.get(adUnitBidder[`${kind}Params`], paramName)
}

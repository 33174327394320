import { useTranslation } from 'react-i18next'

import { TooltipProps } from 'components/Tooltip'
import { KNOWLEDGE_BASE_URL } from 'features/globalSetup/forms/Prebid/Defaults/constants'

export const useTooltips = (): Record<string, TooltipProps> => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.consentManagement',
  })
  const { t: tC } = useTranslation('common')

  return {
    gdprTcfEnabled: {
      content: t('gdprTcfEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GDPR_TCF_TIMEOUT_ENABLED,
      },
    },
    gppControlUsNat: {
      content: t('gppControlUsNat.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_CONTROL_US_NAT,
      },
    },
    gppControlUsStates: {
      content: t('gppControlUsStates.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_CONTROL_US_STATES,
      },
    },
    gppEnabled: {
      content: t('gppEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_TIMEOUT_ENABLED,
      },
    },
    usPrivacyCcpaEnabled: {
      content: t('usPrivacyCcpaEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.US_PRIVACY_CCPA_TIMEOUT_ENABLED,
      },
    },
  } as const
}

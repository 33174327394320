import { gql, TypedDocumentNode } from '@apollo/client'

import { PageDTO } from 'features/inventory/api/types/pageDTO'
import { AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/api/fragment'

export const PAGE_UPDATE_FRAGMENT: TypedDocumentNode<PageDTO> = gql`
  ${AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT}

  fragment PageUpdateFragment on Page {
    adUnits {
      ...AdUnitForPageTransferList
    }
    id
    matchType
    name
    path
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainBidderDTO } from 'features/globalSetup/api/types/domainBidderDTO'

export const DOMAIN_BIDDER_FOR_SELECT_FRAGMENT: TypedDocumentNode<DomainBidderDTO> = gql`
  fragment DomainBidderForSelectFragment on DomainBidder {
    id
    domain {
      id
      name
    }
  }
`

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { PublisherForTable } from '../../../types'
import { PublisherForTableDTO } from './types'

export const publisherForTableFromDTO = (from: PublisherForTableDTO): PublisherForTable =>
  mapDTO<PublisherForTableDTO, PublisherForTable>({ from }).transform(dto => {
    const { email, firstName, id: userId, lastName, status, updatedAt, userWorkspaces } = dto

    return {
      email,
      id: userId,
      role: null,
      status,
      subRows: userWorkspaces?.map(({ id: workspaceId, name, role }) => ({
        id: workspaceId,
        role,
        userWorkspaces: name,
      })),
      updatedAt: new Date(updatedAt),
      username: `${firstName} ${lastName}`,
      userWorkspaces: userWorkspaces?.length || 0,
    }
  })

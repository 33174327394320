import { generatePath, Navigate, useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  SupplyChainContext,
  SupplyChainContextType,
} from 'features/globalSetup/contexts/SupplyChainContext'
import { useGetSupplyChain } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/hooks/useGetSupplyChain'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { SupplyChainEditParams, SupplyChainEditTabs, useTabs } from './hooks/useTabs'

export const SupplyChainEdit = () => {
  const {
    id: urlSupplyChainId,
    tab: urlTab,
    workspaceId: urlWorkspaceId,
  } = useParams<SupplyChainEditParams>()
  const tabs: Tab<SupplyChainEditTabs>[] = useTabs()
  const { supplyChain } = useGetSupplyChain({
    id: urlSupplyChainId,
  })
  const ability = useAbility()

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'supply-chain-form-edit',
  })

  if (
    [urlTab, urlSupplyChainId, urlWorkspaceId].some(
      (param?: string): boolean => param === undefined,
    )
  ) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (supplyChain === undefined) {
    return <Loading />
  }

  const { name } = supplyChain

  const contextProps: SupplyChainContextType = {
    supplyChain,
    ...commonContextProps,
  }

  return (
    <SupplyChainContext.Provider value={contextProps}>
      <StickyBox>
        <SupplyChainContext.Consumer>
          {(ctxProps: SupplyChainContextType): JSX.Element => (
            <PageHeader
              actions={
                ability.can('update', 'GlobalSetupFeature-supplyChain') ? (
                  <SaveButton {...ctxProps} />
                ) : undefined
              }
              backTo={generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.LIST, {
                workspaceId: urlWorkspaceId,
              })}
              title={name}
            />
          )}
        </SupplyChainContext.Consumer>

        <Tabs
          path={ROUTES.GLOBAL_SETUP.SCHAIN.EDIT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<SupplyChainEditTabs>(tabs, urlTab, 'general')}
    </SupplyChainContext.Provider>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_PRODUCT_FRAGMENT } from 'features/product/api/fragment'
import { Data } from './types'

export const GET_WORKSPACE_PRODUCTS: TypedDocumentNode<Data> = gql`
  ${WORKSPACE_PRODUCT_FRAGMENT}

  query getWorkspaceProducts {
    workspaceProducts {
      ...WorkspaceProductFragment
    }
  }
`

import { useTranslation } from 'react-i18next'

import { ProductsActionsCell } from 'components/Table'
import { Product } from 'features/product'
import { ROUTES } from 'routes'
import { useDeleteAdUnit } from '../../hooks/useDeleteAdUnit'
import { ActionsCellProps } from './types'

const products: Product[] = [
  'prebidStack',
  'refresher',
  'viewabilityTools',
  'priceGenius',
  'unfilledRecovery',
]

export const ActionsCell = ({ id, path }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { deleteAdUnit, loading } = useDeleteAdUnit({ path })

  return (
    <ProductsActionsCell
      editPath={ROUTES.INVENTORY.AD_UNIT.EDIT}
      elementName={path}
      feature='adUnit'
      i18nResource={t}
      id={id}
      loading={loading}
      onDelete={() => void deleteAdUnit(id)}
      products={products}
    />
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Schema } from '../../../schema'
import { Variables } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform(data => ({
    ..._.omit(data, ['email']),
  }))

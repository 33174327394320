import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAINS_PRODUCT_ENABLED: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateDomainsProductEnabled($input: UpdateDomainsProductEnabledInput!) {
    updateDomainsProductEnabled(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

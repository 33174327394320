import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { Schema } from '../../schema'
import { CONFIRM_RESET_PASSWORD, Data } from './api'
import { UseConfirmResetPassword } from './types'

export const useConfirmResetPassword = (): UseConfirmResetPassword => {
  const navigate = useNavigate()
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutateConfirmResetPassword] = useMutation(CONFIRM_RESET_PASSWORD, {
    onCompleted: ({ confirmResetPassword }: Data): void => {
      setLoading(false)

      if (confirmResetPassword?.errors && confirmResetPassword?.errors.length > 0) {
        setErrors(mapGraphQLErrors(confirmResetPassword.errors))
      } else {
        setErrors({})

        navigate(ROUTES.AUTH.LOGIN)
        enqueueSnackbar(t('confirmResetPassword.form.success'), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
  })

  const confirmResetPassword = (data: Schema) => {
    setLoading(true)

    return mutateConfirmResetPassword({ variables: { input: data } })
  }

  return { confirmResetPassword, errors, loading }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { WORKSPACE_FRAGMENT_FOR_GLOBAL_SETUP_GENERAL } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_FOR_GLOBAL_SETUP_GENERAL = (
  ability: Ability,
): TypedDocumentNode<Data, { input: Variables }> => gql`
  ${WORKSPACE_FRAGMENT_FOR_GLOBAL_SETUP_GENERAL(ability)}
  ${ERROR_FRAGMENT}

  mutation updateWorkspaceForGlobalSetupGeneral($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ...WorkspaceFragmentForGeneralSetupGeneral
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { ProductStatus } from 'features/product'
import { PRODUCT_STATUSES } from 'features/product/types/productStatus'
import { UserData } from './types'

export const appendCommonRules = (userData: UserData): ProductStatus[] => {
  const { isInEmployeeGroup } = userData

  let allowed: ProductStatus[]

  if (isInEmployeeGroup) {
    allowed = PRODUCT_STATUSES.filter((ps: ProductStatus) => ps !== 'inactive')
  } else {
    allowed = ['trial', 'pro', 'proInSetup']
  }

  return allowed
}

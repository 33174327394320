import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceSliceDTO } from './types'

export const DATA_SAMPLING_FRAGMENT: TypedDocumentNode<WorkspaceSliceDTO> = gql`
  fragment dataSamplingFragment on Workspace {
    id
    prebidManagerSampling
  }
`

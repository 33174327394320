import { useEffect, useState } from 'react'
import { Params, useParams } from 'react-router-dom'

import { useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'
import useUpdateCurrentWorkspace from '../useUpdateCurrentWorkspace'
import { UseMatchWorkspaceUrlParam } from './types'

export const useMatchWorkspaceUrlParam = (): UseMatchWorkspaceUrlParam => {
  const {
    user: { currentWorkspace: userCurrentWorkspace, id: userId },
  } = useAuthUser()
  const { workspaceId: urlWorkspaceId } = useParams<Params>()
  const { update } = useUpdateCurrentWorkspace({ userId })
  const [loading, setLoading] = useState<boolean>(true)

  const stopLoading = (): void => setLoading(false)

  useEffect(() => {
    if (urlWorkspaceId === undefined) {
      stopLoading()
      return
    }

    if (userCurrentWorkspace) {
      if (userCurrentWorkspace.id !== urlWorkspaceId) {
        updateUserCurrentWorkspace(urlWorkspaceId)
      } else {
        stopLoading()
      }
    } else {
      updateUserCurrentWorkspace(urlWorkspaceId)
    }
  }, [urlWorkspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateUserCurrentWorkspace = (workspaceId: Workspace['id']) =>
    update(workspaceId).finally((): void => stopLoading())

  return { loading }
}

import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { WorkspacePrebidModuleTable } from '../../components/WorkspacePrebidModuleTable'
import { useColumns } from './hooks/useColumns'

export const UserIdsList = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const columns = useColumns()

  const navigate = useNavigate()

  const AddUserIdButton = (): JSX.Element => (
    <Button
      data-cy='add-user-id-button'
      onClick={() =>
        navigate(generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.ADD, { workspaceId }))
      }
      variant='contained'
    >
      {t('common:actions.addNew')}
    </Button>
  )

  return (
    <Paper>
      <TableHeader
        actions={
          ability.can('create', 'GlobalSetupFeature-userId') ? <AddUserIdButton /> : undefined
        }
        bottomSpacing
        description={t('prebid.modules.userId.list.description')}
        title={t('prebid.modules.userId.list.title')}
      />
      <WorkspacePrebidModuleTable
        columns={columns}
        moduleType='userId'
      />
    </Paper>
  )
}

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceUserContext } from 'features/globalSetup/contexts/WorkspaceUserContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { Form as WorkspaceUserEditForm } from './Form'
import { useUpdateWorkspaceUser } from './hooks/useUpdateWorkspaceUser'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { setTouched, setValid, user } = useContext(WorkspaceUserContext)
  const { formId, loading } = useContext(WorkspaceUserContext)
  const { errors: apiErrors, update } = useUpdateWorkspaceUser()

  if (!user || !user.currentWorkspaceRole) {
    return <Loading />
  }

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault({ user, workspaceId })}
    >
      {() => (loading ? <Loading /> : <WorkspaceUserEditForm />)}
    </Form>
  )
}

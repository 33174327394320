import { z } from 'zod'

import { domainBidderPriorities } from 'features/globalSetup/types/bidderPriority'
import { SchemaDefaultsProps } from './types'

const CONSTANTS = {
  AD_UNIT_BIDDER_PARAM: {
    NAME: z.string(),
    VALUE: z.string().or(z.number()),
  },
  ID: z.string(),
} as const

const domainIds = z.object({
  domainBidderId: CONSTANTS.ID,
  domainId: CONSTANTS.ID,
})

const adUnitBidderParams = z.record(
  CONSTANTS.AD_UNIT_BIDDER_PARAM.NAME,
  CONSTANTS.AD_UNIT_BIDDER_PARAM.VALUE,
)
export type SchemaAdUnitBidderParams = z.infer<typeof adUnitBidderParams>

export const adUnitBidderProps = z.object({
  csDisabledByRequiredParams: z.boolean(),
  csEnabled: z.boolean(),
  csParams: adUnitBidderParams,
  s2sDisabledByRequiredParams: z.boolean(),
  s2sEnabled: z.boolean(),
  s2sParams: adUnitBidderParams,
})
export type SchemaAdUnitBidderProps = z.infer<typeof adUnitBidderProps>

export const schema = z.object({
  adUnitBidders: z.record(CONSTANTS.ID, adUnitBidderProps),
  csEnabled: z.boolean(),
  domainIds: domainIds,
  priority: z.enum(domainBidderPriorities),
  s2sEnabled: z.boolean(),
})
export type Schema = z.infer<typeof schema>

export const defaults = ({ domainBidderId, domainId }: SchemaDefaultsProps): Schema =>
  ({
    adUnitBidders: {},
    csEnabled: false,
    domainIds: {
      domainBidderId: domainBidderId || '',
      domainId: domainId || '',
    },
    priority: 'primary',
    s2sEnabled: false,
  }) as const

import _ from 'lodash'

import { Ability } from 'providers/casl/types/ability'
import { variablesToOmit } from 'providers/graphql/utils'
import { AddSchema } from '../../schemas/addSchema'
import { Variables } from './api/types'

export const mapper = (data: AddSchema, ability: Ability): Variables => {
  const { divId, name, path } = data

  const variables = {
    ...data,
    divId: divId || null,
    name: name || null,
    path: path.toLowerCase(),
  }

  // TODO: include mediaTypes when mutation will be ready
  return _.omit(
    variables,
    variablesToOmit({
      mediaTypes: false,
      unfilledRecoveryAdUnit: ability.can('update', 'AdUnitField-unfilledRecovery'),
    }),
  )
}

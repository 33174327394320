import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { UserManagementFormContext } from 'features/user/contexts/UserManagementFormContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { Schema } from '../../schema'
import { CREATE_PUBLISHER_BY_EMPLOYEE, Data } from './api'
import { mapper } from './mapper'
import { UseCreatePublisherByEmployee } from './types'

export const useCreatePublisherByEmployee = (): UseCreatePublisherByEmployee => {
  const navigate = useNavigate()
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})
  const { setLoading } = useContext(UserManagementFormContext)

  const [mutateCreatePublisherByEmployee] = useMutation(CREATE_PUBLISHER_BY_EMPLOYEE, {
    onCompleted: ({ createPublisherByEmployee: { errors, user } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.publisher.add.error'), { variant: 'error' })
      } else if (user) {
        setErrors({})

        navigate(generatePath(ROUTES.USER.LIST, { tab: 'publishers' }))
        enqueueSnackbar(t('form.publisher.add.success'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const createPublisherByEmployee = (data: Schema) => {
    setLoading(true)

    return mutateCreatePublisherByEmployee({ variables: { input: mapper(data) } })
  }

  return { createPublisherByEmployee, errors }
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Variables } from '../types'
import { VariablesDTO } from './types'

export const toDTO = (from: Variables): VariablesDTO =>
  mapDTO<Variables, VariablesDTO>({ from }).transform(toDTO => {
    const { from, to } = toDTO

    return {
      ...toDTO,
      from: from && from.toISOString(),
      to: to && to.toISOString(),
    }
  })

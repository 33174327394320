import { useTranslation } from 'react-i18next'

import { TooltipProps } from 'components/Tooltip'
import { KNOWLEDGE_BASE_URL } from './constants'

export const useTooltips = (): Record<string, TooltipProps> => {
  const { t } = useTranslation(['features/adUnit', 'common'])

  return {
    adHoldingTooltip: {
      content: t('form.viewabilityTools.basicSettings.adHolding.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: KNOWLEDGE_BASE_URL.AD_HOLDING,
      },
    },

    lazyLoadingTooltip: {
      content: t('form.viewabilityTools.basicSettings.lazyLoading.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: KNOWLEDGE_BASE_URL.LAZY_LOADING,
      },
    },

    scrollSlowingTooltip: {
      content: t('form.viewabilityTools.basicSettings.scrollSlowing.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: KNOWLEDGE_BASE_URL.SCROLL_SLOWING,
      },
    },
  } as const
}

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { NextFormContext } from '../../contexts/NextFormContext'
import { UserWorkspacesContext } from '../../contexts/UserWorkspacesContext'
import { CommonSchema } from '../../schemas'
import { ASSIGN_USER_TO_WORKSPACE, Data } from './api'
import { userWorkspaceFromDTO } from './mapper'
import { UseInvitePublisherToWorkspace, UseInvitePublisherToWorkspaceParams } from './types'

export const useInvitePublisherToWorkspace = ({
  userId,
}: UseInvitePublisherToWorkspaceParams): UseInvitePublisherToWorkspace => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<FieldErrors<CommonSchema>>({})
  const { append } = useContext(UserWorkspacesContext)
  const { setShowNextInputs } = useContext(NextFormContext)

  const [mutateInvitePublisherToWorkspace] = useMutation(ASSIGN_USER_TO_WORKSPACE, {
    onCompleted: ({ invitePublisherToWorkspace: { errors, workspace } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.publisher.edit.error'), { variant: 'error' })
      } else if (workspace) {
        setErrors({})
        enqueueSnackbar(t('form.publisher.edit.success'), {
          variant: 'success',
        })

        const userWorkspace = userWorkspaceFromDTO(workspace, userId)

        if (userWorkspace) {
          append(userWorkspace)
          setShowNextInputs(false)
        }
      }
    },
    onError: (): void => setLoading(false),
  })

  const invitePublisherToWorkspace = (data: CommonSchema) => {
    setLoading(true)

    return mutateInvitePublisherToWorkspace({ variables: { input: data } })
  }

  return { errors, invitePublisherToWorkspace, loading }
}

import { z } from 'zod'

import { WorkspaceProductSlice } from './types'

export const schema = z.object({
  emails: z.array(z.string()),
  id: z.string(),
  performanceReportUrl: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefaults = (workspaceProduct: WorkspaceProductSlice): Schema => {
  const { emails, id, performanceReportUrl } = workspaceProduct

  return {
    emails,
    id,
    performanceReportUrl: performanceReportUrl ?? '',
  }
}

import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Switch, ToggleButtonGroup } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { adUnitViewabilityToolsStatusValues } from 'types/adUnitViewabilityToolsStatus'
import { isSwitchDisabledBasedOnUnfilledRecovery } from '../../utils/unfilledRecovery'
import { Schema } from './schema'
import { useTooltips } from './useTooltips'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { adUnit } = useContext(AddEditAdUnitContext)

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }

  const {
    domain: {
      domainViewabilityTools: {
        adHoldingEnabled,
        lazyLoadingEnabled,
        lazyLoadType,
        scrollSlowingEnabled,
      },
    },
    unfilledRecoveryAdUnit,
  } = adUnit

  const { adHoldingTooltip, lazyLoadingTooltip, scrollSlowingTooltip } = useTooltips()

  const { control, watch } = useFormContext<Schema>()
  const data = watch()
  const { lazyLoading } = data

  const lazyLoadingHint =
    lazyLoadingEnabled && lazyLoadType === 'googlePublisherTagsLazyLoad' && lazyLoading === 'on'
      ? t('form.viewabilityTools.basicSettings.lazyLoading.hint')
      : ''

  const isDisabled: boolean = isSwitchDisabledBasedOnUnfilledRecovery(
    unfilledRecoveryAdUnit,
    'viewabilityTools',
  )

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.viewabilityTools.enabled.description')}
          title={t('form.viewabilityTools.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              disabled={isDisabled}
              name='viewabilityToolsEnabled'
            />
          }
        />
      </Paper>

      <Paper>
        <SectionHeader
          bottomSpacing
          subtitle={t('form.viewabilityTools.basicSettings.subtitle')}
          title={t('form.viewabilityTools.basicSettings.title')}
        />

        <BoxForm>
          <ToggleButtonGroup
            control={control}
            disabled={!adHoldingEnabled}
            label={t('form.viewabilityTools.basicSettings.adHolding.label')}
            name='adHolding'
            options={adUnitViewabilityToolsStatusValues}
            tooltip={adHoldingTooltip}
          />

          <ToggleButtonGroup
            control={control}
            disabled={!scrollSlowingEnabled}
            label={t('form.viewabilityTools.basicSettings.scrollSlowing.label')}
            name='scrollSlowing'
            options={adUnitViewabilityToolsStatusValues}
            tooltip={scrollSlowingTooltip}
          />

          <ToggleButtonGroup
            control={control}
            disabled={!lazyLoadingEnabled}
            hint={lazyLoadingHint}
            label={t('form.viewabilityTools.basicSettings.lazyLoading.label')}
            name='lazyLoading'
            options={adUnitViewabilityToolsStatusValues}
            tooltip={lazyLoadingTooltip}
          />
        </BoxForm>
      </Paper>
    </>
  )
}

import { z } from 'zod'

import { consentManagementSchema } from 'features/globalSetup'
import { domainBidderPriorities } from 'features/globalSetup/types/bidderPriority'
import { adUnitsAttributesSchema } from 'features/inventory/domain/components/ProductAdUnitsTable'
import { SchemaDefaultProps } from './types'
import { domainBiddersToAttributes } from './utils'

const domainPrebidModulesAttributesSchema = z.object({
  domainPrebidModulesAttributes: z.record(z.string(), z.boolean()),
})

export const schema = z
  .object({
    customConsentManagementEnabled: z.boolean(),
    customPrebidTimeoutEnabled: z.boolean(),
    domainAmazonEnabled: z.boolean(),
    domainBiddersAttributes: z.record(
      z.string(),
      z.object({
        csEnabled: z.boolean(),
        priority: z.enum(domainBidderPriorities),
        s2sEnabled: z.boolean(),
      }),
    ),
    domainId: z.string(),
    prebidStackEnabled: z.boolean(),
    prebidTimeout: z.number(),
  })
  .merge(domainPrebidModulesAttributesSchema)
  .merge(adUnitsAttributesSchema)
  .merge(consentManagementSchema)

export type DomainPrebidModulesAttributesSchema = z.infer<
  typeof domainPrebidModulesAttributesSchema
>
export type Schema = z.infer<typeof schema>

export const schemaDefault = ({
  domain: { domainPrebidStack },
  domainBidders,
}: SchemaDefaultProps): Schema => ({
  ...domainPrebidStack,
  adUnitsAttributes: {},
  customPrebidTimeoutEnabled: domainPrebidStack.customPrebidTimeoutEnabled ?? false,
  domainAmazonEnabled: domainPrebidStack.domainAmazonEnabled ?? false,
  domainBiddersAttributes: domainBiddersToAttributes(domainBidders),
  domainPrebidModulesAttributes: {},
})

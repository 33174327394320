import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { closeSnackbar, SnackbarKey } from 'notistack'

export const CloseAction = ({ snackbarId }: { snackbarId: SnackbarKey }) => (
  <IconButton
    color='inherit'
    onClick={(): void => closeSnackbar(snackbarId)}
  >
    <Close fontSize='small' />
  </IconButton>
)

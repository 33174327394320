import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import { ParametersSectionWrapper } from '../../../../components/ParametersSectionWrapper'
import { ParamsFormBox } from '../../../../components/ParamsFormBox'
import { LEARN_MORE } from './constants'
import { ParametersSectionProps } from './types'

export const ParametersSection = ({
  accountType,
  parametersSchema,
}: ParametersSectionProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.analytics.form.parameters',
  })
  const { t: tc } = useTranslation('common')

  return (
    <ParametersSectionWrapper accountType={accountType}>
      <SectionHeader
        bottomSpacing
        subtitle={t('header.subtitle')}
        subtitleEndAdornment={
          <Link
            newTab
            to={LEARN_MORE}
          >
            {tc('learnMore')}
          </Link>
        }
        title={t('header.title')}
      />

      <ParamsFormBox parametersSchema={parametersSchema} />
    </ParametersSectionWrapper>
  )
}

import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitBidderSlice } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/hooks/UseGetAdUnitBidders'
import { AdUnitBidderPerPage, UseErrorsOnPagesInfo, UseErrorsOnPagesInfoProps } from './types'

export const useErrorsOnPagesInfo = ({
  adUnitBidders,
  currentPage,
  currentRowsPerPage,
}: UseErrorsOnPagesInfoProps): UseErrorsOnPagesInfo => {
  const [tempPerPage, setTempPerPage] = useState<AdUnitBidderPerPage[]>([])
  const [allPerPage, setAllPerPage] = useState<AdUnitBidderPerPage[]>([])
  const [pagesWithError, setPagesWithError] = useState<number[]>([])

  const {
    formState,
    formState: { errors },
  } = useFormContext<Schema>()

  useEffect(() => {
    const tempPerPage: AdUnitBidderPerPage[] = adUnitBidders.map(
      (el: AdUnitBidderSlice): AdUnitBidderPerPage => ({
        adUnitBidderId: el.id,
        pageNumber: currentPage,
      }),
    )

    setTempPerPage(tempPerPage)
  }, [adUnitBidders, currentPage])

  useEffect(() => {
    const mergedUniqueAllPerPage = _.uniqBy<AdUnitBidderPerPage>(
      [...allPerPage, ...tempPerPage],
      'adUnitBidderId',
    )

    setAllPerPage(mergedUniqueAllPerPage)
  }, [tempPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      const pagesWithError = _.chain<AdUnitBidderPerPage>(allPerPage)
        .filter((el: AdUnitBidderPerPage): boolean =>
          _.keys(errors.adUnitBidders).includes(el.adUnitBidderId),
        )
        .map((el: AdUnitBidderPerPage): number => el.pageNumber)
        .uniq()
        .value()

      setPagesWithError(pagesWithError)
    }
  }, [formState, allPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAllPerPage([])
  }, [currentRowsPerPage])

  return { pagesWithError }
}

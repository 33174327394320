import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import { z } from 'zod'

import { WorkspaceProduct } from 'features/product/types/workspaceProduct'

const zodDay = z.custom<Dayjs>(value => value instanceof dayjs)

export const schema = z
  .object({
    from: zodDay,
    to: zodDay.optional(),
  })
  .superRefine(({ from: fromInit, to: toInit }, context) => {
    const from = fromInit?.startOf('day')
    const to = toInit?.startOf('day')

    if (to) {
      if (to.isBefore(from)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18next.t('features/product:dialog.status.trial.error'),
          path: ['to'],
        })
      }

      if (from.isAfter(to)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18next.t('features/product:dialog.status.trial.error'),
          path: ['from'],
        })
      }
    }
  })

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({
  from,
  to,
}: Pick<WorkspaceProduct, 'from' | 'to'>): Partial<Schema> => ({
  from: dayjs(from),
  to: to ? dayjs(to) : undefined,
})

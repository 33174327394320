import { useState } from 'react'

import { CommonFormContextProps } from '../types'

type Props = Pick<CommonFormContextProps, 'formId'>

export const useCommonFormContextProps = ({ formId }: Props): CommonFormContextProps => {
  const [loading, setLoading] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(true)

  return {
    formId,
    loading,
    setLoading,
    setTouched,
    setValid,
    touched,
    valid,
  }
}

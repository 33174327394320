import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Table } from '@tanstack/react-table'
import { ReactNode } from 'react'

const AllRowsExpanderCell = <T,>({ table }: { table: Table<T> }): ReactNode => (
  <IconButton
    data-cy='expand-all-icon'
    onClick={table.getToggleAllRowsExpandedHandler()}
    size='small'
  >
    {table.getIsAllRowsExpanded() ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
  </IconButton>
)

export default AllRowsExpanderCell

import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { UserManagementGroup } from './types'
import { UserList } from './UserList'

export const useUserTabs = (): Tab<UserManagementGroup>[] => {
  const { t } = useTranslation('features/user')

  return [
    {
      element: 'publishers',
      label: t('list.publishers.tab'),
      page: <UserList tab='publishers' />,
    },
    {
      element: 'employees',
      label: t('list.employees.tab'),
      page: <UserList tab='employees' />,
    },
  ]
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_SUPPLY_CHAINS: TypedDocumentNode<Data, Variables> = gql`
  query supplyChains(
    $workspaceId: ID!
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: SupplyChainSortInput
  ) {
    supplyChains(
      workspaceId: $workspaceId
      first: $first
      last: $last
      terms: $terms
      sort: $sort
    ) {
      nodes {
        id
        name
        asi
        updatedAt
        defaultSid
      }
      totalCount
    }
  }
`

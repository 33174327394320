import { MediaTypeDTO } from 'features/inventory/ad-unit/api/types/mediaTypeDTO'
import { MediaType } from 'features/inventory/ad-unit/types/mediaType'
import { AdUnitSizeDTO } from 'features/inventory/viewport'
import { adUnitSizeFromDTO } from 'features/inventory/viewport/utils'

type CommonMediaTypeFieldsDTO = Pick<
  MediaTypeDTO,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'outstreamEnabled' | 'playerSize'
>

type CommonMediaTypeFields = Pick<
  MediaType,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'outstreamEnabled' | 'playerSize'
>

export const commonMediaTypeFieldsFromDTO = (
  from: CommonMediaTypeFieldsDTO,
): CommonMediaTypeFields => {
  const { bannerSizes, playerSize } = from

  return {
    ...from,
    bannerSizes: bannerSizes.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
    playerSize: playerSize.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
  }
}

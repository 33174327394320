import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FRAGMENT } from 'features/inventory/api/fragments/domain'
import { Ability } from 'providers/casl/types/ability'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { permitFields } from 'providers/graphql/utils'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN = (
  ability: Ability,
): TypedDocumentNode<Data, { input: Variables }> => gql`
  ${DOMAIN_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        ${permitFields<Variables>({
          overriddenByYieldbird: ability.can('read', 'DomainField-overriddenByYieldbird'),
        })}
        ...DomainFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ProtectionContext } from 'features/globalSetup/contexts/ProtectionContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { AddSchema } from '../../schemas/addSchema'
import { CREATE_FILTERING_RULE, Data, filteringRuleFromDTO } from './api'
import { UseCreateFilteringRule } from './types'

export const useCreateFilteringRule = (): UseCreateFilteringRule => {
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.protection.form.actions.create',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(ProtectionContext)
  const [errors, setErrors] = useState<FieldErrors<AddSchema>>({})

  const [mutate] = useMutation(CREATE_FILTERING_RULE, {
    onCompleted: ({ createFilteringRule }: Data): void => {
      setLoading(false)

      if (createFilteringRule) {
        const { errors, filteringRule } = createFilteringRule

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else if (filteringRule) {
          setErrors({})
          enqueueSnackbar(
            t('success', {
              filteringRuleName: filteringRuleFromDTO(filteringRule).name,
            }),
            { variant: 'success' },
          )
          navigate(generatePath(ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.LIST, { workspaceId }))
        }
      }
    },
    onError: (): void => setLoading(false),
  })

  const create = (data: AddSchema): void => {
    setLoading(true)

    mutate({
      variables: { input: data.applicableDomains === 'all' ? _.omit(data, ['domainIds']) : data },
    })
  }

  return { create, errors }
}

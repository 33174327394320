import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { ProtectionContext } from 'features/globalSetup/contexts/ProtectionContext'
import { CommonForm } from './CommonForm'
import { useCreateFilteringRule } from './hooks/useCreateFilteringRule'
import { AddSchema, addSchemaDefault } from './schemas/addSchema'
import { commonSchema } from './schemas/commonSchema'

export const AddFilteringRuleForm = () => {
  const { create, errors: apiErrors } = useCreateFilteringRule()
  const { formId, loading, setTouched, setValid } = useContext(ProtectionContext)

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => (loading ? <Loading /> : <CommonForm />)}
    </Form>
  )
}

import { RouteObject } from 'react-router-dom'

import { FinancePage } from 'features/finance/pages/FinancePage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const financeRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='FinancePage'
        page={<FinancePage />}
      />
    ),
    path: ROUTES.FINANCE,
  },
]

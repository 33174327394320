import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const REMOVE_CUSTOMER_FROM_WORKSPACE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation RemovePublisherFromWorkspace($input: RemovePublisherFromWorkspaceInput!) {
    removePublisherFromWorkspace(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

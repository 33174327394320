import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { useGetBidders } from './hooks/useGetBidders'
import { BiddersForTable, BiddersTableProps } from './types'
import { useColumns } from './useColumns'

export const BiddersTable = ({ filters }: BiddersTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const columns = useColumns()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })

  const { bidders, count, loading } = useGetBidders({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const handleRowClick = ({ id }: BiddersForTable): To =>
    generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
      id,
      tab: 'general',
      workspaceId,
    })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<BiddersForTable>
      columns={columns}
      data={bidders}
      loading={loading}
      onClickRow={handleRowClick}
      pagination={paginationProps}
    />
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const CREATE_SUPPLY_CHAIN: TypedDocumentNode<Data, Variables> = gql`
  mutation createSupplyChain(
    $workspaceId: ID!
    $name: String!
    $defaultSid: String!
    $asi: String!
  ) {
    createSupplyChain(
      input: { workspaceId: $workspaceId, name: $name, defaultSid: $defaultSid, asi: $asi }
    ) {
      supplyChain {
        id
        name
      }
      errors {
        attribute
        message
      }
    }
  }
`

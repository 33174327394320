import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GET_DOMAIN } from 'features/inventory/api/queries/domain'
import { domainFromDTO } from 'features/inventory/mappers/domain'
import { Domain } from 'features/inventory/types/domain'
import { useAbility } from 'providers/casl'
import { UseGetDomain, UseGetDomainProps } from './types'

export const useGetDomain = ({ id }: UseGetDomainProps): UseGetDomain => {
  const ability = useAbility()
  const [domain, setDomain] = useState<Domain>()

  useEffect(() => {
    if (id) {
      queryDomain({
        variables: {
          id,
        },
      })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [queryDomain, { loading }] = useLazyQuery(GET_DOMAIN(ability), {
    onCompleted: ({ node: domain }) => {
      if (domain) {
        setDomain(domainFromDTO(domain))
      }
    },
  })

  return { domain, loading }
}

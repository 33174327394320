import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitForPageTransferListDTO } from './types'

export const AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT: TypedDocumentNode<AdUnitForPageTransferListDTO> = gql`
  fragment AdUnitForPageTransferList on AdUnit {
    id
    path
  }
`

import { List } from '@mui/material'
import React from 'react'

export const RelatedList = ({ children }: React.PropsWithChildren): JSX.Element => (
  <List
    dense
    disablePadding
    sx={{
      listStyleType: 'disc',
      ml: 2,
    }}
  >
    {children}
  </List>
)

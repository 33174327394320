import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectRoot,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { SelectOption, SelectProps } from './types'

export const Select = <Data,>({
  disabled,
  error,
  helperText,
  label,
  labelColor,
  name,
  optional,
  options,
  tooltip: tooltipProps,
  value,
  ...selectRootProps
}: SelectProps<Data>): JSX.Element => {
  const { t } = useTranslation('components')

  const mergedOptions: SelectOption<Data>[] = optional
    ? [
        {
          label: t('select.noneOptionLabel'),
          value: '',
        },
        ...options,
      ]
    : options

  const NoOptions = (): JSX.Element => (
    <MenuItem disabled={true}>
      <Typography>{t('select.noOptionsAvailable')}</Typography>
    </MenuItem>
  )

  return (
    <Stack direction='row'>
      <FormControl
        data-cy={`select-${name}`}
        error={error}
      >
        <InputLabel
          data-cy={`select-label-${name}`}
          disabled={disabled}
        >
          {label}
        </InputLabel>

        <SelectRoot
          {...selectRootProps}
          disabled={disabled}
          id={name}
          label={label}
          name={name}
          sx={theme => ({
            backgroundColor: theme.palette.white.main,
          })}
          value={options.length > 0 ? value : ''}
        >
          {mergedOptions.length ? (
            mergedOptions.map(
              ({ label, logo: svg, value }: SelectOption<Data>): JSX.Element => (
                <MenuItem
                  data-cy={`select-option-${value}`}
                  key={value}
                  value={value}
                >
                  <BoxWithLogo logo={{ size: 'small', svg }}>
                    <Typography
                      data-cy={`select-option-label-${label}`}
                      sx={{ color: labelColor?.({ value: value as Data }) }}
                      variant={selectRootProps.size === 'small' ? 'body2' : 'body1'}
                    >
                      {label}
                    </Typography>
                  </BoxWithLogo>
                </MenuItem>
              ),
            )
          ) : (
            <NoOptions />
          )}
        </SelectRoot>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>

      {tooltipProps && <Tooltip {...tooltipProps} />}
    </Stack>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { UserDTO } from '../types/userDTO'

export const USER_WORKSPACE_FRAGMENT: TypedDocumentNode<UserDTO> = gql`
  fragment UserWorkspaceFragment on User {
    userWorkspaces {
      active
      id
      name
      role
    }
  }
`

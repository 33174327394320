import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceProductDTO } from 'features/product/api/types/workspaceProductDTO'

export const WORKSPACE_PRODUCT_FRAGMENT: TypedDocumentNode<WorkspaceProductDTO> = gql`
  fragment WorkspaceProductFragment on WorkspaceProduct {
    createdAt
    from
    id
    product
    status
    to
    updatedAt
    workspace {
      id
      name
    }
  }
`

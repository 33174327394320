import { useTranslation } from 'react-i18next'

import { SliderMark } from 'components/Form/Slider/types'
import { TRAFFIC_PERCENT_VALUES } from './constants'

export const useTrafficPercentMarks = (): SliderMark[] => {
  const { t } = useTranslation('features/domain')

  return [
    {
      description: {
        color: 'warning',
        from: 0,
        title: t('form.priceGenius.trafficPercent.warning', {
          value: TRAFFIC_PERCENT_VALUES.WARNING,
        }),
      },
      value: TRAFFIC_PERCENT_VALUES.WARNING,
    },
    {
      label: t('form.priceGenius.trafficPercent.default', {
        value: TRAFFIC_PERCENT_VALUES.DEFAULT,
      }),
      value: TRAFFIC_PERCENT_VALUES.DEFAULT,
    },
  ]
}

import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { AnalyticsAdaptersList } from '../../AnalyticsAdapters/AnalyticsAdaptersList'
import { UserIdsList } from '../../UserIds/UserIdsList'
import { VendorSpecificList } from '../../VendorSpecific/VendorSpecificList'

export const useTabs = (): Tab<PrebidModuleType>[] => {
  const { t } = useTranslation('features/globalSetup')

  return [
    {
      element: 'userId',
      label: t('prebid.modules.tab.userIds'),
      page: <UserIdsList />,
    },
    {
      element: 'vendorSpecific',
      label: t('prebid.modules.tab.vendorSpecific'),
      page: <VendorSpecificList />,
    },
    {
      element: 'analytics',
      label: t('prebid.modules.tab.analytics'),
      page: <AnalyticsAdaptersList />,
    },
  ]
}

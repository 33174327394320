import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_UNFILLED_RECOVERY: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateDomainUnfilledRecovery($input: UpdateDomainUnfilledRecoveryInput!) {
    updateDomainUnfilledRecovery(input: $input) {
      domain {
        id
        unfilledRecoveryEnabled
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { WorkspaceProduct } from 'features/product/types/workspaceProduct'
import { updateUser, useAuthUser } from 'features/user'
import { mapGraphQLErrors } from 'providers/graphql'
import { REQUEST_WORKSPACE_PRODUCT } from './api/mutation'
import { Data } from './api/types'
import { UseRequestProduct } from './types'
import { mapDTOAndUpdateWorkspaceProducts } from './utils'

export const useRequestProduct = (workspaceProduct: WorkspaceProduct): UseRequestProduct => {
  const { t } = useTranslation('features/product')
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const {
    user: { workspaceProducts: userWorkspaceProducts },
  } = useAuthUser()

  const { id: workspaceProductId, product, status } = workspaceProduct
  const productName = t(`product.${product}`)
  const plan = status === 'trial' || product === 'unfilledRecovery' ? 'pro' : 'trial'

  const [mutateRequestProduct] = useMutation(REQUEST_WORKSPACE_PRODUCT, {
    onCompleted: ({ requestProduct }: Data): void => {
      if (requestProduct) {
        const { errors, workspaceProduct: apiWorkspaceProduct } = requestProduct

        if (errors && errors.length > 0) {
          mapGraphQLErrors(errors)
          enqueueSnackbar(t('notification.request.error', { product: productName }), {
            variant: 'error',
          })
        } else if (apiWorkspaceProduct && userWorkspaceProducts) {
          dispatch(
            updateUser({
              workspaceProducts: mapDTOAndUpdateWorkspaceProducts(
                apiWorkspaceProduct,
                userWorkspaceProducts,
              ),
            }),
          )
          enqueueSnackbar(t('notification.request.success', { product: productName }), {
            variant: 'success',
          })
        }
      }
    },
    refetchQueries: ['getWorkspaceProducts'],
  })

  const request = () =>
    mutateRequestProduct({
      variables: {
        input: {
          plan,
          workspaceProductId,
        },
      },
    })

  return { request }
}

import { z } from 'zod'

import { Ability } from 'providers/casl'
import { AdUnitForForm } from '../hooks/useGetAdUnit'
import { PageForAdUnitTransferList } from '../hooks/useGetPagesForAdUnitTransferList/types'
import { commonSchema } from './commonSchema'
import { mediaTypesToSchema } from './schemaMappers'

export const editSchema = z
  .object({
    id: z.string(),
    rawId: z.number().optional(),
  })
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (
  {
    divId,
    domain,
    id,
    mediaTypes,
    name,
    outOfPage,
    outOfPageType,
    pages,
    path,
    rawId,
    unfilledRecoveryAdUnit,
  }: AdUnitForForm,
  ability: Ability,
): EditSchema => ({
  divId,
  domainId: domain.id,
  id,
  mediaTypes: mediaTypesToSchema(mediaTypes),
  name,
  outOfPage,
  outOfPageType,
  pageIds: pages
    .map((page: PageForAdUnitTransferList): PageForAdUnitTransferList['value'] => page.value)
    .sort(),
  path,
  rawId: ability.can('read', 'AdUnitField-rawId') ? rawId : undefined,
  unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery')
    ? unfilledRecoveryAdUnit
    : undefined,
})

import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { PrebidModule } from 'features/globalSetup/types/prebidModule'

export const useSnackbar = (prebidModuleName: PrebidModule['name']) => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.common.list.updateEnabled.action',
  })

  const snackbar = (enabled: boolean, variant?: VariantType): void => {
    const tParams = {
      action: enabled ? 'enabled' : 'disabled',
      prebidModuleName,
    }

    if (variant === 'error') {
      enqueueSnackbar(t('error', tParams), { variant })
    } else {
      enqueueSnackbar(t('success', tParams), {
        variant: enabled ? 'success' : 'warning',
      })
    }
  }

  return { snackbar }
}

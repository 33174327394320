import { useEffect, useState } from 'react'

import { Switch } from 'components/Switch'
import { useSwitchDomainProduct } from './hooks/useSwitchDomainProduct'
import { ProductSwitchCellProps } from './types'

export const ProductSwitchCell = ({
  disabled,
  getValue,
  product,
  row: {
    original: { id, name },
  },
}: ProductSwitchCellProps) => {
  const [checked, setChecked] = useState<boolean>(getValue() as boolean)

  const { errors, update } = useSwitchDomainProduct({ name, product, value: checked })

  useEffect(() => {
    if (errors && errors.length > 0) {
      setChecked(getValue() as boolean)
    }
  }, [errors]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: boolean = event.target.checked

    setChecked(value)
    void update({ id, product, value })
  }

  return (
    <Switch
      checked={checked}
      disabled={disabled}
      name={product + 'Enabled'}
      onChange={handleChange}
    />
  )
}

import { Navigate } from 'react-router-dom'

import { FlexBox } from 'components/Box'
import Loading from 'components/Loading'
import { useRefreshToken } from 'providers/router/components/AuthenticatedRoute/useRefreshToken'
import { ROUTES } from 'routes/routePaths'
import { BackgroundBox } from './components/BackgroundBox'
import { Logo } from './components/Logo'
import { UserSignLayoutPageProps } from './types'

export const UserSignLayoutPage = ({ children }: UserSignLayoutPageProps): JSX.Element => {
  const { tried, user } = useRefreshToken()

  if (!tried) {
    return <Loading />
  } else if (user) {
    return <Navigate to={ROUTES.BASE.ROOT} />
  }

  return (
    <BackgroundBox>
      <Logo />

      <FlexBox
        axis='y'
        sx={{
          alignItems: 'flex-start',
          pl: 15,
        }}
      >
        {children}
      </FlexBox>
    </BackgroundBox>
  )
}

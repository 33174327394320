import { gql, TypedDocumentNode } from '@apollo/client'

import { CUSTOM_CODE_FRAGMENT } from 'features/inventory/api/fragments/customCode'
import { DOMAIN_VIEWABILITY_TOOL_FRAGMENT } from 'features/inventory/domain'
import { DOMAIN_PREBID_STACK_FRAGMENT } from 'features/inventory/domain/api/fragments/domainPrebidStack'
import { DOMAIN_PRICE_GENIUS_FRAGMENT } from 'features/inventory/domain/api/fragments/domainPriceGenius'
import { DOMAIN_REFRESHER_FRAGMENT } from 'features/inventory/domain/api/fragments/domainRefresher'
import { DomainDTO } from '../types/domainDTO'

export const DOMAIN_FRAGMENT: TypedDocumentNode<DomainDTO> = gql`
  ${CUSTOM_CODE_FRAGMENT}
  ${DOMAIN_PREBID_STACK_FRAGMENT}
  ${DOMAIN_PRICE_GENIUS_FRAGMENT}
  ${DOMAIN_REFRESHER_FRAGMENT}
  ${DOMAIN_VIEWABILITY_TOOL_FRAGMENT}

  fragment DomainFragment on Domain {
    id
    alerts
    createdAt
    customCode {
      ...CustomCodeFragment
    }
    customCodeEnabled
    customFailsafeTimeoutEnabled
    domainPrebidStack {
      ...DomainPrebidStackFragment
    }
    domainPriceGenius {
      ...DomainPriceGeniusFragment
    }
    domainRefresher {
      ...DomainRefresherFragment
    }
    domainViewabilityTools {
      ...DomainViewabilityToolFragment
    }
    failsafeTimeout
    margin
    mobileScaling
    name
    prebidStackEnabled
    priceGeniusEnabled
    pubAdsServiceRefresh
    refresherEnabled
    targetingActionType
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
    workspace {
      id
      failsafeTimeout
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceSliceDTO } from './types'

export const WORKSPACE_FORM_FRAGMENT: TypedDocumentNode<WorkspaceSliceDTO> = gql`
  fragment WorkspaceFormFragment on Workspace {
    id
    alerts
    crmId
    csManager {
      id
      firstName
      lastName
    }
    name
    networkCode
    pipedriveLink
  }
`

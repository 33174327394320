import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { AdUnitForTable, AdUnitSortBy } from '../../../types'
import { AdUnitForTableDTO, AdUnitSortByDTO } from './types'

export const adUnitForTableFromDTO = (from: AdUnitForTableDTO) =>
  mapDTO<AdUnitForTableDTO, AdUnitForTable>({ from }).transform(adUnitDTO => ({
    ...adUnitDTO,
    divId: adUnitDTO.divId || undefined,
    domain: {
      ...adUnitDTO.domain,
    },
    name: adUnitDTO.name || undefined,
    updatedAt: new Date(adUnitDTO.updatedAt),
  }))

export const sortToDTO = (sort: SortInput<AdUnitSortBy>): SortInput<AdUnitSortByDTO> => {
  const { by } = sort

  if (by === 'name') {
    return { ...sort, by: 'adUnitName' }
  } else if (by === 'domain.name') {
    return { ...sort, by: 'domainName' }
  } else {
    return { ...sort, by }
  }
}

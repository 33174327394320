import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { CommonForm } from './CommonForm'
import { useCreateDomain } from './hooks/useCreateDomain'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/addSchema'

export const GeneralFormAdd = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { createDomain, errors: apiErrors } = useCreateDomain()

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={data => createDomain(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault({ workspaceId })}
    >
      {() => (loading ? <Loading /> : <CommonForm />)}
    </Form>
  )
}

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  AddEditPageContext,
  AddEditPageContextType,
} from 'features/inventory/page/contexts/AddEditPageContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { GeneralFormAdd } from '../../forms/GeneralForm'

export const AddPage = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/page')

  const contextProps: AddEditPageContextType = useCommonFormContextProps({
    formId: `page-form-add`,
  })

  return (
    <AddEditPageContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditPageContext.Consumer>
          {(ctxProps: AddEditPageContextType): JSX.Element => (
            <PageHeader
              actions={<SaveButton {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.PAGE.LIST, { workspaceId })}
              title={t('add.title')}
            />
          )}
        </AddEditPageContext.Consumer>
      </StickyBox>

      <GeneralFormAdd />
    </AddEditPageContext.Provider>
  )
}

import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { PrebidModuleAccount, PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { Variant } from 'providers/snackbar'

type UseSnackbar = {
  snackbar: (variant: Variant, moduleType: PrebidModuleType, account?: PrebidModuleAccount) => void
}

export const useSnackbar = (): UseSnackbar => {
  const { t } = useTranslation('features/globalSetup', { keyPrefix: 'prebid.modules' })

  const snackbar: UseSnackbar['snackbar'] = (variant, moduleType, account) => {
    if (variant === 'error') {
      enqueueSnackbar(t(`${moduleType}.add.${variant}`), { variant })
    } else if (variant === 'success') {
      if (moduleType === 'userId') {
        enqueueSnackbar(t(`${moduleType}.add.${variant}`), { variant })
      } else if (account) {
        enqueueSnackbar(t(`${moduleType}.add.${variant}.${account}`), { variant })
      }
    }
  }

  return { snackbar }
}

import { z } from 'zod'

import { commonSchema } from './commonSchema'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof commonSchema>

export const addSchemaDefault: AddSchema = {
  adUnitIds: [],
  domainId: '',
  matchType: 'include',
  name: '',
  path: '',
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceSliceDTO } from './types'

export const WORKSPACE_OPTION_FRAGMENT: TypedDocumentNode<WorkspaceSliceDTO> = gql`
  fragment WorkspaceOptionFragment on Workspace {
    id
    name
  }
`

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { adUnitBiddersAttributesSchemaToDTO } from './api/mapper'
import { UPDATE_AD_UNIT_PREBID_STACK } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateAdUnitPrebidStack } from './types'

export const useUpdateAdUnitPrebidStack = (): UseUpdateAdUnitPrebidStack => {
  const { t } = useTranslation('features/adUnit')
  const { enqueueSnackbar } = useSnackbar()
  const { adUnit, setLoading } = useContext(AddEditAdUnitContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }

  const [mutate] = useMutation(UPDATE_AD_UNIT_PREBID_STACK, {
    onCompleted: ({ updateAdUnitPrebidStack: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema) => {
    const { adUnitAmazonEnabled, adUnitBiddersAttributes, ...rest } = data

    setLoading(true)

    return mutate({
      variables: {
        input: {
          adUnitAmazonEnabled: adUnitAmazonEnabled ?? null,
          adUnitBiddersAttributes: adUnitBiddersAttributesSchemaToDTO(adUnitBiddersAttributes),
          ...rest,
        },
      },
    })
  }

  return { errors, update }
}

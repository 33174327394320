import { gql, TypedDocumentNode } from '@apollo/client'

import { PageDTO } from 'features/inventory/api/types/pageDTO'
import { AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT } from '../../useGetAdUnitsForPageTransferList/api/fragment'

export const PAGE_CREATE_FRAGMENT: TypedDocumentNode<PageDTO> = gql`
  ${AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT}

  fragment PageCreateFragment on Page {
    adUnits {
      ...AdUnitForPageTransferList
    }
    domain {
      id
      name
    }
    id
    matchType
    name
    path
  }
`

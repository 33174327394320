import { gql, TypedDocumentNode } from '@apollo/client'

import { PUBLISHER_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_PUBLISHER: TypedDocumentNode<Data, Variables> = gql`
  ${PUBLISHER_FRAGMENT}

  query getUser($id: ID!) {
    node(id: $id) {
      ... on User {
        ...PublisherFragment
      }
    }
  }
`

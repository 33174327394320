import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_OPTION_FRAGMENT } from './fragment'
import { Data } from './types'

export const GET_WORKSPACES: TypedDocumentNode<Data> = gql`
  ${WORKSPACE_OPTION_FRAGMENT}

  query workspaces($terms: [String!]) {
    workspaces(terms: $terms) {
      nodes {
        ...WorkspaceOptionFragment
      }
    }
  }
`

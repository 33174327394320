import { gql, TypedDocumentNode } from '@apollo/client'

import { AD_UNIT_VIEWABILITY_TOOLS_FRAGMENT } from 'features/inventory/ad-unit/api/fragments/adUnitViewabilityTools'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_AD_UNIT_VIEWABILITY_TOOLS: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${AD_UNIT_VIEWABILITY_TOOLS_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateAdUnitViewabilityTool($input: UpdateAdUnitViewabilityToolInput!) {
    updateAdUnitViewabilityTool(input: $input) {
      adUnitViewabilityTool {
        ...AdUnitViewabilityToolsFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

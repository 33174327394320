import { gql, TypedDocumentNode } from '@apollo/client'

import { FinanceReportDTO } from './types'

export const WORKSPACE_FRAGMENT_FOR_FINANCE_REPORT: TypedDocumentNode<FinanceReportDTO> = gql`
  fragment WorkspaceFragmentForFinanceReport on Workspace {
    id
    financeReportUrl
    users {
      id
      email
    }
  }
`

import { FetchResult, useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { Schema } from 'features/globalSetup/pages/SupplyChainAdd/schema'
import { ROUTES } from 'routes'
import { CREATE_SUPPLY_CHAIN } from './api/mutation'
import { Data } from './api/types'
import { UseCreateSupplyChain, UseCreateSupplyChainProps } from './types'

export const useCreateSupplyChain = ({
  workspaceId,
}: UseCreateSupplyChainProps): UseCreateSupplyChain => {
  const navigate = useNavigate()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.general.actions.create',
  })
  const { setLoading } = useContext(SupplyChainContext)

  const [mutate] = useMutation(CREATE_SUPPLY_CHAIN, {
    onCompleted: ({ createSupplyChain: { errors, supplyChain } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else if (supplyChain) {
        enqueueSnackbar(t('success', { supplyChain: supplyChain.name }), { variant: 'success' })
        navigate(generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.LIST, { workspaceId }))
      }
    },
    onError: (): void => setLoading(false),
  })

  const create = async (data: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: { ...data, workspaceId },
    })
  }

  return { create }
}

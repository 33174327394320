import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'
import { Chip, Tooltip } from '@mui/material'

import { ProductProps } from 'features/product/types/productProps'
import { useProductLabel } from './useProductLabel'

export const ProductLabel = ({ workspaceProduct }: ProductProps): JSX.Element => {
  const { chipLabel, chipSx, chipTooltip, helpIconSx } = useProductLabel({ workspaceProduct })

  const HelpIcon = ({ ...props }): JSX.Element => (
    <HelpCenterOutlinedIcon
      {...props}
      sx={helpIconSx}
    />
  )

  return (
    <Tooltip
      placement='right-start'
      title={chipTooltip}
    >
      <Chip
        deleteIcon={<HelpIcon />}
        label={chipLabel}
        onDelete={() => void {}}
        size='small'
        sx={chipSx}
        variant='outlined'
      />
    </Tooltip>
  )
}

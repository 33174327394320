import { CellContext } from '@tanstack/react-table'

import { DATE_FORMAT, formatDate } from 'utils/date'

const LastModifiedCell = <
  T extends {
    updatedAt: Date
  },
>({
  row: {
    original: { updatedAt },
  },
}: CellContext<T, unknown>): JSX.Element => <>{formatDate(updatedAt, DATE_FORMAT.DEFAULT)}</>

export default LastModifiedCell

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceBidderSlice } from '../types'
import { WorkspaceBidderSliceDTO } from './types'

type From = WorkspaceBidderSliceDTO
type To = WorkspaceBidderSlice

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((from: From): To => {
    const {
      accountType,
      bidCpmAdjustment,
      bidder,
      bidderCode,
      bidderSid,
      consentMode,
      enabled,
      id,
      priority,
      status,
      supplyChain,
    } = from

    return {
      accountType,
      bidCpmAdjustment,
      bidder,
      bidderCode,
      bidderSid: bidderSid || undefined,
      consentMode,
      enabled,
      id,
      priority,
      status,
      supplyChain,
    }
  })

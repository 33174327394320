import { z } from 'zod'

import { adUnitsAttributesSchema } from 'features/inventory/domain/components/ProductAdUnitsTable'
import { Domain } from 'features/inventory/types/domain'

export const schema = z
  .object({
    domainId: z.string(),
    unfilledRecoveryEnabled: z.boolean(),
  })
  .merge(adUnitsAttributesSchema)

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ id, unfilledRecoveryEnabled }: Domain): Schema => ({
  adUnitsAttributes: {},
  domainId: id,
  unfilledRecoveryEnabled,
})

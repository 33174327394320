import { CellContext } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'

import { Switch } from 'components/Form'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { Schema } from 'features/inventory/domain/forms/PrebidStackForm/schema'
import { useAbility } from 'providers/casl'

export const S2sEnabledCell = ({
  row: {
    original: { id, status },
  },
}: CellContext<DomainBidderSlice, unknown>): JSX.Element => {
  const ability = useAbility()
  const disabled = status !== 'available' || !ability.can('update', 'GlobalSetupFeature-bidder')
  const { control } = useFormContext<Schema>()

  return (
    <Switch<Schema>
      control={control}
      disabled={disabled}
      name={`domainBiddersAttributes.${id}.s2sEnabled`}
    />
  )
}

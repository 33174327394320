import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useGetSupplyChains } from './hooks/useGetSupplyChains'
import { SupplyChainForTable } from './hooks/useGetSupplyChains/types'
import { SupplyChainSortBy, SupplyChainTableProps } from './types'
import { useColumns } from './useColumns'

export const SupplyChainTable = ({ filters }: SupplyChainTableProps) => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const columns = useColumns()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<SupplyChainForTable, SupplyChainSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { count, loading, supplyChains } = useGetSupplyChains({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const handleRowClick = ({ id }: SupplyChainForTable): To =>
    generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.EDIT, {
      id,
      tab: 'general',
      workspaceId,
    })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<SupplyChainForTable>
      columns={columns}
      data={supplyChains}
      loading={loading}
      onClickRow={
        ability.can('update', 'GlobalSetupFeature-supplyChain')
          ? id => handleRowClick(id)
          : undefined
      }
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

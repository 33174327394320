import { useTranslation } from 'react-i18next'

import { ProductsActionsCell } from 'components/Table'
import { Product } from 'features/product'
import { ROUTES } from 'routes'
import { useDeleteDomain } from '../../hooks/useDeleteDomain/useDeleteDomain'
import { ActionsCellProps } from './types'

const products: Product[] = [
  'prebidStack',
  'refresher',
  'viewabilityTools',
  'priceGenius',
  'unfilledRecovery',
]

export const ActionsCell = ({ id, name }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { deleteDomain, loading } = useDeleteDomain({ name })

  return (
    <ProductsActionsCell
      editPath={ROUTES.INVENTORY.DOMAIN.EDIT}
      elementName={name}
      feature='domain'
      i18nResource={t}
      id={id}
      loading={loading}
      onDelete={() => void deleteDomain(id)}
      products={products}
    />
  )
}

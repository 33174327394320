import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { Schema } from '../../../schema'
import { DomainPrebidModuleSlice } from '../../../sections/ModulesSection/components/ModuleTable/hooks/useGetDomainPrebidModules/types'
import { DomainBidderAttributeDTO, DomainPrebidModuleAttributeDTO, Variables } from './types'

export const mapper = (data: Schema): Variables => {
  const {
    adUnitsAttributes,
    domainAmazonEnabled,
    domainBiddersAttributes,
    domainPrebidModulesAttributes,
    ...result
  } = data

  return {
    ...result,
    adUnitsAttributes: adUnitsAttributesMapper<'prebidStackEnabled'>(
      adUnitsAttributes,
      'prebidStackEnabled',
    ),
    domainAmazonEnabled: domainAmazonEnabled ?? null,
    domainBiddersAttributes: domainBiddersAttributesSchemaToDTO(domainBiddersAttributes),
    domainPrebidModulesAttributes: domainPrebidModulesAttributesSchemaToDTO(
      domainPrebidModulesAttributes,
    ),
  }
}

const domainBiddersAttributesSchemaToDTO = (
  domainBiddersAttributes: Schema['domainBiddersAttributes'],
): DomainBidderAttributeDTO[] =>
  Object.entries(domainBiddersAttributes).map(
    ([key, value]: [
      key: DomainBidderSlice['id'],
      value: Pick<DomainBidderSlice, 'csEnabled' | 'priority' | 's2sEnabled'>,
    ]): DomainBidderAttributeDTO => ({
      id: key,
      ...value,
    }),
  )

const domainPrebidModulesAttributesSchemaToDTO = (
  domainBiddersAttributes: Schema['domainPrebidModulesAttributes'],
): DomainPrebidModuleAttributeDTO[] =>
  Object.entries(domainBiddersAttributes).map(
    ([key, value]: [
      key: DomainPrebidModuleSlice['id'],
      value: DomainPrebidModuleSlice['enabled'],
    ]): DomainPrebidModuleAttributeDTO => ({
      enabled: value,
      id: key,
    }),
  )

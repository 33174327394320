import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitAmazonSliceDTO } from './types'

export const GET_AD_UNIT_AMAZON_FRAGMENT: TypedDocumentNode<AdUnitAmazonSliceDTO> = gql`
  fragment AdUnitAmazonFragment on AdUnitAmazon {
    adUnit {
      divId
      id
      path
    }
    enabled
    id
    updatedAt
  }
`

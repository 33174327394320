import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_DOMAIN_BIDDERS } from './api/query'
import { DomainBidderSliceDTO } from './api/types'
import { DomainBidderSlice, UseGetDomainBidders, UseGetDomainBiddersProps } from './types'

export const useGetDomainBidders = ({
  domainId,
}: UseGetDomainBiddersProps): UseGetDomainBidders => {
  const [domainBidders, setDomainBidders] = useState<DomainBidderSlice[] | undefined>()

  useEffect(() => {
    if (domainId) {
      query({
        variables: {
          domainId,
        },
      })
    }
  }, [domainId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query] = useLazyQuery(GET_DOMAIN_BIDDERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ domainBidders: { nodes: bidders } }): void => {
      if (bidders) {
        setDomainBidders(
          bidders.map((bidder: DomainBidderSliceDTO): DomainBidderSlice => fromDTO(bidder)),
        )
      }
    },
  })

  return { domainBidders }
}

import { ExternalInfo } from '@adend/asteriobid-ui-lib/dist/utils/externalInfoStore'
import { useSnackbar } from 'notistack'
import { FC, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import { ASTERIO_BID } from './constants'
import { ErrorBox } from './ErrorBox'
import { importAsterioBidModule } from './importer'
import { AsterioBidModuleName, Props, UseAsterioBidModule } from './types'

export const useAsterioBidModule = ({ token }: Props): UseAsterioBidModule => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })
  const { enqueueSnackbar } = useSnackbar()

  if (!token) {
    return function ErrorBoxWrapper() {
      return <ErrorBox />
    }
  }

  const moduleProps: ExternalInfo = {
    apiUrl: ASTERIO_BID.API_URL,
    onAccessError: () => enqueueSnackbar(t('asterioBid.error.connection'), { variant: 'error' }),
    ownerDiv: ASTERIO_BID.WRAPPER_ELEMENT_ID,
    profileCurrency: ASTERIO_BID.CURRENCY,
    token,
  }

  const Module = (moduleName?: AsterioBidModuleName): JSX.Element => {
    if (!moduleName) {
      throw Error('AsterioBid module name must be valid to be import')
    }

    import('./asterio-bid-styles.scss')
    const Component: FC<ExternalInfo> = importAsterioBidModule(moduleName)

    return (
      <Suspense fallback={<Loading />}>
        <div id={ASTERIO_BID.WRAPPER_ELEMENT_ID}>{<Component {...moduleProps} />}</div>
      </Suspense>
    )
  }

  return Module
}

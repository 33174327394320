import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell, RowExpanderColumn } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import ActionsCell from '../components/ActionsCell'
import { WorkspaceForTable } from '../types'
import { AlertsCell } from './AlertsCell'
import { PipedriveNavLink } from './PipedriveNavLink'
import { StatusSwitch } from './StatusSwitch/StatusSwitch'

export const useColumns = (): ColumnDef<WorkspaceForTable>[] => {
  const { t } = useTranslation('features/workspace')

  return [
    RowExpanderColumn<WorkspaceForTable>(),
    {
      accessorKey: 'name',
      header: t('list.header.name'),
    },
    {
      accessorKey: 'domains',
      header: t('list.header.domains'),
    },
    {
      accessorKey: 'alerts',
      cell: ({ row }) => <AlertsCell {...row} />,
      header: t('list.header.alerts'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'networkCode',
      header: t('list.header.networkCode'),
    },
    {
      accessorKey: 'crmId',
      header: t('list.header.crmId'),
    },
    {
      accessorKey: 'pipedriveLink',
      cell: ({ row }) => <PipedriveNavLink url={row.original.pipedriveLink} />,
      header: t('list.header.pipedrive'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'active',
      cell: ({ row }) => <StatusSwitch {...row.original} />,
      header: t('list.header.active'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.SM,
    },
  ]
}

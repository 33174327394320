export const defaultPriorities = {
  primary: 'defaultPrimary',
  secondary: 'defaultSecondary',
} as const

const { primary, secondary } = defaultPriorities

export const workspaceBidderPriorities = ['primary', 'secondary'] as const
export const domainBidderPriorities = [primary, secondary, ...workspaceBidderPriorities] as const

export type WorkspaceBidderPriority = (typeof workspaceBidderPriorities)[number]
export type DomainBidderPriority = (typeof domainBidderPriorities)[number]

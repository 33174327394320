import { useContext } from 'react'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import {
  IsFieldDisabled,
  UseIsFieldDisabled,
} from 'features/globalSetup/forms/Prebid/Bidders/hooks/useIsFieldDisabled/types'
import { useAbility } from 'providers/casl'

export const useIsFieldDisabled = (): UseIsFieldDisabled => {
  const ability = useAbility()
  const { accountType } = useContext(WorkspaceBidderContext)

  const isFieldDisabled = (field: IsFieldDisabled): boolean => {
    return !ability.can('update', `GlobalSetupField-prebid-bidders-${accountType}-${field}`)
  }

  return {
    isFieldDisabled,
  }
}

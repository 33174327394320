import { gql, TypedDocumentNode } from '@apollo/client'

import { AD_UNIT_FOR_PRODUCT_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_AD_UNITS: TypedDocumentNode<Data, Variables> = gql`
  ${AD_UNIT_FOR_PRODUCT_TABLE_FRAGMENT}

  query adUnitsForProductTable(
    $domainId: ID
    $first: Int
    $last: Int
    $terms: [String!]
    $workspaceId: ID!
    $sort: AdUnitSortInput
  ) {
    adUnits(
      domainId: $domainId
      first: $first
      last: $last
      terms: $terms
      workspaceId: $workspaceId
      sort: $sort
    ) {
      nodes {
        ...AdUnitForProductTableFragment
      }

      totalCount
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceAmazonSliceDTO } from './types'

export const WORKSPACE_AMAZON_FRAGMENT_FOR_GET: TypedDocumentNode<WorkspaceAmazonSliceDTO> = gql`
  fragment WorkspaceAmazonFragmentForGet on WorkspaceAmazon {
    accountType
    enabled
    id
    status
    pubId
    supplyChain {
      id
    }
  }
`

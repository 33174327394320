import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../form/schema'
import { toDTO } from './api/mapper'
import { UPDATE_DATA_SAMPLING } from './api/mutation'
import { Data } from './api/types'
import { Props, UseUpdateDataSampling } from './types'

export const useUpdateDataSampling = ({ workspaceId }: Props): UseUpdateDataSampling => {
  const { t } = useTranslation('features/performance', {
    keyPrefix: 'prebidStack.dataSampling.update',
  })
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate, { loading }] = useMutation(UPDATE_DATA_SAMPLING, {
    onCompleted: ({ updateWorkspace: { errors } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('success'), { variant: 'success' })
      }
    },
  })

  const update = (data: Schema) =>
    mutate({
      variables: {
        input: toDTO(data, workspaceId),
      },
    })

  return { errors, loading, update }
}

import { Button, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { TextLink } from 'components/Link'
import { PubAdsServiceRefresh } from 'features/inventory/domain/types/pubAdsServiceRefresh'
import { PUB_ADS_SERVICE_REFRESH_KNOWLEDGE_BASE } from './contants'
import { PubAdsServiceRefreshDialogProps } from './types'

export const PubAdsServiceRefreshDialog = ({
  dialog: { isOpen, setOpen },
}: PubAdsServiceRefreshDialogProps): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { setValue, watch } = useFormContext()

  const value = watch('pubAdsServiceRefresh')

  const variant = (): PubAdsServiceRefresh =>
    value === 'controlledByPublisher' ? 'controlledByYieldbird' : 'controlledByPublisher'

  const Content: JSX.Element = (
    <Stack spacing={2}>
      <Typography>
        {t(
          `form.general.advancedSettings.pubAdsServiceRefresh.values.${variant()}.dialog.subtitle`,
        )}
      </Typography>

      <Typography variant='subtitle2'>
        {t(
          `form.general.advancedSettings.pubAdsServiceRefresh.values.${variant()}.dialog.description`,
        )}
        <TextLink
          link={{
            title: t('common:learnMore'),
            url: PUB_ADS_SERVICE_REFRESH_KNOWLEDGE_BASE,
          }}
        />
      </Typography>
    </Stack>
  )

  const Actions: JSX.Element = (
    <Button
      onClick={(): void => {
        setValue('pubAdsServiceRefresh', variant(), { shouldDirty: true })
        setOpen(false)
      }}
      variant='contained'
    >
      {t(`form.general.advancedSettings.pubAdsServiceRefresh.values.${variant()}.dialog.confirm`)}
    </Button>
  )

  return (
    <Dialog
      actions={Actions}
      content={Content}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t('form.general.advancedSettings.pubAdsServiceRefresh.dialog.title')}
    />
  )
}

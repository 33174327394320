import _ from 'lodash'

import { SelectOption } from 'components/Select'
import { UserWorkspaceRole, userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'

export const userWorkspaceRoleOptions = (): SelectOption<UserWorkspaceRole>[] =>
  userWorkspaceRoles.map((role: UserWorkspaceRole) => ({
    label: _.upperFirst(role),
    value: role,
  }))

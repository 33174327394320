import _ from 'lodash'
import { DeepPartial, FieldValues, FormState } from 'react-hook-form'

export const getDirtyValues = <T extends FieldValues>(
  currentDirtyFields: FormState<T>['dirtyFields'] | true,
  currentValues: DeepPartial<T>,
): DeepPartial<T> => {
  if (typeof currentDirtyFields === 'boolean') {
    if (currentDirtyFields === true) {
      return currentValues
    } else {
      throw Error(`React Hook Form's 'dirtyFields' can only be 'true'`)
    }
  } else {
    return _.mapValues(
      currentDirtyFields,
      (value: FormState<T>['dirtyFields'], key: string): DeepPartial<T> =>
        getDirtyValues<T>(value, currentValues[key]),
    ) as DeepPartial<T>
  }
}

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Schema } from '../../form/schema'
import { useExistingWorkspaceBidder } from '../../hooks/useExistingWorkspaceBidder'
import { DomainBidderSlice, useGetDomainBidder } from '../../hooks/useGetDomainBidder'
import { useGetDomainBiddersForSelect } from '../../hooks/useGetDomainBiddersForSelect'
import { UseDomainBidderData } from './types'

export const useDomainBidderData = (): UseDomainBidderData => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    workspaceBidder: { id: workspaceBidderId },
  } = useExistingWorkspaceBidder()

  const { loading: loadingOptions, options } = useGetDomainBiddersForSelect({
    workspaceBidderId,
  })

  const { getValues, reset, setValue, watch } = useFormContext<Schema>()
  const {
    domainIds: { domainBidderId },
  } = watch()

  const { domainBidder, loading: loadingDomainBidder } = useGetDomainBidder({ id: domainBidderId })

  useEffect(() => {
    if (loadingDomainBidder || loadingOptions) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingDomainBidder, loadingOptions])

  useEffect(() => {
    if (options.length > 0 && domainBidderId === '') {
      selectFirstOption()
    }
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (domainBidder) {
      setDomainBidderParams(domainBidder)
    }
  }, [domainBidder]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectFirstOption = (): void =>
    setValue('domainIds', {
      domainBidderId: options[0].value,
      domainId: options[0].rawData!.domain.id,
    })

  const setDomainBidderParams = (domainBidder: DomainBidderSlice): void => {
    const { csEnabled, priority, s2sEnabled } = domainBidder

    reset({
      ...getValues(),
      csEnabled,
      priority,
      s2sEnabled,
    })
  }

  return {
    domainBidder,
    loading,
    options,
  }
}

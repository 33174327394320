import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { UserManagementFormContext } from '../../contexts/UserManagementFormContext'
import { CommonForm } from './CommonForm'
import { useCreateEmployee } from './hooks/useCreateEmployee'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/add'

export const UserAddEmployeeForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(UserManagementFormContext)
  const { createEmployee, errors: apiErrors } = useCreateEmployee()

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={createEmployee}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='add' />)}
    </Form>
  )
}

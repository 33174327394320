import Alerts from 'components/Alerts'
import { WorkspaceAlert } from 'features/workspace/types/workspaceAlert'
import { Props } from './types'
import { useAlerts } from './useAlerts'

export const WorkspaceAlerts = ({ alerts }: Props): JSX.Element => {
  const { switcher } = useAlerts()

  return <Alerts<WorkspaceAlert> {...{ alerts, switcher }} />
}

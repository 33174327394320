import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Schema } from '../../../schema'
import { Variables } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform((data: Schema): Variables => {
    const { s2sTimeoutMultiplier } = data

    return {
      ...data,
      s2sTimeoutMultiplier: s2sTimeoutMultiplier / 100,
    }
  })

import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { styles } from './styles'
import { FilterProps } from './types'

export const Filter = ({ id, onChange, placeholder, values }: FilterProps): JSX.Element => {
  const { t } = useTranslation('components')

  const generatePlaceholder = () => {
    if (values.length > 0) {
      return undefined
    } else return placeholder ?? t('filter.placeholder')
  }

  const FilterRenderInput = (props: AutocompleteRenderInputParams): JSX.Element => (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: <SearchIcon />,
      }}
      placeholder={generatePlaceholder()}
    />
  )

  return (
    <Autocomplete
      disableClearable
      freeSolo
      id={id}
      limitTags={1}
      multiple
      onChange={(_, values: string[]): void => onChange(values)}
      open={false}
      options={[]}
      renderInput={FilterRenderInput}
      sx={styles}
      value={values}
    />
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainsForTableDTO } from './types'

export const DOMAIN_FOR_TABLE_FRAGMENT: TypedDocumentNode<DomainsForTableDTO> = gql`
  fragment DomainFragmentForTable on Domain {
    id
    alerts
    name
    prebidStackEnabled
    priceGeniusEnabled
    refresherEnabled
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
  }
`

import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'
import { FieldErrors } from 'react-hook-form'

import { Schema, ViewportSchema } from 'features/inventory/viewport/forms/ViewportForm/schema'
import { ViewportAttributeDTO } from 'features/inventory/viewport/types/viewportAttributeDTO'
import { adUnitSizeToDTO } from 'features/inventory/viewport/utils'
import { MutationInputError } from 'providers/graphql'

type From = ViewportSchema
type To = ViewportAttributeDTO

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const {
      bannerEnabled,
      bannerSizes,
      minimumWidth,
      name,
      nativeEnabled,
      outstreamEnabled,
      playerSize,
      viewportId,
    } = data

    return {
      bannerEnabled,
      bannerSizes: bannerSizes.map(adUnitSizeToDTO),
      id: viewportId || null,
      minimumWidth: minimumWidth ?? null,
      name: name || null,
      nativeEnabled,
      outstreamEnabled,
      playerSize: playerSize.map(adUnitSizeToDTO),
    }
  })

export const mapApiErrors = (apiErrors: MutationInputError[]): FieldErrors<Schema>['viewports'] => {
  type ErrorMeta = { field: string; order: string }
  type ErrorTemp = ErrorMeta & MutationInputError
  type ErrorSlice = Pick<MutationInputError, 'message'>
  type GroupedErrorsByField = Record<ErrorTemp['field'], ErrorSlice>
  type GroupedErrors = Record<ErrorTemp['order'], GroupedErrorsByField>

  const grouped = _.chain<MutationInputError[]>(apiErrors)
    .mapValues((value: MutationInputError): ErrorTemp | undefined => {
      const [order, field] = value.attribute.split('.').slice(1, 3)

      return order === undefined ? undefined : { ...value, field, order }
    })
    .filter(undefined)
    .groupBy('order')
    .mapValues(
      (value: ErrorTemp[]): GroupedErrorsByField =>
        _.chain<ErrorTemp[]>(value)
          .groupBy('field')
          .mapValues((field: ErrorTemp[]): ErrorTemp => field[0])
          .mapValues((field: ErrorTemp): ErrorSlice => ({ message: field.message }))
          .value() as unknown as GroupedErrorsByField,
    )
    .value() as unknown as GroupedErrors

  const orderMax: number = parseInt(
    _.chain<GroupedErrors>(grouped)
      .keys()
      .mapKeys((key: ErrorTemp['order']): number => parseInt(key))
      .keys()
      .max()
      .value(),
  )

  const errors: FieldErrors<Schema>['viewports'] = new Array(orderMax).fill(null)

  Object.entries(grouped).forEach((error: [ErrorMeta['order'], GroupedErrorsByField]) => {
    const [order, data] = error

    errors[parseInt(order)] = data
  })

  return errors
}

import { SortInput } from 'components/Table'
import { FilteringRuleSortBy } from './hooks/useGetFilteringRules/types'

export const DEFAULT_SORT: SortInput<FilteringRuleSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: FilteringRuleSortBy[] = [
  'name',
  'blockedCount',
  'applicableDomains',
  'updatedAt',
  'enabled',
]

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  AddEditAdUnitContext,
  AddEditAdUnitContextType,
} from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { GeneralFormAdd } from 'features/inventory/ad-unit/forms/GeneralForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

export const AddAdUnit = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/adUnit')

  const contextProps: AddEditAdUnitContextType = useCommonFormContextProps({
    formId: 'ad-unit-form-add',
  })

  return (
    <AddEditAdUnitContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditAdUnitContext.Consumer>
          {(ctxProps: AddEditAdUnitContextType): JSX.Element => (
            <PageHeader
              actions={<SaveButton {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.AD_UNIT.LIST, { workspaceId })}
              title={t('add.title')}
            />
          )}
        </AddEditAdUnitContext.Consumer>
      </StickyBox>

      <GeneralFormAdd />
    </AddEditAdUnitContext.Provider>
  )
}

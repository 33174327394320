import { gql, TypedDocumentNode } from '@apollo/client'

import { FilteringRuleSliceDTO } from './types'

export const FILTERING_RULE_FRAGMENT_FOR_UPDATE: TypedDocumentNode<FilteringRuleSliceDTO> = gql`
  fragment FilteringRuleFragmentForUpdate on FilteringRule {
    applicableDomains
    blocked
    domainIds
    id
    name
  }
`

import { Edit, MoreVert, Tune } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { AdUnitBidderSlice } from 'features/inventory/ad-unit/forms/PrebidStackForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { ROUTES } from 'routes'

export const ActionsCell = ({
  row: {
    original: { domainBidderId, workspaceBidderId },
  },
}: CellContext<AdUnitBidderSlice, unknown>): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { adUnit } = useContext(AddEditAdUnitContext)
  const navigate = useNavigate()
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { anchor, close, isOpen, open } = useMenu()

  if (!adUnit) {
    throw new Error('AdUnit is required')
  }

  const handleEdit = (): void =>
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
        id: workspaceBidderId,
        tab: 'general',
        workspaceId,
      }),
    )

  const handleParamsAndConnection = (): void =>
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT_SPECIFIC, {
        domainBidderId,
        domainId: adUnit.domain.id,
        id: workspaceBidderId,
        tab: 'bid-params-and-connection',
        workspaceId,
      }),
    )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={handleEdit}
      text={t('common:form.action.edit')}
    />
  )

  const ParamsAndConnectionItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit-params'
      icon={<Tune />}
      onClick={handleParamsAndConnection}
      text={t('form.prebidStack.bidders.list.action.paramsAndConnection')}
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem />

        <Divider
          key='divider'
          sx={{ my: 1 }}
        />

        <ParamsAndConnectionItem />
      </Menu>
    </>
  )
}

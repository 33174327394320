import { useAuthUser } from 'features/user'
import { UsePriceGeniusAlwaysOn } from './types'

export const usePriceGeniusAlwaysOn = (): UsePriceGeniusAlwaysOn => {
  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw new Error('Current Workspace should be accessible')
  }

  const { priceGeniusAlwaysOn } = currentWorkspace

  const overwritePriceGeniusState: UsePriceGeniusAlwaysOn['overwritePriceGeniusState'] = (
    product,
    isUnfilledRecoveryAdUnit,
  ): boolean => {
    if (priceGeniusAlwaysOn && product === 'priceGenius') {
      if (isUnfilledRecoveryAdUnit) {
        return false
      }

      return true
    } else {
      return false
    }
  }

  return {
    overwritePriceGeniusState,
    priceGeniusAlwaysOn,
  }
}

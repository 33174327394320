import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'

import { DomainSupplyChainDTO } from 'features/globalSetup/api/types/domainSupplyChainDTO'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { fromDTO, sortToDTO } from './api/mapper'
import { GET_DOMAIN_SUPPLY_CHAINS } from './api/query'
import { Props, UseGetDomainSupplyChains } from './types'

export const useGetDomainSupplyChains = ({
  filters,
  first,
  last,
  sort,
  supplyChainId,
}: Props): UseGetDomainSupplyChains => {
  const [domainSupplyChains, setDomainSupplyChains] = useState<DomainSupplyChain[]>([])
  const [count, setCount] = useState<number>(0)
  const { setLoading } = useContext(SupplyChainContext)

  const { loading } = useQuery(GET_DOMAIN_SUPPLY_CHAINS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({
      domainSupplyChains: { nodes: domainSupplyChains, totalCount: count },
    }): void => {
      if (domainSupplyChains) {
        setDomainSupplyChains(
          domainSupplyChains.map(
            (domainSupplyChain: DomainSupplyChainDTO): DomainSupplyChain =>
              fromDTO(domainSupplyChain),
          ),
        )
        setCount(count)
      }
    },

    variables: {
      first,
      last,
      sort: sortToDTO(sort),
      supplyChainId,
      terms: filters,
    },
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    count,
    domainSupplyChains,
  }
}

import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { AmazonPageContext } from '../../contexts/AmazonPageContext'
import { AmazonPageContextType } from '../../contexts/AmazonPageContext/types'
import { useGetWorkspaceAmazon, WorkspaceAmazonSlice } from '../../hooks/useGetWorkspaceAmazon'
import { AmazonTabs, AmazonUrlParams } from './types'
import { useTabs } from './useTabs'

export const AmazonPage = (): JSX.Element => {
  const { tab } = useParams<AmazonUrlParams>()
  const { t } = useTranslation(['features/globalSetup/amazon', 'common'])

  const { workspaceId } = useWorkspaceParam()
  const { loading, workspaceAmazon } = useGetWorkspaceAmazon({ workspaceId })
  const [accountType, setAccountType] = useState<undefined | WorkspaceAmazonSlice['accountType']>(
    workspaceAmazon?.accountType,
  )

  const tabs: Tab<AmazonTabs>[] = useTabs({ workspaceAmazon })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'amazon-form',
  })
  const contextProps: AmazonPageContextType = {
    accountType,
    setAccountType,
    workspaceAmazon,
    ...commonContextProps,
  }

  if (loading || tabs.length === 0) {
    return <Loading />
  }

  return (
    <AmazonPageContext.Provider value={contextProps}>
      <StickyBox>
        <AmazonPageContext.Consumer>
          {(ctxProps: AmazonPageContextType) => (
            <PageHeader
              actions={
                <SaveButton
                  {...ctxProps}
                  text={
                    _.isNil(workspaceAmazon) && accountType === 'yieldbird'
                      ? t('common:form.action.sendRequest')
                      : t('common:form.action.save')
                  }
                />
              }
              title={t('header')}
            />
          )}
        </AmazonPageContext.Consumer>

        <Tabs
          path={ROUTES.GLOBAL_SETUP.AMAZON.ROOT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<AmazonTabs>(tabs, tab, 'general')}
    </AmazonPageContext.Provider>
  )
}

import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspaceUserContext } from 'features/globalSetup/contexts/WorkspaceUserContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { toDTO } from './api/mapper'
import { UPDATE_WORKSPACE_USER } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateWorkspaceUser } from './types'

export const useUpdateWorkspaceUser = (): UseUpdateWorkspaceUser => {
  const { t } = useTranslation('features/globalSetup')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})
  const { setLoading } = useContext(WorkspaceUserContext)

  const [mutate] = useMutation(UPDATE_WORKSPACE_USER, {
    onCompleted: ({ updatePublisherByWorkspaceOwner: { errors, user } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('general.userManagement.edit.action.error'), { variant: 'error' })
      } else if (user) {
        setErrors({})
        enqueueSnackbar(t('general.userManagement.edit.action.success', { email: user.email }), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({ variables: { input: toDTO(data) } })
  }

  return { errors, update }
}

import { Box, BoxProps } from '@mui/material'

export const BoxForm = (muiBoxProps: BoxProps): JSX.Element => (
  <Box
    {...muiBoxProps}
    gap={2}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      ...muiBoxProps.sx,
    }}
  />
)

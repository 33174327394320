import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import TransferListRoot from 'components/TransferList'
import { TransferListProps } from './types'

export const TransferList = <T extends FieldValues>({
  control,
  name,
  ...transferListProps
}: TransferListProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field: { onChange } }) => (
      <TransferListRoot
        {...transferListProps}
        onChange={onChange}
      />
    )}
  />
)

import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_VIEWPORTS } from './api/query'
import { ViewportSliceDTO } from './api/types'
import { Props, UseGetViewports, ViewportSlice } from './types'

export const useGetViewports = ({ filters, sort, workspaceId }: Props): UseGetViewports => {
  const [viewports, setViewports] = useState<ViewportSlice[]>([])

  const { loading } = useQuery(GET_VIEWPORTS, {
    onCompleted: ({ viewports: { nodes } }): void => {
      if (nodes) {
        setViewports(nodes.map((node: ViewportSliceDTO): ViewportSlice => fromDTO(node)))
      }
    },
    variables: {
      sort,
      terms: filters,
      workspaceId,
    },
  })

  return {
    loading,
    viewports,
  }
}

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useSwitchAdUnitProduct } from '../hooks/useSwitchAdUnitProduct'
import { CommonForm } from './CommonForm'
import { Schema, schema, schemaDefault } from './schema'

export const UnfilledRecoveryForm = (): JSX.Element => {
  const { adUnit, formId, loading, setLoading, setTouched, setValid } =
    useContext(AddEditAdUnitContext)
  const { update } = useSwitchAdUnitProduct({ setLoading })

  if (!adUnit) {
    return <Loading />
  }

  const handleSubmit = ({ id, unfilledRecoveryEnabled }: Schema): void =>
    void update({ id, product: 'unfilledRecovery', value: unfilledRecoveryEnabled })

  return (
    <Form<Schema>
      id={formId}
      onSubmit={handleSubmit}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(adUnit)}
    >
      {() => (loading ? <Loading /> : <CommonForm />)}
    </Form>
  )
}

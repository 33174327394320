import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { useDeleteFilteringRule } from '../../hooks/useDeleteFilteringRule'
import { ActionCellProps } from './types'

export const ActionsCell = ({ id, name }: ActionCellProps): JSX.Element => {
  const ability = useAbility()
  const navigate = useNavigate()
  const { workspaceId } = useWorkspaceParam()
  const { anchor, close, isOpen, open } = useMenu()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { t } = useTranslation(['features/globalSetup'], { keyPrefix: 'prebid.protection' })
  const { t: tC } = useTranslation('common')
  const { deleteFilteringRule } = useDeleteFilteringRule({ name })

  const openDeleteDialog = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const handleDelete = (): void => {
    deleteFilteringRule(id)
    setIsDialogOpen(false)
  }

  const handleEdit = (): void => {
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.EDIT, {
        id,
        workspaceId,
      }),
    )
  }

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={handleEdit}
      text={tC('form.action.edit')}
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={openDeleteDialog}
      text={tC('form.action.delete')}
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        {ability.can('access', 'GlobalSetupPage-prebid-protection-edit') && <EditItem />}

        {ability.can('delete', 'GlobalSetupFeature-protection') && [
          <Divider
            key='divider'
            sx={{ my: 1 }}
          />,
          <DeleteItem key='delete' />,
        ]}
      </Menu>

      <DeleteDialog
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        elementName={name}
        i18nResource={t}
        loading={false}
        onDelete={handleDelete}
      />
    </>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { MediaTypesDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MEDIA_TYPE_FOR_FORM_FRAGMENT } from './mediaType'
import { VIEWPORT_FOR_FORM_FRAGMENT } from './viewport'

export const MEDIA_TYPES_FOR_FORM_FRAGMENT: TypedDocumentNode<MediaTypesDTO> = gql`
  ${MEDIA_TYPE_FOR_FORM_FRAGMENT}
  ${VIEWPORT_FOR_FORM_FRAGMENT}

  fragment MediaTypesFragment on MediaTypes {
    defaultAttribute {
      ...MediaTypeForFormFragment
    }
    ignoreViewportAttributes
    viewportAttributes {
      ...MediaTypeForFormFragment
      viewport {
        ...ViewportForFormFragment
      }
    }
    nativeConfiguration
    outstreamConfiguration
  }
`

import { ProductStatus } from 'features/product'
import { UserWorkspaceProductsSlice } from 'features/user/types/user'
import { Can } from 'providers/casl'
import { appendCommonRules } from './commonRules'
import { UserData } from './types'

export const productVisibility = (userData: UserData, can: Can): void => {
  const { userProducts } = userData

  userProducts.forEach(({ product, status }: UserWorkspaceProductsSlice): void => {
    const allowed: ProductStatus[] = appendCommonRules(userData)

    if (allowed.includes(status)) {
      can('show', `Product-${product}`)
      can('access', `Product-${product}`)
    }
  })
}

import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { LazyLoad, lazyLoadValues } from 'types/lazyLoad'

export const useLazyLoadRadioGroup = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/domain')

  return lazyLoadValues.map(
    (lazyLoad: LazyLoad): RadioGroupOption => ({
      label: t(
        `form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.lazyLoadType.${lazyLoad}`,
      ),
      value: lazyLoad,
    }),
  )
}

import { Row } from '@tanstack/react-table'

import { DomainAlerts } from 'features/inventory/domain'
import { Domain } from 'features/inventory/types/domain'
import { Workspace } from 'features/workspace/types/workspace'
import WorkspaceAlerts from '../../WorkspaceAlerts'
import { WorkspaceForTable } from '../types'

export const AlertsCell = ({ depth, original }: Row<WorkspaceForTable>) => {
  const isWorkspaceRow: boolean = depth === 0
  const alerts: Domain['alerts'] | Workspace['alerts'] = original.alerts

  return isWorkspaceRow ? (
    <WorkspaceAlerts alerts={alerts} />
  ) : (
    <DomainAlerts alerts={alerts as unknown as Domain['alerts']} />
  )
}

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup as ToggleButtonGroupOrigin,
  Tooltip as TooltipRoot,
} from '@mui/material'
import { isNil } from 'lodash'
import { Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { ToggleButtonGroupProps } from './types'

export const ToggleButtonGroup = <T extends FieldValues>({
  arrangement = 'vertical',
  control,
  hint,
  label,
  name,
  options,
  tooltip: tooltipProps,
  unit,
  ...toggleButtonGroupPropsOrigin
}: ToggleButtonGroupProps<T>): JSX.Element => {
  const Label = ({ error }: { error?: FieldError }): JSX.Element => (
    <Stack direction='row'>
      <FormLabel
        error={Boolean(error)}
        id={name}
      >
        {label}
      </FormLabel>

      {tooltipProps && <Tooltip {...tooltipProps} />}
    </Stack>
  )

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, field: { onChange, value }, fieldState: { error } }) => (
        <Stack
          direction={arrangement === 'horizontal' ? 'row' : 'column'}
          spacing={1}
          sx={{ alignItems: arrangement === 'horizontal' ? 'center' : 'unset' }}
        >
          <Label error={error} />

          <FormControl
            error={Boolean(error)}
            sx={{ minWidth: 'unset' }}
          >
            <>
              <TooltipRoot
                arrow
                title={hint}
              >
                <ToggleButtonGroupOrigin
                  {...field}
                  {...toggleButtonGroupPropsOrigin}
                  exclusive
                  id={name}
                  onChange={(_, newValue: string): void => {
                    if (toggleButtonGroupPropsOrigin.onClick === undefined && !isNil(newValue)) {
                      onChange(newValue)
                    }
                  }}
                  value={value}
                >
                  {options.map((option: string): JSX.Element => {
                    const label: string = option.toUpperCase() + (unit ?? '')

                    return (
                      <ToggleButton
                        key={option}
                        value={option}
                      >
                        {label}
                      </ToggleButton>
                    )
                  })}
                </ToggleButtonGroupOrigin>
              </TooltipRoot>
            </>

            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        </Stack>
      )}
    />
  )
}

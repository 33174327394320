import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceSlice } from '../types'
import { WorkspaceSliceDTO } from './types'

export const workspaceFromDTO = (from: WorkspaceSliceDTO): WorkspaceSlice =>
  mapDTO<WorkspaceSliceDTO, WorkspaceSlice>({ from }).transform(workspace => {
    const { csManager } = workspace

    return {
      ...workspace,
      csManager: csManager ?? undefined,
    }
  })

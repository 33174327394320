import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainAmazonSliceDTO } from './types'

export const DOMAIN_AMAZON_FRAGMENT: TypedDocumentNode<DomainAmazonSliceDTO> = gql`
  fragment DomainAmazonFragment on DomainAmazon {
    enabled
    id
    domain {
      name
    }
  }
`

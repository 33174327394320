import { Delete, MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ removeProps: { index, remove } }: ActionsCellProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('common')
  const { anchor, close, isOpen, open } = useMenu()

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={(): void => {
        remove(index)
        close()
      }}
      text={t('form.action.delete')}
    />
  )

  return (
    <>
      <IconButton
        disabled={!ability.can('delete', 'AdUnitFeature-mediaTypes')}
        onClick={open}
      >
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <DeleteItem />
      </Menu>
    </>
  )
}

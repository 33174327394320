import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Switch'
import { TooltipProps } from 'components/Tooltip'
import { KNOWLEDGE_BASE_URL } from 'features/inventory/ad-unit/constants'
import { usePriceGeniusAlwaysOn } from 'features/inventory/ad-unit/hooks/usePriceGeniusAlwaysOn'
import { Product } from 'features/product'
import { AdUnitForTable } from '../../types'
import { useSwitchAdUnitProduct } from './hooks/useSwitchAdUnitProduct'
import { ProductSwitchCellProps } from './types'
import { useSnackbar } from './useSnackbar'

export const ProductSwitchCell = ({
  column: { id: columnId },
  disabled,
  getValue,
  product,
  row: {
    index,
    original: { id, path, unfilledRecoveryAdUnit },
  },
  table,
}: ProductSwitchCellProps) => {
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { overwritePriceGeniusState, priceGeniusAlwaysOn } = usePriceGeniusAlwaysOn()

  const { update } = useSwitchAdUnitProduct()
  const { snackbar } = useSnackbar({ path, product })

  const tryUpdateRelatedAdUnits = (value: boolean): void => {
    const products: Product[] = ['viewabilityTools', 'priceGenius']
    const related: Row<AdUnitForTable>[] = table
      .getCoreRowModel()
      .rows.filter(
        ({ original }: Row<AdUnitForTable>): boolean =>
          products.includes(product) && original.path === path && original.id !== id,
      )

    table.options.meta?.updateData?.(
      related.map((row: Row<AdUnitForTable>) => ({ columnId, rowIndex: row.index, value })),
    )
  }

  const updateToggle = (value: boolean): void => {
    const data = [{ columnId, rowIndex: index, value }]

    table.options.meta?.updateData?.(data)
    tryUpdateRelatedAdUnits(value)
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value: boolean = event.target.checked
    const previousValue: boolean = !value

    updateToggle(value)

    const { data } = await update({ id, product, value })
    const { errors } = { ...data?.updateAdUnitProductEnabled }

    if (errors && errors.length > 0) {
      updateToggle(previousValue)
      snackbar(value, 'error')
    } else {
      snackbar(value)
    }
  }

  const showTooltip: boolean =
    overwritePriceGeniusState(product, unfilledRecoveryAdUnit) && product === 'priceGenius'

  const tooltip: TooltipProps = {
    content: t('form.priceGenius.enabled.tooltip'),
    link: {
      title: t('common:learnMore'),
      url: KNOWLEDGE_BASE_URL,
    },
  }

  return (
    <Switch
      checked={
        overwritePriceGeniusState(product, unfilledRecoveryAdUnit)
          ? priceGeniusAlwaysOn
          : Boolean(getValue())
      }
      disabled={disabled}
      name={columnId}
      onChange={handleChange}
      {...(showTooltip && {
        tooltip,
        tooltipMode: 'wrap',
      })}
    />
  )
}

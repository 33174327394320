import { CollapsedY } from './types'

export const COLLAPSED_Y_DEFAULT: CollapsedY = {
  globalSetup: false,
  'globalSetup-prebid': false,
  inventory: false,
  performance: false,
  'performance-prebidStack': false,
  portal: false,
} as const

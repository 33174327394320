import { z } from 'zod'

const domainSupplyChainId = z.string()

const customDomainSid = z.string().optional()
export type SchemaCustomDomainSid = z.infer<typeof customDomainSid>

export const schema = z.object({
  domainSupplyChainsSids: z.record(domainSupplyChainId, customDomainSid),
})
export type Schema = z.infer<typeof schema>

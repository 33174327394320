import { CSSObject, Paper as PaperRoot, Theme } from '@mui/material'
import { CSSProperties } from 'react'

import { DEFAULTS } from './constants'
import { PaperProps } from './types'

export const Paper = ({ spacing = 'mp', variant, ...muiPaperProps }: PaperProps): JSX.Element => {
  const spacingProps = (): CSSObject => {
    switch (spacing) {
      case 'mp': {
        return {
          m: DEFAULTS.MARGIN,
          p: DEFAULTS.PADDING,
        }
      }
      case 'm': {
        return {
          m: DEFAULTS.MARGIN,
        }
      }
      case 'p': {
        return {
          p: DEFAULTS.PADDING,
        }
      }
      case 'none': {
        return {}
      }
    }
  }

  return (
    <PaperRoot
      {...muiPaperProps}
      sx={{
        ...spacingProps(),
        backgroundColor: (theme: Theme): CSSProperties['backgroundColor'] =>
          variant === 'cream' ? theme.palette.cream.main : theme.palette.white.main,
        border: 0,
        ...muiPaperProps.sx,
      }}
      variant='outlined'
    />
  )
}

import { SortInput } from 'components/Table'
import { AdUnitSortBy } from './types'

export const DEFAULT_SORT: SortInput<AdUnitSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: AdUnitSortBy[] = [
  'adUnit.path',
  'adUnit.divId',
  'csEnabled',
  's2sEnabled',
  'updatedAt',
]

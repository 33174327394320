import { useContext } from 'react'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { WorkspaceBidderSlice } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/hooks/useGetWorkspaceBidder'

export const useExistingWorkspaceBidder = (): {
  workspaceBidder: WorkspaceBidderSlice
} => {
  const { workspaceBidder } = useContext(WorkspaceBidderContext)
  if (!workspaceBidder) {
    throw Error('WorkspaceBidder should be accessible')
  }

  return { workspaceBidder }
}

import { z } from 'zod'

import { Workspace } from 'features/workspace'
import { commonSchema } from './commonSchema'

export const addSchema = z
  .object({
    workspaceId: z.string(),
  })
  .merge(commonSchema)

export type AddSchema = z.infer<typeof addSchema>

export const addSchemaDefault = ({ workspaceId }: { workspaceId: Workspace['id'] }): AddSchema => ({
  name: '',
  workspaceId,
})

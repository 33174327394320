import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import useDownloadTags from '../../hooks/useDownloadTags'
import { DownloadCellProps } from './types'

export const DownloadCell = ({ id, name, tagKind }: DownloadCellProps): JSX.Element => {
  const { t } = useTranslation('common')
  const { download } = useDownloadTags({ id, name, tagKind })

  return (
    <FlexBox
      axis='x'
      data-cy={`download-cell-${tagKind}`}
      gap={1}
      onClick={() => void download()}
      sx={{
        cursor: 'pointer',
      }}
    >
      <DownloadIcon />
      {t('actions.download')}
    </FlexBox>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_PREBID_MODULE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACE_PREBID_MODULES: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_PREBID_MODULE_FRAGMENT}

  query workspacePrebidModules(
    $first: Int
    $last: Int
    $moduleType: PrebidModuleTypeEnum!
    $sort: WorkspacePrebidModuleSortInput
    $workspaceId: ID!
  ) {
    workspacePrebidModules(
      first: $first
      last: $last
      moduleType: $moduleType
      workspaceId: $workspaceId
      sort: $sort
    ) {
      nodes {
        ...WorkspacePrebidModuleFragment
      }

      totalCount
    }
  }
`

import { Box, FormControlLabel, FormLabel, Switch as SwitchRoot } from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { SwitchProps } from './types'

const prepareValue = <T extends FieldValues>(
  fieldValue: boolean,
  componentValue: SwitchProps<T>['value'],
): boolean | undefined => {
  if (fieldValue !== undefined) {
    return fieldValue
  } else if (typeof componentValue === 'boolean' && componentValue !== undefined) {
    return componentValue
  } else return false
}

export const Switch = <T extends FieldValues>({
  bold,
  control,
  label,
  labelPlacement = 'end',
  name,
  tooltip: tooltipProps,
  tooltipMode = 'icon',
  ...switchProps
}: SwitchProps<T>): JSX.Element => {
  const Container = (): JSX.Element => (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name as FieldPath<T>}
          render={({ field }) => (
            <FlexBox axis='x'>
              <SwitchRoot
                {...field}
                {...switchProps}
                checked={prepareValue(field.value, switchProps.value)}
                id={name}
              />

              {tooltipMode === 'icon' && tooltipProps && <Tooltip {...tooltipProps} />}
            </FlexBox>
          )}
        />
      }
      label={
        <FormLabel
          sx={{
            fontWeight: theme => (bold ? 'bold' : theme.typography.fontWeightMedium),
          }}
        >
          {label}
        </FormLabel>
      }
      labelPlacement={labelPlacement}
      sx={{
        ml: 0,
        mr: label ? 1 : 0,
        width: 'fit-content',
      }}
    />
  )

  return tooltipProps && tooltipMode === 'wrap' ? (
    <Tooltip {...tooltipProps}>
      <Box>
        <Container />
      </Box>
    </Tooltip>
  ) : (
    <Container />
  )
}

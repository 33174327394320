import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Select, StackForm, Switch } from 'components/Form'
import Loading from 'components/Loading'
import { DomainAmazon } from 'features/globalSetup/features/amazon/types/domainAmazon'
import { useAbility } from 'providers/casl'
import { DomainAmazonOption, useDomainAmazonData } from '../../hooks/useDomainAmazonData'
import { Schema } from '../../schema'
import { DomainAmazonChangeEvent } from './types'

export const DomainSection = () => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'pages.inventoryConnection.basicAttributes',
  })
  const { control, setValue } = useFormContext<Schema>()
  const { loading, options } = useDomainAmazonData()

  if (loading) {
    return <Loading />
  }

  const handleChangeDomainAmazon = (event: DomainAmazonChangeEvent): void => {
    setValue('id', event.target.value as DomainAmazon['id'], { shouldDirty: false })
  }

  const canUpdate: boolean = ability.can('update', 'GlobalSetupFeature-amazon')

  return (
    <BoxForm>
      <StackForm>
        <Select<Schema, DomainAmazonOption>
          control={control}
          label={t('id.label')}
          name='id'
          onChange={(event: DomainAmazonChangeEvent) => handleChangeDomainAmazon(event)}
          options={options}
        />

        <Switch
          control={control}
          disabled={!canUpdate}
          label={t('enabled.label')}
          labelPlacement='start'
          name='enabled'
        />
      </StackForm>
    </BoxForm>
  )
}

import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { UPDATE_DOMAIN_VIEWABILITY_TOOLS } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseUpdateDomainViewabilityTools } from './types'

export const useUpdateDomainViewabilityTools = (): UseUpdateDomainViewabilityTools => {
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditDomainContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(UPDATE_DOMAIN_VIEWABILITY_TOOLS, {
    onCompleted: ({ updateDomainViewabilityTool: { errors } }: Data) => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const mapper = (variables: Schema): Variables => {
    const { adUnitsAttributes, fetchMargin, renderMargin, slowingFactor, trafficAffected } =
      variables

    return {
      ...variables,
      adUnitsAttributes: adUnitsAttributesMapper<'viewabilityToolsEnabled'>(
        adUnitsAttributes,
        'viewabilityToolsEnabled',
      ),
      fetchMargin: fetchMargin / 100,
      renderMargin: renderMargin / 100,
      slowingFactor: slowingFactor / 100,
      trafficAffected: trafficAffected / 100,
    }
  }

  const update = (variables: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: {
        input: mapper(variables),
      },
    })
  }

  return { errors, update }
}

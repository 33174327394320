import { z } from 'zod'

import { SignInVars } from 'features/user/api/types/signIn'

export const schema: z.ZodType<SignInVars> = z.object({
  email: z.string().email(),
  password: z.string().min(1),
})

export const schemaDefaults: SignInVars = {
  email: '',
  password: '',
}

export type Schema = z.infer<typeof schema>

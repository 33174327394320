import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_PREBID_CONFIGURATION: TypedDocumentNode<Data, { input: Variables }> =
  gql`
    ${ERROR_FRAGMENT}

    mutation updateWorkspacePrebidConfiguration($input: UpdateWorkspacePrebidConfigurationInput!) {
      updateWorkspacePrebidConfiguration(input: $input) {
        errors {
          ...ErrorFragment
        }
      }
    }
  `

import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { Schema } from '../../form/schema'
import { toDTO } from './api/mapper'
import { UPDATE_BIDDER_SUPPLY_CHAIN } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateBidderSupplyChains, UseUpdateBidderSupplyChainsProps } from './types'

export const useUpdateBidderSupplyChains = ({
  supplyChainName,
}: UseUpdateBidderSupplyChainsProps): UseUpdateBidderSupplyChains => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.bidderSids.update',
  })
  const { setLoading } = useContext(SupplyChainContext)

  const [mutate] = useMutation(UPDATE_BIDDER_SUPPLY_CHAIN, {
    onCompleted: ({ bulkUpdateWorkspaceBidder: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        enqueueSnackbar(t('error', { supplyChain: supplyChainName }), { variant: 'error' })
      } else {
        enqueueSnackbar(t('success', { supplyChain: supplyChainName }), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): void => {
    setLoading(true)

    mutate({ variables: { input: toDTO(data) } })
  }

  return { update }
}

import { Table, TableFooter, TableRow, TableRowProps } from '@mui/material'

export const EmptyTableFooter = ({ children }: TableRowProps): JSX.Element => (
  <Table>
    <TableFooter>
      <TableRow
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </TableRow>
    </TableFooter>
  </Table>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql/api'
import { USER_FRAGMENT } from '../fragments/user'
import { SignInData, SignInVars } from '../types/signIn'

export const SIGN_IN: TypedDocumentNode<SignInData, SignInVars> = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation signIn($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      accessToken
      refreshToken

      user {
        ...UserFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

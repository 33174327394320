import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import WorkspaceAlerts from '../WorkspaceAlerts'
import { OptionProps } from './types'

export const Option = ({ alerts, name, networkCode }: OptionProps): JSX.Element => {
  const { t } = useTranslation('features/workspace')

  return (
    <FlexBox
      axis='x'
      gap={1}
    >
      <Typography variant='button'>{name}</Typography>

      <Box
        sx={{
          alignSelf: 'flex-end',
          display: 'flex',
        }}
      >
        <Typography variant='caption'>
          {t('workspaceSelect.googleAdManagerNetworkCode', { networkCode })}
        </Typography>
      </Box>

      {alerts.length > 0 && <WorkspaceAlerts alerts={alerts} />}
    </FlexBox>
  )
}

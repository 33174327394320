import { Tab as TabMui, Tabs as TabsMui } from '@mui/material'
import _ from 'lodash'
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom'

import Paper from 'components/Paper'
import { ROUTES } from 'routes'
import { Tab, TabsProps, TabsUrlParams } from './types'

export const Tabs = <T extends string>({ path, tabs }: TabsProps<T>): JSX.Element => {
  const navigate = useNavigate()
  const { id, tab: currentTab, workspaceId } = useParams<TabsUrlParams<T>>()

  if (
    !currentTab ||
    !_.includes(
      tabs.map((t: Tab<T>): T => t.element),
      currentTab,
    )
  ) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  }

  const handleTabClick = (tab: Tab<T>): void => {
    navigate(
      generatePath(path, {
        id,
        tab: tab.element,
        workspaceId,
      }),
    )
  }

  return (
    <Paper
      spacing='m'
      sx={{ px: 3 }}
    >
      <TabsMui value={currentTab}>
        {tabs.map(
          (tab: Tab<T>): JSX.Element => (
            <TabMui
              data-cy={tab.element}
              disabled={tab.disabled}
              key={tab.element}
              label={tab.label}
              onClick={(): void => handleTabClick(tab)}
              value={tab.element}
            />
          ),
        )}
      </TabsMui>
    </Paper>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_PRICE_GENIUS: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateDomainPriceGenius($input: UpdateDomainPriceGeniusInput!) {
    updateDomainPriceGenius(input: $input) {
      domainPriceGenius {
        alwaysOn
        createdAt
        domainId
        id
        priceGeniusEnabled
        trafficPercent
        updatedAt
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

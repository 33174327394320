import { Box } from '@mui/material'

import { FooterInfoProps } from './types'

export const FooterInfo = ({ color, text }: FooterInfoProps) => (
  <Box
    component='td'
    sx={{
      alignItems: 'center',
      color: color || undefined,
      display: 'flex',
      p: 2,
    }}
  >
    {text}
  </Box>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_FINANCE_REPORT_URL: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateFinanceReportUrl($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        id
        financeReportUrl
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

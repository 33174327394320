import { gql, TypedDocumentNode } from '@apollo/client'

import { GAM_CONNECTION_ORDER_FRAGMENT } from 'features/workspace/api/fragments/gamConnectionOrder'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_GOOGLE_AD_MANAGER_CONNECTION: TypedDocumentNode<Data, { input: Variables }> =
  gql`
    ${GAM_CONNECTION_ORDER_FRAGMENT}
    ${ERROR_FRAGMENT}

    mutation createGamConnectionOrder($input: CreateGamConnectionOrderInput!) {
      createGamConnectionOrder(input: $input) {
        gamConnectionOrder {
          ...GamConnectionOrderFragment
        }

        errors {
          ...ErrorFragment
        }
      }
    }
  `

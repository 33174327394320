import { Box, Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NumberField, Switch } from 'components/Form'
import { useGetWorkspacePrebidConfiguration } from 'features/globalSetup'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { KNOWLEDGE_BASE_URL } from '../../../constants'
import { EditSchema } from '../../../schemas/editSchema'
import { RelatedSettings } from '../RelatedSettings'

export const FailsafeTimeout = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { workspaceId } = useWorkspaceParam()
  const { domain } = useContext(AddEditDomainContext)
  const { workspacePrebidConfiguration } = useGetWorkspacePrebidConfiguration({
    workspaceId,
  })
  const { control, watch } = useFormContext<EditSchema>()
  const { customFailsafeTimeoutEnabled } = watch()

  return (
    <Stack
      alignItems='flex-start'
      direction='row'
      spacing={2}
    >
      <Box>
        <Switch
          control={control}
          label={t('form.general.advancedSettings.customFailsafeTimeoutEnabled.label')}
          labelPlacement='start'
          name='customFailsafeTimeoutEnabled'
          tooltip={{
            content: [
              t('form.general.advancedSettings.customFailsafeTimeoutEnabled.tooltip.line1'),
              t('form.general.advancedSettings.customFailsafeTimeoutEnabled.tooltip.line2'),
              t('form.general.advancedSettings.customFailsafeTimeoutEnabled.tooltip.line3'),
              t('form.general.advancedSettings.customFailsafeTimeoutEnabled.tooltip.line4'),
            ],
            link: {
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL.FAILSAFE_TIMEOUT,
            },
          }}
        />

        {customFailsafeTimeoutEnabled && (
          <NumberField
            control={control}
            label={t(
              'form.general.advancedSettings.customFailsafeTimeoutEnabled.failsafeTimeout.label',
            )}
            name='failsafeTimeout'
          />
        )}
      </Box>

      {customFailsafeTimeoutEnabled && domain && workspacePrebidConfiguration && (
        <RelatedSettings {...{ domain, workspacePrebidConfiguration }} />
      )}
    </Stack>
  )
}

import { MouseEvent, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ToggleButtonGroup } from 'components/Form'
import { dataSamplingValues } from 'features/workspace/types/dataSampling'
import { AnalyticsContext } from '../../contexts/AnalyticsContext'
import { Schema } from '../../form/schema'

export const DataSamplingInput = (): JSX.Element => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })
  const {
    dataSampling: { setTempValue },
    dialog: { setIsDialogOpen },
  } = useContext(AnalyticsContext)
  const { control } = useFormContext<Schema>()

  const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    const newValue: string = (event.target as HTMLInputElement).value

    setIsDialogOpen(true)
    setTempValue(newValue as Schema['dataSampling'])
  }

  return (
    <ToggleButtonGroup
      arrangement='horizontal'
      control={control}
      label={t('dataSampling.label')}
      name='dataSampling'
      onClick={handleClick}
      options={dataSamplingValues}
      tooltip={{ content: t('dataSampling.tooltip') }}
      unit='%'
    />
  )
}

import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { useAbility } from 'providers/casl'
import { useAsterioBidModule } from '../useAsterioBidModule'
import { UrlTabs, UseTabsProps } from './types'

export const useTabs = ({ token }: UseTabsProps): Tab<UrlTabs>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })

  const AsterioBid = useAsterioBidModule({ token })

  const isDisabled = ability.cannot('access', 'Product-prebidStack')

  return [
    {
      disabled: isDisabled,
      element: 'total',
      label: t('tabs.total'),
      page: AsterioBid('TotalDashboard'),
    },
    {
      disabled: isDisabled,
      element: 'media-types',
      label: t('tabs.mediaTypes'),
      page: AsterioBid('AdTypeComponent'),
    },
    {
      disabled: isDisabled,
      element: 'bidders',
      label: t('tabs.bidders'),
      page: AsterioBid('BiddersComponent'),
    },
    {
      disabled: isDisabled,
      element: 'sites',
      label: t('tabs.sites'),
      page: AsterioBid('SitesComponent'),
    },
    {
      disabled: isDisabled,
      element: 'ab-tests',
      label: t('tabs.abTests'),
      page: AsterioBid('VersionComparisonComponent'),
    },
    {
      disabled: isDisabled,
      element: 'report',
      label: t('tabs.report'),
      page: AsterioBid('AuctionsReportScreen'),
    },
  ]
}

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { UserManagementFormContext } from '../../../../contexts/UserManagementFormContext'
import { EditSchema } from '../../schemas/edit'
import { Data, UPDATE_EMPLOYEE } from './api'
import { mapper } from './mapper'
import { UseUpdateEmployee } from './types'

export const useUpdateEmployee = (): UseUpdateEmployee => {
  const navigate = useNavigate()
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(UserManagementFormContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    onCompleted: ({ updateEmployee: { errors, user } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.employee.edit.error'), { variant: 'error' })
      } else if (user) {
        setErrors({})
        navigate(generatePath(ROUTES.USER.LIST, { tab: 'employees' }))
        enqueueSnackbar(t('form.employee.edit.success', { email: user.email }), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
  })

  const updateEmployee = (data: EditSchema) => {
    setLoading(true)

    return mutateUpdateEmployee({ variables: { input: mapper(data) } })
  }

  return { errors, updateEmployee }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { GamConnectionOrderDTO } from 'features/workspace/api/types/gamConnectionOrderDTO'

export const GAM_CONNECTION_ORDER_FRAGMENT: TypedDocumentNode<GamConnectionOrderDTO> = gql`
  fragment GamConnectionOrderFragment on GamConnectionOrder {
    errorMessage
    id
    status
    updatedAt
  }
`

import { DatePicker } from '@mui/x-date-pickers'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { DATE_FORMAT } from 'utils/date'
import { DateFieldProps } from './types'

export const DateField = <T extends FieldValues>({
  control,
  name,
  ...datePickerProps
}: DateFieldProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field, fieldState: { error } }): JSX.Element => (
      <DatePicker
        {...field}
        {...datePickerProps}
        format={DATE_FORMAT.DATE_PICKER}
        slotProps={{
          textField: {
            error: Boolean(error),
            helperText: error?.message,
            id: name,
            sx: {
              minHeight: 80,
              minWidth: 250,
              width: 250,
            },
          },
        }}
        timezone='system'
        value={field.value || null}
      />
    )}
  />
)

import i18next from 'i18next'
import { z } from 'zod'

import { prebidModuleAccounts } from 'features/globalSetup/types/prebidModule'
import { params } from './paramsSchema'

const common = z.object({
  accountType: z.enum(prebidModuleAccounts),
  prebidModuleId: z.string().min(1, i18next.t('common:form.error.nonEmpty')),
})

export const commonSchema = z.intersection(common, params)

export type CommonSchema = z.infer<typeof commonSchema>

import { Provider as RollbarProvider } from '@rollbar/react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'

import 'providers/i18n'
import 'providers/monaco/config'

import { AbilityProvider } from 'providers/casl'
import { GraphQLProvider } from 'providers/graphql'
import { MaterialUiProvider } from 'providers/material-ui'
import { LocalizationProvider } from 'providers/mui-x'
import { store } from 'providers/redux'
import { rollbarConfig } from 'providers/rollbar/config'
import { RouterProvider } from 'providers/router'
import { SnackbarProvider } from 'providers/snackbar'

import 'styles/index.scss'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RollbarProvider config={rollbarConfig}>
    <ReduxProvider store={store}>
      <MaterialUiProvider>
        <SnackbarProvider>
          <GraphQLProvider>
            <AbilityProvider>
              <LocalizationProvider>
                <RouterProvider />
              </LocalizationProvider>
            </AbilityProvider>
          </GraphQLProvider>
        </SnackbarProvider>
      </MaterialUiProvider>
    </ReduxProvider>
  </RollbarProvider>,
)

import { mapDTO } from '@twistezo/ts-dto-mapper'
import { isNil } from 'lodash'

import { demandToAccount } from 'features/globalSetup/api/mappers/demandToAccount'
import { PrebidModuleDTO } from 'features/globalSetup/api/types/prebidModule'
import {
  PrebidModuleSlice,
  WorkspacePrebidModuleSlice,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { WorkspacePrebidModuleParameter } from 'features/globalSetup/types/workspacePrebidModule'
import { withoutOuterQuotes } from 'utils/string'
import { WorkspacePrebidModuleSliceDTO } from './types'

type From = WorkspacePrebidModuleSliceDTO
type To = WorkspacePrebidModuleSlice
type PrebidModuleSliceDTO = Pick<PrebidModuleDTO, 'demand' | 'id' | 'name'>

const prebidModuleSliceFromDTO = (
  prebidModuleSliceDTO: PrebidModuleSliceDTO,
): PrebidModuleSlice => {
  const { demand, id, name } = prebidModuleSliceDTO

  return {
    account: demandToAccount(demand),
    id: id,
    name: name,
  }
}

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((workspacePrebidModule: From): To => {
    const { enabled, id, params, prebidModule, status } = workspacePrebidModule

    const parameters: WorkspacePrebidModuleParameter[] = Object.entries(params).map(
      ([key, value]: [key: string, value: unknown]) => {
        return {
          name: key,
          value: isNil(value) ? '' : withoutOuterQuotes(JSON.stringify(value)),
        }
      },
    )

    return {
      enabled,
      id,
      parameters,
      prebidModule: prebidModuleSliceFromDTO(prebidModule),
      status,
    }
  })

import { useTranslation } from 'react-i18next'

import { SelectOption } from 'components/Select'
import {
  defaultPriorities,
  DomainBidderPriority,
  workspaceBidderPriorities,
} from 'features/globalSetup/types/bidderPriority'
import { UseGetPrioritiesForSelectProps } from './types'

export const useGetPrioritiesForSelect = (
  props: UseGetPrioritiesForSelectProps,
): SelectOption<DomainBidderPriority>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.priorityOptions',
  })

  let availablePriorities: DomainBidderPriority[]

  if (props.variant === 'domainBidder') {
    const { workspaceBidderPriority } = props

    availablePriorities = [defaultPriorities[workspaceBidderPriority], ...workspaceBidderPriorities]
  } else {
    availablePriorities = [...workspaceBidderPriorities]
  }

  return availablePriorities.map(
    (priority: DomainBidderPriority): SelectOption<DomainBidderPriority> => ({
      label: t(priority),
      value: priority,
    }),
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Schema } from '../../../schema'
import { DomainAmazonSlice } from '../types'
import { AdUnitAmazonAttribute, DomainAmazonSliceDTO, Variables } from './types'

export const fromDTO = (from: DomainAmazonSliceDTO): DomainAmazonSlice =>
  mapDTO<DomainAmazonSliceDTO, DomainAmazonSlice>({ from }).transform(
    (data: DomainAmazonSliceDTO): DomainAmazonSlice => ({
      ...data,
    }),
  )

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform((data: Schema): Variables => {
    const { adUnitAmazonsAttributes, enabled, id } = data

    if (id === undefined) {
      throw Error('DomainAmazon ID should be defined')
    }

    const parsedAttributes: AdUnitAmazonAttribute[] = Object.entries(adUnitAmazonsAttributes).map(
      ([key, value]: [key: string, value: boolean]): AdUnitAmazonAttribute => ({
        enabled: value,
        id: key,
      }),
    )

    return {
      adUnitAmazonAttributes: parsedAttributes,
      enabled,
      id,
    }
  })

import { createContext, ReactNode, useState } from 'react'

import { useCommonFormContextProps } from 'components/Form'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { DATA_SAMPLING_DEFAULT } from '../../constants'
import { Schema } from '../../form/schema'
import { useUpdateDataSampling } from '../../hooks/useUpdateDataSampling'
import { AnalyticsContextType } from './types'

export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType)

export const AnalyticsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [tempValue, setTempValue] = useState<Schema['dataSampling']>(DATA_SAMPLING_DEFAULT)

  const { workspaceId } = useWorkspaceParam()
  const { errors, loading, update } = useUpdateDataSampling({ workspaceId })

  const props: AnalyticsContextType = {
    ...useCommonFormContextProps({
      formId: 'performance-prebid-stack-analytics-form',
    }),
    dataSampling: {
      setTempValue,
      tempValue,
    },
    dialog: {
      isDialogOpen,
      setIsDialogOpen,
    },
    update: {
      errors,
      loading,
      update,
    },
  }

  return <AnalyticsContext.Provider value={props}>{children}</AnalyticsContext.Provider>
}

import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { fromDTO } from './api/mapper'
import { GET_DOMAIN_BIDDERS_FOR_SELECT } from './api/query'
import { DomainBidderSliceDTO } from './api/types'
import { DomainBidderOption, Props, UseGetDomainBiddersForSelect } from './types'

export const useGetDomainBiddersForSelect = ({
  workspaceBidderId,
}: Props): UseGetDomainBiddersForSelect => {
  const [options, setOptions] = useState<SelectOption<DomainBidderOption>[]>([])

  const { loading } = useQuery(GET_DOMAIN_BIDDERS_FOR_SELECT, {
    onCompleted: ({ domainBidders: { nodes } }): void => {
      if (nodes && nodes.length > 0) {
        setOptions(nodes.map((node: DomainBidderSliceDTO) => fromDTO(node)))
      }
    },

    variables: {
      workspaceBidderId,
    },
  })

  return { loading, options }
}

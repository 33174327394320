import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { FILTERING_RULE_FRAGMENT_FOR_UPDATE } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_FILTERING_RULE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${FILTERING_RULE_FRAGMENT_FOR_UPDATE}
  ${ERROR_FRAGMENT}

  mutation updateFilteringRule($input: UpdateFilteringRuleInput!) {
    updateFilteringRule(input: $input) {
      filteringRule {
        ...FilteringRuleFragmentForUpdate
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { UPDATE_AD_UNIT_PRODUCT_ENABLED } from './api/mutation'
import { Data, Variables } from './api/types'
import { SwitchVariables, UseSwitchAdUnitProduct, useSwitchAdUnitProductProps } from './types'

export const useSwitchAdUnitProduct = ({
  setLoading,
}: useSwitchAdUnitProductProps): UseSwitchAdUnitProduct => {
  const { t } = useTranslation('features/adUnit')
  const { enqueueSnackbar } = useSnackbar()

  const [mutate] = useMutation(UPDATE_AD_UNIT_PRODUCT_ENABLED, {
    onCompleted: ({ updateAdUnitProductEnabled: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const mapper = ({ id, product, value }: SwitchVariables): Variables => ({
    enabledValue: value,
    id,
    productType: product,
  })

  const update = (variables: SwitchVariables): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: {
        input: mapper(variables),
      },
    })
  }

  return { update }
}

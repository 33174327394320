import { Stack } from '@mui/material'

import { IframeProps } from './types'

export const Iframe = ({ src }: IframeProps): JSX.Element => (
  <Stack
    alignItems='center'
    direction='row'
    sx={{
      '& iframe': {
        border: 'none',
        minHeight: '1200px',
        width: '100%',
      },
      pt: 2,
      px: 2,
    }}
  >
    <iframe src={src}></iframe>
  </Stack>
)

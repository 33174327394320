import { gql, TypedDocumentNode } from '@apollo/client'

import { EMPLOYEE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_EMPLOYEE: TypedDocumentNode<Data, Variables> = gql`
  ${EMPLOYEE_FRAGMENT}

  query getUser($id: ID!) {
    node(id: $id) {
      ... on User {
        ...EmployeeFragment
      }
    }
  }
`

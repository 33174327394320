import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_PAGES_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${PAGE_FOR_TABLE_FRAGMENT}

  query getPages(
    $first: Int
    $last: Int
    $terms: [String!]
    $workspaceId: ID!
    $sort: PageSortInput
  ) {
    pages(first: $first, last: $last, terms: $terms, workspaceId: $workspaceId, sort: $sort) {
      nodes {
        ...PageForTableFragment
      }

      totalCount
    }
  }
`

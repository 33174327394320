import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { PAGE_UPDATE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_PAGE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${PAGE_UPDATE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      page {
        ...PageUpdateFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

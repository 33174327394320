import { gql, TypedDocumentNode } from '@apollo/client'

import { PREBID_MODULE_PARAMETERS } from './fragment'
import { Data, Variables } from './types'

export const GET_PREBID_MODULE_PARAMETERS_SCHEMA: TypedDocumentNode<Data, Variables> = gql`
  ${PREBID_MODULE_PARAMETERS}

  query getPrebidModuleParametersSchema($id: ID!) {
    node(id: $id) {
      ... on PrebidModule {
        id
        paramsSchema {
          ...PrebidModuleParameter
        }
      }
    }
  }
`

import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { Account, accounts } from 'features/globalSetup/types/workspaceBidder'

export const useAccountTypeOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/globalSetup')

  return accounts.map(
    (value: Account): RadioGroupOption => ({
      label: t(`common.accountType.options.${value}`),
      value,
    }),
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ViewportSliceDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MEDIA_TYPE_FOR_FORM_FRAGMENT, MEDIA_TYPE_FRAGMENT } from './mediaType'

export const VIEWPORT_FOR_FORM_FRAGMENT: TypedDocumentNode<ViewportSliceDTO> = gql`
  ${MEDIA_TYPE_FOR_FORM_FRAGMENT}

  fragment ViewportForFormFragment on Viewport {
    id
    minimumWidth
    name
    updatedAt
    ${MEDIA_TYPE_FRAGMENT}
  }
`

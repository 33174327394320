import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { AdUnit } from 'features/inventory/types/adUnit'
import { AdUnitsAttributes, AdUnitsAttributesEnabledName } from './types'

type AdUnitAttributesDTO<T extends AdUnitsAttributesEnabledName> = Pick<AdUnitDTO, 'id'> &
  Record<T, boolean>

export const adUnitsAttributesMapper = <T extends AdUnitsAttributesEnabledName>(
  adUnitsAttributes: AdUnitsAttributes,
  enabledAttributeName: T,
): AdUnitAttributesDTO<T>[] =>
  Object.entries(adUnitsAttributes).map(
    ([key, value]: [key: AdUnit['id'], value: boolean]): AdUnitAttributesDTO<T> =>
      ({
        [enabledAttributeName]: value,
        id: key,
      }) as AdUnitAttributesDTO<T>,
  )

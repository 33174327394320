import { z } from 'zod'

import { commonSchema } from './commonSchema'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof addSchema>

export const addSchemaDefault: AddSchema = {
  accountType: 'own',
  prebidModuleId: '',
}

import { Alert, AlertProps, AlertTitle, Typography } from '@mui/material'

export const AlertBox = ({ children, title, ...props }: AlertProps): JSX.Element => (
  <Alert
    {...props}
    data-cy={`alert-box-${props.severity}`}
  >
    <AlertTitle>
      <Typography fontWeight='bold'>{title}</Typography>
    </AlertTitle>

    {children}
  </Alert>
)

import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MutationInputError } from 'providers/graphql'
import { UPDATE_DOMAIN_PRODUCT_ENABLED } from './api/mutation'
import { Data, Variables } from './api/types'
import { SwitchVariables, UseSwitchDomainProduct, UseSwitchDomainProductProps } from './types'

export const useSwitchDomainProduct = ({
  name,
  product,
  value,
}: UseSwitchDomainProductProps): UseSwitchDomainProduct => {
  const { t } = useTranslation('features/domain')
  const { t: tProduct } = useTranslation('features/product')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<MutationInputError[]>([])

  const tParams = {
    action: value ? 'enabled' : 'disabled',
    name,
    product: tProduct(`product.${product}`),
  }

  const [mutate] = useMutation(UPDATE_DOMAIN_PRODUCT_ENABLED, {
    onCompleted: ({ updateDomainProductEnabled: { errors } }): void => {
      if (errors && errors.length > 0) {
        setErrors(errors)
        enqueueSnackbar(t('list.product.switch.error', tParams), {
          variant: 'error',
        })
      } else {
        setErrors([])
        enqueueSnackbar(t('list.product.switch.success', tParams), {
          variant: value ? 'success' : 'warning',
        })
      }
    },
  })

  const mapper = ({ id, product, value }: SwitchVariables): Variables => ({
    enabledValue: value,
    id,
    productType: product,
  })

  const update = (variables: SwitchVariables): Promise<FetchResult<Data>> =>
    mutate({
      variables: {
        input: mapper(variables),
      },
    })

  return { errors, update }
}

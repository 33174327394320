import { Viewport } from 'features/inventory/viewport'
import { MediaType } from './mediaType'

export const mediaTypesKindValues = ['default', 'viewports'] as const

export type MediaTypesKind = (typeof mediaTypesKindValues)[number]

export type MediaTypes = {
  defaultAttribute: MediaTypeDefaultAttribute
  kind: MediaTypesKind
  nativeConfiguration: string
  outstreamConfiguration: string
  viewportAttributes: MediaTypeViewportAttribute[]
}

export type ViewportSlice = Pick<
  Viewport,
  | 'bannerEnabled'
  | 'bannerSizes'
  | 'id'
  | 'minimumWidth'
  | 'name'
  | 'nativeEnabled'
  | 'outstreamEnabled'
  | 'playerSize'
>

export type MediaTypeDefaultAttribute = {
  mediaTypeId: MediaType['id']
} & Pick<
  MediaType,
  | 'bannerEnabled'
  | 'bannerSizes'
  | 'nativeEnabled'
  | 'outstreamEnabled'
  | 'playerSize'
  | 'updatedAt'
>

export type MediaTypeViewportAttribute = {
  viewport: ViewportSlice
} & MediaTypeDefaultAttribute

import { useEffect, useState } from 'react'

import { TransferListItem } from '../types'
import { UseFilteredList, UseTransferListProps } from './types'

export const useFilteredList = ({ leftList, rightList }: UseTransferListProps): UseFilteredList => {
  const [filter, setFilter] = useState<string>('')
  const [left, setLeft] = useState<TransferListItem[]>(leftList)
  const [right, setRight] = useState<TransferListItem[]>(rightList)

  const filterList = (list: TransferListItem[]): TransferListItem[] =>
    list.filter(({ label }: TransferListItem): boolean => label.includes(filter))

  useEffect(() => {
    if (filter.length > 0) {
      setLeft(filterList(leftList))
      setRight(filterList(rightList))
    } else {
      setLeft(leftList)
      setRight(rightList)
    }
  }, [filter, leftList, rightList]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    filter,
    filteredLeft: left,
    filteredRight: right,
    setFilter,
  }
}

import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { AddThirdPartyProviderSchema } from '../../schemas/addSchema'
import { useOnCompleted } from '../useOnCompleted'
import { toDTO } from './api/mapper'
import { CREATE_THIRD_PARTY_PROVIDER_WORKSPACE_BIDDER } from './api/mutation'
import { UseCreateThirdPartyProviderWorkspaceBidder } from './types'

export const useCreateThirdPartyProviderWorkspaceBidder =
  (): UseCreateThirdPartyProviderWorkspaceBidder => {
    const { workspaceId } = useWorkspaceParam()
    const { onCompleted } = useOnCompleted()
    const [errors, setErrors] = useState<FieldErrors<AddThirdPartyProviderSchema>>({})

    const [mutate] = useMutation(CREATE_THIRD_PARTY_PROVIDER_WORKSPACE_BIDDER)

    const createThirdPartyProvider = async (data: AddThirdPartyProviderSchema): Promise<void> => {
      const variables = { input: toDTO({ ...data, workspaceId }) }
      const { data: response } = await mutate({ variables })

      if (response) {
        const { bidderCode } = data

        setErrors(onCompleted(bidderCode, response))
      }
    }

    return { createThirdPartyProvider, thirdPartyProviderErrors: errors }
  }

import { z } from 'zod'

import { AdUnitForForm } from '../GeneralForm/hooks/useGetAdUnit'

export const schema = z.object({
  id: z.string(),
  unfilledRecoveryEnabled: z.boolean(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ id, unfilledRecoveryEnabled }: AdUnitForForm): Schema => ({
  id,
  unfilledRecoveryEnabled,
})

import { gql, TypedDocumentNode } from '@apollo/client'

import { USER_WORKSPACE_FRAGMENT } from 'features/user/api/fragments/userWorkspace'
import { PublisherForTableDTO } from './types'

export const PUBLISHER_FOR_TABLE_FRAGMENT: TypedDocumentNode<PublisherForTableDTO> = gql`
  ${USER_WORKSPACE_FRAGMENT}

  fragment PublisherForTableFragment on User {
    email
    firstName
    id
    lastName
    role
    status
    updatedAt

    ...UserWorkspaceFragment
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_BIDDERS: TypedDocumentNode<Data, Variables> = gql`
  query bidders($accountType: BidderAccountTypeEnum!) {
    bidders(accountType: $accountType) {
      nodes {
        id
        name
        code
        validator
        ybSid
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { CustomCodeDTO } from '../types/customCodeDTO'

export const CUSTOM_CODE_FRAGMENT: TypedDocumentNode<CustomCodeDTO> = gql`
  fragment CustomCodeFragment on CustomCode {
    codeAfter
    codeBefore
    comment
    createdAt
    id
    updatedAt
  }
`

import { Box } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'

import { TOOLBAR_HEIGHT } from 'providers/material-ui/theme/constants'
import { StickyBoxProps } from './types'

const checkStickyStatus = (): boolean => window.scrollY > 0

export const StickyBox = ({ children }: StickyBoxProps): JSX.Element => {
  const [isSticky, setIsSticky] = useState<boolean>(checkStickyStatus())

  window.onscroll = _.throttle(() => {
    setIsSticky(checkStickyStatus())
  }, 100)

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        boxShadow: isSticky ? '10px 10px 10px 0 rgba(0, 0, 0, 0.05)' : 'none',
        position: 'sticky',
        top: TOOLBAR_HEIGHT,
        zIndex: theme => theme.zIndex.stickyBox,
      }}
    >
      {children}
    </Box>
  )
}

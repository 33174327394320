import { useTranslation } from 'react-i18next'

import { SliderMark } from 'components/Form/Slider/types'

const VALUES = {
  DEFAULT: 75,
} as const

export const useS2sTimeoutMultiplierMarks = (): SliderMark[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.timeouts.s2sTimeoutMultiplier',
  })

  return [
    {
      label: t('default', { value: VALUES.DEFAULT }),
      value: VALUES.DEFAULT,
    },
  ]
}

import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Dialog from 'components/Dialog'
import { useDialogConfig } from './hooks/useDialogConfig'
import { useUploadAdUnitsConfiguration } from './hooks/useUploadAdUnitsConfiguration'
import { UploadDialogProps } from './types'

export const UploadDialog = ({ domainId, isOpen, setOpen }: UploadDialogProps): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const [file, setFile] = useState<File>()
  const useUploadProps = useUploadAdUnitsConfiguration({ domainId })

  useEffect(() => {
    if (!isOpen) {
      useUploadProps.reset()
      setFile(undefined)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    button: { action, disabled, title },
    content,
  } = useDialogConfig({
    file,
    setFile,
    setOpen,
    ...useUploadProps,
  })

  const Content: JSX.Element = <FlexBox axis='xy'>{content}</FlexBox>

  const Action: JSX.Element = (
    <Button
      disabled={disabled || file === undefined}
      onClick={action}
      variant='contained'
    >
      {title}
    </Button>
  )

  return (
    <Dialog
      actions={Action}
      content={Content}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t('form.general.bulkManagement.dialog.title')}
    />
  )
}

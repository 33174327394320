import { Form as FormContainer } from 'components/Form'
import Loading from 'components/Loading'
import { Form } from './Form'
import { useRequestResetPassword } from './hooks/useRequestResetPassword'
import { Schema, schema, schemaDefaults } from './schema'

export const UserRequestPasswordResetForm = (): JSX.Element => {
  const { errors: apiErrors, loading, requestResetPassword } = useRequestResetPassword()

  return (
    <FormContainer<Schema>
      externalErrors={apiErrors}
      onSubmit={requestResetPassword}
      schema={schema}
      schemaDefaults={schemaDefaults}
      withLeaveDialog={false}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormContainer>
  )
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  PrebidModulesContext,
  PrebidModulesContextType,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { AddContainer } from 'features/globalSetup/forms/Prebid/Modules/VendorSpecificForm/GeneralForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

export const VendorSpecificAdd = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const [saveButtonText, setSaveButtonText] = useState<string>('')

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-modules-vendor-specific-form-add',
  })
  const contextProps: PrebidModulesContextType = {
    saveButtonText,
    setSaveButtonText,
    ...commonContextProps,
  }

  return (
    <PrebidModulesContext.Provider value={contextProps}>
      <PrebidModulesContext.Consumer>
        {(ctxProps: PrebidModulesContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                text={saveButtonText}
              />
            }
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.VENDOR_SPECIFIC.LIST, {
              workspaceId,
            })}
            title={t('prebid.modules.vendorSpecific.add.title')}
          />
        )}
      </PrebidModulesContext.Consumer>

      <AddContainer />
    </PrebidModulesContext.Provider>
  )
}

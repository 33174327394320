import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_PRODUCT_ENABLED: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateDomainProductEnabled($input: UpdateDomainProductEnabledInput!) {
    updateDomainProductEnabled(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

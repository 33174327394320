import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'

import { AddEditPageContext } from 'features/inventory/page/contexts/AddEditPageContext'
import { AdUnitForPageTransferList } from './adUnitForPageTransferList'
import { adUnitForPageTransferListFromDTO } from './api/mapper'
import { GET_AD_UNITS_FOR_PAGE_TRANSFER_LIST } from './api/query'
import { AdUnitForPageTransferListDTO } from './api/types'
import { UseGetAdUnitsForPageTransferList, UseGetAdUnitsForPageTransferListProps } from './types'

export const useGetAdUnitsForPageTransferList = ({
  domainId,
}: UseGetAdUnitsForPageTransferListProps): UseGetAdUnitsForPageTransferList => {
  const { page } = useContext(AddEditPageContext)
  const [all, setAll] = useState<AdUnitForPageTransferList[]>([])

  const active: AdUnitForPageTransferList[] = page?.adUnits ?? []

  useEffect(() => {
    if (domainId) {
      fetch({ variables: { domainId } })
    }
  }, [domainId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetch, { loading }] = useLazyQuery(GET_AD_UNITS_FOR_PAGE_TRANSFER_LIST, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ node: { adUnits } }) => {
      setAll(
        adUnits.map(
          (adUnit: AdUnitForPageTransferListDTO): AdUnitForPageTransferList =>
            adUnitForPageTransferListFromDTO(adUnit),
        ),
      )
    },
  })

  return {
    active,
    all,
    available: _.differenceBy(all, active, 'value'),
    loading,
  }
}

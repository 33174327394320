import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceAmazonSlice } from '../types'
import { WorkspaceAmazonSliceDTO } from './types'

type From = WorkspaceAmazonSliceDTO
type To = WorkspaceAmazonSlice

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((workspaceAmazonSliceDTO: From): To => {
    const { accountType, enabled, id, pubId, status, supplyChain } = workspaceAmazonSliceDTO

    return {
      accountType,
      enabled,
      id,
      pubId: pubId ?? undefined,
      status,
      supplyChain: supplyChain ?? undefined,
    }
  })

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_BIDDERS: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspaceBidders($workspaceId: ID!, $first: Int, $last: Int, $terms: [String!]) {
    workspaceBidders(workspaceId: $workspaceId, first: $first, last: $last, terms: $terms) {
      nodes {
        accountType
        bidCpmAdjustment
        bidder {
          name
          code
        }
        bidderCode
        consentMode
        enabled
        id
        priority
        supplyChain {
          name
        }
        status
      }

      totalCount
    }
  }
`

import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'

import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { fromDTO, sortToDTO } from './api/mapper'
import { GET_WORKSPACE_BIDDERS_FOR_SUPPLY_CHAIN_TABLE } from './api/query'
import { WorkspaceBidderForTableDTO } from './api/types'
import { UseGetBidders, UseGetBiddersProps, WorkspaceBidderForTable } from './types'

export const useGetBidders = ({
  filters,
  first,
  last,
  sort,
  supplyChainId,
}: UseGetBiddersProps): UseGetBidders => {
  const { workspaceId } = useWorkspaceParam()
  const { setLoading } = useContext(SupplyChainContext)

  const [bidders, setBidders] = useState<WorkspaceBidderForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_WORKSPACE_BIDDERS_FOR_SUPPLY_CHAIN_TABLE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ workspaceBidders: { nodes: bidders, totalCount: count } }): void => {
      if (bidders) {
        setBidders(
          bidders.map(
            (bidder: WorkspaceBidderForTableDTO): WorkspaceBidderForTable => fromDTO(bidder),
          ),
        )
        setCount(count)
      }
    },
    variables: {
      first,
      last,
      sort: sortToDTO(sort),
      supplyChainId,
      terms: filters,
      workspaceId,
    },
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return { bidders, count }
}

import { CSSObject, Theme } from '@mui/material'

import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { Ability } from 'providers/casl'
import { PrebidModule, PrebidModuleType } from './types/prebidModule'

type CanUpdateProps = {
  ability: Ability
  account: PrebidModule['account']
  moduleType: PrebidModuleType
}

export type YieldbirdStatusColor = {
  status: YieldbirdStatus
  theme: Theme
}

export const canUpdate = ({ ability, account, moduleType }: CanUpdateProps): boolean =>
  account === 'own' && ability.can('update', `GlobalSetupFeature-${moduleType}`)

export const yieldbirdStatusColor = ({
  status,
  theme,
}: YieldbirdStatusColor): CSSObject['color'] => {
  const {
    palette: {
      error: { main: error },
      success: { main: success },
      warning: { main: warning },
    },
  } = theme

  switch (status) {
    case 'available': {
      return success
    }
    case 'denied': {
      return error
    }
    case 'requested': {
      return warning
    }
  }
}

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { refreshToken } from 'features/user'
import { RootState } from 'providers/redux'
import { UseRefreshToken } from './types'

export const useRefreshToken = (): UseRefreshToken => {
  const [loading, setLoading] = useState<boolean>(false)
  const [tried, setTried] = useState<boolean>(false)

  const { user } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    const tryToRefreshToken = async () => {
      setLoading(true)

      await refreshToken().then(() => {
        setLoading(false)
        setTried(true)
      })
    }

    if (user === null && tried === false) {
      tryToRefreshToken()
    }
  }, [user, tried])

  return { loading, tried, user }
}

import { AddSchema } from '../../schemas/add'
import { Variables } from './api/types'

export const mapper = (data: AddSchema): Variables => {
  const { csManagerId } = data

  return {
    ...data,
    csManagerId: csManagerId || null,
  }
}

import { CheckCircleOutline } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'

type Props = {
  filename: File['name']
}

export const DialogContentSuccess = ({ filename }: Props): JSX.Element => {
  const { t } = useTranslation('features/domain')

  return (
    <FlexBox axis='xy'>
      <CheckCircleOutline
        sx={{
          color: theme => theme.palette.success.main,
          fontSize: 75,
          pb: 2,
        }}
      />

      <Typography>
        {t('form.general.bulkManagement.dialog.upload.success', { filename })}
      </Typography>
    </FlexBox>
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceDTO } from '..'
import { Workspace } from '../types/workspace'
import { dataSamplingFromDTO } from './dataSampling'
import { gamConnectionOrderFromDTO } from './gamConnectionOrderFromDTO'

export const workspaceFromDTO = (from: WorkspaceDTO): Workspace =>
  mapDTO<WorkspaceDTO, Workspace>({ from }).transform(workspaceDTO => {
    const {
      createdAt,
      csManager,
      financeReportUrl,
      latestGamConnectionOrder,
      prebidManagerAccountId,
      prebidManagerSampling,
      updatedAt,
    } = workspaceDTO

    return {
      ...workspaceDTO,
      createdAt: new Date(createdAt),
      csManager: csManager ?? undefined,
      financeReportUrl: financeReportUrl ?? undefined,
      latestGamConnectionOrder: latestGamConnectionOrder
        ? gamConnectionOrderFromDTO(latestGamConnectionOrder)
        : undefined,
      prebidManagerAccountId: prebidManagerAccountId ?? undefined,
      prebidManagerSampling: dataSamplingFromDTO(prebidManagerSampling),
      updatedAt: new Date(updatedAt),
    }
  })

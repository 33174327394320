import { useTranslation } from 'react-i18next'

import { PrebidModuleAccount } from 'features/globalSetup/types/prebidModule'
import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { useAbility } from 'providers/casl'

type UseDataForEnabledSwitchProps = {
  accountType: PrebidModuleAccount
  status: YieldbirdStatus
}

type UseDataForEnabledSwitch = {
  disabled: boolean
  tooltip: { content: string } | undefined
}

export const useDataForEnabledSwitch = ({
  accountType,
  status,
}: UseDataForEnabledSwitchProps): UseDataForEnabledSwitch => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.vendorSpecific.form.enabled',
  })
  const ability = useAbility()

  const tooltip =
    accountType === 'yieldbird' && status === 'requested'
      ? {
          content: t('tooltip'),
        }
      : undefined

  const disabled =
    (accountType === 'yieldbird' && status !== 'available') ||
    ability.cannot('update', 'GlobalSetupFeature-vendorSpecific')

  return {
    disabled,
    tooltip,
  }
}

import _ from 'lodash'

import { SelectOption } from 'components/Select'
import { UserRole } from 'features/user'
import { userGroupRoles } from 'features/user/types/UserGroupRoles'

export const userEmployeeRoleOptions = (): SelectOption<UserRole>[] =>
  userGroupRoles.employeesOnly.map(
    (role: UserRole): SelectOption<UserRole> => ({
      label: _.startCase(role),
      value: role,
    }),
  )

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_THIRD_PARTY_PROVIDER_WORKSPACE_BIDDER: TypedDocumentNode<
  Data,
  { input: Variables }
> = gql`
  ${ERROR_FRAGMENT}

  mutation createThirdPartyProviderWorkspaceBidder(
    $input: CreateThirdPartyProviderWorkspaceBidderInput!
  ) {
    result: createThirdPartyProviderWorkspaceBidder(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

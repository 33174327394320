import { gql, TypedDocumentNode } from '@apollo/client'

import { UserSliceDTO } from 'features/user/forms/UserEmployeeForm/types'

export const PUBLISHER_FRAGMENT: TypedDocumentNode<UserSliceDTO> = gql`
  fragment PublisherFragment on User {
    id
    email
    userWorkspaces {
      id
      role
    }
  }
`

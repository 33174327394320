import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_VIEWABILITY_TOOL_FRAGMENT } from 'features/inventory/domain/api/fragments/domainViewabilityTools'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_VIEWABILITY_TOOLS: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_VIEWABILITY_TOOL_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateDomainViewabilityTool($input: UpdateDomainViewabilityToolInput!) {
    updateDomainViewabilityTool(input: $input) {
      domainViewabilityTool {
        ...DomainViewabilityToolFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

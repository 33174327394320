import { PrebidManagerSamplingDTO } from '../api/types/workspaceDTO'
import { Workspace } from '../types/workspace'

const ERROR_MESSAGE: string = 'Unsupported Data sampling value'

export const dataSamplingToDTO = (
  dataSampling: Workspace['prebidManagerSampling'],
): PrebidManagerSamplingDTO => {
  switch (dataSampling) {
    case '1': {
      return 'onePercent'
    }
    case '10': {
      return 'tenPercent'
    }
    case '100': {
      return 'oneHundredPercent'
    }

    default: {
      throw Error(ERROR_MESSAGE)
    }
  }
}

export const dataSamplingFromDTO = (
  dataSampling: PrebidManagerSamplingDTO,
): Workspace['prebidManagerSampling'] => {
  switch (dataSampling) {
    case 'onePercent': {
      return '1'
    }
    case 'tenPercent': {
      return '10'
    }
    case 'oneHundredPercent': {
      return '100'
    }

    default: {
      throw Error(ERROR_MESSAGE)
    }
  }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_FILTERING_RULES: TypedDocumentNode<Data, Variables> = gql`
  query filteringRules(
    $workspaceId: ID!
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: FilteringRuleSortInput
  ) {
    filteringRules(
      workspaceId: $workspaceId
      first: $first
      last: $last
      terms: $terms
      sort: $sort
    ) {
      nodes {
        id
        name
        updatedAt
        enabled
        blockedCount
        applicableDomains
      }
      totalCount
    }
  }
`

import { useTranslation } from 'react-i18next'

import { SelectOption } from 'components/Select'
import { ConsentMode, consentModes } from 'features/globalSetup/types/workspaceBidder'
import { UseGetConsentModesForSelect } from './types'

export const useGetConsentModesForSelect = (): UseGetConsentModesForSelect => {
  const { t } = useTranslation('features/globalSetup')

  return consentModes.map(
    (consentMode: ConsentMode): SelectOption<ConsentMode> => ({
      label: t(`prebid.bidders.add.basicAttributes.consentMode.options.${consentMode}`),
      value: consentMode,
    }),
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { demandToAccount } from 'features/globalSetup/api/mappers/demandToAccount'
import { SortBy, DomainPrebidModuleSlice as To } from '../types'
import { DomainPrebidModuleSliceDTO as From, SortByDTO } from './types'

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform(
    (dto: From): To => ({
      ...dto,
      prebidModule: {
        ...dto.prebidModule,
        account: demandToAccount(dto.prebidModule.demand),
      },
      updatedAt: new Date(dto.updatedAt),
    }),
  )

export const sortToDTO = (sort: SortInput<SortBy>): SortInput<SortByDTO> => {
  const { by } = sort

  if (by === 'prebidModule.name') {
    return { ...sort, by: 'prebidModuleName' }
  } else {
    return { ...sort, by }
  }
}

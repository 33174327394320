import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SwitchProps } from 'components/Form/Switch'
import { Schema } from 'features/globalSetup/features/amazon/forms/GeneralPageForm'
import { useAbility } from 'providers/casl'
import { KONWLEDGE_BASE_URL } from '../../constants'
import { Props, UseSwitchProps } from './types'

export const useSwitchProps = ({ workspaceAmazon }: Props): UseSwitchProps => {
  const ability = useAbility()
  const { t } = useTranslation(['features/globalSetup/amazon', 'common'])

  const { control, watch } = useFormContext<Schema>()
  const { accountType } = watch()

  const tooltipProps = (): Pick<SwitchProps<Schema>, 'disabled' | 'tooltip'> => {
    if (!ability.can('update', 'GlobalSetupFeature-amazon')) {
      return {
        disabled: true,
        tooltip: undefined,
      }
    } else if (_.isNil(workspaceAmazon)) {
      return {
        disabled: true,
        tooltip: {
          content: t('pages.general.amazonOnOff.tooltip.disabled'),
        },
      }
    } else if (accountType === 'yieldbird' && workspaceAmazon.status === 'requested') {
      return {
        disabled: true,
        tooltip: {
          content: t('pages.general.amazonOnOff.tooltip.yieldbirdRequested'),
          link: {
            title: t('common:learnMore'),
            url: KONWLEDGE_BASE_URL.TOOLTIP,
          },
        },
      }
    } else {
      return {
        disabled: false,
        tooltip: undefined,
      }
    }
  }

  return {
    control,
    name: 'enabled',
    tooltipMode: 'wrap',
    ...tooltipProps(),
  }
}

import _ from 'lodash'
import { useContext } from 'react'

import { useMergeFormData } from 'components/Form'
import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { calculatePagination } from 'providers/graphql'
import { InventoryConnectionContext } from '../../../contexts/InventoryConnectionContext'
import { useGetPrebidModulesDomains } from '../../hooks/useGetPrebidModuleDomains'
import {
  DomainPrebidModuleSlice,
  DomainPrebidModuleSortBy,
} from '../../hooks/useGetPrebidModuleDomains/types'
import { DomainPrebidModuleAttributes, Schema } from '../../schema'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { DomainsTableProps } from './types'
import { useColumns } from './useColumns'

export const DomainsTable = ({ filters }: DomainsTableProps): JSX.Element => {
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const { moduleType } = useContext(InventoryConnectionContext)
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<DomainPrebidModuleSlice, DomainPrebidModuleSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )
  const columns = useColumns()

  const { count, loading, prebidModuleDomains } = useGetPrebidModulesDomains({
    filters,
    moduleType,
    workspacePrebidModuleId: workspacePrebidModule?.id,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const formData: DomainPrebidModuleAttributes = _.chain(prebidModuleDomains)
    .keyBy('id')
    .mapValues('enabled')
    .value()

  useMergeFormData<Schema>({
    data: formData,
    path: 'domainPrebidModuleAttributes',
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<DomainPrebidModuleSlice>
      columns={columns}
      data={prebidModuleDomains}
      data-cy={`${moduleType}-domains-table`}
      loading={loading}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

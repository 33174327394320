import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { MediaTypesKind, mediaTypesKindValues } from 'features/inventory/ad-unit/types/mediaTypes'

export const useMediaTypesKindOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/adUnit')

  return mediaTypesKindValues.map(
    (value: MediaTypesKind): RadioGroupOption => ({
      label: t(`form.general.mediaTypes.kind.options.${value}`),
      value,
    }),
  )
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  WorkspaceBidderContext,
  WorkspaceBidderContextType,
} from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { AddBidderForm } from 'features/globalSetup/forms/Prebid/Bidders/AddBidderForm'
import { isYieldbirdBidder } from 'features/globalSetup/forms/Prebid/Bidders/utils'
import { WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

export const AddBidderPage = () => {
  const { t } = useTranslation(['features/globalSetup', 'common'])

  const { workspaceId } = useWorkspaceParam()

  const [accountType, setAccountType] = useState<WorkspaceBidder['accountType']>('own')

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-bidders-form-add',
  })
  const contextProps: WorkspaceBidderContextType = {
    accountType,
    setAccountType,
    ...commonContextProps,
  }

  return (
    <WorkspaceBidderContext.Provider value={contextProps}>
      <WorkspaceBidderContext.Consumer>
        {(ctxProps: WorkspaceBidderContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                text={
                  isYieldbirdBidder(accountType)
                    ? t('common:form.action.sendRequest')
                    : t('common:form.action.save')
                }
              />
            }
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT, {
              workspaceId,
            })}
            title={t('prebid.bidders.add.title')}
          />
        )}
      </WorkspaceBidderContext.Consumer>

      <AddBidderForm />
    </WorkspaceBidderContext.Provider>
  )
}

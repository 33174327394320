import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl/types/ability'
import { permitFields } from 'providers/graphql/utils'
import { AdUnitForTableDTO } from './types'

export const AD_UNIT_FOR_TABLE_FRAGMENT = (
  ability: Ability,
): TypedDocumentNode<AdUnitForTableDTO> => gql`
  fragment AdUnitForTableFragment on AdUnit {
    ${permitFields({
      unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery'),
    })}
    divId
    domain {
      id
      alerts
      name
    }
    id
    name
    path
    prebidStackEnabled
    priceGeniusEnabled
    refresherEnabled
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
  }
`

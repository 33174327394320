import { StandardTextFieldProps } from '@mui/material'
import { FieldError, Path, UseFormReturn } from 'react-hook-form'

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { ParamsSchema } from './schemas/paramsSchema'

export const unregisterParameters = <T extends ParamsSchema>(
  params: PrebidModuleParameter[],
  unregister: UseFormReturn<T>['unregister'],
): void => params.forEach(({ name }) => void unregister(name as Path<T>))

export const errorProps = (
  error: FieldError,
): Pick<StandardTextFieldProps, 'error' | 'helperText'> => ({
  error: true,
  helperText: error.message,
})

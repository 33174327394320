import { SelectOption } from 'components/Select'
import { DomainBidder } from 'features/globalSetup/types/domainBidder'
import { DomainBidderOption } from '../../hooks/useGetDomainBiddersForSelect'

export const getRelatedDomainId = (
  domainBidderId: DomainBidder['id'],
  options: SelectOption<DomainBidderOption>[],
): DomainBidder['id'] => {
  const domainBidder: SelectOption<DomainBidderOption> | undefined = options.find(
    (option: SelectOption<DomainBidderOption>): boolean => option.value === domainBidderId,
  )

  if (domainBidder === undefined || domainBidder.rawData === undefined) {
    throw Error('DomainBidder related IDs should be accessible')
  }

  return domainBidder.rawData.domain.id
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_AMAZON_OPTION_FRAGMENT } from './fragment'
import { Data } from './types'

export const GET_DOMAIN_AMAZONS: TypedDocumentNode<Data> = gql`
  ${DOMAIN_AMAZON_OPTION_FRAGMENT}

  query getDomainAmazons {
    domainAmazons {
      nodes {
        ...DomainAmazonOptionFragment
      }

      totalCount
    }
  }
`

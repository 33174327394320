import { RouteObject } from 'react-router-dom'

import { UserCompleteSetupPage } from 'features/user/pages/UserCompleteSetupPage'
import { UserConfirmResetPasswordPage } from 'features/user/pages/UserConfirmResetPasswordPage'
import UserLoginPage from 'features/user/pages/UserLoginPage'
import { UserRequestPasswordResetPage } from 'features/user/pages/UserRequestPasswordResetPage'
import PageNotFound from 'providers/router/components/PageNotFound'
import { ROUTES } from 'routes'

export const publicRoutes: RouteObject[] = [
  {
    element: <UserCompleteSetupPage />,
    path: ROUTES.AUTH.COMPLETE_SETUP,
  },
  {
    element: <UserConfirmResetPasswordPage />,
    path: ROUTES.AUTH.CONFIRM_RESET_PASSWORD,
  },
  {
    element: <UserLoginPage />,
    path: ROUTES.AUTH.LOGIN,
  },
  {
    element: <UserRequestPasswordResetPage />,
    path: ROUTES.AUTH.REQUEST_RESET_PASSWORD,
  },
  {
    element: <PageNotFound />,
    path: ROUTES.BASE.NOT_FOUND,
  },
]

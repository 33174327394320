import { useContext, useEffect } from 'react'

import { Form } from 'components/Form'
import { AmazonPageContext } from '../../contexts/AmazonPageContext'
import { useUpsertWorkspaceAmazon } from '../../hooks/useUpsertWorkspaceAmazon'
import { Schema, schema, schemaDefault } from './schema'
import { AmazonOnOffSection } from './sections/AmazonOnOffSection'
import { AmazonUamTamSection } from './sections/AmazonUamTamSection'

export const GeneralPageForm = () => {
  const { accountType, formId, setLoading, setTouched, setValid, workspaceAmazon } =
    useContext(AmazonPageContext)

  const {
    errors: apiErrors,
    loading,
    upsert,
  } = useUpsertWorkspaceAmazon({
    accountType: accountType ?? 'own',
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={upsert}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(workspaceAmazon)}
    >
      {() => (
        <>
          <AmazonOnOffSection />
          <AmazonUamTamSection />
        </>
      )}
    </Form>
  )
}

import { useMutation } from '@apollo/client'
import { ChangeEvent, useState } from 'react'

import { UPDATE_WORKSPACE_BIDDER } from './api/mutation'
import { UseUpdateWorkspaceBidder, UseUpdateWorkspaceBidderProps } from './types'
import { useSnackbar } from './useSnackbar'

export const useUpdateWorkspaceBidder = ({
  bidderCode,
  enabled,
  id,
}: UseUpdateWorkspaceBidderProps): UseUpdateWorkspaceBidder => {
  const { snackbar } = useSnackbar(bidderCode)
  const [checked, setChecked] = useState<boolean>(enabled)

  const [mutate] = useMutation(UPDATE_WORKSPACE_BIDDER)

  const update = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value: boolean = event.target.checked

    const variables = { input: { enabled: value, workspaceBidderId: id } }
    const { data } = await mutate({ variables })
    const { errors } = { ...data?.updateWorkspaceBidder }

    if (errors && errors.length > 0) {
      snackbar(value, 'error')
    } else {
      setChecked(value)
      snackbar(value)
    }
  }

  return { checked, update }
}

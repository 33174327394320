import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FRAGMENT } from '../fragments/page'
import { PageData, PageVars } from '../types/page'

export const GET_PAGE: TypedDocumentNode<PageData, PageVars> = gql`
  ${PAGE_FRAGMENT}

  query page($id: ID!) {
    node(id: $id) {
      ...PageFragment
    }
  }
`

import { useTranslation } from 'react-i18next'

import { Account } from 'features/globalSetup/types/workspaceBidder'
import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { useAbility } from 'providers/casl'

type UseDataForEnabledSwitchProps = {
  accountType: Account
  status: YieldbirdStatus
}

type UseDataForEnabledSwitch = {
  disabled: boolean
  tooltip: { content: string } | undefined
}

export const useDataForEnabledSwitch = ({
  accountType,
  status,
}: UseDataForEnabledSwitchProps): UseDataForEnabledSwitch => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.general',
  })
  const ability = useAbility()

  const tooltip =
    accountType === 'yieldbird' && status === 'requested'
      ? {
          content: t('tooltip'),
        }
      : undefined

  const disabled =
    (accountType === 'yieldbird' && status !== 'available') ||
    !ability.can('update', 'GlobalSetupFeature-bidder')

  return {
    disabled,
    tooltip,
  }
}

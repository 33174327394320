import { RouteObject } from 'react-router-dom'

export const multiPathRoute = ({
  element,
  paths,
}: {
  element: JSX.Element
  paths: string[]
}): RouteObject[] =>
  paths.map((path: string) => ({
    element,
    path,
  }))

/**
 * Helper for browser relative redirect.
 *
 * Use only outside of `<RouterProvider />`.
 *  */
export const browserRelativeRedirect = (url: string): void => void window.location.replace(url)

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_AD_UNIT_BIDDERS: TypedDocumentNode<Data, Variables> = gql`
  query getAdUnitBidders($adUnitId: ID!) {
    adUnitBidders(adUnitId: $adUnitId) {
      nodes {
        workspaceBidder {
          accountType
          bidderCode
          id
          bidder {
            id
            name
            s2sAvailable
          }
          status
        }
        id
        domainBidderId
        csEnabled
        s2sEnabled
        csRequiredParams
        s2sRequiredParams
        updatedAt
      }
    }
  }
`

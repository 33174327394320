import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspacePrebidConfigurationDTO } from 'features/globalSetup/api/types/workspacePrebidConfiguration'
import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'

type From = WorkspacePrebidConfigurationDTO
type To = WorkspacePrebidConfiguration

export const fromDTO = (from: From): To =>
  mapDTO<From, WorkspacePrebidConfiguration>({ from }).transform(
    (workspacePrebidConfiguration: From): To => {
      const { s2sTimeoutMultiplier } = workspacePrebidConfiguration

      return {
        ...workspacePrebidConfiguration,
        s2sTimeoutMultiplier: s2sTimeoutMultiplier * 100,
      }
    },
  )

import { Warning } from '@mui/icons-material'
import { SvgIcon, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Alerts'
import { WorkspaceAlert } from 'features/workspace/types/workspaceAlert'
import SignalDisconnected from 'images/icons/signal-disconnected.svg?react'
import { GAM_NOT_CONNECTED_URL } from './constants'

type UseAlerts = {
  switcher: Switch<WorkspaceAlert>
}

export const useAlerts = (): UseAlerts => {
  const { t } = useTranslation('features/workspace')
  const theme = useTheme()

  const switcher: UseAlerts['switcher'] = alert => {
    switch (alert) {
      case 'gamNotConnected':
        return {
          color: theme.palette.error.main,
          Icon: (
            <SvgIcon
              component={SignalDisconnected}
              inheritViewBox
            />
          ),
          tooltip: {
            link: GAM_NOT_CONNECTED_URL,
            text: t('alerts.gamNotConnected'),
          },
        }
      case 'inactive':
        return {
          color: theme.palette.warning.main,
          Icon: <Warning />,
          tooltip: {
            text: t('alerts.inactive'),
          },
        }
      default:
        throw Error('Missing alert config')
    }
  }

  return { switcher }
}

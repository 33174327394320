import { z } from 'zod'

import { SupplyChainSlice } from '../hooks/useGetSupplyChain'
import { commonSchema } from './commonSchema'

export type EditSchema = z.infer<typeof commonSchema>

export const editSchemaDefault = (supplyChain: SupplyChainSlice): EditSchema => {
  const { asi, defaultSid, name } = supplyChain

  return {
    asi,
    defaultSid,
    name,
  }
}

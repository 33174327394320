import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { useAbility } from 'providers/casl'
import { AdUnitAmazonSlice } from '../../hooks/useGetAdUnitAmazons'
import { Schema } from '../../schema'

export const useColumns = (): ColumnDef<AdUnitAmazonSlice>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'pages.inventoryConnection.table',
  })

  const { control } = useFormContext<Schema>()

  const canUpdate: boolean = ability.can('update', 'GlobalSetupFeature-amazon')

  return [
    {
      accessorKey: 'path',
      header: t('path'),
    },
    {
      accessorKey: 'divId',
      header: t('divId'),
    },
    {
      accessorKey: 'enabled',
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <Switch<Schema>
          control={control}
          disabled={!canUpdate}
          name={`adUnitAmazonsAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: t('enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
  ]
}

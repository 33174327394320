import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_DOMAINS_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${DOMAIN_FOR_TABLE_FRAGMENT}

  query getDomains(
    $workspaceId: ID!
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: DomainSortInput
  ) {
    domains(workspaceId: $workspaceId, first: $first, last: $last, terms: $terms, sort: $sort) {
      nodes {
        ...DomainFragmentForTable
      }

      totalCount
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT } from 'features/inventory/api/fragments/pageForAdUnitTransferList'
import { PagesForAdUnitTransferListData, PagesForAdUnitTransferListVars } from './types'

export const GET_PAGES_FOR_AD_UNIT_TRANSFER_LIST: TypedDocumentNode<
  PagesForAdUnitTransferListData,
  PagesForAdUnitTransferListVars
> = gql`
  ${PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT}

  query pagesForTransferList($domainId: ID!, $workspaceId: ID!) {
    pages(domainId: $domainId, workspaceId: $workspaceId) {
      nodes {
        ...PageForAdUnitTransferList
      }
    }
  }
`

import { useTranslation } from 'react-i18next'

import { SliderMark } from 'components/Form/Slider/types'
import { SCROLLING_FACTOR_VALUES } from './constants'

export const useSlowingFactorMarks = (): SliderMark[] => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.viewabilityTools.basicSettings.scrollSlowingEnabled.options.slowingFactor',
  })

  return [
    {
      label: tC('percentage', { value: SCROLLING_FACTOR_VALUES.MIN }),
      value: SCROLLING_FACTOR_VALUES.MIN,
    },
    {
      description: {
        from: 5,
        title: t('superLight'),
      },
      value: 10,
    },
    {
      description: {
        from: 11,
        title: t('light'),
      },
      value: 15,
    },
    {
      description: {
        from: 16,
        title: t('moderate'),
      },
      value: 25,
    },
    {
      description: {
        color: 'warning',
        from: 26,
        title: t('heavy'),
      },
      label: tC('percentage', { value: SCROLLING_FACTOR_VALUES.MAX }),
      value: SCROLLING_FACTOR_VALUES.MAX,
    },
  ]
}

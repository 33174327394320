import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_USER_FOR_TABLE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateWorkspaceUserForTable($input: UpdatePublisherByWorkspaceOwnerInput!) {
    updatePublisherByWorkspaceOwner(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useContext, useEffect } from 'react'

import { Form } from 'components/Form'
import { ViewportPageContext } from '../../contexts/ViewportPageContext'
import { useUpsertViewports } from '../../hooks/useUpsertViewports'
import { defaultSchema, schema, Schema } from './schema'
import { Props } from './types'

export const ViewportForm = ({ children }: Props) => {
  const { formId, setLoading, setTouched, setValid } = useContext(ViewportPageContext)
  const { errors, loading, update } = useUpsertViewports()

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form<Schema>
      externalErrors={errors}
      id={formId}
      onSubmit={(data: Schema) => update(data.viewports)}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={defaultSchema()}
    >
      {() => children}
    </Form>
  )
}

import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { EditSchema } from '../../../schemas/editSchema'
import { useDataForEnabledSwitch } from '../hooks/useDataForEnabledSwitch'

export const ModuleEnabledSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.vendorSpecific.form.enabled',
  })
  const { control, watch } = useFormContext<EditSchema>()
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const { accountType, status } = watch()

  const { disabled, tooltip } = useDataForEnabledSwitch({ accountType, status })

  if (!workspacePrebidModule) {
    throw Error('workspacePrebidModule is required!')
  }

  return (
    <Paper>
      <SectionHeader
        subtitle={t('header.subtitle', {
          prebidModuleName: workspacePrebidModule.prebidModule.name,
        })}
        title={t('header.title')}
        titleEndAdornment={
          <Switch
            control={control}
            disabled={disabled}
            name='enabled'
            tooltip={tooltip}
            tooltipMode='wrap'
          />
        }
      />
    </Paper>
  )
}

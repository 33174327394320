import { SvgIcon } from '@mui/material'

import YieldbirdFullSvg from 'images/logos/yieldbird-full.svg?react'

export const FullLogotype = (): JSX.Element => (
  <SvgIcon
    component={YieldbirdFullSvg}
    inheritViewBox
    sx={{
      height: 'inherit',
      width: 'inherit',
    }}
  />
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { WORKSPACE_FORM_FRAGMENT } from '../../useGetWorkspace/api/fragment'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${WORKSPACE_FORM_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ...WorkspaceFormFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

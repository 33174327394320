import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { reset } from 'features/user'
import { SIGN_OUT } from 'features/user/api/mutations/signOut'
import { AppDispatch } from 'providers/redux'
import { UseSignOut } from './types'

export const useSignOut = (): UseSignOut => {
  const dispatch: AppDispatch = useDispatch()

  const [mutateSignOut, { loading }] = useMutation(SIGN_OUT, {
    onCompleted: () => void dispatch(reset()),
  })

  return {
    loading,
    signOut: () => mutateSignOut(),
  }
}

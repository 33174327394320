import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainsForTableDTO } from './types'

export const DOMAIN_FOR_DOWNLOAD_TAGS_FRAGMENT: TypedDocumentNode<DomainsForTableDTO> = gql`
  fragment DomainFragmentForDownloadTags on Domain {
    id
    name
    updatedAt
  }
`

import { Box, Switch as SwitchRoot } from '@mui/material'

import Tooltip from 'components/Tooltip'
import { SwitchProps } from './types'

export const Switch = ({
  checked,
  tooltip: tooltipProps,
  tooltipMode,
  ...muiSwitchProps
}: SwitchProps): JSX.Element => {
  const Container = (): JSX.Element => (
    <SwitchRoot
      {...muiSwitchProps}
      checked={checked}
    />
  )

  return tooltipProps && tooltipMode === 'wrap' ? (
    <Tooltip {...tooltipProps}>
      <Box>
        <SwitchRoot
          {...muiSwitchProps}
          checked={checked}
        />
      </Box>
    </Tooltip>
  ) : (
    <Container />
  )
}

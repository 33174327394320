import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { AddBidderFormBody } from 'features/globalSetup/forms/Prebid/Bidders/AddBidderFormBody'
import { commonSchema } from 'features/globalSetup/forms/Prebid/Bidders/schemas/commonSchema'
import { useCreateWorkspaceBidder } from './hooks/useCreateWorkspaceBidder'
import { AddSchema, addSchemaDefault } from './schemas/addSchema'

export const AddBidderForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(WorkspaceBidderContext)
  const { getApiErrors, handleSubmit } = useCreateWorkspaceBidder()

  return (
    <Form<AddSchema>
      externalErrors={getApiErrors()}
      id={formId}
      onSubmit={handleSubmit}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => (loading ? <Loading /> : <AddBidderFormBody />)}
    </Form>
  )
}

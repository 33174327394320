import { kebabCase } from 'change-case'
import { TFunction } from 'i18next'
import { generatePath } from 'react-router-dom'

import { Product } from 'features/product/types/product'
import { Workspace } from 'features/workspace'
import { Ability } from 'providers/casl'
import { ROUTES } from 'routes'

export type Props = {
  ability: Ability
  products: readonly Product[]
  t: TFunction<'features/product'>
  workspaceId: Workspace['id']
}

export const performanceProductItems = ({ ability, products, t, workspaceId }: Props) =>
  products.map((product: Product) => ({
    hidden: ability.cannot('show', `Product-${product}`),
    title: t(`product.${product}`),
    to: generatePath(`${ROUTES.PERFORMANCE.ROOT}/${kebabCase(product)}`, { workspaceId }),
  }))

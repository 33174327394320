import { Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, TextField } from 'components/Form'
import { Schema } from './schema'

export const Form = () => {
  const {
    control,
    formState: { isValid },
  } = useFormContext<Schema>()
  const { t } = useTranslation('features/user')

  return (
    <>
      <BoxForm>
        <TextField
          control={control}
          label={t('userRequestPassword.form.email.label')}
          name='email'
          placeholder={t('userRequestPassword.form.email.placeholder')}
        />
      </BoxForm>

      <Button
        data-cy='button-submit-request-password-form'
        disabled={!isValid}
        sx={{ mt: 2 }}
        type='submit'
        variant='contained'
      >
        {t('userRequestPassword.form.button')}
      </Button>
    </>
  )
}

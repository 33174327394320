import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { UserSignLayoutPage } from 'features/layout/pages/UserSignLayoutPage'
import { BoxHeader } from 'features/user/components/BoxHeader'
import { UserCompleteSetupForm } from 'features/user/forms/UserCompleteSetupForm'
import { ROUTES } from 'routes'
import { UserCompleteSetupPageParams } from './types'

export const UserCompleteSetupPage = (): JSX.Element => {
  const { t } = useTranslation('features/user')
  const { invitationToken } = useParams<UserCompleteSetupPageParams>()

  if (!invitationToken) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  }

  return (
    <UserSignLayoutPage>
      <BoxHeader
        description={<Typography>{t('completeSetup.description')}</Typography>}
        title={t('completeSetup.title')}
      />

      <UserCompleteSetupForm invitationToken={invitationToken} />
    </UserSignLayoutPage>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_DOMAIN_BIDDERS: TypedDocumentNode<Data, Variables> = gql`
  query getDomainBidders($domainId: ID!) {
    domainBidders(domainId: $domainId) {
      nodes {
        workspaceBidder {
          bidderCode
          id
          bidder {
            id
            name
            s2sAvailable
          }
          status
          priority
        }
        id
        priority
        csEnabled
        s2sEnabled
        updatedAt
      }
    }
  }
`

import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { Tab } from '.'

export const getPage = <T,>(
  tabs: Tab<T>[],
  urlTab: string | undefined,
  defaultTab: T,
): JSX.Element | undefined => {
  const tab: Tab<T> | undefined = tabs.find(
    (tab: Tab<T>): boolean => tab.element === (urlTab ?? defaultTab),
  )
  if (!tab) return undefined

  if (!tab.authorized) {
    return tab.page
  } else {
    return (
      <AuthorizedRoute
        access={tab.authorized}
        page={tab.page}
      />
    )
  }
}

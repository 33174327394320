import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const ASSIGN_USER_TO_WORKSPACE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation InvitePublisherToWorkspace($input: InvitePublisherToWorkspaceInput!) {
    invitePublisherToWorkspace(input: $input) {
      workspace {
        id
        users {
          id
          email
          userWorkspaces {
            id
            role
          }
        }
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl'
import { permitFields } from 'providers/graphql/utils'
import { WorkspaceSliceDTO } from './types'

export const WORKSPACE_FRAGMENT_FOR_GLOBAL_SETUP_GENERAL = (
  ability: Ability,
): TypedDocumentNode<WorkspaceSliceDTO> => gql`
  fragment WorkspaceFragmentForGeneralSetupGeneral on Workspace {
    ${permitFields({
      priceGeniusAlwaysOn: ability.can('read', 'GlobalSetupField-priceGenius'),
    })}

    id
    failsafeTimeout
    name
  }
`

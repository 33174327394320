import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_BIDDER_ERROR_FRAGMENT } from './errors/fragment'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_BIDDER: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_BIDDER_ERROR_FRAGMENT}

  mutation updateDomainBidder($input: UpdateDomainBidderInput!) {
    updateDomainBidder(input: $input) {
      errors {
        ...DomainBidderErrorFragment
      }
    }
  }
`

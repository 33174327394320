import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { DATA_SAMPLING_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_DATA_SAMPLING: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DATA_SAMPLING_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateDataSampling($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ...dataSamplingFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { Checkbox as CheckboxRoot, FormControlLabel } from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { CheckboxProps } from './types'

export const Checkbox = <T extends FieldValues>({
  control,
  label,
  name,
  ...checkboxProps
}: CheckboxProps<T>): JSX.Element => (
  <FormControlLabel
    control={
      <Controller
        control={control}
        name={name as FieldPath<T>}
        render={({ field }) => (
          <CheckboxRoot
            {...field}
            {...checkboxProps}
            id={name}
          />
        )}
      />
    }
    label={label}
  />
)

import { Configuration } from 'rollbar'

import { EnvironmentMode } from 'types/env'
import { getEnvironmentMode } from 'utils/env'

const mode: EnvironmentMode = getEnvironmentMode()

export const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: mode === 'staging' || mode === 'production',
  payload: {
    client: {
      guess_uncaught_frames: true,
      source_map_enabled: true,
    },
    environment: mode,
  },
}

import { styled } from '@mui/material'
import {
  SnackbarKey,
  SnackbarProviderProps,
  SnackbarProvider as SnackbarProviderRoot,
} from 'notistack'

import { CloseAction } from './CloseAction'

const SnackbarStyled = styled(SnackbarProviderRoot)<SnackbarProviderProps>(({ theme }) => ({
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
}))

export const SnackbarProvider = ({ children }: SnackbarProviderProps): JSX.Element => (
  <SnackbarStyled action={(snackbarId: SnackbarKey) => <CloseAction snackbarId={snackbarId} />}>
    {children}
  </SnackbarStyled>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { PageForTableDTO } from '../types'

export const PAGE_FOR_TABLE_FRAGMENT: TypedDocumentNode<PageForTableDTO> = gql`
  fragment PageForTableFragment on Page {
    domain {
      name
    }
    id
    name
    path
    updatedAt
  }
`

import { sentenceCase } from 'change-case'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SwitchProps } from 'components/Form/Switch'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { KONWLEDGE_BASE_URL } from 'features/globalSetup/features/amazon/constants'
import { Props } from './types'
import { useSwitchProps } from './useSwitchProps'

export const InventoryAmazonUamTamSection = <Schema extends FieldValues>({
  amazonState,
  feature,
}: Props): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup/amazon', 'common'])

  const switchProps: SwitchProps<Schema> = useSwitchProps<Schema>({ amazonState, feature })

  return (
    <Paper>
      <SectionHeader
        subtitle={t('inventory.uamTamSection.subtitle', { feature: sentenceCase(feature) })}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KONWLEDGE_BASE_URL.AMAZON_UAM_TAM,
            }}
          />
        }
        title={t('inventory.uamTamSection.title')}
        titleEndAdornment={<Switch {...switchProps} />}
      />
    </Paper>
  )
}

import { Location, Navigate, Outlet, useLocation } from 'react-router-dom'

import Loading from 'components/Loading'
import { ROUTES } from 'routes'
import { useRefreshToken } from './useRefreshToken'

export const AuthenticatedRoute = (): JSX.Element => {
  const location: Location = useLocation()
  const { loading, tried, user } = useRefreshToken()

  if (loading) {
    return <Loading variant='full-screen' />
  } else if (tried && !user) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={ROUTES.AUTH.LOGIN}
      />
    )
  } else if (user) {
    return <Outlet />
  } else return <></>
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_PREBID_MODULE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateWorkspacePrebidModule($input: UpdateWorkspacePrebidModuleInput!) {
    updateWorkspacePrebidModule(input: $input) {
      workspacePrebidModule {
        id
        params
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

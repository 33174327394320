import { FetchResult } from '@apollo/client'
import _ from 'lodash'

import { getTokenCookies } from 'features/user'
import { userFromDTO } from 'features/user/api/mappers/user'
import { REFRESH_TOKEN } from 'features/user/api/mutations/refreshToken'
import { RefreshTokenData } from 'features/user/api/types/refreshToken'
import { authenticate, reset } from 'features/user/store/userSlice'
import { client } from 'providers/graphql'
import { store } from 'providers/redux'
import { RefreshTokenResult } from './types'

export const refreshToken = (): Promise<RefreshTokenResult> => {
  const { refreshToken } = getTokenCookies()

  if (_.isNil(refreshToken)) {
    store.dispatch(reset())

    return Promise.resolve(undefined)
  }

  return client
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: REFRESH_TOKEN,
      variables: { refreshToken },
    })
    .then(({ data }: FetchResult<RefreshTokenData>): RefreshTokenResult => {
      if (_.isNil(data)) {
        return undefined
      }

      const {
        refreshToken: { accessToken, refreshToken, user },
      } = data

      if (_.isNil(accessToken) || _.isNil(refreshToken) || _.isNil(user)) {
        return undefined
      }

      store.dispatch(
        authenticate({
          accessToken,
          refreshToken,
          user: userFromDTO(user),
        }),
      )

      return { accessToken, refreshToken, user }
    })
    .catch(() => undefined)
}

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from 'components/Form/Select'
import Switch from 'components/Form/Switch'
import { useGetPrioritiesForSelect } from 'features/globalSetup'
import { Bidder } from 'features/globalSetup/types/bidder'
import {
  DomainBidderPriority,
  WorkspaceBidderPriority,
} from 'features/globalSetup/types/bidderPriority'
import { useAbility } from 'providers/casl'
import { Schema } from '../../../form/schema'

type Props = {
  s2sAvailable: Bidder['s2sAvailable']
  workspaceBidderPriority: WorkspaceBidderPriority
}

export const DomainBidderFields = ({
  s2sAvailable,
  workspaceBidderPriority,
}: Props): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.domainSection',
  })
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  const priorities = useGetPrioritiesForSelect({ variant: 'domainBidder', workspaceBidderPriority })

  return (
    <>
      <Select<Schema, DomainBidderPriority>
        control={control}
        disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-priority')}
        label={t('priority.label')}
        name='priority'
        options={priorities}
        tooltip={{
          content: t('priority.tooltip'),
        }}
      />

      <Switch
        control={control}
        disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-cs')}
        label={t('csEnabled.label')}
        labelPlacement='start'
        name='csEnabled'
        tooltip={{
          content: t('csEnabled.tooltip'),
        }}
      />

      {s2sAvailable && (
        <Switch
          control={control}
          disabled={!ability.can('update', 'GlobalSetupField-prebid-bidders-bid-params-s2s')}
          label={t('s2sEnabled.label')}
          labelPlacement='start'
          name='s2sEnabled'
          tooltip={{
            content: t('s2sEnabled.tooltip'),
          }}
        />
      )}
    </>
  )
}

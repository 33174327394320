import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainAmazonOption } from '../types'
import { DomainAmazonSliceDTO } from './types'

export const domainAmazonsFromDTO = (from: DomainAmazonSliceDTO) =>
  mapDTO<DomainAmazonSliceDTO, DomainAmazonOption>({ from }).transform(
    (data: DomainAmazonSliceDTO): DomainAmazonOption => {
      const {
        domain: { name },
        enabled,
        id,
      } = data

      return {
        enabled,
        label: name,
        value: id,
      }
    },
  )

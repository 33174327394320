import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { MutationInputError } from 'providers/graphql'
import { UPLOAD_AD_UNITS_CONFIGURATION } from './api/mutation'
import { Data } from './api/types'
import {
  UploadStatus,
  UseUploadAdUnitsConfiguration,
  UseUploadAdUnitsConfigurationProps,
} from './types'

export const useUploadAdUnitsConfiguration = ({
  domainId,
}: UseUploadAdUnitsConfigurationProps): UseUploadAdUnitsConfiguration => {
  const [response, setResponse] = useState<UploadStatus>({ status: 'initial' })

  const [mutate] = useMutation(UPLOAD_AD_UNITS_CONFIGURATION, {
    onCompleted: ({ uploadAdUnitsXls }: Data): void => {
      if (uploadAdUnitsXls) {
        const { errors } = uploadAdUnitsXls

        if (errors && errors.length > 0) {
          setResponse({
            errors: errors.map((e: MutationInputError): MutationInputError['message'] => e.message),
            status: 'failure',
          })
        }
      } else setResponse({ status: 'success' })
    },
    onError: (): void => setResponse({ status: 'failure' }),
  })

  const upload = (file: File) => {
    setResponse({ status: 'loading' })

    return mutate({
      variables: {
        input: {
          id: domainId,
          xlsxFile: file,
        },
      },
    })
  }

  return {
    reset: () => setResponse({ status: 'initial' }),
    response,
    upload,
  }
}

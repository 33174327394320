import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  RelatedList,
  RelatedListItem,
  RelatedSettings as RelatedSettingsRoot,
} from 'components/RelatedSettings'
import { ROUTES } from 'routes'
import { RelatedSettingsProps } from './types'

export const RelatedSettings = ({
  domain: {
    domainPrebidStack: { customPrebidTimeoutEnabled, prebidTimeout: customPrebidTimeout },
    id,
    workspace: { failsafeTimeout, id: workspaceId },
  },
  workspacePrebidConfiguration: { prebidTimeout },
}: RelatedSettingsProps): JSX.Element => {
  const { t: tGlobal } = useTranslation('features/globalSetup')
  const { t: tDomain } = useTranslation('features/domain')

  const PrebidTimeoutItem = (): JSX.Element => {
    if (customPrebidTimeoutEnabled) {
      return (
        <RelatedListItem
          content={tDomain('relatedSettings.customPrebidTimeout')}
          url={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
            id,
            tab: 'prebidStack',
            workspaceId,
          })}
          value={customPrebidTimeout}
        />
      )
    } else {
      return (
        <RelatedListItem
          content={tGlobal('relatedSettings.prebidTimeout')}
          url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, { workspaceId })}
          value={prebidTimeout}
        />
      )
    }
  }

  return (
    <RelatedSettingsRoot>
      <RelatedList>
        <RelatedListItem
          content={tGlobal('relatedSettings.failsafeTimeout')}
          url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, { tab: 'workspace', workspaceId })}
          value={failsafeTimeout}
        />
        <PrebidTimeoutItem />
      </RelatedList>
    </RelatedSettingsRoot>
  )
}

import { ColumnDef } from '@tanstack/react-table'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'
import { FilteringRuleForTable } from './hooks/useGetFilteringRules/types'

export const useColumns = (): ColumnDef<FilteringRuleForTable>[] => {
  const { t } = useTranslation(['features/globalSetup'])

  return [
    {
      accessorKey: 'name',
      header: t('prebid.protection.list.header.name'),
    },
    {
      accessorKey: 'blockedCount',
      header: t('prebid.protection.list.header.blocked'),
    },
    {
      accessorKey: 'applicableDomains',
      cell: ({ row }) => upperFirst(row.original.applicableDomains),
      header: t('prebid.protection.list.header.inventory'),
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('prebid.protection.list.header.enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('prebid.protection.list.header.updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: props => <ActionsCell {...props.row.original} />,
      header: t('prebid.protection.list.header.actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}

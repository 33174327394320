import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceForTableDTO } from './types'

export const WORKSPACE_FOR_TABLE_FRAGMENT: TypedDocumentNode<WorkspaceForTableDTO> = gql`
  fragment WorkspaceForTableFragment on Workspace {
    id
    active
    alerts
    crmId
    domains {
      id
      alerts
      name
    }
    name
    networkCode
    pipedriveLink
    updatedAt
  }
`

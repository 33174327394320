import { useTranslation } from 'react-i18next'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  WorkspacePrebidConfigurationContext,
  WorkspacePrebidConfigurationContextType,
} from 'features/globalSetup/contexts/WorkspacePrebidConfigurationContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { FormContainer } from '../../../forms/Prebid/Defaults'
import { useGetWorkspacePrebidConfiguration } from './hooks/useGetWorkspacePrebidConfiguration'

export const PrebidDefaultsPage = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { workspacePrebidConfiguration } = useGetWorkspacePrebidConfiguration({ workspaceId })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-defaults-form',
  })
  const contextProps: WorkspacePrebidConfigurationContextType = {
    workspacePrebidConfiguration,
    ...commonContextProps,
  }

  return (
    <WorkspacePrebidConfigurationContext.Provider value={contextProps}>
      <StickyBox>
        <WorkspacePrebidConfigurationContext.Consumer>
          {(ctxProps: WorkspacePrebidConfigurationContextType): JSX.Element => (
            <PageHeader
              actions={
                ability.can('update', 'GlobalSetupFeature-prebidDefaults') ? (
                  <SaveButton {...ctxProps} />
                ) : undefined
              }
              title={t('prebid.defaults.header')}
            />
          )}
        </WorkspacePrebidConfigurationContext.Consumer>
      </StickyBox>

      <FormContainer />
    </WorkspacePrebidConfigurationContext.Provider>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_BIDDER_FOR_SELECT_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_DOMAIN_BIDDERS_FOR_SELECT: TypedDocumentNode<Data, Variables> = gql`
  ${DOMAIN_BIDDER_FOR_SELECT_FRAGMENT}

  query getDomainBiddersForSelect($workspaceBidderId: ID) {
    domainBidders(workspaceBidderId: $workspaceBidderId) {
      nodes {
        ...DomainBidderForSelectFragment
      }
    }
  }
`

import i18next from 'i18next'
import { z } from 'zod'

import { WorkspaceSlice } from 'features/globalSetup/contexts/GeneralPageContext'
import { Ability } from 'providers/casl'

const failsafeTimeoutError = i18next.t(
  'features/globalSetup:general.workspace.form.failsafeTimeout.error',
)

export const schema = z.object({
  failsafeTimeout: z.number().min(200, failsafeTimeoutError).max(20000, failsafeTimeoutError),
  id: z.string(),
  name: z.string(),
  priceGeniusAlwaysOn: z.boolean().optional(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (workspace: WorkspaceSlice, ability: Ability): Schema => ({
  ...workspace,
  priceGeniusAlwaysOn: ability.can('read', 'GlobalSetupField-priceGenius')
    ? workspace.priceGeniusAlwaysOn
    : undefined,
})

import { useContext, useEffect } from 'react'

import { Form } from 'components/Form'
import { AnalyticsContext } from '../contexts/AnalyticsContext'
import { useDataSamplingFromCurrentWorkspace } from '../hooks/useDataSamplingFromCurrentWorkspace'
import { defaultSchema, schema, Schema } from './schema'

export const AnalyticsForm = ({ children }: { children: JSX.Element }) => {
  const { dataSampling } = useDataSamplingFromCurrentWorkspace()

  const {
    dialog: { setIsDialogOpen },
    formId,
    setLoading,
    setTouched,
    setValid,
    update: { errors, loading, update },
  } = useContext(AnalyticsContext)

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (data: Schema): void =>
    void update(data).then((): void => {
      setIsDialogOpen(false)
    })

  return (
    <Form<Schema>
      externalErrors={errors}
      id={formId}
      onSubmit={handleSubmit}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={defaultSchema({ dataSampling })}
    >
      {() => {
        return children
      }}
    </Form>
  )
}

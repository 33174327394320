import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { WorkspacePrebidModuleSlice } from 'features/globalSetup/contexts/PrebidModulesContext'
import { fromDTO } from './api/mapper'
import { GET_WORKSPACE_PREBID_MODULE } from './api/query'
import { Props, UseGetWorkspacePrebidModule } from './types'

export const useGetWorkspacePrebidModule = ({ id }: Props): UseGetWorkspacePrebidModule => {
  const [workspacePrebidModule, setWorkspacePrebidModule] = useState<WorkspacePrebidModuleSlice>()

  useEffect(() => {
    if (id) {
      get({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [get, { loading }] = useLazyQuery(GET_WORKSPACE_PREBID_MODULE, {
    onCompleted: ({ node: workspacePrebidModule }) => {
      if (workspacePrebidModule) {
        setWorkspacePrebidModule(fromDTO(workspacePrebidModule))
      }
    },
  })

  return { loading, workspacePrebidModule }
}

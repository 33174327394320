import { generatePath, Navigate, useParams } from 'react-router-dom'

import { BoxWithLogo } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  PrebidModulesContext,
  PrebidModulesContextType,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { prebidModuleLogos } from 'features/globalSetup/forms/Prebid/Modules/logos/prebidModuleLogos'
import { useGetWorkspacePrebidModule } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModule'
import { ROUTES } from 'routes'
import { useTabs } from './hooks/useTabs'
import { AnalyticsAdaptersEditTabs } from './hooks/useTabs/types'
import { UrlParams } from './types'

export const AnalyticsAdaptersEdit = (): JSX.Element => {
  const { id: urlWorkspacePrebidModuleId, tab: urlTab, workspaceId } = useParams<UrlParams>()
  const { workspacePrebidModule } = useGetWorkspacePrebidModule({ id: urlWorkspacePrebidModuleId })
  const tabs: Tab<AnalyticsAdaptersEditTabs>[] = useTabs()

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-modules-analytics-adapters-form-edit',
  })

  if (!workspaceId || !urlWorkspacePrebidModuleId) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!workspacePrebidModule) {
    return <Loading />
  }

  const contextProps: PrebidModulesContextType = {
    workspacePrebidModule,
    ...commonContextProps,
  }

  const prebidModuleName: string = workspacePrebidModule.prebidModule.name

  return (
    <PrebidModulesContext.Provider value={contextProps}>
      <PrebidModulesContext.Consumer>
        {(ctxProps: PrebidModulesContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.ANALYTICS.LIST, {
              workspaceId,
            })}
            title={prebidModuleName}
            titleAdornment={{
              start: (
                <BoxWithLogo logo={{ size: 'big', svg: prebidModuleLogos[prebidModuleName] }} />
              ),
            }}
          />
        )}
      </PrebidModulesContext.Consumer>

      <Tabs
        path={ROUTES.GLOBAL_SETUP.PREBID.MODULES.ANALYTICS.EDIT}
        tabs={tabs}
      />

      {getPage<AnalyticsAdaptersEditTabs>(tabs, urlTab, 'general')}
    </PrebidModulesContext.Provider>
  )
}

import InfoIcon from '@mui/icons-material/Info'
import { Stack, Typography } from '@mui/material'

import Space from 'components/Space'
import { HintProps } from './types'

export const Hint = ({ content, contentEndAdornment, ...stackProps }: HintProps): JSX.Element => (
  <Stack
    {...stackProps}
    alignItems='center'
    data-cy='hint'
    direction='row'
    gap={1}
    p={1}
  >
    <InfoIcon sx={{ color: theme => theme.palette.grey[400] }} />
    <Typography variant='subtitle1'>
      {content}

      {contentEndAdornment && (
        <>
          <Space />
          {contentEndAdornment}
        </>
      )}
    </Typography>
  </Stack>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_PREBID_MODULE_ENABLED: TypedDocumentNode<Data, { input: Variables }> =
  gql`
    ${ERROR_FRAGMENT}

    mutation updateWorkspacePrebidModuleEnabled($input: UpdateWorkspacePrebidModuleEnabledInput!) {
      updateWorkspacePrebidModuleEnabled(input: $input) {
        errors {
          ...ErrorFragment
        }
      }
    }
  `

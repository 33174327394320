import { TablePagination } from '@mui/material'
import { ChangeEvent } from 'react'

import { PaginationProps } from './types'

export const Pagination = ({
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
}: PaginationProps): JSX.Element => (
  <TablePagination
    count={count}
    onPageChange={(_, newPage: number): void => setPage(newPage + 1)}
    onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setPage(1)
      setRowsPerPage(Number(event.target.value))
    }}
    page={page - 1} // MUI page prop starts at 0 while in our API from 1
    rowsPerPage={rowsPerPage}
    showFirstButton
    showLastButton
    sx={{ marginLeft: 'auto' }}
  />
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_FILTERING_RULE: TypedDocumentNode<Data, Variables> = gql`
  query getFilteringRule($id: ID!) {
    node(id: $id) {
      ... on FilteringRule {
        applicableDomains
        blocked
        domainIds
        id
        name
      }
    }
  }
`

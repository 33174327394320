import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Workspace } from 'features/workspace'
import { dataSamplingToDTO } from 'features/workspace/mappers/dataSampling'
import { Schema } from '../../../form/schema'
import { Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From, workspaceId: Workspace['id']): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const { dataSampling } = data

    return {
      id: workspaceId,
      prebidManagerSampling: dataSamplingToDTO(dataSampling),
    }
  })

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Schema } from '../../../schema'
import { DomainPrebidModuleAttribute, Variables } from './types'

export const toDTO = (from: Schema): Variables =>
  mapDTO<Schema, Variables>({ from }).transform((data: Schema): Variables => {
    const { domainPrebidModuleAttributes } = data

    const parsedAttributes: DomainPrebidModuleAttribute[] = Object.entries(
      domainPrebidModuleAttributes,
    ).map(
      ([key, value]: [key: string, value: boolean]): DomainPrebidModuleAttribute => ({
        enabled: value,
        id: key,
      }),
    )

    return {
      attributes: parsedAttributes,
    }
  })

import { gql, TypedDocumentNode } from '@apollo/client'

import { USER_FRAGMENT } from 'features/user/api/fragments/user'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CONFIRM_USER: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation confirmUser($input: ConfirmUserInput!) {
    confirmUser(input: $input) {
      accessToken
      refreshToken

      user {
        ...UserFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/hooks/useGetWorkspaceBidder/api/mapper'
import { GET_WORKSPACE_BIDDER } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/hooks/useGetWorkspaceBidder/api/query'
import { Props, UseGetWorkspaceBidder, WorkspaceBidderSlice } from './types'

export const useGetWorkspaceBidder = ({ id }: Props): UseGetWorkspaceBidder => {
  const [workspaceBidder, setWorkspaceBidder] = useState<WorkspaceBidderSlice>()

  useEffect(() => {
    if (id) {
      get({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [get, { loading }] = useLazyQuery(GET_WORKSPACE_BIDDER, {
    onCompleted: ({ node }) => {
      if (node) {
        setWorkspaceBidder(fromDTO(node))
      }
    },
  })

  return { loading, workspaceBidder }
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { CustomCodeDTO } from '../api/types/customCodeDTO'
import { CustomCode } from '../types/customCode'

export const customCodeFromDTO = (from: CustomCodeDTO): CustomCode =>
  mapDTO<CustomCodeDTO, CustomCode>({ from }).transform(customCodeDto => {
    const { codeAfter, codeBefore, comment, createdAt, updatedAt } = customCodeDto

    return {
      ...customCodeDto,
      codeAfter: codeAfter || undefined,
      codeBefore: codeBefore || undefined,
      comment: comment || undefined,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }
  })

import { IsHeaderSortableProps } from './types'

export const isHeaderSortable = <T>({ id, sort }: IsHeaderSortableProps<T>): boolean => {
  const isSortable: boolean | undefined = sort?.sortableColumns.some((accessorKey: string) => {
    let key = accessorKey
    if (accessorKey.includes('.')) {
      key = key.replace('.', '_')
    }

    return key === id
  })

  return isSortable ?? false
}

import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RequestButtonProps } from './types'

export const RequestButton = ({
  onClick,
  workspaceProduct: { product, status },
}: RequestButtonProps): JSX.Element => {
  const { t } = useTranslation('features/product')

  let text: string

  if (status === 'trial') {
    text = t('button.pro')
  } else if (product === 'unfilledRecovery') {
    text = t('button.buy')
  } else {
    text = t('button.try')
  }

  return (
    <Button
      onClick={onClick}
      variant='contained'
    >
      {text}
    </Button>
  )
}

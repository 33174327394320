import { generatePath, Navigate, useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  AddEditPageContext,
  AddEditPageContextType,
} from 'features/inventory/page/contexts/AddEditPageContext'
import { useGetPage } from 'features/inventory/page/forms/GeneralForm/hooks/useGetPage'
import { ROUTES } from 'routes'
import { ProductTab } from 'types/productTab'
import { EditPageUrlParams } from './types'
import { useProductTabs } from './useProductTabs'

export const EditPage = (): JSX.Element => {
  const { id: urlPageId, tab: urlCurrentTab, workspaceId } = useParams<EditPageUrlParams>()
  const tabs: Tab<ProductTab>[] = useProductTabs()
  const { page } = useGetPage({ id: urlPageId })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: `page-form-edit`,
  })

  if (!workspaceId || !urlPageId) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!page) {
    return <Loading />
  }

  const contextProps: AddEditPageContextType = {
    page,
    ...commonContextProps,
  }

  return (
    <AddEditPageContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditPageContext.Consumer>
          {(ctxProps: AddEditPageContextType): JSX.Element => (
            <PageHeader
              actions={<SaveButton {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.PAGE.LIST, { workspaceId })}
              title={page.name}
            />
          )}
        </AddEditPageContext.Consumer>

        <Tabs
          path={ROUTES.INVENTORY.PAGE.EDIT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<ProductTab>(tabs, urlCurrentTab, 'general')}
    </AddEditPageContext.Provider>
  )
}

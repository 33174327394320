import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { DataSamplingContainer } from './components/DataSamplingContainer'
import { HeaderDescriptionText } from './components/HeaderDescriptionText'
import { KNOWLEDGE_BASE_URL } from './constants'
import { AnalyticsContextProvider } from './contexts/AnalyticsContext'
import { AnalyticsForm } from './form/AnalyticsForm'
import { useGetAsterioBidToken } from './hooks/useGetAsterioBidToken'
import { UrlTabs, useTabs } from './hooks/useTabs'
import { UrlParams } from './types'

export const PerformancePrebidStackAnalyticsPage = (): JSX.Element => {
  const ability = useAbility()
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })

  const { loading, token } = useGetAsterioBidToken()

  const { tab } = useParams<UrlParams>()
  const tabs: Tab<UrlTabs>[] = useTabs({ token })

  if (loading) {
    return <Loading />
  }

  if (ability.cannot('access', 'Product-prebidStack')) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  }

  return (
    <AnalyticsContextProvider>
      <AnalyticsForm>
        <PageHeader
          actions={
            ability.can('read', 'Performance-dataSampling') ? <DataSamplingContainer /> : undefined
          }
          description={{
            link: { title: tc('learnMore'), url: KNOWLEDGE_BASE_URL },
            text: <HeaderDescriptionText />,
          }}
          title={t('title')}
        />
      </AnalyticsForm>

      <Tabs
        path={ROUTES.PERFORMANCE.PREBID_STACK.ANALYTICS}
        tabs={tabs}
      />

      {tabs.length > 0 && getPage<UrlTabs>(tabs, tab, 'total')}
    </AnalyticsContextProvider>
  )
}

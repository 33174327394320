import { mapDTO } from '@twistezo/ts-dto-mapper'

import { MediaTypeViewportAttributeDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { commonMediaTypeFieldsFromDTO } from './commonMediaTypes'

export const mediaTypeViewportAttributesFromDTO = (
  from: MediaTypeViewportAttributeDTO,
): MediaTypeViewportAttribute =>
  mapDTO<MediaTypeViewportAttributeDTO, MediaTypeViewportAttribute>({ from }).transform(
    (dto: MediaTypeViewportAttributeDTO): MediaTypeViewportAttribute => {
      const { id: mediaTypeId, updatedAt, viewport } = dto
      const { id: viewportId, minimumWidth, name } = viewport

      return {
        ...commonMediaTypeFieldsFromDTO(dto),
        mediaTypeId,
        updatedAt: new Date(updatedAt),
        viewport: {
          ...commonMediaTypeFieldsFromDTO(viewport),
          id: viewportId,
          minimumWidth,
          name,
        },
      }
    },
  )

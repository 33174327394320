import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { domainAmazonsFromDTO, DomainAmazonSliceDTO, GET_DOMAIN_AMAZONS } from './api'
import { DomainAmazonOption, UseGetDomainAmazons } from './types'

export const useGetDomainAmazons = (): UseGetDomainAmazons => {
  const [options, setOptions] = useState<DomainAmazonOption[]>([])

  const { loading } = useQuery(GET_DOMAIN_AMAZONS, {
    fetchPolicy: 'cache-and-network',

    onCompleted: ({ domainAmazons: { nodes: domainAmazons } }): void => {
      if (domainAmazons) {
        setOptions(
          domainAmazons.map(
            (domainAmazon: DomainAmazonSliceDTO): DomainAmazonOption =>
              domainAmazonsFromDTO(domainAmazon),
          ),
        )
      }
    },
  })

  return { loading, options }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { VIEWPORT_FRAGMENT } from 'features/inventory/viewport/hooks/useGetViewports/api/fragment'
import { Data, Variables } from './types'

export const GET_VIEWPORTS: TypedDocumentNode<Data, Variables> = gql`
  ${VIEWPORT_FRAGMENT}

  query getViewports($workspaceId: ID!, $sort: ViewportSortInput, $terms: [String!]) {
    viewports(workspaceId: $workspaceId, sort: $sort, terms: $terms) {
      nodes {
        ...ViewportFragment
      }
    }
  }
`

import { useTranslation } from 'react-i18next'

import { SelectOption } from 'components/Select'
import { YieldbirdStatus, yieldbirdStatuses } from 'features/globalSetup/types/yieldbirdStatus'

export const useGetYieldbirdStatusesForSelect = (
  currentStatus?: YieldbirdStatus,
): SelectOption<YieldbirdStatus>[] => {
  const { t } = useTranslation('features/globalSetup')
  const availbleStatuses =
    currentStatus !== undefined && currentStatus !== 'requested'
      ? yieldbirdStatuses.filter((status: YieldbirdStatus): boolean => status !== 'requested')
      : yieldbirdStatuses

  return availbleStatuses.map(
    (status: YieldbirdStatus): SelectOption<YieldbirdStatus> => ({
      label: t(`common.yieldbirdStatuses.${status}`),
      value: status,
    }),
  )
}

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { Schema } from '../../form/schema'
import { toDTO } from './api/mapper'
import { UPDATE_DOMAIN_SUPPLY_CHAINS } from './api/mutation'
import { UseUpdateDomainSupplyChains, UseUpdateDomainSupplyChainsProps } from './types'

export const useUpdateDomainSupplyChains = ({
  supplyChainName,
}: UseUpdateDomainSupplyChainsProps): UseUpdateDomainSupplyChains => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.domainSids.update',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(SupplyChainContext)

  const [mutate] = useMutation(UPDATE_DOMAIN_SUPPLY_CHAINS, {
    onCompleted: ({ bulkUpdateDomainSupplyChains: { errors } }): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        enqueueSnackbar(t('error', { supplyChain: supplyChainName }), { variant: 'error' })
      } else {
        enqueueSnackbar(t('success', { supplyChain: supplyChainName }), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): void => {
    setLoading(true)

    mutate({ variables: { input: toDTO(data) } })
  }

  return {
    update,
  }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_USER_FRAGMENT } from 'features/globalSetup/api/fragments/workspaceUser'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_WORKSPACE_USER: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${WORKSPACE_USER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation createWorkspaceUser($input: CreatePublisherByWorkspaceOwnerInput!) {
    createPublisherByWorkspaceOwner(input: $input) {
      user {
        ...WorkspaceUserFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

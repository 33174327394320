import { useMergeFormData } from 'components/Form'
import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { calculatePagination } from 'providers/graphql'
import { Schema } from '../../form/schema'
import { useGetAdUnitBidders } from '../../hooks/UseGetAdUnitBidders'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useErrors } from './hooks/useErrors'
import { useErrorsOnPagesInfo } from './hooks/useErrorsOnPagesInfo'
import { useFooterInfoProps } from './hooks/useFooterInfoProps'
import { AdUnitsForTable, AdUnitSortBy, AdUnitsTableProps } from './types'
import { useColumns } from './useColumns'
import { adUnitBiddersToSchema, getRequiredParams } from './utils'

export const AdUnitsTable = ({ domainBidder, filters }: AdUnitsTableProps): JSX.Element => {
  const {
    domain: { id: domainId },
    paramsSchema,
    workspaceBidder: {
      bidder: { s2sAvailable },
      status,
    },
  } = domainBidder

  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination({
    resetOnChange: [domainId, filters],
  })
  const { props: sortProps, sort } = useSort<AdUnitsForTable, AdUnitSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { adUnitBidders, count, loading } = useGetAdUnitBidders({
    domainBidder,
    ...calculatePagination({ page, rowsPerPage }),
    filters,
    sort,
  })

  const { csParams, s2sParams } = adUnitBidders[0] || {}
  const columns = useColumns({
    csParamsInitial: csParams,
    requiredParams: getRequiredParams(paramsSchema),
    s2sParamsInitial: s2sAvailable ? s2sParams : undefined,
    workspaceStatus: status,
  })

  useMergeFormData<Schema>({
    data: adUnitBiddersToSchema(adUnitBidders),
    path: 'adUnitBidders',
    resetDependency: domainId,
    resetValue: {},
  })

  useErrors({ adUnitBidders })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  }

  const { pagesWithError } = useErrorsOnPagesInfo({
    adUnitBidders,
    currentPage: page,
    currentRowsPerPage: rowsPerPage,
  })
  const footerInfoProps = useFooterInfoProps({ pagesWithError })

  return (
    <Table<AdUnitsForTable>
      columns={columns}
      data={adUnitBidders}
      footerInfo={footerInfoProps}
      loading={loading}
      meta={{ verticalBorders: true }}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

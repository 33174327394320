import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { DomainAmazon } from 'features/globalSetup/features/amazon/types/domainAmazon'
import { Schema } from '../../schema'
import { DomainAmazonOption, useGetDomainAmazons } from '../useGetDomainAmazons'
import { UseDomainAmazonData } from './types'

export const useDomainAmazonData = (): UseDomainAmazonData => {
  const { loading, options } = useGetDomainAmazons()

  const { getValues, reset, setValue, watch } = useFormContext<Schema>()
  const { id } = watch()

  useEffect(() => {
    if (options.length > 0 && id === undefined) {
      selectFirstOption()
    }
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id !== undefined) {
      setDomainAmazonParams(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectFirstOption = (): void => setValue('id', options[0].value)

  const setDomainAmazonParams = (id: DomainAmazon['id']): void => {
    const { enabled } = options.find((option: DomainAmazonOption) => option.value === id) || {
      enabled: false,
    }

    reset({
      ...getValues(),
      enabled,
    })
  }

  return {
    loading,
    options,
  }
}

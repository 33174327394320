import { z } from 'zod'

import { commonSchema } from './commonSchema'

export type AddSchema = z.infer<typeof commonSchema>

export const addSchemaDefault: AddSchema = {
  applicableDomains: 'specified',
  blocked: [],
  domainIds: [],
  name: '',
}

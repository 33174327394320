import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql/api'
import { USER_FRAGMENT } from '../fragments/user'
import { RefreshTokenData, RefreshTokenVars } from '../types/refreshToken'

export const REFRESH_TOKEN: TypedDocumentNode<RefreshTokenData, RefreshTokenVars> = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation refreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken

      user {
        ...UserFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { createContext, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'providers/redux'
import { abilityBuilder } from './abilityBuilder'
import { Ability } from './types/ability'

export const AbilityContext = createContext<Ability>({} as Ability)

export const AbilityProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.user)

  const ability: Ability = abilityBuilder(user)

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

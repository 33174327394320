import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainViewabilityToolsDTO } from '../types/domainViewabilityToolsDTO'

export const DOMAIN_VIEWABILITY_TOOL_FRAGMENT: TypedDocumentNode<DomainViewabilityToolsDTO> = gql`
  fragment DomainViewabilityToolFragment on DomainViewabilityTool {
    adHoldingEnabled
    createdAt
    domainId
    fetchMargin
    id
    lazyLoadingEnabled
    lazyLoadType
    mobileScaling
    renderMargin
    scrollSlowingEnabled
    slowingFactor
    trafficAffected
    updatedAt
    viewabilityToolsEnabled
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { EmployeeForTableDTO } from './types'

export const EMPLOYEE_FOR_TABLE_FRAGMENT: TypedDocumentNode<EmployeeForTableDTO> = gql`
  fragment EmployeeForTableFragment on User {
    email
    firstName
    id
    lastName
    role
    status
    updatedAt
  }
`

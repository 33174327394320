import { Navigate } from 'react-router-dom'

import { Subjects, useAbility } from 'providers/casl'
import { ROUTES } from 'routes'

export const AuthorizedRoute = ({
  access,
  page,
}: {
  access: Subjects
  page: JSX.Element
}): JSX.Element => {
  const ability = useAbility()
  const hasAccess = ability.can('access', access)

  return hasAccess ? page : <Navigate to={ROUTES.BASE.NOT_FOUND} />
}

import { Link as LinkMui } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { LinkProps } from './types'

export const Link = ({ newTab, to, ...muiNavLinkProps }: LinkProps): JSX.Element => (
  <LinkMui
    {...muiNavLinkProps}
    component={RouterLink}
    rel={newTab ? 'noopener noreferrer' : undefined}
    relative='path'
    sx={{
      display: 'inline-flex',
      ...muiNavLinkProps.sx,
    }}
    target={newTab ? '_blank' : undefined}
    to={to}
  />
)

import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { AlertData, Container, Props } from './types'

export const Alerts = <T extends string>({ alerts, switcher }: Props<T>): JSX.Element => {
  const { t } = useTranslation('common')

  const containers: Container<T>[] = alerts
    .map(
      (alert: T): Container<T> => ({
        alert,
        data: switcher(alert),
      }),
    )
    .filter((container: Container<T>): AlertData | undefined => container.data)

  const prepare = (containers: Container<T>[]): JSX.Element[] =>
    containers.map((container: Container<T>): JSX.Element => {
      const { alert, data } = container
      const {
        color,
        Icon,
        tooltip: { link, text },
      } = data!

      return (
        <Box
          data-cy={`alert-${alert}`}
          key={alert}
        >
          <Tooltip
            content={text}
            {...(link && {
              link: {
                title: t('learnMore'),
                url: link,
              },
            })}
          >
            <Box>
              <FlexBox
                axis='xy'
                sx={{ color }}
              >
                {Icon}
              </FlexBox>
            </Box>
          </Tooltip>
        </Box>
      )
    })

  return (
    <Stack
      direction='row'
      gap={1}
    >
      {prepare(containers)}
    </Stack>
  )
}

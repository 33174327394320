import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceSliceDTO } from './types'

export const WORKSPACE_PERFORMANCE_FRAGMENT: TypedDocumentNode<WorkspaceSliceDTO> = gql`
  fragment WorkspacePerformanceFragment on Workspace {
    users {
      id
      email
    }
    products {
      id
      product
      performanceReportUrl
    }
  }
`

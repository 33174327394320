import { Typography } from '@mui/material'

export const Space = () => (
  <Typography
    component='span'
    sx={{ fontSize: 'inherit' }}
  >
    &nbsp;
  </Typography>
)

import { z } from 'zod'

import { UserSlice } from '../types'
import { commonSchema } from './common'

export const editSchema = z
  .object({
    id: z.string(),
  })
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (user: UserSlice): EditSchema => ({ ...user })

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspaceProduct } from 'features/product/types/workspaceProduct'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../components/TrialDialog/schema'
import { toDTO } from './api/mapper'
import { UPDATE_PRODUCT } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateProduct, Variables } from './types'

export const useUpdateProduct = ({ product }: WorkspaceProduct): UseUpdateProduct => {
  const { t } = useTranslation('features/product')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const productName = t(`product.${product}`)

  const [mutateUpdateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({ updateWorkspaceProductStatus: { errors } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('notification.update.error', { product: productName }), {
          variant: 'error',
        })
      } else {
        enqueueSnackbar(t('notification.update.success', { product: productName }), {
          variant: 'success',
        })
      }
    },
    refetchQueries: ['getWorkspaceProducts'],
  })

  const update = (variables: Variables) =>
    mutateUpdateProduct({
      variables: {
        input: toDTO(variables),
      },
    })

  return { errors, update }
}

import { PrebidModuleParameterDTO } from 'features/globalSetup/api/types/prebidModule'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { isPrimitive } from 'utils/primitive'

type From = PrebidModuleParameterDTO[]
type To = PrebidModuleParameter[]

export const fromDTO = (from: From): To =>
  from.map((parametersSchemaDTO: PrebidModuleParameterDTO): PrebidModuleParameter => {
    const { tooltip, type } = parametersSchemaDTO

    if (!isPrimitive(type)) {
      throw Error('Type of input does not match to primitive types.')
    }

    return {
      ...parametersSchemaDTO,
      tooltip: tooltip ? { content: tooltip } : undefined,
      type: type,
    }
  })

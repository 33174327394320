import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { AdUnitForProductTable, AdUnitSortBy } from '../../types'
import { AdUnitForProductTableDTO, AdUnitSortByDTO } from './types'

export const adUnitForProductTableFromDTO = (from: AdUnitForProductTableDTO) =>
  mapDTO<AdUnitForProductTableDTO, AdUnitForProductTable>({
    from,
  }).transform(adUnitDTO => ({
    ...adUnitDTO,
    divId: adUnitDTO.divId || undefined,
    name: adUnitDTO.name || undefined,
    updatedAt: new Date(adUnitDTO.updatedAt),
  }))

export const sortToDTO = (sort: SortInput<AdUnitSortBy>): SortInput<AdUnitSortByDTO> => {
  const { by } = sort

  if (by === 'name') {
    return { ...sort, by: 'adUnitName' }
  } else {
    return { ...sort, by }
  }
}

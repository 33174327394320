import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { DomainSidsPage, GeneralPage } from '../../pages'
import { BidderSidsPage } from '../../pages/BidderSidsPage'
import { SupplyChainEditTabs } from './types'

export const useTabs = (): Tab<SupplyChainEditTabs>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.tabs',
  })

  return [
    {
      element: 'general',
      label: t('general'),
      page: <GeneralPage />,
    },
    {
      element: 'domain-sid',
      label: t('domainSid'),
      page: <DomainSidsPage />,
    },
    {
      element: 'bidder-sid',
      label: t('bidderSid'),
      page: <BidderSidsPage />,
    },
  ]
}

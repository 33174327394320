import { Theme } from '@mui/material'
import { Row } from '@tanstack/react-table'
import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useAdUnits } from './hooks/useAdUnits'
import { AdUnitForTable, AdUnitListProps, AdUnitSortBy } from './types'
import { useColumns } from './useColumns'

export const AdUnitsTable = ({ filters }: AdUnitListProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<AdUnitForTable, AdUnitSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { adUnits, count, loading, setAdUnits } = useAdUnits({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const columns = useColumns(filters, count)

  const handleRowClick = (row: AdUnitForTable): To =>
    generatePath(ROUTES.INVENTORY.AD_UNIT.EDIT, {
      id: row.id,
      tab: 'general',
      workspaceId,
    })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<AdUnitForTable>
      columns={columns}
      data={adUnits}
      loading={loading}
      meta={{
        styleRow: (row: Row<AdUnitForTable>, theme: Theme) =>
          row.original.unfilledRecoveryAdUnit ? { backgroundColor: theme.palette.cream.main } : {},
      }}
      onClickRow={ability.can('update', 'AdUnit') ? id => handleRowClick(id) : undefined}
      onUpdateData={setAdUnits}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

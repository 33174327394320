import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { DOMAIN_AMAZON_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_AMAZON: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_AMAZON_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateDomainAmazon($input: UpdateDomainAmazonInput!) {
    updateDomainAmazon(input: $input) {
      domainAmazon {
        ...DomainAmazonFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

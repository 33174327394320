import { ProductStatus } from 'features/product'
import { UserWorkspaceRole } from 'features/user'
import { UserWorkspaceProductsSlice } from 'features/user/types/user'
import { Can } from 'providers/casl'
import { appendCommonRules } from './commonRules'
import { UserData } from './types'

export const productToggles = (userData: UserData, can: Can): void => {
  const { isInEmployeeGroup, userProducts, userWorkspaceRole } = userData

  userProducts.forEach(({ product, status }: UserWorkspaceProductsSlice): void => {
    const allowedStatuses: ProductStatus[] = appendCommonRules(userData)
    const allowedWorkspaceRoles: UserWorkspaceRole[] = ['editor', 'owner']

    if (allowedStatuses.includes(status)) {
      can('access', `ProductToggle-${product}`)
    }

    if (
      isInEmployeeGroup ||
      (userWorkspaceRole && allowedWorkspaceRoles.includes(userWorkspaceRole))
    ) {
      can('update', `ProductToggle-${product}`)
    }
  })
}

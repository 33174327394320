import { z } from 'zod'

import {
  CommonOwnSchema,
  commonSchema,
  CommonThirdPartyProviderSchema,
  CommonYieldbirdSchema,
} from 'features/globalSetup/forms/Prebid/Bidders/schemas/commonSchema'
import { DEFAULT_CONSENT_MODE, DEFAULT_CPM_ADJUSTMENT } from '../constants'

export type AddSchema = z.infer<typeof commonSchema>
export type AddOwnSchema = CommonOwnSchema
export type AddYieldbirdSchema = Omit<CommonYieldbirdSchema, 'status'>
export type AddThirdPartyProviderSchema = CommonThirdPartyProviderSchema

export const addSchemaDefault: AddSchema = {
  accountType: 'own',
  bidCpmAdjustment: DEFAULT_CPM_ADJUSTMENT.OWN,
  bidderCode: '',
  bidderId: '',
  bidderSid: '',
  consentMode: DEFAULT_CONSENT_MODE,
  priority: 'primary',
}

import { Delete as DeleteIcon } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, Stack } from '@mui/material'
import { ArrayPath, FieldArrayWithId, FieldValues, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm } from 'components/Form'
import { FieldArrayInputColumn, FieldArrayInputProps } from './types'

export const FieldArrayInput = <T extends FieldValues>({
  addNextDisabled,
  control,
  defaults,
  inputs,
  name,
}: FieldArrayInputProps<T>): JSX.Element => {
  const { t } = useTranslation('features/user')
  const { append, fields, remove } = useFieldArray<T>({
    control,
    name,
  })

  const createDefaults = (): FieldArrayWithId<T, ArrayPath<T>, 'id'> => {
    return Object.assign({ id: '' }, defaults)
  }

  const headerColumns = (): JSX.Element[] =>
    inputs.map((column: FieldArrayInputColumn, columnIndex: number) => (
      <FormControl key={`${name}-header-${columnIndex}`}>{column.label}</FormControl>
    ))

  const bodyColumns = (index: number): JSX.Element[] =>
    inputs.map((column: FieldArrayInputColumn): JSX.Element => column.cell(index))

  return (
    <BoxForm>
      <Stack
        direction='row'
        gap={2}
      >
        {headerColumns()}
      </Stack>

      {fields.map((field: FieldArrayWithId<T, ArrayPath<T>, 'id'>, index: number) => (
        <Stack
          direction='row'
          gap={2}
          key={field.id}
        >
          {bodyColumns(index)}

          <Box sx={{ pt: 1 }}>
            <IconButton onClick={(): void => remove(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>
      ))}

      <Box sx={{ pt: 1 }}>
        <Button
          data-cy='add-next'
          disabled={addNextDisabled}
          onClick={() => append(createDefaults())}
          type='button'
        >
          {t('form.publisher.addNext')}
        </Button>
      </Box>
    </BoxForm>
  )
}

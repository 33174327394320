import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SpaceBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import { Select, StackForm } from 'components/Form'
import Loading from 'components/Loading'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { DomainBidder } from 'features/globalSetup/types/domainBidder'
import { Schema } from '../../form/schema'
import { DomainBidderOption } from '../../hooks/useGetDomainBiddersForSelect'
import { AdUnitsTable } from '../AdUnitsTable'
import { DomainBidderFields } from './sections/DomainBidderFields'
import { DomainBidderChangeEvent } from './types'
import { useDomainBidderData } from './useDomainBidderData'
import { getRelatedDomainId } from './utils'

export const FormBody = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection',
  })
  const { setValid } = useContext(WorkspaceBidderContext)
  const [tableFilters, setTableFilters] = useState<FilterValue[]>([])

  const { domainBidder, loading, options } = useDomainBidderData()

  const {
    control,
    formState,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<Schema>()
  const {
    domainIds: { domainId },
  } = watch()

  useEffect(() => {
    setTableFilters([])
  }, [domainId])

  useEffect(() => {
    if (setValid !== undefined) {
      setValid(_.isEmpty(errors))
    }
  }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!domainBidder || loading) {
    return <Loading />
  }

  const handleChangeDomain = (event: DomainBidderChangeEvent): void => {
    const nextDomainBidderId = event.target.value as DomainBidder['id']

    setValue('domainIds', {
      domainBidderId: nextDomainBidderId,
      domainId: getRelatedDomainId(nextDomainBidderId, options),
    })
  }

  return (
    <>
      <StackForm pb={3}>
        <Select<Schema, DomainBidderOption>
          control={control}
          label={t('domainSection.domain')}
          name='domainIds.domainBidderId'
          onChange={(event: DomainBidderChangeEvent) => handleChangeDomain(event)}
          options={options}
        />

        <>
          <DomainBidderFields
            s2sAvailable={domainBidder.workspaceBidder.bidder.s2sAvailable}
            workspaceBidderPriority={domainBidder.workspaceBidder.priority}
          />

          <SpaceBox />

          <Filter
            id='adUnitsTableFilter'
            onChange={setTableFilters}
            placeholder={t('filter')}
            values={tableFilters}
          />
        </>
      </StackForm>

      <AdUnitsTable
        domainBidder={domainBidder}
        filters={tableFilters}
      />
    </>
  )
}

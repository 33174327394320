import _ from 'lodash'

import { DomainBidderSlice } from './hooks/useGetDomainBidders'
import { DomainBiddersAttributes } from './types'

export const domainBiddersToAttributes = (
  domainBidders: DomainBidderSlice[],
): DomainBiddersAttributes =>
  _.chain(domainBidders)
    .keyBy('id')
    .mapValues((domainBidder: DomainBidderSlice) => {
      const { csEnabled, priority, s2sEnabled } = domainBidder

      return { csEnabled, priority, s2sEnabled }
    })
    .value()

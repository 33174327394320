import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_BIDDER_FRAGMENT } from './fragments/domainBidder'
import { Data, Variables } from './types'

export const GET_DOMAIN_BIDDER: TypedDocumentNode<Data, Variables> = gql`
  ${DOMAIN_BIDDER_FRAGMENT}

  query getDomainBidder($id: ID!) {
    node(id: $id) {
      ... on DomainBidder {
        ...DomainBidderFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_BIDDER: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateWorkspaceBidder($input: UpdateWorkspaceBidderInput!) {
    updateWorkspaceBidder(input: $input) {
      workspaceBidder {
        id
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { MutationInputError } from 'providers/graphql'

export const ERROR_FRAGMENT: TypedDocumentNode<MutationInputError> = gql`
  fragment ErrorFragment on Error {
    attribute
    message
  }
`

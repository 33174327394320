import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitViewabilityToolsDTO } from '../types/adUnitViewabilityToolsDTO'

export const AD_UNIT_VIEWABILITY_TOOLS_FRAGMENT: TypedDocumentNode<AdUnitViewabilityToolsDTO> = gql`
  fragment AdUnitViewabilityToolsFragment on AdUnitViewabilityTool {
    adHolding
    adUnitId
    createdAt
    id
    lazyLoading
    scrollSlowing
    updatedAt
    viewabilityToolsEnabled
  }
`

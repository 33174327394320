import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Schema } from '../../form/schema'

export const HeaderDescriptionText = (): JSX.Element => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })
  const { watch } = useFormContext<Schema>()

  const sampling: string = watch()['dataSampling']

  return (
    <Trans
      components={{
        bold: (
          <Typography
            fontWeight='bold'
            variant='inherit'
          />
        ),
      }}
      i18nKey='description'
      t={t}
      values={{ sampling }}
    />
  )
}

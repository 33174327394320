import { useTheme } from '@mui/material'
import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { BoxForm, RadioGroup, Select, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AmazonPageContext } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext'
import { AmazonPageContextType } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext/types'
import { Schema } from 'features/globalSetup/features/amazon/forms/GeneralPageForm'
import { useAccountTypeOptions } from 'features/globalSetup/forms/Prebid/Bidders/hooks/useAccountTypeOptions'
import { useGetSupplyChainsForSelect } from 'features/globalSetup/forms/Prebid/Bidders/sections/hooks/useGetSupplyChainsForSelect'
import { useGetYieldbirdStatusesForSelect } from 'features/globalSetup/hooks/useGetYieldbirdStatusesForSelect'
import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { yieldbirdStatusColor } from 'features/globalSetup/utils'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { schemaDefault } from '../../schema'

export const AmazonUamTamSection = () => {
  const theme = useTheme()
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t: tc } = useTranslation('features/globalSetup')
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'pages.general.amazonUamTam',
  })
  const { workspaceAmazon } = useContext<AmazonPageContextType>(AmazonPageContext)

  const { control, reset, watch } = useFormContext<Schema>()
  const { accountType } = watch()

  const statuses = useGetYieldbirdStatusesForSelect()
  const accountTypeOptions = useAccountTypeOptions()
  const supplyChains = useGetSupplyChainsForSelect()

  const isDisabled: boolean = !ability.can('update', 'GlobalSetupFeature-amazon')

  useEffect(() => {
    if (!_.isNil(workspaceAmazon)) {
      const { accountType: contextAccountType } = workspaceAmazon

      reset({
        ...schemaDefault(contextAccountType === accountType ? workspaceAmazon : null),
        accountType: accountType,
      } as Schema)
    }
  }, [workspaceAmazon, accountType]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        title={t('title')}
      />

      <BoxForm>
        <RadioGroup
          control={control}
          disabled={isDisabled}
          label={tc('common.accountType.label')}
          name='accountType'
          options={accountTypeOptions}
          row
          tooltip={{ content: tc('common.accountType.tooltip') }}
        />

        {accountType === 'yieldbird' && (
          <Select
            control={control}
            disabled={isDisabled || _.isNil(workspaceAmazon)}
            label={tc('common.status.label')}
            labelColor={({ value }: { value: YieldbirdStatus }) =>
              yieldbirdStatusColor({ status: value, theme })
            }
            name='status'
            options={statuses}
            tooltip={{ content: tc('common.status.tooltip') }}
          />
        )}

        {accountType === 'thirdPartyProvider' && (
          <Select
            control={control}
            disabled={isDisabled}
            label={tc('common.supplyChain.label')}
            name='supplyChainId'
            options={supplyChains}
            tooltip={{
              content: tc('common.supplyChain.tooltip.content'),
              link: {
                title: tc('common.supplyChain.tooltip.link'),
                url: generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.LIST, { workspaceId }),
              },
            }}
          />
        )}

        {accountType !== 'yieldbird' && (
          <TextField
            control={control}
            disabled={isDisabled}
            label={t('pubId.label')}
            name='pubId'
            placeholder={t('pubId.label')}
          />
        )}
      </BoxForm>
    </Paper>
  )
}

import { sentenceCase } from 'change-case'
import { upperFirst } from 'lodash'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SwitchProps } from 'components/Form/Switch'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { Props } from '../types'

export const useSwitchProps = <Schema extends FieldValues>({
  amazonState,
  feature,
}: Props): SwitchProps<Schema> => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'inventory.uamTamSection',
  })
  const { workspaceId } = useWorkspaceParam()
  const { control } = useFormContext<Schema>()

  const commonProps: SwitchProps<Schema> = {
    control,
    name: `${feature}AmazonEnabled`,
    tooltipMode: 'wrap',
  }

  let props: Pick<SwitchProps<Schema>, 'disabled' | 'tooltip'> = {
    disabled: false,
    tooltip: undefined,
  }

  if (!ability.can('update', upperFirst(feature) as 'AdUnit' | 'Domain')) {
    props = {
      disabled: true,
      tooltip: undefined,
    }
  } else if (amazonState === undefined) {
    props = {
      disabled: true,
      tooltip: {
        content: t('tooltip.content', { feature: sentenceCase(feature) }),
        link: {
          title: t('tooltip.link'),
          url: generatePath(ROUTES.GLOBAL_SETUP.AMAZON.ROOT, { tab: 'general', workspaceId }),
        },
      },
    }
  } else {
    props = {
      disabled: false,
      tooltip: undefined,
    }
  }

  return {
    ...commonProps,
    ...props,
  }
}

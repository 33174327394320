import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { PageHeader } from 'components/Header'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { ROUTES } from 'routes'
import { UserManagementGroup, UserUrlParams } from './types'
import { useUserTabs } from './useUserTabs'

export const UserListPage = (): JSX.Element => {
  const { tab: urlTab } = useParams<UserUrlParams>()
  const { t } = useTranslation('features/user')
  const tabs: Tab<UserManagementGroup>[] = useUserTabs()

  return (
    <>
      <PageHeader title={t('list.title')} />

      <Tabs
        path={ROUTES.USER.LIST}
        tabs={tabs}
      />

      {getPage<UserManagementGroup>(tabs, urlTab, 'publishers')}
    </>
  )
}

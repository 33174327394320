import { mapDTO } from '@twistezo/ts-dto-mapper'

import { User } from 'features/user'
import { UserSliceDTO, UserWorkspaceForForm, UserWorkspacePayloadDTO } from '../../../types'
import { WorkspaceSliceDTO } from './types'

export const userWorkspaceFromDTO = (
  from: WorkspaceSliceDTO,
  userId: User['id'],
): undefined | UserWorkspaceForForm =>
  mapDTO<WorkspaceSliceDTO, undefined | UserWorkspaceForForm>({ from }).transform(
    (workspaceSliceDTO: WorkspaceSliceDTO): undefined | UserWorkspaceForForm => {
      const userDTO = workspaceSliceDTO.users.find((user: UserSliceDTO) => user.id === userId)

      if (userDTO) {
        const userWorkspaceDTO = userDTO.userWorkspaces.find(
          (userWorkspace: UserWorkspacePayloadDTO) => userWorkspace.id === workspaceSliceDTO.id,
        )

        if (userWorkspaceDTO) {
          return {
            role: userWorkspaceDTO.role,
            userId,
            workspaceId: userWorkspaceDTO.id,
          }
        }
      }

      return undefined
    },
  )

import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralPage } from '../../pages/GeneralPage'
import { InventoryConnectionPage } from '../../pages/InventoryConnectionPage'
import { UserIdsEditTabs } from './types'

export const useTabs = (): Tab<UserIdsEditTabs>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.edit.tabs',
  })

  return [
    {
      element: 'general',
      label: t('general'),
      page: <GeneralPage />,
    },
    {
      element: 'inventory-connection',
      label: t('inventoryConnection'),
      page: <InventoryConnectionPage />,
    },
  ]
}

import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { AnalyticsContext } from '../../contexts/AnalyticsContext'
import { DataSamplingInput } from '../DataSamplingInput'
import { DialogAction } from '../DialogAction'
import { DialogContent } from '../DialogContent'

export const DataSamplingContainer = (): JSX.Element => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })
  const {
    dialog: { isDialogOpen, setIsDialogOpen },
  } = useContext(AnalyticsContext)

  return (
    <>
      <DataSamplingInput />

      <Dialog
        actions={<DialogAction />}
        content={<DialogContent />}
        isOpen={isDialogOpen}
        setOpen={setIsDialogOpen}
        title={t('dataSampling.modal.title')}
      />
    </>
  )
}

import { OpenInNew } from '@mui/icons-material'
import { ListItem, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import { ListItemProps } from './types'

export const RelatedListItem = ({ content, url, value }: ListItemProps): JSX.Element => {
  return (
    <ListItem
      disableGutters
      sx={{ display: 'list-item' }}
    >
      <FlexBox axis='x'>
        <Trans
          components={{
            bold: (
              <Typography
                fontWeight='bold'
                px={1}
              />
            ),
          }}
          values={{ value }}
        >
          {content}
        </Trans>

        {url && (
          <Link
            newTab
            to={url}
          >
            <OpenInNew
              fontSize='small'
              sx={theme => ({ color: theme.palette.info.main })}
            />
          </Link>
        )}
      </FlexBox>
    </ListItem>
  )
}

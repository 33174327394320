import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceUserDTO } from 'features/globalSetup/api/types/workspaceUser'
import { WorkspaceUser } from 'features/globalSetup/types/workspaceUser'

export const fromDTO = (from: WorkspaceUserDTO): WorkspaceUser =>
  mapDTO<WorkspaceUserDTO, WorkspaceUser>({ from }).transform(
    ({ currentWorkspaceRole, email, id }: WorkspaceUserDTO): WorkspaceUser => ({
      currentWorkspaceRole: currentWorkspaceRole || undefined,
      email,
      id,
    }),
  )

import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainPrebidModuleSliceDTO } from './types'

export const DOMAIN_PREBID_MODULE_FRAGMENT: TypedDocumentNode<DomainPrebidModuleSliceDTO> = gql`
  fragment DomainPrebidModuleFragment on DomainPrebidModule {
    enabled
    id
    updatedAt
    domain {
      id
      name
    }
  }
`

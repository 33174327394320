import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import WorkspaceList from 'features/workspace/components/WorkspaceList'
import { ROUTES } from 'routes'

export const WorkspaceListPage = (): JSX.Element => {
  const { t } = useTranslation('features/workspace')
  const [filters, setFilters] = useState<FilterValue[]>([])

  const WorkspaceFilter = (
    <Filter
      id='workspaceFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        actions={
          <Link to={ROUTES.WORKSPACE.ADD}>
            <Button
              data-cy='add-workspace-button'
              variant='contained'
            >
              {t('list.add')}
            </Button>
          </Link>
        }
        bottomSpacing
        description={t('list.description')}
        filter={WorkspaceFilter}
        title={t('list.title')}
      />
      <WorkspaceList filters={filters} />
    </Paper>
  )
}

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { useUpdateWorkspacePrebidModule } from '../../hooks/useUpdateWorkspacePrebidModule'
import { EditSchema, editSchema, editSchemaDefault } from '../../schemas/editSchema'
import { VendorSpecificForm } from './VendorSpecificForm'

export const EditContainer = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspacePrebidModule } =
    useContext(PrebidModulesContext)
  const { errors: apiErrors, update } = useUpdateWorkspacePrebidModule({
    moduleType: 'vendorSpecific',
  })

  if (!workspacePrebidModule) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={(data: EditSchema) => update(workspacePrebidModule.id, data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(workspacePrebidModule)}
    >
      {() => (loading ? <Loading /> : <VendorSpecificForm variant='edit' />)}
    </Form>
  )
}

import i18next from 'i18next'
import { z } from 'zod'

import { userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'

export const commonSchema = z.object({
  role: z.enum(userWorkspaceRoles),
  userId: z.string(),
  workspaceId: z.string().min(1, i18next.t('common:form.error.nonEmpty')),
})

export type CommonSchema = z.infer<typeof commonSchema>

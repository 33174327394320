import { RouteObject } from 'react-router-dom'

import { ProtectionAddPage } from 'features/globalSetup/pages/Prebid/Protection/pages/ProtectionAddPage'
import { ProtectionEditPage } from 'features/globalSetup/pages/Prebid/Protection/pages/ProtectionEditPage'
import { ProtectionListPage } from 'features/globalSetup/pages/Prebid/Protection/pages/ProtectionListPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const protectionRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access={'GlobalSetupPage-prebid-protection-list'}
        page={<ProtectionListPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.LIST,
  },
  {
    element: (
      <AuthorizedRoute
        access={'GlobalSetupPage-prebid-protection-add'}
        page={<ProtectionAddPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.ADD,
  },
  {
    element: (
      <AuthorizedRoute
        access={'GlobalSetupPage-prebid-protection-edit'}
        page={<ProtectionEditPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.PREBID.PROTECTION.EDIT,
  },
]

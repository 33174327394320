import { CellContext } from '@tanstack/react-table'

import { Switch } from 'components/Switch'
import { useUpdateWorkspaceBidder } from 'features/globalSetup/pages/Prebid/Bidders/hooks/useUpdateWorkspaceBidder'
import { useAbility } from 'providers/casl'
import { BiddersForTable } from '../../types'

export const EnabledCell = ({
  getValue,
  row: {
    original: { accountType, code: bidderCode, id, status },
  },
}: CellContext<BiddersForTable, unknown>): JSX.Element => {
  const ability = useAbility()
  const disabled =
    (accountType === 'yieldbird' && status !== 'available') ||
    !ability.can('update', 'GlobalSetupFeature-bidder')

  const { checked, update } = useUpdateWorkspaceBidder({
    bidderCode,
    enabled: Boolean(getValue()),
    id,
  })

  return (
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={update}
    />
  )
}

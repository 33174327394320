import { useContext, useState } from 'react'

import { FilterValue } from 'components/Filter'
import { Form } from 'components/Form'
import Paper from 'components/Paper'
import { usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { calculatePagination } from 'providers/graphql'
import { DomainSidsTable } from './components/DomainSidsTable'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { defaults } from './form/defaults'
import { schema, Schema } from './form/schema'
import { DomainSupplyChainSortBy, useGetDomainSupplyChains } from './hooks/useGetDomainSupplyChains'
import { useUpdateDomainSupplyChains } from './hooks/useUpdateDomainSupplyChains'

export const DomainSidsPage = (): JSX.Element => {
  const { formId, setTouched, setValid, supplyChain } = useContext(SupplyChainContext)
  const [filters, setFilters] = useState<FilterValue[]>([])
  const { page, rowsPerPage, ...usePagniationProps } = usePagination({ resetOnChange: [filters] })
  const { props: sortProps, sort } = useSort<DomainSupplyChain, DomainSupplyChainSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  if (supplyChain === undefined) {
    throw Error('Supply chain should be accessible')
  }

  const { id: supplyChainId, name: supplyChainName } = supplyChain
  const { count, domainSupplyChains } = useGetDomainSupplyChains({
    supplyChainId,
    ...calculatePagination({ page, rowsPerPage }),
    filters,
    sort,
  })
  const { update } = useUpdateDomainSupplyChains({ supplyChainName })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    ...usePagniationProps,
  }

  return (
    <Paper>
      <Form<Schema>
        id={formId}
        onSubmit={update}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={defaults(domainSupplyChains)}
      >
        {() => (
          <DomainSidsTable
            data={domainSupplyChains}
            filter={{ filters, setFilters }}
            pagination={paginationProps}
            sort={sortProps}
          />
        )}
      </Form>
    </Paper>
  )
}

import { Box, Paper, TableBody, TableContainer, TableHead, Table as TableRoot } from '@mui/material'
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  RowData,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import Pagination from '../Pagination'
import { useTableSort } from '../Sort'
import { EmptyTableFooter } from './components/EmptyTableFooter'
import { FooterInfo } from './components/FooterInfo'
import { HeaderGroups } from './components/Header'
import { LoadingCell } from './components/LoadingCell'
import { NotFoundCell } from './components/NotFoundCell'
import { Rows } from './components/Rows'
import { TableProps } from './types'
import { collectLeftPinnedColumns, getSubRowsHandler, updateData } from './utils'

export const Table = <T extends RowData>({
  columns,
  columnVisibility,
  data,
  footerInfo: foterInfoProps,
  loading,
  meta,
  onClickRow,
  onUpdateData,
  pagination: paginationProps,
  sort,
  ...muiTableContainerProps
}: TableProps<T>): JSX.Element => {
  const [expanded, setExpanded] = useState<ExpandedState>(meta?.expanded ?? {})
  const { setSorting, sorting } = useTableSort<T>(sort)
  const table = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: getSubRowsHandler<T>,
    initialState: {
      columnPinning: {
        left: collectLeftPinnedColumns<T>(columns),
      },
    },
    meta: {
      ...meta,
      updateData: updateData(onUpdateData),
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,

    state: {
      columnVisibility,
      expanded,
      sorting,
    },
  })

  const { getAllColumns, getHeaderGroups, getRowModel } = table
  const rows: Row<T>[] = getRowModel().rows
  const anyRows: boolean = rows.length > 0
  const columnsCount: number = getAllColumns().length

  return (
    <Box>
      <TableContainer
        {...muiTableContainerProps}
        component={Paper}
      >
        <TableRoot stickyHeader>
          <TableHead>
            <HeaderGroups
              headerGroups={getHeaderGroups()}
              sort={sort}
            />
          </TableHead>

          <TableBody>
            {loading ? (
              <LoadingCell columnsCount={columnsCount} />
            ) : anyRows ? (
              <Rows {...{ onClickRow, rows }} />
            ) : (
              <NotFoundCell columnsCount={columnsCount} />
            )}
          </TableBody>
        </TableRoot>
      </TableContainer>

      <EmptyTableFooter>
        {foterInfoProps && <FooterInfo {...foterInfoProps} />}
        {paginationProps && <Pagination {...paginationProps} />}
      </EmptyTableFooter>
    </Box>
  )
}

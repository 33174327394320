import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { UserSlice } from 'features/user/forms/UserPublisherForm/types'
import { GET_PUBLISHER, userFromDTO } from './api'
import { UseGetPublisherProps, UseGetPublisherType } from './types'

export const useGetPublisher = ({ id }: UseGetPublisherProps): UseGetPublisherType => {
  const [user, setUser] = useState<UserSlice>()

  useEffect(() => {
    if (id) {
      query({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_PUBLISHER, {
    onCompleted: ({ node: user }) => {
      if (user) {
        setUser(userFromDTO(user))
      }
    },
  })

  return { loading, user }
}

import { z } from 'zod'

export const domainPrebidModuleAttributes = z.record(z.string(), z.boolean())

export const schema = z.object({
  domainPrebidModuleAttributes,
})

export type DomainPrebidModuleAttributes = z.infer<typeof domainPrebidModuleAttributes>
export type Schema = z.infer<typeof schema>

export const schemaDefaults = (): Schema => {
  return {
    domainPrebidModuleAttributes: {},
  }
}

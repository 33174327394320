import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Ability } from 'providers/casl/types/ability'
import { variablesToOmit } from 'providers/graphql/utils'
import { EditSchema } from '../../../schemas/editSchema'
import { Variables } from './types'

export const toDTO = (from: EditSchema, ability: Ability) =>
  mapDTO<EditSchema, Variables>({ from }).transform((data: EditSchema) => {
    const { codeAfter, codeBefore, comment } = { ...data.customCodeAttributes }

    const variables: Variables = {
      ...data,
      customCodeAttributes: data.customCodeEnabled
        ? {
            codeAfter: codeAfter || null,
            codeBefore: codeBefore || null,
            comment: comment || null,
          }
        : null,
    }

    const omitVariables: (keyof Pick<
      EditSchema,
      'margin' | 'mobileScaling' | 'overriddenByYieldbird'
    >)[] = variablesToOmit({
      overriddenByYieldbird: ability.can('update', 'DomainField-overriddenByYieldbird'),
    })

    if (data.targetingActionType !== 'onPageLoadAndLazyLoad') {
      omitVariables.push('margin', 'mobileScaling')
    }

    return _.omit(variables, omitVariables)
  })

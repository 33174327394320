import { z } from 'zod'

export const schema = z.object({
  email: z.string().email(),
})

export const schemaDefaults: Schema = {
  email: '',
}

export type Schema = z.infer<typeof schema>

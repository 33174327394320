import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { DomainsForTable } from '../..'
import { domainsFromDTO, sortToDTO } from './mapper'
import { GET_DOMAINS_FOR_DOWNLOAD_TAGS } from './query'
import { DomainsForTableDTO, UseGetDomains, UseGetDomainsProps } from './types'

export const useGetDomains = ({
  filters,
  first,
  last,
  sort,
}: UseGetDomainsProps): UseGetDomains => {
  const { workspaceId } = useWorkspaceParam()
  const [domains, setDomains] = useState<DomainsForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_DOMAINS_FOR_DOWNLOAD_TAGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ domains: { nodes: domains, totalCount: count } }): void => {
      if (domains) {
        setDomains(
          domains.map((domain: DomainsForTableDTO): DomainsForTable => domainsFromDTO(domain)),
        )
        setCount(count)
      }
    },

    variables: {
      first,
      last,
      sort: sortToDTO(sort),
      terms: filters,
      workspaceId,
    },
  })

  return { count, domains, loading }
}

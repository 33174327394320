import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { fromDTO, sortToDTO } from './api/mapper'
import { GET_DOMAIN_PREBID_MODULES } from './api/query'
import { DomainPrebidModuleSliceDTO } from './api/types'
import { DomainPrebidModuleSlice, UseGetDomainModules, UseGetDomainModulesProps } from './types'

export const useGetDomainPrebidModules = ({
  domainId,
  moduleType,
  sort,
}: UseGetDomainModulesProps): UseGetDomainModules => {
  const [modules, setModules] = useState<DomainPrebidModuleSlice[]>([])

  const { loading } = useQuery(GET_DOMAIN_PREBID_MODULES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ domainPrebidModules: { nodes: modules } }): void => {
      if (modules) {
        setModules(
          modules.map(
            (module: DomainPrebidModuleSliceDTO): DomainPrebidModuleSlice => fromDTO(module),
          ),
        )
      }
    },

    variables: {
      domainId,
      moduleType,
      sort: sortToDTO(sort),
    },
  })

  return {
    loading,
    modules,
  }
}

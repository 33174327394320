import { Button } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAbility } from 'providers/casl'
import { AnalyticsContext } from '../../contexts/AnalyticsContext'
import { Schema } from '../../form/schema'

export const DialogAction = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('common')
  const {
    dataSampling: { tempValue },
    formId,
    loading,
  } = useContext(AnalyticsContext)
  const { setValue } = useFormContext<Schema>()

  return (
    <Button
      data-cy='data-sampling-confirm-button'
      disabled={ability.cannot('update', 'Performance-dataSampling') || loading}
      form={formId}
      onClick={() => void setValue('dataSampling', tempValue)}
      type='submit'
      variant='contained'
    >
      {t('actions.confirm')}
    </Button>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT } from 'features/inventory/api/fragments/pageForAdUnitTransferList'
import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { MEDIA_TYPES_FOR_FORM_FRAGMENT } from '../../useGetAdUnit/api/fragments/mediaTypes'

export const AD_UNIT_UPDATE_FRAGMENT: TypedDocumentNode<AdUnitDTO> = gql`
  ${MEDIA_TYPES_FOR_FORM_FRAGMENT}
  ${PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT}

  fragment AdUnitUpdateFragment on AdUnit {
    divId
    id
    mediaTypes {
      ...MediaTypesFragment
    }
    name
    outOfPage
    outOfPageType
    pages {
      ...PageForAdUnitTransferList
    }
    path
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl/types/ability'
import { AD_UNIT_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_AD_UNITS_FOR_TABLE = (ability: Ability): TypedDocumentNode<Data, Variables> => gql`
  ${AD_UNIT_FOR_TABLE_FRAGMENT(ability)}

  query getAdUnitsForTable(
    $first: Int
    $last: Int
    $workspaceId: ID!
    $terms: [String!]
    $sort: AdUnitSortInput
  ) {
    adUnits(first: $first, last: $last, workspaceId: $workspaceId, terms: $terms, sort: $sort) {
      nodes {
        ...AdUnitForTableFragment
      }

      totalCount
    }
  }
`

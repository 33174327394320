import { CellContext } from '@tanstack/react-table'
import _ from 'lodash'
import { ReactNode } from 'react'
import { FieldArrayWithId } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CommonSchema } from 'features/inventory/ad-unit/forms/GeneralForm/schemas/commonSchema'
import { MediaTypeSchema } from 'features/inventory/ad-unit/forms/GeneralForm/schemas/mediaTypesSchema'
import { ViewportSchema } from 'features/inventory/viewport/forms/ViewportForm/schema'

export const NameCell = ({
  row: {
    original,
    original: {
      viewport: { name },
    },
  },
}: CellContext<FieldArrayWithId<CommonSchema>, unknown>): ReactNode => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.viewportList.cells.name',
  })

  const comparableValues: (keyof ViewportSchema)[] = [
    'bannerEnabled',
    'bannerSizes',
    'outstreamEnabled',
    'playerSize',
    'nativeEnabled',
  ]

  const originalValues: Partial<ViewportSchema> = _.pick(original.viewport, comparableValues)
  const mediaTypeValues: Partial<MediaTypeSchema> = _.pick(original, comparableValues)

  return _.isEqual(originalValues, mediaTypeValues) ? name : t('suffix', { name })
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { AD_UNIT_UPDATE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const UPDATE_AD_UNIT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${AD_UNIT_UPDATE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateAdUnit($input: UpdateAdUnitInput!) {
    updateAdUnit(input: $input) {
      adUnit {
        ...AdUnitUpdateFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { Form as FormContainer } from 'components/Form'
import Loading from 'components/Loading'
import { SignInVars } from 'features/user/api/types/signIn'
import { useSignIn } from 'features/user/hooks/useSignIn'
import { Form } from './Form'
import { schema, schemaDefaults } from './schema'

export const UserLoginForm = (): JSX.Element => {
  const { errors: apiErrors, loading, signIn } = useSignIn()

  return (
    <FormContainer<SignInVars>
      externalErrors={apiErrors}
      onSubmit={signIn}
      schema={schema}
      schemaDefaults={schemaDefaults}
      withLeaveDialog={false}
    >
      {() => (loading ? <Loading /> : <Form />)}
    </FormContainer>
  )
}

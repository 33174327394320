import { UserWorkspaceProductsSliceDTO } from 'features/user/api/types/userDTO'
import { UserWorkspaceProductsSlice } from 'features/user/types/user'

export const mapDTOAndUpdateWorkspaceProducts = (
  apiWorkspaceProductDTO: UserWorkspaceProductsSliceDTO,
  userWorkspaceProducts: UserWorkspaceProductsSlice[],
): UserWorkspaceProductsSlice[] => {
  const updatedWP: UserWorkspaceProductsSlice = apiWorkspaceProductDTO

  return userWorkspaceProducts.map(
    (userWP: UserWorkspaceProductsSlice): UserWorkspaceProductsSlice =>
      userWP.product === updatedWP.product ? { ...userWP, status: updatedWP.status } : userWP,
  )
}

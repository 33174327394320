import { mapDTO } from '@twistezo/ts-dto-mapper'

import { adUnitViewabilityToolsFromDTO } from 'features/inventory/ad-unit/api/mappers/adUnitViewabilityTools'
import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { PageDTO } from 'features/inventory/api/types/pageDTO'
import { mediaTypesFromDTO } from 'features/inventory/mappers/adUnit/mediaTypes'
import { domainFromDTO } from 'features/inventory/mappers/domain'
import { pageForAdUnitTransferListFromDTO } from '../../../useGetPagesForAdUnitTransferList/api/mapper'
import { PageForAdUnitTransferList } from '../../../useGetPagesForAdUnitTransferList/types'
import { AdUnitForForm } from '../../types'

export const adUnitForFormFromDTO = (from: AdUnitDTO): AdUnitForForm =>
  mapDTO<AdUnitDTO, AdUnitForForm>({ from }).transform((adUnitDTO: AdUnitDTO): AdUnitForForm => {
    const {
      adUnitAmazonEnabled,
      adUnitId,
      adUnitViewabilityTool,
      divId,
      domain,
      mediaTypes,
      name,
      pages,
    } = adUnitDTO

    return {
      ...adUnitDTO,
      adUnitAmazonEnabled: adUnitAmazonEnabled ?? undefined,
      adUnitViewabilityTool: adUnitViewabilityToolsFromDTO(adUnitViewabilityTool),
      divId: divId || undefined,
      domain: domainFromDTO(domain),
      mediaTypes: mediaTypesFromDTO(mediaTypes),
      name: name || undefined,
      pages: pages.map(
        (page: PageDTO): PageForAdUnitTransferList => pageForAdUnitTransferListFromDTO(page),
      ),
      rawId: adUnitId || undefined,
    }
  })

import { useTranslation } from 'react-i18next'

import { UseTooltips } from './types'

export const useTooltips = (): UseTooltips => {
  const { t } = useTranslation(['features/domain', 'common'])

  return {
    adHolding: {
      content: t('form.viewabilityTools.basicSettings.adHolding.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: 'https://knowledge-base.yieldbird.com/viewability-tools/ad-holding',
      },
    },

    fetchMargin: {
      content: t(
        'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.fetchMargin.tooltip',
      ),
    },

    lazyLoading: {
      content: t('form.viewabilityTools.basicSettings.lazyLoadingEnabled.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: 'https://knowledge-base.yieldbird.com/viewability-tools/lazy-loading',
      },
    },

    mobileScaling: {
      content: t(
        'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.mobileScaling.tooltip',
      ),
    },

    renderMargin: {
      content: t(
        'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.renderMargin.tooltip',
      ),
    },

    scrollSlownig: {
      content: t('form.viewabilityTools.basicSettings.scrollSlowingEnabled.tooltip'),
      link: {
        title: t('common:learnMore'),
        url: 'https://knowledge-base.yieldbird.com/viewability-tools/scroll-slowing',
      },
    },
  } as const
}

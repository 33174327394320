import { Controller, FieldValues } from 'react-hook-form'

import { FilterableListBox } from 'components/FilterableListBox'
import { CheckboxList } from './components/CheckboxList'
import { CheckboxListFilterableProps } from './types'

export const CheckboxListFilterable = <T extends FieldValues>({
  control,
  name,
  options,
  placeholder,
}: CheckboxListFilterableProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <FilterableListBox
        error={error}
        placeholder={placeholder}
      >
        <CheckboxList<T>
          field={field}
          name={name}
          options={options}
        />
      </FilterableListBox>
    )}
  />
)

import { isNil } from 'lodash'
import { z } from 'zod'

import { AdUnitSizeDTO } from './types/adUnitSizeDTO'

export const adUnitSizeFromDTO = (size: AdUnitSizeDTO): string => {
  const { fluid, height, width } = size

  if (!isNil(fluid)) {
    return 'fluid'
  } else if (![width, height].every(isNil)) {
    return `${width}x${height}`
  } else {
    throw Error('Unsupported combination of parameters')
  }
}

export const adUnitSizeToDTO = (size: string): AdUnitSizeDTO => {
  if (size === 'fluid') {
    return {
      fluid: true,
    }
  } else {
    const [width, height] = size.split('x')

    return {
      height: parseInt(height),
      width: parseInt(width),
    }
  }
}

export const validateAdUnitSize = (size: string): boolean => /^\d+x\d+$/.test(size)

export const validateAdUnitSizes = (sizes: string[], ctx: z.RefinementCtx): void => {
  sizes.forEach(val => {
    if (val === 'fluid') {
      return
    }

    if (!validateAdUnitSize(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [val],
      })
    }
  })
}

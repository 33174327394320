import { SnackbarKey } from 'notistack'

import { CloseAction } from './CloseAction'

export const mergeCloseAction = (action: JSX.Element, snackbarId: SnackbarKey): JSX.Element => (
  <>
    {action}
    <CloseAction snackbarId={snackbarId} />
  </>
)

import { z } from 'zod'

import { WorkspacePrebidModuleSlice } from 'features/globalSetup/contexts/PrebidModulesContext'
import { yieldbirdStatuses } from 'features/globalSetup/types/yieldbirdStatus'
import { commonSchema } from './commonSchema'

export const editSchema = z.intersection(
  z.object({ enabled: z.boolean(), status: z.enum(yieldbirdStatuses) }),
  commonSchema,
)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (
  workspacePrebidModule: WorkspacePrebidModuleSlice,
): EditSchema => {
  const {
    enabled,
    prebidModule: { account, id },
    status,
  } = workspacePrebidModule

  return {
    accountType: account,
    enabled,
    prebidModuleId: id,
    status,
  }
}

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { mapGraphQLErrors } from 'providers/graphql/utils'
import { InventoryConnectionContext } from '../../../contexts/InventoryConnectionContext'
import { Schema } from '../../schema'
import { Data, toDTO, UPDATE_DOMAIN_PREBID_MODULES } from './api'
import { UseUpdatePrebidModuleDomains } from './types'

export const useUpdatePrebidModuleDomains = (): UseUpdatePrebidModuleDomains => {
  const { moduleType } = useContext(InventoryConnectionContext)
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit.inventoryConnection.form`,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(PrebidModulesContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(UPDATE_DOMAIN_PREBID_MODULES, {
    onCompleted: ({ updateDomainPrebidModules }: Data): void => {
      setLoading(false)

      if (updateDomainPrebidModules) {
        const { errors } = updateDomainPrebidModules

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else {
          setErrors({})
          enqueueSnackbar(t('success'), { variant: 'success' })
        }
      }
    },
    onError: (): void => setLoading(false),
    refetchQueries: ['getDomainPrebidModules'],
  })

  const update = (data: Schema): void => {
    setLoading(true)

    mutate({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return { errors, update }
}

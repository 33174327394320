import { Box, Stack, Typography } from '@mui/material'

import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { BoxHeaderProps } from './types'

export const BoxHeader = ({ description, title }: BoxHeaderProps): JSX.Element => (
  <Box sx={{ mb: CONTAINER_SPACING }}>
    <Typography
      sx={{ mb: 2 }}
      variant='h4'
    >
      {title}
    </Typography>

    <Stack direction='row'>{description}</Stack>
  </Box>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_AD_UNIT_PRODUCT_ENABLED: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateAdUnitProductEnabled($input: UpdateAdUnitProductEnabledInput!) {
    updateAdUnitProductEnabled(input: $input) {
      adUnit {
        id
        prebidStackEnabled
        refresherEnabled
        priceGeniusEnabled
        unfilledRecoveryEnabled
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

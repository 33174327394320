import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainPriceGeniusDTO } from '../types/domainPriceGeniusDTO'

export const DOMAIN_PRICE_GENIUS_FRAGMENT: TypedDocumentNode<DomainPriceGeniusDTO> = gql`
  fragment DomainPriceGeniusFragment on DomainPriceGenius {
    alwaysOn
    createdAt
    domainId
    id
    priceGeniusEnabled
    trafficPercent
    updatedAt
  }
`

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitViewabilityTools } from '../../types/AdUnitViewabilityTools'
import { AdUnitViewabilityToolsDTO } from '../types/adUnitViewabilityToolsDTO'

export const adUnitViewabilityToolsFromDTO = (
  from: AdUnitViewabilityToolsDTO,
): AdUnitViewabilityTools =>
  mapDTO<AdUnitViewabilityToolsDTO, AdUnitViewabilityTools>({ from }).transform(auvtDTO => {
    const { createdAt, updatedAt } = auvtDTO

    return {
      ...auvtDTO,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }
  })

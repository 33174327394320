import { SortInput } from 'components/Table'
import { DomainSupplyChainSortBy } from './hooks/useGetDomainSupplyChains'

export const DEFAULT_SORT: SortInput<DomainSupplyChainSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: DomainSupplyChainSortBy[] = [
  'customDomainSid',
  'domain.name',
  'updatedAt',
]

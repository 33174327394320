import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { DomainSlice } from '../types'
import { DomainSliceDTO } from './types'

export const domainFromDTO = (from: DomainSliceDTO) =>
  mapDTO<DomainSliceDTO, SelectOption<DomainSlice>>({ from }).transform(domainForSelect => ({
    label: domainForSelect.name,
    value: domainForSelect.id,
  }))

import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GET_FINANCE_REPORT } from './api'
import { fromDTO } from './api/mapper'
import { FinanceReport, UseGetFinanceReport, UseGetFinanceReportProps } from './types'

export const useGetFinanceReport = ({
  workspaceId,
}: UseGetFinanceReportProps): UseGetFinanceReport => {
  const [financeReport, setFinanceReport] = useState<FinanceReport>()

  useEffect(() => {
    if (workspaceId) {
      query({
        variables: {
          id: workspaceId,
        },
      })
    }
  }, [workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_FINANCE_REPORT, {
    onCompleted: ({ node: workspace }) => {
      if (workspace) {
        setFinanceReport(fromDTO(workspace))
      }
    },
  })

  return {
    financeReport,
    loading,
  }
}

import { useLazyQuery } from '@apollo/client'

import { downloadFile } from 'utils/file'
import { DOWNLOAD_TAGS } from './query'
import { UseDownloadTags, UseDownloadTagsProps } from './types'

export const useDownloadTags = ({ id, name, tagKind }: UseDownloadTagsProps): UseDownloadTags => {
  const [downloadTagsQuery] = useLazyQuery(DOWNLOAD_TAGS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ downloadTags: file }): void => {
      const blob = new Blob([file.data], { type: file.type })

      const filename: string = `${name} - ${tagKind}.txt`
      downloadFile(blob, filename)
    },
  })

  const download = (): void => {
    downloadTagsQuery({
      variables: {
        domainId: id,
        tagsType: tagKind,
      },
    })
  }

  return { download }
}

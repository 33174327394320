import { z } from 'zod'

import { adUnitsAttributesSchema } from 'features/inventory/domain/components/ProductAdUnitsTable'
import { Domain } from 'features/inventory/types/domain'
import { lazyLoadValues } from 'types/lazyLoad'

export const schema = z
  .object({
    adHoldingEnabled: z.boolean(),
    domainId: z.string(),
    fetchMargin: z.number().int().min(0),
    lazyLoadingEnabled: z.boolean(),
    lazyLoadType: z.enum(lazyLoadValues),
    mobileScaling: z.number().min(0).multipleOf(0.01),
    renderMargin: z.number().int().min(0),
    scrollSlowingEnabled: z.boolean(),
    slowingFactor: z.number().min(0).max(50),
    trafficAffected: z.number().min(0).max(100),
    viewabilityToolsEnabled: z.boolean(),
  })
  .merge(adUnitsAttributesSchema)

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ domainViewabilityTools }: Domain): Schema => ({
  ...domainViewabilityTools,
  adUnitsAttributes: {},
})

import { ExternalInfo } from '@adend/asteriobid-ui-lib/dist/utils/externalInfoStore'
import { FC, lazy, LazyExoticComponent } from 'react'

import { AsterioBidModuleName, AsterioBidModulesInterface } from './types'

export const importAsterioBidModule = (
  moduleName: AsterioBidModuleName,
): LazyExoticComponent<FC<ExternalInfo>> =>
  lazy(() =>
    import('@adend/asteriobid-ui-lib').then(
      (
        modulesInterface: AsterioBidModulesInterface,
      ): {
        default: FC<ExternalInfo>
      } => ({
        default: modulesInterface[moduleName as keyof AsterioBidModulesInterface],
      }),
    ),
  )

import _ from 'lodash'

import {
  MediaTypeDefaultAttribute,
  MediaTypes,
  MediaTypeViewportAttribute,
  ViewportSlice,
} from 'features/inventory/ad-unit/types/mediaTypes'
import { MediaTypeSchema, MediaTypesSchema, MediaTypeViewportsSchema } from './mediaTypesSchema'

type CommonFields = keyof Pick<
  ViewportSlice,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'outstreamEnabled' | 'playerSize'
>

const COMMON_FIELDS: CommonFields[] = [
  'bannerEnabled',
  'bannerSizes',
  'nativeEnabled',
  'outstreamEnabled',
  'playerSize',
]

export const mediaTypeDefaultAttributeToSchema = (
  defaultAttribute: MediaTypeDefaultAttribute,
): MediaTypeSchema => _.pick(defaultAttribute, [...COMMON_FIELDS, 'mediaTypeId', 'updatedAt'])

export const mediaTypeViewportAttributesToSchema = (
  viewportAttributes: MediaTypeViewportAttribute[],
): MediaTypesSchema['viewportAttributes'] =>
  viewportAttributes.map(
    (attribute: MediaTypeViewportAttribute): MediaTypeViewportsSchema => ({
      ...mediaTypeDefaultAttributeToSchema(attribute),
      viewport: {
        ..._.pick(attribute.viewport, [...COMMON_FIELDS, 'minimumWidth', 'name']),
        viewportId: attribute.viewport.id,
      },
      viewportId: attribute.viewport.id,
    }),
  )

export const mediaTypesToSchema = ({
  defaultAttribute,
  kind,
  nativeConfiguration,
  outstreamConfiguration,
  viewportAttributes,
}: MediaTypes): MediaTypesSchema => ({
  defaultAttribute: mediaTypeDefaultAttributeToSchema(defaultAttribute),
  kind,
  nativeConfiguration,
  outstreamConfiguration,
  viewportAttributes: mediaTypeViewportAttributesToSchema(viewportAttributes),
})

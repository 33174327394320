import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { AdUnitForProductTable } from '../types'
import { adUnitForProductTableFromDTO, sortToDTO } from './api/mapper'
import { GET_AD_UNITS } from './api/query'
import { AdUnitForProductTableDTO } from './api/types'
import { Props, UseGetAdUnits } from './types'

export const useGetAdUnits = ({ domainId, filters, first, last, sort }: Props): UseGetAdUnits => {
  const { workspaceId } = useWorkspaceParam()

  const [adUnits, setAdUnits] = useState<AdUnitForProductTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_AD_UNITS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ adUnits: { nodes: adUnits, totalCount: count } }): void => {
      if (adUnits) {
        setAdUnits(
          adUnits.map(
            (node: AdUnitForProductTableDTO): AdUnitForProductTable =>
              adUnitForProductTableFromDTO(node),
          ),
        )
        setCount(count)
      }
    },

    variables: {
      domainId,
      first,
      last,
      sort: sortToDTO(sort),
      terms: filters,
      workspaceId,
    },
  })

  return { adUnits, count, loading }
}

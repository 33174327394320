import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { DomainAlerts } from 'features/inventory/domain'
import ActionsCell from './components/ActionsCell'
import { useProductSwitchColumns } from './hooks/useProductSwitchColumns'
import { UseColumns } from './types'

export const useColumns: UseColumns = (tableFilters, tableItemsCount) => {
  const { t } = useTranslation('features/adUnit')
  const productColumns = useProductSwitchColumns({ tableFilters, tableItemsCount })

  return [
    {
      accessorKey: 'domain.name',
      header: t('list.header.domainName'),
      size: COLUMN_SIZE.LG,
    },
    {
      accessorKey: 'name',
      header: t('list.header.adUnitName'),
    },
    {
      accessorKey: 'path',
      header: t('list.header.adUnitPath'),
    },
    {
      accessorKey: 'divId',
      header: t('list.header.divId'),
    },
    ...productColumns,
    {
      accessorKey: 'alerts',
      cell: ({
        row: {
          original: {
            domain: { alerts },
          },
        },
      }) => <DomainAlerts alerts={alerts} />,
      header: t('list.header.alerts'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.SM,
    },
  ]
}

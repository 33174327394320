import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { UserWorkspacesContext } from '../../contexts/UserWorkspacesContext'
import { CommonSchema } from '../../schemas'
import { Data, UPDATE_CUSTOMER_ROLE } from './api'
import { userWorkspaceFromDTO } from './mapper'
import { UseUpdatePublisherWorkspaceRole } from './types'

export const useUpdatePublisherWorkspaceRole = (): UseUpdatePublisherWorkspaceRole => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<FieldErrors<CommonSchema>>({})
  const { update } = useContext(UserWorkspacesContext)

  const [mutateUpdatePublisherWorkspaceRole] = useMutation(UPDATE_CUSTOMER_ROLE, {
    onCompleted: ({ updatePublisherWorkspaceRole: { errors, userWorkspace } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.publisher.edit.error'), { variant: 'error' })
      } else if (userWorkspace) {
        setErrors({})
        enqueueSnackbar(t('form.publisher.edit.success'), {
          variant: 'success',
        })
        update(userWorkspaceFromDTO(userWorkspace))
      }
    },
    onError: (): void => setLoading(false),
  })

  const updatePublisherWorkspaceRole = (data: CommonSchema) => {
    setLoading(true)

    return mutateUpdatePublisherWorkspaceRole({ variables: { input: data } })
  }

  return { errors, loading, updatePublisherWorkspaceRole }
}

import { Button } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, NavLink } from 'react-router-dom'

import { FlexBox } from 'components/Box'
import { BoxForm, Checkbox, TextField } from 'components/Form'
import { PasswordIcon } from 'features/user/components/PasswordIcon'
import { ROUTES } from 'routes'
import { Schema } from './schema'

export const Form = () => {
  const { t } = useTranslation('features/user')
  const {
    control,
    formState: { isValid },
  } = useFormContext<Schema>()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <>
      <BoxForm>
        <TextField
          control={control}
          label={t('login.form.email.label')}
          name='email'
          placeholder={t('login.form.email.placeholder')}
        />

        <TextField
          control={control}
          InputProps={{ endAdornment: <PasswordIcon {...{ setShowPassword, showPassword }} /> }}
          label={t('login.form.password.label')}
          name='password'
          type={showPassword ? 'text' : 'password'}
        />

        <FlexBox
          axis='x'
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            control={control}
            label={t('login.form.rememberMe')}
            name={'rememberMe'}
          />

          <NavLink to={generatePath(ROUTES.AUTH.REQUEST_RESET_PASSWORD)}>
            {t('login.form.forgotPassword')}
          </NavLink>
        </FlexBox>
      </BoxForm>

      <Button
        data-cy='button-submit-login-form'
        disabled={!isValid}
        sx={{ mt: 2 }}
        type='submit'
        variant='contained'
      >
        {t('login.form.button')}
      </Button>
    </>
  )
}

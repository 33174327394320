import { gql, TypedDocumentNode } from '@apollo/client'

import { MediaTypeDefaultAttributeDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'

export const MEDIA_TYPE_FRAGMENT = `
    bannerEnabled
    bannerSizes {
      height
      width
      fluid
    }
    nativeEnabled
    outstreamEnabled
    playerSize {
      height
      width
      fluid
    }
`
export const MEDIA_TYPE_FOR_FORM_FRAGMENT: TypedDocumentNode<MediaTypeDefaultAttributeDTO> = gql`
  fragment MediaTypeForFormFragment on MediaType {
    id
    updatedAt
    ${MEDIA_TYPE_FRAGMENT}
  }
`

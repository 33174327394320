import { useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'

export const usePipedriveLink = (): Workspace['pipedriveLink'] => {
  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw Error('User workspace should exists at this page')
  }

  const { pipedriveLink } = currentWorkspace

  return pipedriveLink
}

import Cookies from 'js-cookie'

import { AuthenticationToken } from 'features/user/api/types/authenticationToken'
import { AUTH_COOKIE_OPTIONS, TOKEN_COOKIES } from './constants'

export const setTokenCookies = ({ accessToken, refreshToken }: AuthenticationToken): void => {
  Cookies.set(TOKEN_COOKIES.AT, accessToken, AUTH_COOKIE_OPTIONS)
  Cookies.set(TOKEN_COOKIES.RT, refreshToken, AUTH_COOKIE_OPTIONS)
}

export const getTokenCookies = (): Partial<AuthenticationToken> => ({
  accessToken: Cookies.get(TOKEN_COOKIES.AT),
  refreshToken: Cookies.get(TOKEN_COOKIES.RT),
})

export const removeTokenCookies = (): void => {
  Cookies.remove(TOKEN_COOKIES.AT)
  Cookies.remove(TOKEN_COOKIES.RT)
}

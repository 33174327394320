import { CircularProgress, CSSObject } from '@mui/material'

import { FlexBox } from 'components/Box'
import { LoadingProps } from './types'

export const Loading = ({ variant = 'full-container' }: LoadingProps): JSX.Element => {
  const variantHeight = (): CSSObject => {
    switch (variant) {
      case 'full-container': {
        return { height: '100%' }
      }
      case 'full-screen': {
        return { height: '100vh' }
      }
    }
  }

  return (
    <FlexBox
      axis='xy'
      data-cy='loading'
      sx={{ ...variantHeight() }}
    >
      <CircularProgress />
    </FlexBox>
  )
}

import { constantCase } from 'change-case'
import { generatePath, To } from 'react-router-dom'
import { ScreamingSnakeCase } from 'type-fest'

import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModule } from 'features/globalSetup/types/workspacePrebidModule'
import { Workspace } from 'features/workspace'
import { ROUTES } from 'routes/routePaths'

type Props = {
  id: WorkspacePrebidModule['id']
  moduleType: PrebidModuleType
  workspaceId: Workspace['id']
}

export const prebidModulePathGenerator = ({ id, moduleType, workspaceId }: Props): To => {
  return generatePath(
    ROUTES.GLOBAL_SETUP.PREBID.MODULES[
      constantCase(moduleType) as ScreamingSnakeCase<PrebidModuleType>
    ].EDIT,
    {
      id,
      tab: 'general',
      workspaceId,
    },
  )
}

import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { BoxForm, NumberField } from 'components/Form'
import TextField, { TextFieldProps } from 'components/Form/TextField'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModuleParameter } from 'features/globalSetup/types/workspacePrebidModule'
import { ParamsSchema } from '../../schemas/paramsSchema'
import { ParamsFormBoxProps } from './types'
import { prepareValue } from './utils'

export const ParamsFormBox = ({ parametersSchema }: ParamsFormBoxProps): JSX.Element => {
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const { control, register } = useFormContext<ParamsSchema>()

  const parameters: WorkspacePrebidModuleParameter[] = workspacePrebidModule?.parameters || []

  return (
    <BoxForm>
      {parametersSchema.map((prebidModuleParameter: PrebidModuleParameter): JSX.Element => {
        const { label, name, tooltip, type } = prebidModuleParameter

        register(name, { value: prepareValue(name, parameters) })

        const props: TextFieldProps<ParamsSchema> = {
          control,
          key: name,
          label,
          name: name,
          tooltip,
        }

        return type === 'string' ? (
          <TextField {...props} />
        ) : (
          <NumberField
            {...props}
            type={type}
          />
        )
      })}
    </BoxForm>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const UPDATE_WORKSPACE_STATUS: TypedDocumentNode<Data, { input: Variables }> = gql`
  mutation updateWorkspaceStatus($input: UpdateWorkspaceStatusInput!) {
    updateWorkspaceStatus(input: $input) {
      workspace {
        id
        active
      }
    }
  }
`

import { TextFieldProps } from 'components/Form/TextField'
import { Primitive } from 'types/primitive'

export type PrebidModule = {
  account: PrebidModuleAccount
  id: string
  name: string
  parametersSchema: PrebidModuleParameter[]
}

export type PrebidModuleParameter = {
  type: Primitive
} & FieldProps

type FieldProps = Pick<
  TextFieldProps<Record<string, unknown>>,
  'label' | 'name' | 'required' | 'tooltip'
>

export type PrebidModuleAccount = (typeof prebidModuleAccounts)[number]
export type PrebidModuleType = (typeof prebidModuleTypes)[number]

export const prebidModuleAccounts = ['own', 'yieldbird'] as const
export const prebidModuleTypes = ['userId', 'vendorSpecific', 'analytics'] as const

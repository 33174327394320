import { generatePath, Navigate, useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { DomainAlerts } from 'features/inventory/domain'
import {
  AddEditDomainContext,
  AddEditDomainContextType,
} from 'features/inventory/domain/contexts/AddEditDomainContext'
import { ROUTES } from 'routes'
import { ProductTab } from 'types/productTab'
import { useGetDomain } from '../../forms/GeneralForm/hooks/useGetDomain'
import { EditDomainUrlParams } from './types'
import { useProductTabs } from './useProductTabs'

export const EditDomain = (): JSX.Element => {
  const { id: domainId, tab: currentTab, workspaceId } = useParams<EditDomainUrlParams>()
  const tabs: Tab<ProductTab>[] = useProductTabs()
  const { domain } = useGetDomain({ id: domainId })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: `domain-form-edit`,
  })

  if (!workspaceId || !domainId) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!domain) {
    return <Loading />
  }

  const contextProps: AddEditDomainContextType = {
    domain,
    ...commonContextProps,
  }

  return (
    <AddEditDomainContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditDomainContext.Consumer>
          {(ctxProps: AddEditDomainContextType): JSX.Element => (
            <PageHeader
              actions={<SaveButton {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.DOMAIN.LIST, { workspaceId })}
              title={domain.name}
              titleAdornment={{ end: <DomainAlerts alerts={domain.alerts} /> }}
            />
          )}
        </AddEditDomainContext.Consumer>

        <Tabs
          path={ROUTES.INVENTORY.DOMAIN.EDIT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<ProductTab>(tabs, currentTab, 'general')}
    </AddEditDomainContext.Provider>
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { Schema, SchemaCustomDomainSid } from '../../../form/schema'
import { DomainSupplyChainsAttribute, Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((schema: From): To => {
    const { domainSupplyChainsSids } = schema

    type IterProps = [key: keyof Schema['domainSupplyChainsSids'], value: SchemaCustomDomainSid]

    const domainSupplyChainsAttributes = Object.entries(domainSupplyChainsSids)
      .map(
        ([key, value]: IterProps): Partial<DomainSupplyChainsAttribute> => ({
          customDomainSid: value?.trim(),
          id: key,
        }),
      )
      .filter(
        ({ customDomainSid }: Partial<DomainSupplyChainsAttribute>): boolean =>
          !_.isEmpty(customDomainSid),
      ) as DomainSupplyChainsAttribute[]

    return { domainSupplyChainsAttributes }
  })

import { useTranslation } from 'react-i18next'

import { ProductsActionsCell } from 'components/Table'
import { Product } from 'features/product'
import { ROUTES } from 'routes'
import { useDeletePage } from '../../hooks/useDeletePage'
import { ActionsCellProps } from './types'

const products: Product[] = ['prebidStack']

export const ActionsCell = ({ id, name }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation('features/page')
  const { deletePage, loading } = useDeletePage({ name })

  return (
    <ProductsActionsCell
      editPath={ROUTES.INVENTORY.PAGE.EDIT}
      elementName={name}
      feature='page'
      i18nResource={t}
      id={id}
      loading={loading}
      onDelete={() => void deletePage(id)}
      products={products}
    />
  )
}

import { useTheme } from '@mui/material'
import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useAuthUser } from 'features/user'
import useUpdateCurrentWorkspace from 'features/workspace/hooks/useUpdateCurrentWorkspace'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { useGetWorkspaces } from './api/useGetWorkspaces'
import { useColumns } from './Columns/useColumns'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { WorkspaceForTable, WorkspaceListProps, WorkspaceSortBy } from './types'

export const WorkspaceList = ({ filters }: WorkspaceListProps): JSX.Element => {
  const { user } = useAuthUser()
  const { update } = useUpdateCurrentWorkspace({ userId: user.id })
  const columns = useColumns()
  const ability = useAbility()
  const theme = useTheme()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<WorkspaceForTable, WorkspaceSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { count, loading, workspaces } = useGetWorkspaces({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const handleRowClick = async (row: WorkspaceForTable): Promise<To | void> => {
    const workspaceId = row.id

    const status = await update(workspaceId)
    if (status === 'failure') return

    return generatePath(ROUTES.INVENTORY.DOMAIN.LIST, { workspaceId })
  }

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<WorkspaceForTable>
      columns={columns}
      data={workspaces}
      loading={loading}
      meta={{
        headerColor: ability.can('have', 'EmployeeElement') ? theme.palette.cream : undefined,
      }}
      onClickRow={handleRowClick}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

import { FetchResult, useMutation } from '@apollo/client'

import { UPDATE_AD_UNIT_PRODUCT_ENABLED } from './api/mutation'
import { Data, Variables } from './api/types'
import { SwitchVariables, UseSwitchAdUnitProduct } from './types'

export const useSwitchAdUnitProduct = (): UseSwitchAdUnitProduct => {
  const [mutate] = useMutation(UPDATE_AD_UNIT_PRODUCT_ENABLED)

  const mapper = ({ id, product, value }: SwitchVariables): Variables => ({
    enabledValue: value,
    id,
    productType: product,
  })

  const update = (variables: SwitchVariables): Promise<FetchResult<Data>> =>
    mutate({
      variables: {
        input: mapper(variables),
      },
    })

  return { update }
}

import { z } from 'zod'

import { FinanceReport, UserSlice } from 'features/finance/pages/FinancePage/useGetFinanceReport'

export const schema = z.object({
  emails: z.array(z.string()),
  financeReportUrl: z.string().optional(),
  id: z.string(),
})

export type Schema = z.infer<typeof schema>

export const defaults = (data: FinanceReport): Schema => {
  const { financeReportUrl, users } = data

  return {
    ...data,
    emails: users.map((user: UserSlice): string => user.email),
    financeReportUrl: financeReportUrl ?? '',
  }
}

import { Workspace } from 'features/workspace'
import { Bidder } from './bidder'
import { WorkspaceBidderPriority } from './bidderPriority'
import { SupplyChain } from './supplyChain'
import { YieldbirdStatus } from './yieldbirdStatus'

export type Account = (typeof accounts)[number]
export type ConsentMode = (typeof consentModes)[number]

export const accounts = ['own', 'yieldbird', 'thirdPartyProvider'] as const
export const consentModes = ['consentOnly', 'nonConsentOnly', 'all'] as const

export type WorkspaceBidder = {
  accountType: Account
  bidCpmAdjustment: number
  bidder: Bidder
  bidderCode: string
  bidderSid?: string
  consentMode: ConsentMode
  createdAt: Date
  enabled: boolean
  id: string
  priority: WorkspaceBidderPriority
  status: YieldbirdStatus
  supplyChain: SupplyChain
  updatedAt: Date
  workspace: Workspace
}

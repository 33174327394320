import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { Form as FormBody } from './Form'
import { useUpdateDomainPriceGenius } from './hooks/useUpdateDomainPriceGenius'
import { Schema, schema, schemaDefault } from './schema'

export const PriceGeniusForm = (): JSX.Element => {
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { errors: apiErrors, updateDomainPriceGenius } = useUpdateDomainPriceGenius()

  if (!domain) {
    return <Loading />
  }

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={updateDomainPriceGenius}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(domain)}
    >
      {() => (loading ? <Loading /> : <FormBody />)}
    </Form>
  )
}

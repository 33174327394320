import { ArrowBackIosNew } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'

import { FlexBox } from 'components/Box'
import { Link, TextLink } from 'components/Link'
import Space from 'components/Space'
import { PageHeaderProps } from './types'

export const PageHeader = ({
  actions,
  backTo,
  description,
  title,
  titleAdornment,
}: PageHeaderProps): JSX.Element => (
  <>
    <Stack
      alignItems='center'
      direction='row'
      gap={2}
      sx={{
        pt: 2,
        px: 2,
      }}
    >
      {backTo && (
        <Link to={backTo}>
          <Button>
            <ArrowBackIosNew />
          </Button>
        </Link>
      )}

      {titleAdornment?.start && <FlexBox axis='x'>{titleAdornment.start}</FlexBox>}
      <Typography
        data-cy='page-header-title'
        variant='h4'
      >
        {title}
      </Typography>
      {titleAdornment?.end && <FlexBox axis='x'>{titleAdornment.end}</FlexBox>}

      <Box sx={{ flexGrow: 1 }} />

      {actions}
    </Stack>

    {description && (
      <Box
        sx={{
          pt: 1,
          px: 2,
        }}
      >
        <Stack direction='row'>
          <Typography
            sx={{ display: 'flex' }}
            variant='subtitle1'
          >
            {description.text}
            {description.link && (
              <>
                <Space />
                <TextLink link={description.link} />
              </>
            )}
          </Typography>
        </Stack>
      </Box>
    )}
  </>
)

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { PageForAdUnitTransferListDTO } from 'features/inventory/api/types/pageForAdUnitTransferListDTO'
import { PageForAdUnitTransferList } from '../types'

export const pageForAdUnitTransferListFromDTO = (
  from: PageForAdUnitTransferListDTO,
): PageForAdUnitTransferList =>
  mapDTO<PageForAdUnitTransferListDTO, PageForAdUnitTransferList>({ from }).transform(
    ({ id, name, path }) => ({
      label: name ?? path,
      value: id,
    }),
  )

import { gql, TypedDocumentNode } from '@apollo/client'

import { PrebidModuleDTO } from 'features/globalSetup/api/types/prebidModule'

export const PREBID_MODULE_FOR_SELECT_FRAGMENT: TypedDocumentNode<PrebidModuleDTO> = gql`
  fragment PrebidModuleForSelectFragment on PrebidModule {
    id
    name
    demand
  }
`

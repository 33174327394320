import i18next from 'i18next'
import { z } from 'zod'

export const parseValidJSON = (v: string): unknown => {
  try {
    return JSON.parse(v)
  } catch {
    return v
  }
}

export const validateJSONSchema = (value: string, ctx: z.RefinementCtx): void => {
  try {
    JSON.parse(value)
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18next.t('common:form.error.json'),
    })
  }
}

import { t } from 'i18next'
import _ from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { FieldErrors, FieldValues } from 'react-hook-form'

import { browserRelativeRedirect } from 'providers/router/utils'
import { ROUTES } from 'routes'
import { PaginationVariables } from '.'
import { MutationInputError } from './types/mutationError'

/**
 * Map GraphQL mutation errors to `react-hook-form` errors
 */
export const mapGraphQLErrors = <T extends FieldValues>(
  graphQLErrors: MutationInputError[],
): FieldErrors<T> =>
  Object.assign(
    {},
    ...graphQLErrors.map((error: MutationInputError) => ({
      [error.attribute]: {
        message: error.message,
      },
    })),
  )

export const redirectToPageNotFound = (): void =>
  void browserRelativeRedirect(ROUTES.BASE.NOT_FOUND)

export const enqueueSnackbarForGeneralError = (): void => {
  const message = t('error.graphQLError', { ns: 'providers/graphql' })
  const code = '(GQL_ERR)'

  enqueueSnackbar(`${message} ${code}`, { variant: 'error' })
}

export const enqueueSnackbarForCodeError = (code: string): void => {
  const text = _.startCase(code.toLowerCase())
  enqueueSnackbar(text, { variant: 'error' })
}

export const calculatePagination = ({
  page,
  rowsPerPage,
}: {
  page: number
  rowsPerPage: number
}): PaginationVariables => ({
  first: page * rowsPerPage,
  last: rowsPerPage,
})

export const dateOrUndefined = (value: null | string): Date | undefined =>
  value ? new Date(value) : undefined

export const permitFields = <T>(fieldAccessObject: Partial<Record<keyof T, boolean>>): string[] =>
  Object.entries(fieldAccessObject)
    .filter(([, value]) => value)
    .map(([key]) => key)

export const variablesToOmit = <T>(abilityObject: Record<keyof T, boolean>) =>
  (Object.keys(abilityObject) as (keyof T)[]).filter((key: keyof T) => !abilityObject[key])

import _ from 'lodash'

import { TooltipProps } from '.'
import { Line } from './Line'

export const Content = ({ content }: { content: TooltipProps['content'] }): JSX.Element => (
  <>
    {_.isArray(content) ? (
      content.map(
        (text: string): JSX.Element => (
          <Line
            key={text}
            text={text}
          />
        ),
      )
    ) : (
      <Line text={content} />
    )}
  </>
)

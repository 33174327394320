import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_SUPPPLY_CHAINS: TypedDocumentNode<Data, Variables> = gql`
  query supplyChains($workspaceId: ID!) {
    supplyChains(workspaceId: $workspaceId) {
      nodes {
        id
        name
      }
    }
  }
`

import { Schema } from '../../../schema'
import { AdUnitBidderSlice } from '../../useGetAdUnitBidders'
import { AdUnitBidderAttributeDTO } from './types'

export const adUnitBiddersAttributesSchemaToDTO = (
  adUnitBiddersAttributes: Schema['adUnitBiddersAttributes'],
): AdUnitBidderAttributeDTO[] =>
  Object.entries(adUnitBiddersAttributes).map(
    ([key, value]: [
      key: AdUnitBidderSlice['id'],
      value: Pick<AdUnitBidderSlice, 'csEnabled' | 's2sEnabled'>,
    ]): AdUnitBidderAttributeDTO => ({
      id: key,
      ...value,
    }),
  )

import { authorizeGroupRole, User } from 'features/user'
import { Can } from '../../Can'
import { productForms } from './productForms'
import { productToggles } from './productToggles'
import { productVisibility } from './productVisibility'
import { UserData } from './types'

export const productByStatus = (user: User, can: Can): void => {
  const { currentWorkspaceRole, role, workspaceProducts } = user

  const userData: UserData = {
    isInEmployeeGroup: authorizeGroupRole('employeesOnly', role),
    userProducts: workspaceProducts || [],
    userWorkspaceRole: currentWorkspaceRole,
  }

  productToggles(userData, can)
  productForms(userData, can)
  productVisibility(userData, can)
}

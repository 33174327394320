import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { PublisherForTable } from '../../types'
import { publisherForTableFromDTO } from './api/mapper'
import { GET_PUBLISHERS_FOR_TABLE } from './api/query'
import { PublisherForTableDTO } from './api/types'
import { UseGetPublishers, UseGetPublishersProps } from './types'

export const useGetPublishers = ({
  filters,
  first,
  last,
}: UseGetPublishersProps): UseGetPublishers => {
  const [users, setUsers] = useState<PublisherForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_PUBLISHERS_FOR_TABLE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ users: { nodes: users, totalCount: count } }): void => {
      if (users) {
        setUsers(
          users.map(
            (user: PublisherForTableDTO): PublisherForTable => publisherForTableFromDTO(user),
          ),
        )
        setCount(count)
      }
    },

    variables: {
      first,
      last,
      terms: filters,
      userGroup: 'publishers',
    },
  })

  return { count, loading, users }
}

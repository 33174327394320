import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_EMPLOYEE_FOR_TABLE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation UpdateEmployeeForTable($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

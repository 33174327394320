import { mapDTO } from '@twistezo/ts-dto-mapper'

import { FinanceReport } from '../types'
import { FinanceReportDTO } from './types'

export const fromDTO = (from: FinanceReportDTO): FinanceReport =>
  mapDTO<FinanceReportDTO, FinanceReport>({ from }).transform(financeReport => {
    const { financeReportUrl } = financeReport

    return {
      ...financeReport,
      financeReportUrl: financeReportUrl ?? undefined,
    }
  })

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { bidderLogos } from 'constants/bidderLogos'
import { Account } from 'features/globalSetup/types/workspaceBidder'
import { BidderSlice } from '../../../../types'
import { BidderSliceDTO } from './types'

type From = BidderSliceDTO
type To = SelectOption<BidderSlice>

export const fromDTO = (from: From, accountType: Account): To =>
  mapDTO<From, To>({ from }).transform(({ code, id, name, ybSid }: From): To => {
    let bidderLogoName: string = name

    if (accountType === 'yieldbird') {
      // they have extra `Yb` characters at the end
      bidderLogoName = bidderLogoName.slice(0, -2)
    }

    return {
      label: name,
      logo: bidderLogos[bidderLogoName],
      rawData: {
        code,
        id,
        name,
        ybSid: ybSid || undefined,
      },
      value: id,
    }
  })

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_DOMAIN_PREBID_MODULES: TypedDocumentNode<Data, Variables> = gql`
  query domainPrebidModules(
    $domainId: ID!
    $moduleType: PrebidModuleTypeEnum
    $sort: DomainPrebidModuleSortInput
  ) {
    domainPrebidModules(domainId: $domainId, moduleType: $moduleType, sort: $sort) {
      nodes {
        id
        createdAt
        enabled
        prebidModule {
          id
          demand
          name
        }
        updatedAt
        workspacePrebidModule {
          id
        }
      }
    }
  }
`

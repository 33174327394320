import { EditSchema } from '../../schemas/edit'
import { Variables } from './api/types'

export const mapper = (data: EditSchema): Variables => {
  const { csManagerId } = data

  return {
    ...data,
    csManagerId: csManagerId || null,
  }
}

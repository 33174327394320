import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { PageHeader } from 'components/Header'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { ROUTES } from 'routes'
import { useTabs } from './hooks/useTabs'
import { PrebidModulesParams } from './types'

export const PrebidModulesPage = (): JSX.Element => {
  const { tab: urlTab } = useParams<PrebidModulesParams>()
  const tabs: Tab<PrebidModuleType>[] = useTabs()
  const { t } = useTranslation('features/globalSetup')

  return (
    <>
      <StickyBox>
        <PageHeader title={t('prebid.modules.header')} />

        <Tabs
          path={ROUTES.GLOBAL_SETUP.PREBID.MODULES.ROOT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<PrebidModuleType>(tabs, urlTab, 'userId')}
    </>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainBidderSchemaDTO } from 'features/globalSetup/api/types/domainBidderDTO'

export const DOMAIN_BIDDER_SCHEMA_FRAGMENT: TypedDocumentNode<DomainBidderSchemaDTO> = gql`
  fragment DomainBidderSchemaFragment on DomainBidderSchema {
    label
    name
    required
    tooltip
    type
  }
`

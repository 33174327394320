import { useParams } from 'react-router-dom'

import { Workspace } from 'features/workspace'
import { Params } from 'routes'

export const useWorkspaceParam = (): { workspaceId: Workspace['id'] } => {
  const { workspaceId } = useParams<Params>()

  if (!workspaceId) throw Error('Cannot find Workspace')

  return { workspaceId }
}

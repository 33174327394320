import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NumberField, RadioGroupOption, StackForm } from 'components/Form'
import {
  TargetingActionType,
  targetingActionTypes,
} from 'features/inventory/domain/types/targetingActionType'
import { useAbility } from 'providers/casl'
import { EditSchema } from '../../../../schemas/editSchema'

export const useTargetingActionTypeOptions = (): RadioGroupOption[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { control } = useFormContext<EditSchema>()

  const disabled = !ability.can('update', 'DomianFeature-advancedSettings')

  const TargetingActionTypeSubforms = (
    <StackForm>
      <NumberField
        control={control}
        disabled={disabled}
        label={t('form.general.advancedSettings.margin.label')}
        name='margin'
        tooltip={{ content: t('form.general.advancedSettings.margin.tooltip') }}
      />

      <NumberField
        control={control}
        disabled={disabled}
        label={t('form.general.advancedSettings.mobileScaling.label')}
        name='mobileScaling'
        tooltip={{ content: t('form.general.advancedSettings.mobileScaling.tooltip') }}
        type='float'
      />
    </StackForm>
  )

  return targetingActionTypes.map(
    (targetingActionType: TargetingActionType): RadioGroupOption => ({
      label: t(
        `form.general.advancedSettings.targetingActionType.values.${targetingActionType}.title`,
      ),
      onSelectedContent:
        targetingActionType === 'onPageLoadAndLazyLoad' ? TargetingActionTypeSubforms : undefined,
      tooltip: {
        content: t(
          `form.general.advancedSettings.targetingActionType.values.${targetingActionType}.tooltip`,
        ),
      },
      value: targetingActionType,
    }),
  )
}

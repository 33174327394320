export const userRoles = {
  admin: 'admin',
  adops: 'adops',
  backOffice: 'backOffice',
  customerPerformance: 'customerPerformance',
  customerSuccess: 'customerSuccess',
  publisher: 'publisher',
} as const

export type UserRole = (typeof userRoles)[keyof typeof userRoles]

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { User } from 'features/user'
import { RESEND_INVITATION } from './api/mutation'
import { UseResendInvitation } from './types'

export const useResendInvitation = (): UseResendInvitation => {
  const { t } = useTranslation('features/user')
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { loading }] = useMutation(RESEND_INVITATION, {
    onCompleted: ({ errors }): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('resendInvitation.action.error'), { variant: 'error' })
      } else {
        enqueueSnackbar(t('resendInvitation.action.success'), { variant: 'success' })
      }
    },
  })

  const resend = (id: User['id']) => mutate({ variables: { input: { userId: id } } })

  return { loading, resend }
}

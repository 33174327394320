import _ from 'lodash'

import { WorkspaceProductSlice } from 'features/performance/forms'
import { Product } from 'features/product'
import { ProductSliceDTO, UserSliceDTO, WorkspaceSliceDTO } from './types'

export const workspaceProductSliceFromDTO = (
  workspace: WorkspaceSliceDTO,
  product: Product,
): WorkspaceProductSlice => {
  const emails: UserSliceDTO['email'][] = workspace.users.map(
    (user: UserSliceDTO): UserSliceDTO['email'] => user.email,
  )
  const workspaceProduct: ProductSliceDTO | undefined = workspace.products.find(
    (workspaceProduct: ProductSliceDTO) => workspaceProduct.product === product,
  )

  if (!workspaceProduct) throw new Error(`Product ${_.startCase(product)} not found in workspace`)

  return {
    emails,
    id: workspaceProduct.id,
    performanceReportUrl: workspaceProduct.performanceReportUrl || undefined,
  }
}

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { control } = useFormContext<Schema>()

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.refresher.enabled.description')}
        title={t('form.refresher.enabled.title')}
        titleEndAdornment={
          <Switch
            control={control}
            name='refresherEnabled'
          />
        }
      />
    </Paper>
  )
}

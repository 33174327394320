import { isBoolean, isNumber, isString } from 'lodash'

import { parseValidJSON } from 'utils/json'
import { isNumericString } from 'utils/string'

export const parseBidderParameters = (
  params: Record<string, boolean | number | string>,
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(params).map(
      ([key, value]: [string, boolean | number | string]): [string, unknown] => [
        key,
        parseBidderParameter(value),
      ],
    ),
  )

const parseBidderParameter = (value: boolean | number | string): unknown => {
  if (isBoolean(value) || isNumber(value)) {
    return value
  } else if (isString(value)) {
    if (value.length === 0) {
      return null
    } else if (isNumericString(value)) {
      return value.toString()
    } else {
      return parseValidJSON(value)
    }
  }

  throw Error('Unsupported value type')
}

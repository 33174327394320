import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitForSnackbar } from '../types'
import { AdUnitCreateDTO } from './types'

export const adUnitForSnackbarFromDTO = (from: AdUnitCreateDTO): AdUnitForSnackbar =>
  mapDTO<AdUnitCreateDTO, AdUnitForSnackbar>({ from }).transform(({ id, name, path }) => ({
    id,
    name: name || path,
  }))

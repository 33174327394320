import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import Filter, { FilterValue } from 'components/Filter'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { KNOWLEDGE_BASE } from 'features/globalSetup/pages/SupplyChainList/constants'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { SupplyChainTable as Table } from './SupplyChainTable'

export const SupplyChainList = () => {
  const ability = useAbility()
  const navigate = useNavigate()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const [filters, setFilters] = useState<FilterValue[]>([])

  const SupplyChainsFilter = (
    <Filter
      id='supplyChainsFilter'
      onChange={setFilters}
      placeholder={t('supplyChain.list.filter')}
      values={filters}
    />
  )

  const AddChainButton = (): JSX.Element => (
    <Button
      data-cy='add-supply-chain-button'
      onClick={(): void => navigate(generatePath(ROUTES.GLOBAL_SETUP.SCHAIN.ADD, { workspaceId }))}
      variant='contained'
    >
      {t('common:actions.addNew')}
    </Button>
  )

  const canCreate: boolean = ability.can('create', 'GlobalSetupFeature-supplyChain')

  return (
    <Paper>
      <TableHeader
        actions={canCreate ? <AddChainButton /> : undefined}
        description={
          canCreate
            ? t('supplyChain.list.description.employee')
            : t('supplyChain.list.description.viewer')
        }
        descriptionEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE.SUPPLY_CHAIN,
            }}
          />
        }
        filter={SupplyChainsFilter}
        title={t('supplyChain.list.title')}
      />
      <Box sx={{ mt: 3 }}>
        <Table filters={filters} />
      </Box>
    </Paper>
  )
}

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { DELETE_PAGE } from './api/mutation'
import { UseDeletePage, UseDeletePageProps } from './types'

export const useDeletePage = ({ name }: UseDeletePageProps): UseDeletePage => {
  const { t } = useTranslation('features/page')
  const { enqueueSnackbar } = useSnackbar()

  const [mutateDeletePage, { loading }] = useMutation(DELETE_PAGE, {
    onCompleted: ({ deletePage }): void => {
      if (deletePage) {
        const { errors } = deletePage

        if (errors && errors.length > 0) {
          enqueueSnackbar(
            t('delete.action.error', { interpolation: { escapeValue: false }, pageName: name }),
            { variant: 'error' },
          )
        }
      } else {
        enqueueSnackbar(
          t('delete.action.success', { interpolation: { escapeValue: false }, pageName: name }),
          { variant: 'success' },
        )
      }
    },
    refetchQueries: ['getPages'],
  })

  const deletePage: UseDeletePage['deletePage'] = id =>
    mutateDeletePage({ variables: { input: { id } } })

  return { deletePage, loading }
}

import { snakeCase } from 'lodash'
import { generatePath, To } from 'react-router-dom'
import { SnakeCase } from 'type-fest'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModuleSortBy } from 'features/globalSetup/types/workspacePrebidModule'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { calculatePagination } from 'providers/graphql/utils'
import { ROUTES } from 'routes'
import {
  useGetWorkspacePrebidModules,
  WorkspacePrebidModuleSlice,
} from '../../hooks/useGetWorkspacePrebidModules'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { WorkspacePrebidModuleTableProps } from './types'

export const WorkspacePrebidModuleTable = ({
  columns,
  moduleType,
}: WorkspacePrebidModuleTableProps): JSX.Element => {
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination()
  const { props: sortProps, sort } = useSort<
    WorkspacePrebidModuleSlice,
    WorkspacePrebidModuleSortBy
  >(DEFAULT_SORT, SORTABLE_COLUMNS)
  const { workspaceId } = useWorkspaceParam()

  const { count, loading, workspacePrebidModules } = useGetWorkspacePrebidModules({
    moduleType,
    sort,
    workspaceId,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  const handleRowClick = (data: WorkspacePrebidModuleSlice): To => {
    const transformedModuleType = snakeCase(moduleType).toUpperCase() as Uppercase<
      SnakeCase<PrebidModuleType>
    >

    return generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES[transformedModuleType].EDIT, {
      id: data.id,
      tab: 'general',
      workspaceId,
    })
  }

  return (
    <Table<WorkspacePrebidModuleSlice>
      columns={columns}
      data={workspacePrebidModules}
      loading={loading}
      onClickRow={handleRowClick}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}

import { z } from 'zod'

import { userWorkspaceRoles } from 'features/user/types/userWorkspaceRole'
import { Workspace } from 'features/workspace'

export const schema = z.object({
  email: z.string().email(),
  role: z.enum(userWorkspaceRoles),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ workspaceId }: { workspaceId: Workspace['id'] }): Schema => ({
  email: '',
  role: 'viewer',
  workspaceId,
})

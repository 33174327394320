import { gql, TypedDocumentNode } from '@apollo/client'

import { EMPLOYEE_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_EMPLOYEES_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${EMPLOYEE_FOR_TABLE_FRAGMENT}

  query getEmployeesForTable(
    $first: Int
    $last: Int
    $terms: [String!]
    $userGroup: UserGroupTypeEnum!
  ) {
    users(first: $first, last: $last, terms: $terms, userGroup: $userGroup) {
      nodes {
        ...EmployeeForTableFragment
      }

      totalCount
    }
  }
`

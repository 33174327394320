import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PRODUCT_STATUSES, ProductStatus } from 'features/product/types/productStatus'
import { UseSelectStatus, UseSelectStatusProps } from './types'

export const useSelectStatus = ({ currentStatus }: UseSelectStatusProps): UseSelectStatus => {
  const { t } = useTranslation('features/product')

  const [isOpen, setOpen] = useState<boolean>(false)
  const [newStatus, setNewStatus] = useState<ProductStatus>(currentStatus)

  const isTrial = currentStatus === 'trial'
  const isTrialRequested = newStatus === 'trial'

  const options = PRODUCT_STATUSES.map((status: ProductStatus) => ({
    label: t(`status.${status}`),
    value: status,
  }))

  return {
    isOpen,
    isTrial,
    isTrialRequested,
    newStatus,
    options,
    setNewStatus,
    setOpen,
  }
}

import { useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'

type Return = {
  dataSampling: Workspace['prebidManagerSampling']
}

export const useDataSamplingFromCurrentWorkspace = (): Return => {
  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw Error('Workspace should be accessible')
  }

  return {
    dataSampling: currentWorkspace.prebidManagerSampling,
  }
}

import { Theme, Typography } from '@mui/material'
import _ from 'lodash'

import { User } from 'features/user'

export const StatusCell = ({ status }: { status: User['status'] }): JSX.Element => (
  <Typography
    sx={(theme: Theme) => ({
      color: status === 'active' ? theme.palette.success.main : theme.palette.warning.main,
    })}
  >
    {_.startCase(status)}
  </Typography>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl/types/ability'
import { AD_UNIT_FRAGMENT } from '../fragments/adUnit'
import { AdUnitData, AdUnitVars } from '../types/adUnit'

export const GET_AD_UNIT = (ability: Ability): TypedDocumentNode<AdUnitData, AdUnitVars> => {
  return gql`
    ${AD_UNIT_FRAGMENT(ability)}

    query adUnit($id: ID!) {
      node(id: $id) {
        ...AdUnitFragment
      }
    }
  `
}

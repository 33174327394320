import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'

export const useSnackbar = () => {
  const { t } = useTranslation('features/globalSetup', { keyPrefix: 'prebid.bidders.add.action' })

  const snackbar = (bidderCode: WorkspaceBidder['bidderCode'], variant?: VariantType): void => {
    if (variant === 'error') {
      enqueueSnackbar(t('error', { bidderCode }), { variant: 'error' })
    } else {
      enqueueSnackbar(t('success', { bidderCode }), { variant: 'success' })
    }
  }

  return { snackbar }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceUserForTableDTO } from './types'

export const WORKSPACE_USER_FOR_TABLE_FRAGMENT: TypedDocumentNode<WorkspaceUserForTableDTO> = gql`
  fragment WorkspaceUserForTableFragment on User {
    currentWorkspaceRole
    email
    firstName
    id
    lastName
    status
    updatedAt
  }
`

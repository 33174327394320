import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_USER_FRAGMENT } from 'features/globalSetup/api/fragments/workspaceUser'
import { Data, Variables } from './types'

export const GET_WORKSPACE_USER: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_USER_FRAGMENT}

  query getWorkspaceUser($id: ID!) {
    node(id: $id) {
      ... on User {
        ...WorkspaceUserFragment
      }
    }
  }
`

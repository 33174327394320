import { Box } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { InventoryConnectionForm } from '../../forms/InventoryConnection'
import { AdUnitAmazonsTable } from '../../forms/InventoryConnection/components/AdUnitAmazonsTable'
import { DomainSection } from '../../forms/InventoryConnection/components/DomainSection'

export const InventoryConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'pages.inventoryConnection',
  })
  const [filters, setFilters] = useState<FilterValue[]>([])

  const AdUnitAmazonsTableFilter = (
    <Filter
      id='adUnitAmazonsTableFilter'
      onChange={setFilters}
      placeholder={t('filter')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        bottomSpacing
        description={t('description')}
        filter={AdUnitAmazonsTableFilter}
        title={t('title')}
      />

      <InventoryConnectionForm>
        <DomainSection />

        <Box sx={{ pt: CONTAINER_SPACING }}>
          <AdUnitAmazonsTable filters={filters} />
        </Box>
      </InventoryConnectionForm>
    </Paper>
  )
}

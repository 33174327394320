import { useContext } from 'react'

import { Table } from 'components/Table'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { MediaTypeDefaultAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { CommonFormProps } from '../../../../types'
import { useColumns } from './useColumns'
import { composeTemporaryMediaType } from './utils'

export const DefaultTable = ({ variant }: CommonFormProps): JSX.Element => {
  const { adUnit } = useContext(AddEditAdUnitContext)
  const columns = useColumns()

  const data: MediaTypeDefaultAttribute[] =
    adUnit && variant === 'edit'
      ? [adUnit.mediaTypes.defaultAttribute]
      : [composeTemporaryMediaType()]

  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}

import { CellContext, ColumnDef } from '@tanstack/react-table'
import { FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NumberField, Switch, TagsField, TextField } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { useAbility } from 'providers/casl'
import { PREDEFINED_AD_UNIT_SIZES } from '../../constants'
import { Schema } from '../../forms/ViewportForm/schema'
import { ViewportSlice } from '../../hooks/useGetViewports'
import { ActionsCell } from './components/ActionsCell'

type Props = {
  remove: UseFieldArrayRemove
}

export const useColumns = ({ remove }: Props): ColumnDef<FieldArrayWithId<ViewportSlice>>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/viewport', {
    keyPrefix: 'list.header',
  })

  const { control } = useFormContext<Schema>()

  const canEdit: boolean = ability.can('update', 'Viewport')

  return [
    {
      accessorKey: 'name',
      cell: ({ cell: { getValue }, row: { index } }) => (
        <TextField
          control={control}
          disabled={!canEdit}
          name={`viewports.${index}.name`}
          useTooltipForErrors
          value={getValue()}
          width='sm'
        />
      ),
      header: t('name'),
    },
    {
      accessorKey: 'minimumWidth',
      cell: ({ row: { index } }) => (
        <NumberField
          control={control}
          disabled={!canEdit}
          name={`viewports.${index}.minimumWidth`}
          useTooltipForErrors
        />
      ),
      header: t('minimumWidth'),
    },
    {
      columns: [
        {
          accessorKey: 'bannerEnabled',
          cell: ({ cell: { getValue }, row: { index } }) => (
            <Switch
              control={control}
              disabled={!canEdit}
              name={`viewports.${index}.bannerEnabled`}
              value={getValue()}
            />
          ),
          header: t('mediaTypes.bannerEnabled'),
          meta: { centered: true },
          size: COLUMN_SIZE.SM,
        },
        {
          accessorKey: 'bannerSizes',
          cell: ({ cell: { getValue }, row: { index } }) => (
            <TagsField
              control={control}
              disabled={!canEdit}
              name={`viewports.${index}.bannerSizes`}
              options={PREDEFINED_AD_UNIT_SIZES}
              value={getValue()}
            />
          ),
          header: t('mediaTypes.bannerSizes'),
        },
        {
          accessorKey: 'outstreamEnabled',
          cell: ({ cell: { getValue }, row: { index } }) => (
            <Switch
              control={control}
              disabled={!canEdit}
              name={`viewports.${index}.outstreamEnabled`}
              value={getValue()}
            />
          ),
          header: t('mediaTypes.outstreamEnabled'),
          meta: { centered: true },
          size: COLUMN_SIZE.SM,
        },
        {
          accessorKey: 'playerSize',
          cell: ({ cell: { getValue }, row: { index } }) => (
            <TagsField
              control={control}
              disabled={!canEdit}
              name={`viewports.${index}.playerSize`}
              options={PREDEFINED_AD_UNIT_SIZES}
              value={getValue()}
              width='sm'
            />
          ),
          header: t('mediaTypes.playerSize'),
        },
        {
          accessorKey: 'nativeEnabled',
          cell: ({ cell: { getValue }, row: { index } }) => (
            <Switch
              control={control}
              disabled={!canEdit}
              name={`viewports.${index}.nativeEnabled`}
              value={getValue()}
            />
          ),
          header: t('mediaTypes.native'),
          meta: { centered: true },
          size: COLUMN_SIZE.SM,
        },
      ],
      header: t('mediaTypes.header'),
      meta: { tooltip: { content: t('mediaTypes.tooltip') } },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => (
        <LastModifiedCell {...(props as unknown as CellContext<ViewportSlice, unknown>)} />
      ),
      header: t('updatedAt'),
    },
    {
      cell: ({ row: { index } }) => <ActionsCell removeProps={{ index, remove }} />,
      header: t('actions'),
      id: 'actions',
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}

import i18next from 'i18next'
import { z } from 'zod'

import { matchTypeValues } from 'features/inventory/page/types/matchType'
import { adUnitIdsField } from './adUnitIdsField'

export const commonSchema = z
  .object({
    domainId: z.string().nonempty(i18next.t('common:form.error.nonEmpty')),
    matchType: z.enum(matchTypeValues),
    name: z.string().nonempty(),
    path: z.string().nonempty(),
  })
  .merge(adUnitIdsField)

export type CommonSchema = z.infer<typeof commonSchema>

import { FieldErrors, FieldValues } from 'react-hook-form'

import { mapGraphQLErrors, MutationInputError } from 'providers/graphql'

// TODO: Based on Backend README file, this function should be moved to geenral error mapper.
export const errorsWithParamsMapper = <T extends FieldValues>(
  errors: MutationInputError[],
): FieldErrors<T> => {
  const mappedErrors = errors.map((error: MutationInputError) => {
    error.attribute = error.attribute.includes('params.')
      ? error.attribute.split('.')[1]
      : error.attribute

    return error
  })

  return mapGraphQLErrors(mappedErrors) as FieldErrors<T>
}

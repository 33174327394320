import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_PREBID_MODULE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation createPrebidModule($input: CreateWorkspacePrebidModuleInput!) {
    createWorkspacePrebidModule(input: $input) {
      workspacePrebidModule {
        id
        prebidModule {
          demand
        }
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FOR_SELECT_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_DOMAINS: TypedDocumentNode<Data, Variables> = gql`
  ${DOMAIN_FOR_SELECT_FRAGMENT}

  query getDomains($workspaceId: ID!) {
    domains(workspaceId: $workspaceId) {
      nodes {
        ...DomainForSelectFragment
      }
    }
  }
`

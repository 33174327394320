import { gql, TypedDocumentNode } from '@apollo/client'

import { Data } from './types'

export const GET_CS_MANAGERS_FOR_SELECT: TypedDocumentNode<Data> = gql`
  query getCSManagersForSelect {
    customerSuccessUsers {
      nodes {
        id
        firstName
        lastName
      }
    }
  }
`

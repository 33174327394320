import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { userFromDTO } from 'features/user/api/mappers/user'
import { authenticate } from 'features/user/store/userSlice'
import { AppDispatch } from 'providers/redux'
import { ROUTES } from 'routes'
import { AuthenticateProps, UseAuthenticate } from './types'

export const useAuthenticate = (): UseAuthenticate => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()

  const handleAuthenticate = ({ accessToken, refreshToken, user }: AuthenticateProps) => {
    dispatch(
      authenticate({
        accessToken,
        refreshToken,
        user: userFromDTO(user),
      }),
    )

    navigate(ROUTES.BASE.ROOT)
  }

  return { authenticate: handleAuthenticate }
}

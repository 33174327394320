import { z } from 'zod'

import { UsePriceGeniusAlwaysOn } from 'features/inventory/ad-unit/hooks/usePriceGeniusAlwaysOn/types'
import { AdUnitForForm } from '../../GeneralForm/hooks/useGetAdUnit'

export const commonSchema = z.object({
  id: z.string(),
  priceGeniusEnabled: z.boolean(),
})

export type CommonSchema = z.infer<typeof commonSchema>

export const schemaDefault = (
  { id, priceGeniusEnabled, unfilledRecoveryAdUnit }: AdUnitForForm,
  { overwritePriceGeniusState, priceGeniusAlwaysOn }: UsePriceGeniusAlwaysOn,
): CommonSchema => ({
  id,
  priceGeniusEnabled: overwritePriceGeniusState('priceGenius', unfilledRecoveryAdUnit)
    ? priceGeniusAlwaysOn
    : priceGeniusEnabled,
})

import { useContext, useState } from 'react'

import { FilterValue } from 'components/Filter'
import { Form } from 'components/Form'
import Paper from 'components/Paper'
import { usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { calculatePagination } from 'providers/graphql'
import { BidderSidsTable } from './components/BidderSidsTable'
import { TableHeader } from './components/TableHeader'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { defaults, schema } from './form/schema'
import { useGetBidders } from './hooks/useGetBidders'
import { WorkspaceBidderForTable, WorkspaceBidderForTableSortBy } from './hooks/useGetBidders/types'
import { useUpdateBidderSupplyChains } from './hooks/useUpdateBidderSupplyChains'

export const BidderSidsPage = (): JSX.Element => {
  const { formId, setTouched, setValid, supplyChain } = useContext(SupplyChainContext)
  const [filters, setFilters] = useState<FilterValue[]>([])
  const { page, rowsPerPage, ...usePagniationProps } = usePagination({ resetOnChange: [filters] })
  const { props: sortProps, sort } = useSort<
    WorkspaceBidderForTable,
    WorkspaceBidderForTableSortBy
  >(DEFAULT_SORT, SORTABLE_COLUMNS)

  if (!supplyChain) {
    throw Error('Supply chain should be accessible')
  }

  const { id: supplyChainId, name: supplyChainName } = supplyChain
  const { bidders, count } = useGetBidders({
    filters,
    sort,
    supplyChainId,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const { update } = useUpdateBidderSupplyChains({ supplyChainName })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    ...usePagniationProps,
  }

  return (
    <Paper>
      <TableHeader filter={{ filters, setFilters }} />

      <Form
        id={formId}
        onSubmit={update}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={defaults(bidders)}
      >
        {() => (
          <BidderSidsTable
            data={bidders}
            pagination={paginationProps}
            sort={sortProps}
          />
        )}
      </Form>
    </Paper>
  )
}

import { z } from 'zod'

import { applicableDomains } from 'features/globalSetup/types/filteringRule'

export const commonSchema = z.object({
  applicableDomains: z.enum(applicableDomains),
  blocked: z.array(z.string()),
  domainIds: z.array(z.string()),
  name: z.string().min(1),
})

export type CommonSchema = z.infer<typeof commonSchema>

import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import {
  PubAdsServiceRefresh,
  pubAdsServiceRefresh,
} from 'features/inventory/domain/types/pubAdsServiceRefresh'

export const usePubAdsServiceRefreshOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/domain')

  return pubAdsServiceRefresh.map(
    (pubAdsServiceRefreshType: PubAdsServiceRefresh): RadioGroupOption => ({
      label: t(
        `form.general.advancedSettings.pubAdsServiceRefresh.values.${pubAdsServiceRefreshType}.title`,
      ),
      value: pubAdsServiceRefreshType,
    }),
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { CheckboxListOption } from 'components/Form/CheckboxListFilterable'
import { DomainSliceDTO } from './types'

export const domainsFromDTO = (from: DomainSliceDTO): CheckboxListOption =>
  mapDTO<DomainSliceDTO, CheckboxListOption>({ from }).transform(
    (domain: DomainSliceDTO): CheckboxListOption => ({
      label: domain.name,
      value: domain.id,
    }),
  )

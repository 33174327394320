import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import Paper from 'components/Paper'
import ProductList from 'features/product/components/ProductList'
import { useGetWorkspaceProducts } from './hooks/useGetWorkspaceProducts'
import { groupProducts } from './utils'

export const ProductsPage = (): JSX.Element => {
  const { t } = useTranslation('features/product')
  const { loading, products } = useGetWorkspaceProducts()

  if (loading) {
    return <Loading variant='full-screen' />
  }

  const { active, recommended } = groupProducts(products)

  return (
    <Paper>
      {active.length > 0 && (
        <ProductList
          products={active}
          title={t('header.active')}
        />
      )}
      {recommended.length > 0 && (
        <ProductList
          products={recommended}
          title={t('header.inactive')}
        />
      )}
    </Paper>
  )
}

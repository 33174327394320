import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DialogProps } from './types'

export const Dialog = ({
  actions,
  content,
  isOpen,
  setOpen,
  title,
  ...dialogProps
}: DialogProps): JSX.Element => {
  const { t } = useTranslation('common')

  const handleClose = (): void => setOpen(false)

  return (
    <MuiDialog
      {...dialogProps}
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle data-cy='dialog-title'>
        {title}

        <IconButton onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>{content}</DialogContent>

      <DialogActions>
        <Stack
          direction='row'
          spacing={1}
        >
          <Button onClick={handleClose}>{t('actions.cancel')}</Button>

          {actions}
        </Stack>
      </DialogActions>
    </MuiDialog>
  )
}

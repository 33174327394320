import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_REFRESHER_FRAGMENT } from 'features/inventory/domain/api/fragments/domainRefresher'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_REFRESHER: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_REFRESHER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateDomainRefresher($input: UpdateDomainRefresherInput!) {
    updateDomainRefresher(input: $input) {
      domainRefresher {
        ...DomainRefresherFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

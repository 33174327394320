import { Box, Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'components/Link'
import ProductDialog from 'features/product/components/ProductDialog'
import ProductLabel from 'features/product/components/ProductLabel'
import RequestButton from 'features/product/components/RequestButton'
import { LABEL_STATUSES, REQUEST_STATUSES } from 'features/product/constants'
import { ProductProps } from 'features/product/types/productProps'
import { getProductWithConfig } from 'features/product/utils/productsConfig'
import { useAbility } from 'providers/casl'
import { SelectStatus } from '../SelectStatus'
import { containsStatus } from './utils'

export const ProductCard = ({ workspaceProduct }: ProductProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/product', 'common'])
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { learnMoreUrl, logo, product, status } = getProductWithConfig(workspaceProduct)

  const withLabel = containsStatus(status, LABEL_STATUSES)
  const withRequestButton =
    ability.can('update', 'Product') && containsStatus(status, REQUEST_STATUSES)

  const Header = (): JSX.Element => (
    <Stack
      alignItems='center'
      data-cy={`product-header-${product}`}
      direction='row'
    >
      <Typography
        sx={{ mr: 1 }}
        variant='h5'
      >
        {t(`product.${product}`)}
      </Typography>

      {withLabel && <ProductLabel workspaceProduct={workspaceProduct} />}
    </Stack>
  )

  const Subtitle = (): JSX.Element => (
    <Typography
      data-cy={`product-subtitle-${product}`}
      variant='subtitle1'
    >
      {t(`${product}.subtitle`)}
    </Typography>
  )

  const Description = (): JSX.Element => (
    <Typography
      data-cy={`product-description-${product}`}
      variant='subtitle1'
    >
      {t(`${product}.description`)}
    </Typography>
  )

  const Actions = (): JSX.Element => (
    <Stack
      data-cy={`product-buttons-${product}`}
      direction='row'
      mb={2}
      spacing={1}
    >
      <Link
        newTab
        to={learnMoreUrl}
      >
        <Button>{t('common:learnMore')}</Button>
      </Link>

      {withRequestButton && (
        <RequestButton
          onClick={(): void => setOpenDialog(true)}
          workspaceProduct={workspaceProduct}
        />
      )}
    </Stack>
  )

  return (
    <>
      <Card
        elevation={0}
        sx={{ height: '100%' }}
      >
        <CardContent>
          <Stack direction='row'>
            <SvgIcon
              component={logo}
              fontSize='large'
              inheritViewBox
            />

            <Box>
              <Box
                sx={{
                  mb: 2,
                  ml: 1,
                }}
              >
                <Header />
                <Subtitle />
                <Description />
              </Box>

              <Actions />

              {ability.can('read', 'ProductField-status') && (
                <SelectStatus workspaceProduct={workspaceProduct} />
              )}
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <ProductDialog
        isOpen={openDialog}
        setOpen={setOpenDialog}
        workspaceProduct={workspaceProduct}
      />
    </>
  )
}

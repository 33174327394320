import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { PasswordIconProps } from './types'

export const PasswordIcon = ({ setShowPassword, showPassword }: PasswordIconProps): JSX.Element => (
  <IconButton
    edge='end'
    onClick={() => setShowPassword(!showPassword)}
  >
    {showPassword ? <VisibilityOff /> : <Visibility />}
  </IconButton>
)

import { z } from 'zod'

import { AdUnitViewabilityTools } from 'features/inventory/ad-unit/types/AdUnitViewabilityTools'
import { adUnitViewabilityToolsStatusValues } from 'types/adUnitViewabilityToolsStatus'

export const schema = z.object({
  adHolding: z.enum(adUnitViewabilityToolsStatusValues),
  adUnitId: z.string(),
  lazyLoading: z.enum(adUnitViewabilityToolsStatusValues),
  scrollSlowing: z.enum(adUnitViewabilityToolsStatusValues),
  viewabilityToolsEnabled: z.boolean(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  data: Omit<AdUnitViewabilityTools, 'createdAt' | 'id' | 'updatedAt'>,
): Schema => ({
  ...data,
})

import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GET_SUPPLY_CHAIN } from './api/query'
import { SupplyChainSlice, UseGetSupplyChain, UseGetSupplyChainProps } from './types'

export const useGetSupplyChain = ({ id }: UseGetSupplyChainProps): UseGetSupplyChain => {
  const [supplyChain, setSupplyChain] = useState<SupplyChainSlice>()

  useEffect(() => {
    if (id) {
      get({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [get] = useLazyQuery(GET_SUPPLY_CHAIN, {
    onCompleted: ({ node: supplyChain }) => {
      if (supplyChain) {
        setSupplyChain(supplyChain)
      }
    },
  })

  return { supplyChain }
}

import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { Schema } from '../../schema'
import { Variables } from './api/types'

export const mapper = (data: Schema): Variables => {
  const { adUnitsAttributes, ...result } = data

  return {
    ...result,
    adUnitsAttributes: adUnitsAttributesMapper<'unfilledRecoveryEnabled'>(
      adUnitsAttributes,
      'unfilledRecoveryEnabled',
    ),
  }
}

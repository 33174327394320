import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_DOMAIN_SUPPLY_CHAINS: TypedDocumentNode<Data, Variables> = gql`
  query getDomainSupplyChains(
    $supplyChainId: ID!
    $first: Int
    $last: Int
    $sort: DomainSupplyChainSortInput
    $terms: [String!]
  ) {
    domainSupplyChains(
      supplyChainId: $supplyChainId
      first: $first
      last: $last
      sort: $sort
      terms: $terms
    ) {
      nodes {
        id
        customDomainSid
        domain {
          id
          name
        }
        supplyChain {
          id
          name
        }
        updatedAt
      }

      totalCount
    }
  }
`

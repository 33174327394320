import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_CUSTOMER_ROLE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation UpdatePublisherWorkspaceRole($input: UpdatePublisherWorkspaceRoleInput!) {
    updatePublisherWorkspaceRole(input: $input) {
      userWorkspace {
        id
        role
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { Divider } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, CodeField, RadioGroup } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useAbility } from 'providers/casl'
import { CommonSchema } from '../../schemas/commonSchema'
import { editSchemaDefault } from '../../schemas/editSchema'
import { MediaTypeSchema, MediaTypeViewportsSchema } from '../../schemas/mediaTypesSchema'
import {
  mediaTypeDefaultAttributeToSchema,
  mediaTypeViewportAttributesToSchema,
} from '../../schemas/schemaMappers'
import { CommonFormProps } from '../../types'
import { DefaultTable } from './components/DefaultTable'
import { composeTemporaryMediaType } from './components/DefaultTable/utils'
import { ViewportsTable } from './components/ViewportsTable'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useMediaTypesKindOptions } from './hooks/useMediaTypesKindOptions'

export const MediaTypesSection = ({ variant }: CommonFormProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { adUnit } = useContext(AddEditAdUnitContext)
  const { control, getValues, reset, watch } = useFormContext<CommonSchema>()

  if (!adUnit && variant === 'edit') {
    throw new Error('Ad unit should be accessible')
  }

  const options = useMediaTypesKindOptions()
  const {
    mediaTypes: { kind },
  } = watch()

  const resetMediaTypesAttributes = (): void => {
    const { mediaTypes, ...values } = getValues()

    let defaultAttribute: MediaTypeSchema
    let viewportAttributes: MediaTypeViewportsSchema[]

    if (adUnit && variant === 'edit') {
      defaultAttribute = editSchemaDefault(adUnit, ability).mediaTypes.defaultAttribute
      viewportAttributes = editSchemaDefault(adUnit, ability).mediaTypes.viewportAttributes
    } else {
      defaultAttribute = mediaTypeDefaultAttributeToSchema(composeTemporaryMediaType())
      viewportAttributes = mediaTypeViewportAttributesToSchema([])
    }

    reset({
      ...values,
      mediaTypes: {
        ...mediaTypes,
        ...(kind === 'default' ? { viewportAttributes } : { defaultAttribute }),
      },
    })
  }

  useEffect(() => {
    resetMediaTypesAttributes()
  }, [kind]) // eslint-disable-line react-hooks/exhaustive-deps

  const disabledCodeFields = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('form.general.mediaTypes.subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL.MEDIA_TYPES,
            }}
          />
        }
        title={t('form.general.mediaTypes.title')}
      />

      <RadioGroup
        bottomSpacing
        control={control}
        label={t('form.general.mediaTypes.kind.label')}
        name='mediaTypes.kind'
        options={options}
        row
      />

      {kind === 'default' ? <DefaultTable variant={variant} /> : <ViewportsTable />}

      <Divider sx={{ my: 3 }} />

      <SectionHeader
        bottomSpacing
        subtitle={t('form.general.mediaTypes.configuration.subtitle')}
        title={t('form.general.mediaTypes.configuration.title')}
      />

      <BoxForm>
        <CodeField
          control={control}
          disabled={disabledCodeFields}
          language='json'
          name='mediaTypes.outstreamConfiguration'
          title={t('form.general.mediaTypes.configuration.outstreamConfiguration.header')}
          tooltip={{
            content: t(
              'form.general.mediaTypes.configuration.outstreamConfiguration.tooltip.content',
            ),
            link: {
              title: t(
                'form.general.mediaTypes.configuration.outstreamConfiguration.tooltip.title',
              ),
              url: KNOWLEDGE_BASE_URL.OUTSTREAM_CONFIGURATION,
            },
          }}
        />

        <CodeField
          control={control}
          disabled={disabledCodeFields}
          language='json'
          name='mediaTypes.nativeConfiguration'
          title={t('form.general.mediaTypes.configuration.nativeConfiguration.header')}
          tooltip={{
            content: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.content'),
            link: {
              title: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.title'),
              url: KNOWLEDGE_BASE_URL.NATIVE_CONFIGURATION,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}

import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import { ROUTES } from 'routes'
import { UserEditPublisherForm } from '../../forms/UserPublisherForm/UserEditPublisherForm'
import { useGetPublisher } from './hooks/useGetPublisher'
import { UserEditPublisherPageParams } from './types'

export const UserEditPublisherPage = (): JSX.Element => {
  const { id } = useParams<UserEditPublisherPageParams>()
  const { t } = useTranslation('features/user')
  const { user } = useGetPublisher({ id })

  if (!id) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!user) {
    return <Loading />
  }
  return (
    <>
      <PageHeader
        backTo={generatePath(ROUTES.USER.LIST, { tab: 'publishers' })}
        title={t('form.publisher.edit.title', { email: user.email })}
      />

      <UserEditPublisherForm user={user} />
    </>
  )
}

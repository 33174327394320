import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { EmployeeTable } from '../../components/Table/EmployeeTable'
import { PublisherTable } from '../../components/Table/PublisherTable'
import { UserListProps, UserUrlParams } from './types'

export const UserList = ({ tab }: UserListProps): JSX.Element => {
  const ability = useAbility()
  const { tab: urlTab } = useParams<UserUrlParams>()
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/user')
  const [filters, setFilters] = useState<FilterValue[]>([])

  if (!urlTab) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  }

  const isPublishersTab = urlTab === 'publishers'

  const AddNew = (): JSX.Element | undefined => {
    const can = ability.can('create', isPublishersTab ? 'Publisher' : 'Employee')
    if (!can) return undefined

    const path: string = isPublishersTab ? ROUTES.USER.PUBLISHER.ADD : ROUTES.USER.EMPLOYEE.ADD

    return (
      <Link to={generatePath(path)}>
        <Button
          data-cy={`add-${urlTab}-button`}
          variant='contained'
        >
          {tC('actions.addNew')}
        </Button>
      </Link>
    )
  }

  const UsersFilter = (
    <Filter
      id='usersFilter'
      onChange={setFilters}
      placeholder={tC('filter.search')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        actions={<AddNew />}
        bottomSpacing
        description={t(`list.${tab}.description`)}
        filter={UsersFilter}
        title={t(`list.${tab}.title`)}
      />
      {isPublishersTab ? <PublisherTable filters={filters} /> : <EmployeeTable filters={filters} />}
    </Paper>
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { FilteringRuleSlice } from '../types'
import { FilteringRuleSliceDTO } from './types'

export const filteringRuleFromDTO = (from: FilteringRuleSliceDTO) =>
  mapDTO<FilteringRuleSliceDTO, FilteringRuleSlice>({ from }).transform(
    (data: FilteringRuleSliceDTO): FilteringRuleSlice => ({
      ...data,
    }),
  )

import { CellContext } from '@tanstack/react-table'

import { Switch } from 'components/Switch'
import { useToggleFilteringRule } from 'features/globalSetup/pages/Prebid/Protection/pages/hooks/useToggleFilteringRule'
import { useAbility } from 'providers/casl'
import { FilteringRuleForTable } from '../../hooks/useGetFilteringRules/types'

export const EnabledCell = ({
  getValue,
  row: {
    original: { id, name },
  },
}: CellContext<FilteringRuleForTable, unknown>): JSX.Element => {
  const ability = useAbility()
  const canEdit: boolean = ability.can('update', 'GlobalSetupFeature-protection')

  const { checked, update } = useToggleFilteringRule({
    enabled: Boolean(getValue()),
    id,
    name,
  })

  return (
    <Switch
      checked={checked}
      disabled={!canEdit}
      onChange={update}
    />
  )
}

import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { StatusCell } from '../Cell/StatusCell'
import { ActionsCell } from './Cell/ActionsCell'
import { UserRoleCell } from './Cell/UserRoleCell'
import { EmployeeForTable } from './types'

export const useColumns = (): ColumnDef<EmployeeForTable>[] => {
  const { t } = useTranslation('features/user')

  return [
    {
      accessorKey: 'id',
      header: t('list.header.id'),
    },
    {
      accessorKey: 'username',
      header: t('list.header.username'),
    },
    {
      accessorKey: 'email',
      header: t('list.header.email'),
    },
    {
      accessorKey: 'role',
      cell: ({ row }) => <UserRoleCell {...row.original} />,
      header: t('list.header.role'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'status',
      cell: ({ row }) => <StatusCell status={row.original.status} />,
      header: t('list.header.status'),
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.SM,
    },
  ]
}

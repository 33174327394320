import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { AdUnit } from 'features/inventory/types/adUnit'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { mapGraphQLErrors } from 'providers/graphql'
import { browserRelativeRedirect } from 'providers/router/utils'
import { mergeCloseAction } from 'providers/snackbar/mergeCloseAction'
import { ROUTES } from 'routes'
import { AddSchema } from '../../schemas/addSchema'
import { adUnitForSnackbarFromDTO } from './api/mapper'
import { CREATE_AD_UNIT } from './api/mutation'
import { Data } from './api/types'
import { mapper } from './mapper'
import { AdUnitForSnackbar, UseCreateAdUnit } from './types'

export const useCreateAdUnit = (): UseCreateAdUnit => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const navigate = useNavigate()
  const { t } = useTranslation('features/adUnit')
  const { t: tCommon } = useTranslation('common')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditAdUnitContext)
  const [errors, setErrors] = useState<FieldErrors<AddSchema>>({})

  const [mutateCreateAdUnit] = useMutation(CREATE_AD_UNIT, {
    onCompleted: ({ createAdUnit: { adUnit, errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.create'), { variant: 'error' })
      } else if (adUnit) {
        setErrors({})
        navigate(generatePath(ROUTES.INVENTORY.AD_UNIT.LIST, { workspaceId }))
        enqueueSuccessSnackbar(adUnitForSnackbarFromDTO(adUnit))
      }
    },
    onError: (): void => setLoading(false),
  })

  const enqueueSuccessSnackbar = (adUnit: AdUnitForSnackbar) => {
    const { id, name } = adUnit

    enqueueSnackbar(
      t('form.success.create', { adUnitName: name, interpolation: { escapeValue: false } }),
      {
        action: snackbarId =>
          mergeCloseAction(
            <Button
              color='white'
              onClick={() => handleClickEdit(id)}
            >
              {tCommon('form.action.edit').toUpperCase()}
            </Button>,
            snackbarId,
          ),
        variant: 'success',
      },
    )
  }

  const handleClickEdit = (id: AdUnit['id']): void =>
    browserRelativeRedirect(
      generatePath(ROUTES.INVENTORY.AD_UNIT.EDIT, {
        id,
        tab: 'general',
        workspaceId,
      }),
    )

  const createAdUnit = (data: AddSchema) => {
    setLoading(true)

    return mutateCreateAdUnit({ variables: { input: mapper(data, ability) } })
  }

  return { createAdUnit, errors }
}

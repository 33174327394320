import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainDTO } from '../api/types/domainDTO'
import { domainPrebidStackFromDTO } from '../domain/api/mappers/domainPrebidStack'
import { domainPriceGeniusFromDTO } from '../domain/api/mappers/domainPriceGenius'
import { domainRefresherFromDTO } from '../domain/api/mappers/domainRefresher'
import { domainViewabilityToolsFromDTO } from '../domain/api/mappers/domainViewabilityTools'
import { Domain } from '../types/domain'
import { customCodeFromDTO } from './customCode'

export const domainFromDTO = (from: DomainDTO) =>
  mapDTO<DomainDTO, Domain>({ from }).transform(domainDTO => ({
    ...domainDTO,
    createdAt: new Date(domainDTO.createdAt),
    customCode: customCodeFromDTO(domainDTO.customCode),
    domainPrebidStack: domainPrebidStackFromDTO(domainDTO.domainPrebidStack),
    domainPriceGenius: domainPriceGeniusFromDTO(domainDTO.domainPriceGenius),
    domainRefresher: domainRefresherFromDTO(domainDTO.domainRefresher),
    domainViewabilityTools: domainViewabilityToolsFromDTO(domainDTO.domainViewabilityTools),
    updatedAt: new Date(domainDTO.updatedAt),
  }))

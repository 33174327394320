import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_FRAGMENT } from 'features/workspace'
import { UserDTO } from '../types/userDTO'

export const USER_FRAGMENT: TypedDocumentNode<UserDTO> = gql`
  ${WORKSPACE_FRAGMENT}

  fragment UserFragment on User {
    createdAt
    currentWorkspace {
      ...WorkspaceFragment
    }
    currentWorkspaceRole
    email
    firstName
    id
    lastName
    role
    updatedAt
    workspaceProducts {
      product
      status
    }
    workspaces {
      ...WorkspaceFragment
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'
import { EmptyObject } from 'type-fest'

import { ERROR_FRAGMENT } from 'providers/graphql/api'
import { SignOutData } from '../types/signOut'

export const SIGN_OUT: TypedDocumentNode<SignOutData, EmptyObject> = gql`
  ${ERROR_FRAGMENT}

  mutation signOut {
    signOut(input: {}) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

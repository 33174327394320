import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceDTO } from 'features/workspace'
import { GAM_CONNECTION_ORDER_FRAGMENT } from 'features/workspace/api/fragments/gamConnectionOrder'

export const GET_GOOGLE_AD_MANAGER_CONNECTION: TypedDocumentNode<{ node: WorkspaceDTO }> = gql`
  ${GAM_CONNECTION_ORDER_FRAGMENT}

  query getWorkspaceForGamConnection($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        latestGamConnectionOrder {
          ...GamConnectionOrderFragment
        }
      }
    }
  }
`

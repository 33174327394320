import { gql, TypedDocumentNode } from '@apollo/client'

import { AD_UNIT_VIEWABILITY_TOOLS_FRAGMENT } from 'features/inventory/ad-unit/api/fragments/adUnitViewabilityTools'
import { Ability } from 'providers/casl/types/ability'
import { permitFields } from 'providers/graphql/utils'
import { MEDIA_TYPES_FOR_FORM_FRAGMENT } from '../../ad-unit/forms/GeneralForm/hooks/useGetAdUnit/api/fragments/mediaTypes'
import { AdUnitDTO } from '../types/adUnitDTO'
import { DOMAIN_FRAGMENT } from './domain'
import { PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT } from './pageForAdUnitTransferList'

export const AD_UNIT_FRAGMENT = (ability: Ability): TypedDocumentNode<AdUnitDTO> => gql`
  ${AD_UNIT_VIEWABILITY_TOOLS_FRAGMENT}
  ${DOMAIN_FRAGMENT}
  ${MEDIA_TYPES_FOR_FORM_FRAGMENT}
  ${PAGE_FOR_AD_UNIT_TRANSFER_LIST_FRAGMENT}

  fragment AdUnitFragment on AdUnit {
    ${permitFields<AdUnitDTO>({
      adUnitId: ability.can('read', 'AdUnitField-rawId'),
      unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery'),
    })}
    adUnitAmazonEnabled
    adUnitViewabilityTool {
      ...AdUnitViewabilityToolsFragment
    }
    createdAt
    divId
    domain {
      ...DomainFragment
    }
    id
    name
    mediaTypes {
      ...MediaTypesFragment
    }
    outOfPage
    outOfPageType
    pages {
      ...PageForAdUnitTransferList
    }
    path
    prebidStackEnabled
    priceGeniusEnabled
    refresherEnabled
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
  }
`

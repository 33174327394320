import { gql, TypedDocumentNode } from '@apollo/client'

import { PREBID_MODULE_FOR_SELECT_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_PREBID_MODULES_FOR_SELECT: TypedDocumentNode<Data, Variables> = gql`
  ${PREBID_MODULE_FOR_SELECT_FRAGMENT}

  query getPrebidModulesForSelect($moduleType: PrebidModuleTypeEnum!) {
    prebidModules(moduleType: $moduleType) {
      nodes {
        ...PrebidModuleForSelectFragment
      }
    }
  }
`

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Select, TextField } from 'components/Form'
import Paper from 'components/Paper'
import { UserRole } from 'features/user/types/UserRole'
import { CommonSchema } from './schemas/common'
import { CommonFormProps } from './types'
import { userEmployeeRoleOptions } from './utils/userEmployeeRoleOptions'

export const CommonForm = ({ variant }: CommonFormProps): JSX.Element => {
  const { t } = useTranslation('features/user')
  const { control } = useFormContext<CommonSchema>()

  return (
    <Paper>
      <BoxForm>
        <TextField
          control={control}
          disabled={variant === 'edit'}
          label={t('form.employee.common.email.label')}
          name='email'
          placeholder={t('form.employee.common.email.placeholder')}
        />

        <Select<CommonSchema, UserRole>
          control={control}
          label={t('form.employee.common.role.label')}
          name='role'
          options={userEmployeeRoleOptions()}
        />
      </BoxForm>
    </Paper>
  )
}

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { PageForTable, PageSortBy } from '../../../types'
import { PageForTableDTO, PageSortByDTO } from '../types'

export const pageForTableFromDTO = (from: PageForTableDTO) =>
  mapDTO<PageForTableDTO, PageForTable>({ from }).transform(pageDTO => ({
    ...pageDTO,
    domain: {
      name: pageDTO.domain.name,
    },
    updatedAt: new Date(pageDTO.updatedAt),
  }))

export const sortToDTO = (sort: SortInput<PageSortBy>): SortInput<PageSortByDTO> => {
  const { by } = sort

  if (by === 'name') {
    return { ...sort, by: 'pageName' }
  } else if (by === 'domain.name') {
    return { ...sort, by: 'domainName' }
  } else {
    return { ...sort, by }
  }
}

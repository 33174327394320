import { Product, ProductStatus } from 'features/product'

export const selectContentTranslation = (
  product: Product,
  status: ProductStatus,
): 'pro' | 'trial' => {
  if (product === 'unfilledRecovery' || status === 'trial') {
    return 'pro'
  } else {
    return 'trial'
  }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainSliceDTO } from './types'

export const DOMAIN_FOR_FILTERING_RULE: TypedDocumentNode<DomainSliceDTO> = gql`
  fragment DomainFragmentForFilteringRule on Domain {
    id
    name
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl/types/ability'
import { permitFields } from 'providers/graphql/utils'
import { DOMAIN_FRAGMENT } from '../fragments/domain'
import { DomainData, DomainVars } from '../types/domain'

export const GET_DOMAIN = (ability: Ability): TypedDocumentNode<DomainData, DomainVars> => gql`
  ${DOMAIN_FRAGMENT}

  query domain($id: ID!) {
    node(id: $id) {
      ... on Domain {
        ${permitFields<DomainData['node']>({
          overriddenByYieldbird: ability.can('read', 'DomainField-overriddenByYieldbird'),
        })}
        ...DomainFragment
      }
    }
  }
`

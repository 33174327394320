import { Button } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { browserRelativeRedirect } from 'providers/router/utils'
import { mergeCloseAction } from 'providers/snackbar/mergeCloseAction'
import { ROUTES } from 'routes'
import { WorkspaceSlice } from '../useGetWorkspace'
import { WorkspaceForSnackbar } from './types'

type UseEnqueueSuccessSnackbar = {
  enqueueSuccessSnackbar: (workspace: WorkspaceForSnackbar) => void
}

export const useEnqueueSuccessSnackbar = (): UseEnqueueSuccessSnackbar => {
  const { t } = useTranslation('features/workspace')
  const { t: tCommon } = useTranslation('common')

  const handleClick = (id: WorkspaceSlice['id']): void =>
    browserRelativeRedirect(generatePath(ROUTES.WORKSPACE.EDIT, { id }))

  const enqueueSuccessSnackbar: UseEnqueueSuccessSnackbar['enqueueSuccessSnackbar'] = workspace => {
    const { id, name } = workspace

    enqueueSnackbar(t('form.success.create', { workspaceName: name }), {
      action: snackbarId =>
        mergeCloseAction(
          <Button
            color='white'
            onClick={() => handleClick(id)}
          >
            {tCommon('form.action.edit').toUpperCase()}
          </Button>,
          snackbarId,
        ),
      variant: 'success',
    })
  }

  return { enqueueSuccessSnackbar }
}

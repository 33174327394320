import { ColumnDef } from '@tanstack/react-table'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { useAbility } from 'providers/casl'
import { InventoryConnectionContext } from '../../../contexts/InventoryConnectionContext'
import { DomainPrebidModuleSlice } from '../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../schema'

export const useColumns = (): ColumnDef<DomainPrebidModuleSlice>[] => {
  const ability = useAbility()
  const { moduleType } = useContext(InventoryConnectionContext)

  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit.inventoryConnection.list.header`,
  })

  const { control } = useFormContext<Schema>()

  const canUpdate: boolean = ability.can('update', `GlobalSetupFeature-${moduleType}`)

  return [
    {
      accessorKey: 'domain.name',
      header: t('domainName'),
    },
    {
      accessorKey: 'enabled',
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <Switch<Schema>
          control={control}
          disabled={!canUpdate}
          name={`domainPrebidModuleAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: t('enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
  ]
}

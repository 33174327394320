import { useContext } from 'react'

import { Form } from 'components/Form'
import { AmazonPageContext } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext'
import { useUpdateDomainAmazon } from './hooks/useUpdateDomainAmazon'
import { schema, Schema, schemaDefaults } from './schema'

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const InventoryConnectionForm = ({ children }: Props) => {
  const { errors: apiErrors, update } = useUpdateDomainAmazon()
  const { formId, setTouched, setValid } = useContext(AmazonPageContext)

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefaults()}
    >
      {() => children}
    </Form>
  )
}

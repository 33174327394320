import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspacePrebidConfigurationContext } from 'features/globalSetup/contexts/WorkspacePrebidConfigurationContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { Data, toDTO, UPDATE_WORKSPACE_PREBID_CONFIGURATION } from './api'
import { UseUpdateWorkspacePrebidConfiguration } from './types'

export const useUpdateWorkspacePrebidConfiguration = (): UseUpdateWorkspacePrebidConfiguration => {
  const { t } = useTranslation('features/globalSetup')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})
  const { setLoading } = useContext(WorkspacePrebidConfigurationContext)

  const [mutate] = useMutation(UPDATE_WORKSPACE_PREBID_CONFIGURATION, {
    onCompleted: ({ updateWorkspacePrebidConfiguration: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('prebid.defaults.action.error'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('prebid.defaults.action.success'), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({ variables: { input: toDTO(data) } })
  }

  return { errors, update }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_PREBID_MODULE_FRAGMENT } from './fragment'
import { Data } from './types'

export const GET_DOMAIN_PREBID_MODULES: TypedDocumentNode<Data> = gql`
  ${DOMAIN_PREBID_MODULE_FRAGMENT}

  query getDomainPrebidModules(
    $workspacePrebidModuleId: ID
    $moduleType: PrebidModuleTypeEnum!
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: DomainPrebidModuleSortInput
  ) {
    domainPrebidModules(
      workspacePrebidModuleId: $workspacePrebidModuleId
      moduleType: $moduleType
      first: $first
      last: $last
      terms: $terms
      sort: $sort
    ) {
      nodes {
        ...DomainPrebidModuleFragment
      }

      totalCount
    }
  }
`

import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { ApplicableDomain, applicableDomains } from 'features/globalSetup/types/filteringRule'

export const useApplicableDomainsOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/globalSetup')

  return applicableDomains.map(
    (value: ApplicableDomain): RadioGroupOption => ({
      label: t(`prebid.protection.form.applicableDomains.options.${value}`),
      value,
    }),
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const DELETE_FILTERING_RULE: TypedDocumentNode<Data, Variables> = gql`
  mutation deleteFilteringRule($id: ID!) {
    deleteFilteringRule(input: { id: $id }) {
      errors {
        attribute
        message
      }
    }
  }
`

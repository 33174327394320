import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import {
  AddEditDomainContext,
  AddEditDomainContextType,
} from 'features/inventory/domain/contexts/AddEditDomainContext'
import { GeneralFormAdd } from 'features/inventory/domain/forms/GeneralForm'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

export const AddDomain = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/domain')

  const contextProps: AddEditDomainContextType = useCommonFormContextProps({
    formId: 'domain-form-add',
  })

  return (
    <AddEditDomainContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditDomainContext.Consumer>
          {(ctxProps: AddEditDomainContextType): JSX.Element => (
            <PageHeader
              actions={<SaveButton {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.DOMAIN.LIST, { workspaceId })}
              title={t('add.title')}
            />
          )}
        </AddEditDomainContext.Consumer>
      </StickyBox>

      <GeneralFormAdd />
    </AddEditDomainContext.Provider>
  )
}

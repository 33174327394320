import { Delete } from '@mui/icons-material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useDeleteWorkspacePrebidModule } from './hooks/useDeleteWorkspacePrebidModule'
import { DeleteItemProps } from './types'

export const DeleteItem = ({ id, prebidModule }: DeleteItemProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.common.list',
  })
  const { t: tC } = useTranslation('common')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { destroy, loading } = useDeleteWorkspacePrebidModule({ prebidModule })

  const onDelete = (): void => {
    destroy(id)
    setIsDialogOpen(false)
  }

  return (
    <>
      <ListItemButton
        data-cy='actions-delete'
        icon={<Delete />}
        onClick={() => setIsDialogOpen(true)}
        text={tC('form.action.delete')}
      />

      <DeleteDialog
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        elementName={prebidModule.name}
        i18nResource={t}
        loading={loading}
        onDelete={onDelete}
      />
    </>
  )
}

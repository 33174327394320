import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_PREBID_CONFIGURATION: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspacePrebidConfiguration($workspaceId: ID!) {
    workspacePrebidConfiguration(workspaceId: $workspaceId) {
      gdprTcfTimeout
      gdprTcfEnabled
      gppControlUsNat
      gppControlUsStates
      gppTimeout
      gppEnabled
      id
      prebidTimeout
      s2sTimeoutMultiplier
      usPrivacyCcpaTimeout
      usPrivacyCcpaEnabled
    }
  }
`

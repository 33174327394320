import { Button, Stack, TextField, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { DeleteDialogProps } from './types'

export const DeleteDialog = ({
  dialog: { isOpen, setOpen },
  elementName,
  i18nResource: t,
  loading,
  onDelete,
}: DeleteDialogProps): JSX.Element => {
  const { t: tc } = useTranslation('common')
  const [confirmation, setConfirmation] = useState<string>('')

  const handleDelete = (): void => {
    onDelete()
    setOpen(false)
  }

  const Content: JSX.Element = (
    <Stack spacing={2}>
      <Typography data-cy='delete-dialog-description'>{t('delete.dialog.description')}</Typography>

      <Typography
        data-cy='delete-dialog-confirmation'
        sx={{ display: 'flex' }}
      >
        <Trans
          components={{
            bold: (
              <Typography
                component='span'
                fontWeight='bold'
                px={1}
              />
            ),
          }}
          i18nKey='delete.dialog.confirm'
          shouldUnescape
          t={t}
          values={{ elementName }}
        />
      </Typography>

      <TextField
        fullWidth
        id='confirmation'
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
          setConfirmation(e.target.value)
        }
        value={confirmation}
      />
    </Stack>
  )

  const Actions: JSX.Element = (
    <Button
      disabled={loading || confirmation !== elementName}
      onClick={handleDelete}
      variant='contained'
    >
      {tc('form.action.delete')}
    </Button>
  )

  return (
    <Dialog
      actions={Actions}
      content={Content}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t('delete.dialog.title')}
    />
  )
}

import { Row } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { generatePath } from 'react-router-dom'

import { useAuthUser } from 'features/user'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { UserActionsCell } from '../../Cell/UserActionsCell'
import { useDeleteUser } from '../../hooks/useDeleteUser'
import { WorkspaceUserForTable } from '../types'

export const ActionsCell = ({ email, id }: Row<WorkspaceUserForTable>['original']): ReactNode => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const {
    user: { id: currentUserId },
  } = useAuthUser()
  const { deleteUser, loading } = useDeleteUser({
    email,
    refetchQueries: ['getWorkspaceUsersForTable'],
  })

  const disabled = id === currentUserId || !ability.can('update', 'WorkspaceUser')

  return (
    <UserActionsCell
      disabled={disabled}
      editPath={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.USER.EDIT, { id, workspaceId })}
      email={email}
      id={id}
      loading={loading}
      onDelete={() => deleteUser(id)}
    />
  )
}

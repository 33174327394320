import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_WORKSPACE_AMAZON } from './api/query'
import { Props, UseGetWorkspaceAmazon, WorkspaceAmazonSlice } from './types'

export const useGetWorkspaceAmazon = ({ workspaceId }: Props): UseGetWorkspaceAmazon => {
  const [workspaceAmazon, setWorkspaceAmazon] = useState<null | WorkspaceAmazonSlice>()

  useEffect(() => {
    if (workspaceId) {
      query({
        variables: {
          workspaceId,
        },
      })
    }
  }, [workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_WORKSPACE_AMAZON, {
    onCompleted: ({ workspaceAmazon }) => {
      setWorkspaceAmazon(workspaceAmazon ? fromDTO(workspaceAmazon) : null)
    },
  })

  return {
    loading,
    workspaceAmazon,
  }
}

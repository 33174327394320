import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, VariablesDTO } from './types'

export const UPDATE_PRODUCT: TypedDocumentNode<Data, { input: VariablesDTO }> = gql`
  ${ERROR_FRAGMENT}

  mutation updateProduct($input: UpdateWorkspaceProductStatusInput!) {
    updateWorkspaceProductStatus(input: $input) {
      workspaceProduct {
        id
        product
        status
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FRAGMENT } from 'features/inventory/api/fragments/domain'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CREATE_DOMAIN: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation createDomain($input: CreateDomainInput!) {
    createDomain(input: $input) {
      domain {
        ...DomainFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { UserTableProps } from 'features/user/components/Table/types'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { useGetWorkspaceUsers } from './hooks/useGetWorkspaceUsers'
import { WorkspaceUserForTable } from './types'
import { useColumns } from './useColumns'

export const WorkspaceUserTable = ({ filters }: UserTableProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const columns = useColumns()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })

  const { count, loading, users } = useGetWorkspaceUsers({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const handleRowClick = ({ id }: WorkspaceUserForTable): To =>
    generatePath(ROUTES.GLOBAL_SETUP.GENERAL.USER.EDIT, { id, workspaceId })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<WorkspaceUserForTable>
      columns={columns}
      data={users}
      loading={loading}
      onClickRow={ability.can('update', 'WorkspaceUser') ? id => handleRowClick(id) : undefined}
      pagination={paginationProps}
    />
  )
}

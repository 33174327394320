import { CSSObject, PaletteColor, useTheme } from '@mui/material'
import { flexRender, HeaderContext } from '@tanstack/react-table'
import { ReactNode } from 'react'

import { FlexBox } from 'components/Box'
import { isHeaderSortable, SortArrow } from 'components/Table/Sort'
import Tooltip, { TooltipProps } from 'components/Tooltip'
import { isGroupColumn } from '../../utils'
import { UseHeader, UseHeaderProps } from './types'

export const useHeader = <T,>({ header, sort }: UseHeaderProps<T>): UseHeader<T> => {
  const {
    colSpan,
    column,
    column: {
      columnDef: { header: headerDef, meta: columnMeta },
      getIsPinned,
      getIsSorted,
      getSize: columnGetSize,
      getStart: columnGetStart,
      toggleSorting,
    },
    getContext,
    getSize: headerGetSize,
    getStart: headerGetStart,
    id,
    isPlaceholder,
  } = header

  const {
    palette: { background, grey, primary },
    zIndex: { tableHeaderPinned },
  } = useTheme()

  const context: HeaderContext<T, unknown> = getContext()
  const {
    table: {
      getHeaderGroups,
      getLeftTotalSize,
      options: { meta: tableMeta },
    },
  } = context

  const isSortable: boolean = isHeaderSortable<T>({ id, sort })
  const isDisabled = Boolean(columnMeta?.disabledHeader)
  const tooltip: TooltipProps | undefined = columnMeta?.tooltip
  const headerColor: PaletteColor | undefined = tableMeta?.headerColor
  const headerMenu: JSX.Element | undefined = columnMeta?.headerMenu
  const centered = Boolean(columnMeta?.centered)

  const isPinned: boolean = getIsPinned() === 'left'
  const isGroup: boolean = isGroupColumn(column)
  const tableHasOneHeaderRow: boolean = getHeaderGroups().length === 1
  const leftPinnedTotalSize: number = getLeftTotalSize()
  const leftStart: number = isGroup ? headerGetStart('left') : columnGetStart('left')
  const width: number = isGroup ? headerGetSize() : columnGetSize()
  const isPinnedAtEnd: boolean = isPinned && leftStart + width === leftPinnedTotalSize

  const styles: CSSObject = {
    backgroundColor: headerColor ? headerColor.main : background.default,
    borderRight: tableHasOneHeaderRow ? 'none' : '1px solid',
    // eslint-disable-next-line perfectionist/sort-objects
    '&:last-of-type': {
      borderRight: 'none',
    },
    borderColor: 'divider',
    boxShadow: isPinnedAtEnd ? '-5px 0 5px -5px grey inset' : undefined,
    color: isDisabled ? grey['500'] : primary.main,
    cursor: isSortable ? 'pointer' : 'default',
    left: isPinned ? `${leftStart}px` : undefined,
    minWidth: width,
    zIndex: isPinned ? tableHeaderPinned : 'unset',
  }

  const Content: ReactNode = isPlaceholder ? null : (
    <FlexBox
      axis='x'
      sx={{ justifyContent: centered ? 'center' : undefined }}
    >
      {flexRender(headerDef, getContext())}
      {tooltip && <Tooltip {...tooltip} />}
      {isSortable && SortArrow<T>(getIsSorted())}
      {headerMenu}
    </FlexBox>
  )

  const onSort = () => isSortable && toggleSorting()

  return {
    colSpan,
    Content,
    id,
    isSortable,
    onSort,
    styles,
  }
}

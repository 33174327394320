import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioGroup } from 'components/Form'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { EditSchema } from '../../schemas/editSchema'
import { PubAdsServiceRefreshDialog } from './components/PubAdsServiceRefreshDialog'
import { usePubAdsServiceRefreshOptions } from './hooks/usePubAdsServiceRefreshOptions'

export const PubAdsServiceRefreshInput = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const { control } = useFormContext<EditSchema>()
  const [isOpen, setIsOpen] = useState(false)

  const pubAdsServiceRefreshOptions = usePubAdsServiceRefreshOptions()

  return (
    <>
      <RadioGroup
        control={control}
        disabled={!ability.can('update', 'DomianFeature-advancedSettings')}
        label={t('form.general.advancedSettings.pubAdsServiceRefresh.label')}
        name='pubAdsServiceRefresh'
        onChange={(): void => {
          setIsOpen(true)
        }}
        options={pubAdsServiceRefreshOptions}
        tooltip={{
          content: t('form.general.advancedSettings.pubAdsServiceRefresh.tooltip'),
          link: {
            title: t('common:learnMore'),
            url: KNOWLEDGE_BASE_URL.PUB_ADS_SERVICE,
          },
        }}
      />

      <PubAdsServiceRefreshDialog dialog={{ isOpen, setOpen: setIsOpen }} />
    </>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_FRAGMENT_FOR_FINANCE_REPORT } from './fragment'
import { Data, Variables } from './types'

export const GET_FINANCE_REPORT: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_FRAGMENT_FOR_FINANCE_REPORT}

  query getFinanceReportUrl($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        ...WorkspaceFragmentForFinanceReport
      }
    }
  }
`

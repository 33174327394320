import { AdUnit } from 'features/inventory/types/adUnit'
import { Product } from 'features/product'

export const isSwitchDisabledBasedOnUnfilledRecovery = (
  unfilledRecoveryAdUnit: AdUnit['unfilledRecoveryAdUnit'],
  product: Product,
): boolean => {
  const unfilledRecoveryCondition: boolean = unfilledRecoveryAdUnit === true

  const productCondition: boolean = [
    'priceGenius',
    'unfilledRecovery',
    'viewabilityTools',
  ].includes(product)

  return unfilledRecoveryCondition && productCondition
}

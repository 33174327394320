import { AppBar, AppBarProps } from '@mui/material'

import { useAbility } from 'providers/casl'

export const AppBarStyled = ({ ...props }: AppBarProps): JSX.Element => {
  const ability = useAbility()

  return (
    <AppBar
      {...props}
      color={ability.can('have', 'EmployeeElement') ? 'cream' : 'white'}
      elevation={0}
      position='sticky'
      sx={{ zIndex: theme => theme.zIndex.appBar }}
    />
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const DELETE_SUPPLY_CHAIN: TypedDocumentNode<Data, Variables> = gql`
  mutation deleteSupplyChain($id: ID!) {
    deleteSupplyChain(input: { id: $id }) {
      errors {
        attribute
        message
      }
    }
  }
`

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../schema'
import { UPDATE_AD_UNIT_VIEWABILITY_TOOLS } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseUpdateAdUnitViewabilityTools } from './types'

export const useUpdateAdUnitViewabilityTools = (): UseUpdateAdUnitViewabilityTools => {
  const { t } = useTranslation('features/adUnit')
  const { enqueueSnackbar } = useSnackbar()
  const { adUnit, setLoading } = useContext(AddEditAdUnitContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }

  const [mutate] = useMutation(UPDATE_AD_UNIT_VIEWABILITY_TOOLS, {
    onCompleted: ({ updateAdUnitViewabilityTool: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const mapper = (data: Schema): Variables => {
    const { adHolding, lazyLoading, scrollSlowing, ...rest } = data

    const {
      domain: {
        domainViewabilityTools: { adHoldingEnabled, lazyLoadingEnabled, scrollSlowingEnabled },
      },
    } = adUnit

    const result: Variables = { ...rest }

    if (adHoldingEnabled) result.adHolding = adHolding
    if (lazyLoadingEnabled) result.lazyLoading = lazyLoading
    if (scrollSlowingEnabled) result.scrollSlowing = scrollSlowing

    return result
  }

  const update = (data: Schema) => {
    setLoading(true)

    return mutate({
      variables: {
        input: mapper(data),
      },
    })
  }

  return { errors, update }
}

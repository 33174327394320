import { Grid, Typography } from '@mui/material'

import ProductCard from 'features/product/components/ProductCard'
import { WorkspaceProduct } from 'features/product/types/workspaceProduct'
import { ProductListProps } from './types'

export const ProductList = ({ products, title }: ProductListProps): JSX.Element => (
  <>
    <Typography variant='h4'>{title}</Typography>

    <Grid container>
      {products.map((workspaceProduct: WorkspaceProduct): JSX.Element => {
        const { id } = workspaceProduct

        return (
          <Grid
            item
            key={id}
            sx={{ p: 1 }}
            xs={6}
          >
            <ProductCard workspaceProduct={workspaceProduct} />
          </Grid>
        )
      })}
    </Grid>
  </>
)

import { useContext } from 'react'

import { Form } from 'components/Form'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { CommonForm } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/CommonForm'
import { useCreateSupplyChain } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/hooks/useCreateSupplyChain'
import {
  AddSchema,
  addSchemaDefault,
} from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/schemas/addSchema'
import { commonSchema } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/schemas/commonSchema'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'

export const AddSupplyChainForm = () => {
  const { workspaceId } = useWorkspaceParam()
  const { create } = useCreateSupplyChain({ workspaceId })
  const { formId, setTouched, setValid } = useContext(SupplyChainContext)

  return (
    <Form<AddSchema>
      id={formId}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => <CommonForm />}
    </Form>
  )
}

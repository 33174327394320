import { gql, TypedDocumentNode } from '@apollo/client'

import { AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_AD_UNITS_FOR_PAGE_TRANSFER_LIST: TypedDocumentNode<Data, Variables> = gql`
  ${AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT}

  query adUnitsForPageTransferList($domainId: ID!) {
    node(id: $domainId) {
      ... on Domain {
        adUnits {
          ...AdUnitForPageTransferList
        }
      }
    }
  }
`

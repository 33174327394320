import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { SortingColumn } from '@tanstack/react-table'

export const SortArrow = <T,>(
  isSorted: ReturnType<SortingColumn<T>['getIsSorted']>,
): JSX.Element => {
  switch (isSorted) {
    case 'asc': {
      return <ArrowDropUp />
    }
    case 'desc': {
      return <ArrowDropDown />
    }
    default: {
      return <></>
    }
  }
}

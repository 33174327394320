import { useTranslation } from 'react-i18next'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import {
  ProtectionContext,
  ProtectionContextType,
} from 'features/globalSetup/contexts/ProtectionContext'
import { AddFilteringRuleForm } from 'features/globalSetup/forms/Prebid/Protection/AddFilteringRuleForm'

export const ProtectionAddPage = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])

  const contextProps: ProtectionContextType = useCommonFormContextProps({
    formId: 'prebid-protection-form-add',
  })

  return (
    <ProtectionContext.Provider value={contextProps}>
      <ProtectionContext.Consumer>
        {(ctxProps: ProtectionContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            description={{
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE.URL_FILTERING,
              },
              text: t('prebid.protection.form.header.description'),
            }}
            title={t('prebid.protection.form.header.title')}
          />
        )}
      </ProtectionContext.Consumer>

      <AddFilteringRuleForm />
    </ProtectionContext.Provider>
  )
}

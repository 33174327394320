import { SxProps, Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch } from 'components/Form'
import { useAbility } from 'providers/casl'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { ConsentManagementSchema } from './schema'
import { ConsentManagementCommonFieldsProps } from './types'
import { useTooltips } from './useTooltips'

export const ConsentManagementCommonFields = <T extends ConsentManagementSchema>({
  control,
  sx: sxProps,
  watch,
}: ConsentManagementCommonFieldsProps<T>): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.consentManagement',
  })
  const tooltips = useTooltips()
  const ability = useAbility()
  const isDisabled = ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')
  const { gdprTcfEnabled, gppEnabled, usPrivacyCcpaEnabled } = watch()

  const sx: SxProps<Theme> = {
    ml: CONTAINER_SPACING,
  }

  return (
    <BoxForm
      data-cy='consent-management-common-fields'
      sx={sxProps}
    >
      <Switch
        control={control}
        disabled={isDisabled}
        label={t('gdprTcfEnabled.label')}
        labelPlacement='start'
        name='gdprTcfEnabled'
        tooltip={tooltips.gdprTcfEnabled}
      />

      {gdprTcfEnabled && (
        <NumberField
          control={control}
          disabled={isDisabled}
          label={t('gdprTcfTimeout.label')}
          name='gdprTcfTimeout'
          sx={sx}
        />
      )}

      <Switch
        control={control}
        disabled={isDisabled}
        label={t('usPrivacyCcpaEnabled.label')}
        labelPlacement='start'
        name='usPrivacyCcpaEnabled'
        tooltip={tooltips.usPrivacyCcpaEnabled}
      />

      {usPrivacyCcpaEnabled && (
        <NumberField
          control={control}
          disabled={isDisabled}
          label={t('usPrivacyCcpaTimeout.label')}
          name='usPrivacyCcpaTimeout'
          sx={sx}
        />
      )}

      <Switch
        control={control}
        disabled={isDisabled}
        label={t('gppEnabled.label')}
        labelPlacement='start'
        name='gppEnabled'
        tooltip={tooltips.gppEnabled}
      />

      {gppEnabled && (
        <BoxForm sx={sx}>
          <NumberField
            control={control}
            disabled={isDisabled}
            label={t('gppTimeout.label')}
            name='gppTimeout'
          />

          <Switch
            control={control}
            disabled={isDisabled}
            label={t('gppControlUsNat.label')}
            labelPlacement='start'
            name='gppControlUsNat'
            tooltip={tooltips.gppControlUsNat}
          />

          <Switch
            control={control}
            disabled={isDisabled}
            label={t('gppControlUsStates.label')}
            labelPlacement='start'
            name='gppControlUsStates'
            tooltip={tooltips.gppControlUsStates}
          />
        </BoxForm>
      )}
    </BoxForm>
  )
}

import { generatePath, Navigate } from 'react-router-dom'

import { useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'
import { ROUTES } from 'routes'

export const RootRedirection = (): JSX.Element => {
  const {
    helpers: { isEmployee },
    user: { currentWorkspace },
  } = useAuthUser()

  const pathWithCurrentWorkspace = (workspace: Workspace): string =>
    generatePath(ROUTES.PRODUCT.LIST, { workspaceId: workspace.id })

  let path: string | undefined = undefined

  if (isEmployee) {
    if (currentWorkspace) {
      path = pathWithCurrentWorkspace(currentWorkspace)
    } else {
      path = ROUTES.WORKSPACE.LIST
    }
  } else if (currentWorkspace) {
    path = pathWithCurrentWorkspace(currentWorkspace)
  }

  if (path === undefined) {
    throw Error('Root redirection failed')
  }

  return <Navigate to={path} />
}

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  RelatedList,
  RelatedListItem,
  RelatedSettings as RelatedSettingsRoot,
} from 'components/RelatedSettings'
import { ROUTES } from 'routes'
import { RelatedSettingsProps } from './types'

export const RelatedSettings = ({
  domain: {
    customFailsafeTimeoutEnabled,
    failsafeTimeout: customFailsafeTimeout,
    id,
    workspace: { failsafeTimeout, id: workspaceId },
  },
  workspacePrebidConfiguration: { prebidTimeout },
}: RelatedSettingsProps): JSX.Element => {
  const { t: tGlobal } = useTranslation('features/globalSetup')
  const { t: tDomain } = useTranslation('features/domain')

  const FailsafeTimeoutItem = (): JSX.Element => {
    if (customFailsafeTimeoutEnabled) {
      return (
        <RelatedListItem
          content={tDomain('relatedSettings.customFailsafeTimeout')}
          url={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
            id,
            tab: 'general',
            workspaceId,
          })}
          value={customFailsafeTimeout}
        />
      )
    } else {
      return (
        <RelatedListItem
          content={tGlobal('relatedSettings.failsafeTimeout')}
          url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
            tab: 'workspace',
            workspaceId,
          })}
          value={failsafeTimeout}
        />
      )
    }
  }

  return (
    <RelatedSettingsRoot>
      <RelatedList>
        <FailsafeTimeoutItem />
        <RelatedListItem
          content={tGlobal('relatedSettings.prebidTimeout')}
          url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, {
            workspaceId,
          })}
          value={prebidTimeout}
        />
      </RelatedList>
    </RelatedSettingsRoot>
  )
}

import PrebidStackLogo from 'images/logos/prebid-stack.svg?react'
import PriceGeniusLogo from 'images/logos/price-genius.svg?react'
import RefresherLogo from 'images/logos/refresher.svg?react'
import UnfilledRecoveryLogo from 'images/logos/unfilled-recovery.svg?react'
import ViewabilityToolsLogo from 'images/logos/viewability-tools.svg?react'
import { SvgElement } from 'types/image'
import { Product } from '..'

type ProductsLogos = Record<Product, SvgElement>

export const productsLogos: ProductsLogos = {
  prebidStack: PrebidStackLogo,
  priceGenius: PriceGeniusLogo,
  refresher: RefresherLogo,
  unfilledRecovery: UnfilledRecoveryLogo,
  viewabilityTools: ViewabilityToolsLogo,
} as const

import { z } from 'zod'

import { commonSchema } from './common'

export type AddSchema = z.infer<typeof commonSchema>

export const addSchema = commonSchema
export const addSchemaDefault: AddSchema = {
  email: '',
  role: 'admin',
}

import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { AddOwnSchema } from '../../schemas/addSchema'
import { useOnCompleted } from '../useOnCompleted'
import { toDTO } from './api/mapper'
import { CREATE_OWN_WORKSPACE_BIDDER } from './api/mutation'
import { UseCreateOwnWorkspaceBidder } from './types'

export const useCreateOwnWorkspaceBidder = (): UseCreateOwnWorkspaceBidder => {
  const { workspaceId } = useWorkspaceParam()
  const { onCompleted } = useOnCompleted()
  const [errors, setErrors] = useState<FieldErrors<AddOwnSchema>>({})

  const [mutate] = useMutation(CREATE_OWN_WORKSPACE_BIDDER)

  const createOwn = async (data: AddOwnSchema): Promise<void> => {
    const variables = { input: toDTO({ ...data, workspaceId }) }
    const { data: response } = await mutate({ variables })

    if (response) {
      const { bidderCode } = data

      setErrors(onCompleted(bidderCode, response))
    }
  }

  return { createOwn, ownErrors: errors }
}

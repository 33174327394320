import { useContext } from 'react'

import { Form } from 'components/Form'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { useUpdatePrebidModuleDomains } from './hooks/useUpdatePrebidModuleDomains'
import { schema, Schema, schemaDefaults } from './schema'
import { InventoryConnectionFormProps } from './types'

export const InventoryConnectionForm = ({ children }: InventoryConnectionFormProps) => {
  const { formId, setTouched, setValid } = useContext(PrebidModulesContext)
  const { errors: apiErrors, update } = useUpdatePrebidModuleDomains()

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefaults()}
    >
      {() => children}
    </Form>
  )
}

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import ErrorPage from 'providers/router/components/ErrorPage'

export const ErrorBox = (): JSX.Element => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })

  return (
    <Box p={CONTAINER_SPACING}>
      <ErrorPage
        cause={t('asterioBid.error.connection')}
        variant='full-container'
      />
    </Box>
  )
}

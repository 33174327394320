import { enqueueSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { UseSnackbar } from './types'

export const useSnackbar = ({ path, product }: UseSnackbar) => {
  const { t } = useTranslation('features/adUnit')
  const { t: tProduct } = useTranslation('features/product')

  const snackbar = (enabled: boolean, variant?: VariantType): void => {
    const tParams = {
      action: enabled ? 'enabled' : 'disabled',
      interpolation: { escapeValue: false },
      name: path,
      product: tProduct(`product.${product}`),
    }

    if (variant === 'error') {
      enqueueSnackbar(t('list.product.switch.error', tParams), { variant })
    } else {
      enqueueSnackbar(t('list.product.switch.success', tParams), {
        variant: enabled ? 'success' : 'warning',
      })
    }
  }

  return { snackbar }
}

import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getTokenCookies } from 'features/user'

export const authLink: ApolloLink = setContext((_, { headers }) => {
  const { accessToken } = getTokenCookies()

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }
})

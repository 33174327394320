import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { EditSchema } from 'features/globalSetup/pages/SupplyChainList/forms/SupplyChainForm/schemas/editSchema'
import { mapGraphQLErrors } from 'providers/graphql'
import { UPDATE_SUPPLY_CHAIN } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateSupplyChain, UseUpdateSupplyChainProps } from './types'

export const useUpdateSupplyChain = ({ id }: UseUpdateSupplyChainProps): UseUpdateSupplyChain => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.general.actions.update',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(SupplyChainContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutate] = useMutation(UPDATE_SUPPLY_CHAIN, {
    onCompleted: ({ updateSupplyChain: { errors, supplyChain } }) => {
      setLoading(false)

      const { name } = supplyChain
      const tParams = {
        action: 'updated',
        supplyChain: name,
      } as const

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('error', tParams), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('success', tParams), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = ({ asi, defaultSid, name }: EditSchema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({
      variables: {
        asi,
        defaultSid,
        id,
        name,
      },
    })
  }

  return { errors, update }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const DOWNLOAD_TAGS: TypedDocumentNode<Data, Variables> = gql`
  query downloadTags($domainId: ID!, $tagsType: TagsTypeEnum) {
    downloadTags(domainId: $domainId, tagsType: $tagsType) {
      data
      filename
      type
    }
  }
`

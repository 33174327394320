import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FOR_FILTERING_RULE } from './fragment'
import { Data, Variables } from './types'

export const GET_DOMAINS_FOR_FILTERING_RULE: TypedDocumentNode<Data, Variables> = gql`
  ${DOMAIN_FOR_FILTERING_RULE}

  query getDomainsForFilteringRule($workspaceId: ID!) {
    domains(workspaceId: $workspaceId) {
      nodes {
        ...DomainFragmentForFilteringRule
      }

      totalCount
    }
  }
`

import { RouteObject } from 'react-router-dom'

import { adUnitRoutes } from './adUnitRoutes'
import { domainRoutes } from './domainRoutes'
import { pageRoutes } from './pageRoutes'
import { viewportRoutes } from './viewportRoutes'

export const inventoryRoutes: RouteObject[] = [
  ...domainRoutes,
  ...pageRoutes,
  ...adUnitRoutes,
  ...viewportRoutes,
]

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceUserForTable } from '../../../types'
import { WorkspaceUserForTableDTO } from './types'

export const fromDTO = (from: WorkspaceUserForTableDTO): WorkspaceUserForTable =>
  mapDTO<WorkspaceUserForTableDTO, WorkspaceUserForTable>({ from }).transform(dto => {
    const { currentWorkspaceRole, email, firstName, id, lastName, status, updatedAt } = dto

    return {
      email,
      id,
      role: currentWorkspaceRole || undefined,
      status,
      updatedAt: new Date(updatedAt),
      username: `${firstName} ${lastName}`,
    }
  })

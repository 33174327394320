import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddSchema } from './schemas/addSchema'

export const CommonForm = (): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { control } = useFormContext<AddSchema>()

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('form.general.basicAttributes.subtitle')}
        title={t('form.general.basicAttributes.title')}
      />

      <BoxForm>
        <TextField
          control={control}
          label={t('form.general.basicAttributes.name.label')}
          name='name'
          placeholder={t('form.general.basicAttributes.name.placeholder')}
        />
      </BoxForm>
    </Paper>
  )
}

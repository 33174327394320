import { z } from 'zod'

import { userGroupRoles } from 'features/user/types/UserGroupRoles'

export const commonSchema = z.object({
  email: z.string().email(),
  role: z.enum(userGroupRoles.employeesOnly),
})

export type CommonSchema = z.infer<typeof commonSchema>

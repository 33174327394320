import { FetchResult, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { WorkspaceUserContext } from 'features/globalSetup/contexts/WorkspaceUserContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { mapGraphQLErrors } from 'providers/graphql'
import { ROUTES } from 'routes'
import { Schema } from '../../schema'
import { CREATE_WORKSPACE_USER } from './api/mutation'
import { Data } from './api/types'
import { UseCreateWorkspaceUser } from './types'

export const useCreateWorkspaceUser = (): UseCreateWorkspaceUser => {
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()
  const { t } = useTranslation('features/globalSetup')
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})
  const { setLoading } = useContext(WorkspaceUserContext)

  const [mutate] = useMutation(CREATE_WORKSPACE_USER, {
    onCompleted: ({ createPublisherByWorkspaceOwner: { errors, user } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('general.userManagement.add.action.error'), { variant: 'error' })
      } else if (user) {
        setErrors({})
        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
            tab: 'user-management',
            workspaceId,
          }),
        )
        enqueueSnackbar(t('general.userManagement.add.action.success'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const create = (data: Schema): Promise<FetchResult<Data>> => {
    setLoading(true)

    return mutate({ variables: { input: data } })
  }

  return { create, errors }
}

import { Search } from '@mui/icons-material'
import { Box, Button, Card, Divider, InputBase, Stack } from '@mui/material'
import _ from 'lodash'
import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { TransferListContext } from './contexts/TransferListContext'
import CustomList from './CustomList'
import { useFilteredList } from './hooks/useFilteredList'
import { useTransferList } from './hooks/useTransferList'
import { TransferListItem, TransferListProps } from './types'

export const TransferList = ({ leftList, onChange, rightList }: TransferListProps): JSX.Element => {
  const { t } = useTranslation('components')

  const { left, leftChecked, moveLeft, moveRight, right, rightChecked, ...contextProps } =
    useTransferList({ leftList, rightList })

  const { filter, filteredLeft, filteredRight, setFilter } = useFilteredList({
    leftList: left,
    rightList: right,
  })

  useEffect(() => {
    onChange(right.map(({ value }: TransferListItem): TransferListItem['value'] => value).sort())
  }, [filteredRight]) // eslint-disable-line react-hooks/exhaustive-deps

  const ButtonGroup = (): JSX.Element => (
    <FlexBox
      axis='xy'
      sx={{
        gap: 1,
        px: 1,
      }}
    >
      <Button
        data-cy='transfer-list-left-to-right-button'
        disabled={_.isEmpty(leftChecked)}
        onClick={moveRight}
        variant='outlined'
      >
        {t('transferList.button.left')}
      </Button>

      <Button
        data-cy='transfer-list-right-to-left-button'
        disabled={_.isEmpty(rightChecked)}
        onClick={moveLeft}
        variant='outlined'
      >
        {t('transferList.button.right')}
      </Button>
    </FlexBox>
  )

  return (
    <Box sx={{ width: '900px' }}>
      <Card>
        <InputBase
          data-cy='transfer-list-filter'
          endAdornment={<Search />}
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>): void => setFilter(event.target.value)}
          placeholder={t('transferList.filter')}
          sx={{ p: 2 }}
          value={filter}
        />

        <Divider />

        <Stack direction='row'>
          <TransferListContext.Provider value={contextProps}>
            <CustomList
              items={filteredLeft}
              title={t('transferList.title.left')}
            />

            <ButtonGroup />

            <CustomList
              items={filteredRight}
              title={t('transferList.title.right')}
            />
          </TransferListContext.Provider>
        </Stack>
      </Card>
    </Box>
  )
}

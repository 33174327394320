import { z } from 'zod'

import { WorkspaceSlice } from '../hooks/useGetWorkspace'
import { commonSchema } from './common'

export const editSchema = z
  .object({
    id: z.string(),
  })
  .merge(commonSchema)

export type EditSchema = z.infer<typeof editSchema>

export const editSchemaDefault = (workspaceSlice: WorkspaceSlice): EditSchema => {
  const { csManager } = workspaceSlice

  return {
    ...workspaceSlice,
    csManagerId: csManager?.id ?? '',
  }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACES_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_FOR_TABLE_FRAGMENT}

  query workspacesForTable($first: Int, $last: Int, $terms: [String!], $sort: WorkspaceSortInput) {
    workspaces(first: $first, last: $last, terms: $terms, sort: $sort) {
      nodes {
        ...WorkspaceForTableFragment
      }
      totalCount
    }
  }
`

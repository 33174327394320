import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { CSManagerDTO } from 'features/workspace/api/types/workspaceDTO'
import { CSManager } from 'features/workspace/types/csManager'
import { GET_CS_MANAGERS_FOR_SELECT } from './api/query'
import { UseGetCSManagersForSelect } from './types'

export const useGetCSManagersForSelect = (): UseGetCSManagersForSelect => {
  const [csManagers, setCSManagers] = useState<SelectOption<CSManager>[]>([])

  useQuery(GET_CS_MANAGERS_FOR_SELECT, {
    onCompleted: ({ customerSuccessUsers: { nodes: csManagers } }): void => {
      if (csManagers && csManagers.length > 0) {
        setCSManagers(
          csManagers.map((csm: CSManagerDTO): SelectOption<CSManager> => {
            const { firstName, id, lastName } = csm

            return {
              label: `${firstName} ${lastName}`,
              value: id,
            }
          }),
        )
      }
    },
  })

  return { csManagers }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_FORM_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACE_FOR_FORM: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_FORM_FRAGMENT}

  query getWorkspaceForForm($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        ...WorkspaceFormFragment
      }
    }
  }
`

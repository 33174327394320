import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { WORKSPACE_AMAZON_FRAGMENT_FOR_UPSERT } from './fragment'
import { Data, Variables } from './types'

export const UPSERT_WORKSPACE_AMAZON: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${WORKSPACE_AMAZON_FRAGMENT_FOR_UPSERT}
  ${ERROR_FRAGMENT}

  mutation upsertWorkspaceAmazon($input: UpsertWorkspaceAmazonInput!) {
    upsertWorkspaceAmazon(input: $input) {
      workspaceAmazon {
        ...WorkspaceAmazonFragmentForUpsert
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

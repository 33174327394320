import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { CheckboxListOption } from 'components/Form/CheckboxListFilterable'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { domainsFromDTO, DomainSliceDTO, GET_DOMAINS_FOR_FILTERING_RULE } from './api'
import { UseGetDomainsOptions } from './types'

export const useGetDomainsOptions = (): UseGetDomainsOptions => {
  const { workspaceId } = useWorkspaceParam()
  const [domainsOptions, setDomainsOptions] = useState<CheckboxListOption[]>([])

  const { loading } = useQuery(GET_DOMAINS_FOR_FILTERING_RULE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ domains: { nodes: domains } }): void => {
      if (domains) {
        setDomainsOptions(
          domains.map((domain: DomainSliceDTO): CheckboxListOption => domainsFromDTO(domain)),
        )
      }
    },

    variables: {
      workspaceId,
    },
  })

  return { domainsOptions, loading }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { AD_UNIT_CREATE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const CREATE_AD_UNIT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${AD_UNIT_CREATE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation createAdUnit($input: CreateAdUnitInput!) {
    createAdUnit(input: $input) {
      adUnit {
        ...AdUnitCreateFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { FILTERING_RULE_FRAGMENT_FOR_CREATE } from './fragment'
import { Data, Variables } from './types'

export const CREATE_FILTERING_RULE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${FILTERING_RULE_FRAGMENT_FOR_CREATE}
  ${ERROR_FRAGMENT}

  mutation createFilteringRule($input: CreateFilteringRuleInput!) {
    createFilteringRule(input: $input) {
      filteringRule {
        ...FilteringRuleFragmentForCreate
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useContext } from 'react'
import { generatePath, To } from 'react-router-dom'

import { Table, useFilteredData } from 'components/Table'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { PrebidStackFormContext } from 'features/inventory/domain/forms/PrebidStackForm/context/PrebidStackFormContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { BiddersTableProps } from './types'
import { useColumns } from './useColumns'

export const BiddersTable = ({ filters }: BiddersTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { domainBidders } = useContext(PrebidStackFormContext)
  const columns = useColumns()
  const data = useFilteredData<DomainBidderSlice>({
    data: domainBidders,
    filterBy: ['code', 'name'],
    filters,
  })

  if (!domainBidders) {
    throw new Error('Domain bidders are required')
  }

  const handleRowClick = ({ workspaceBidderId }: DomainBidderSlice): To =>
    generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
      id: workspaceBidderId,
      tab: 'general',
      workspaceId,
    })

  return (
    <Table<DomainBidderSlice>
      columns={columns}
      data={data}
      data-cy='domain-bidders-table'
      onClickRow={handleRowClick}
    />
  )
}

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { RelatedList, RelatedListItem, RelatedSettings } from 'components/RelatedSettings'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { WorkspaceDefaultsProps } from './types'

export const WorkspaceDefaults = ({
  workspacePrebidConfiguration: {
    gdprTcfEnabled,
    gdprTcfTimeout,
    gppControlUsNat,
    gppControlUsStates,
    gppEnabled,
    gppTimeout,
    usPrivacyCcpaEnabled,
    usPrivacyCcpaTimeout,
  },
}: WorkspaceDefaultsProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'relatedSettings.consentManagement',
  })
  const { workspaceId } = useWorkspaceParam()

  const GdprTcFTimeout = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('gdprTcfEnabled')}
        url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, { workspaceId })}
        value={t(`settingEnabled.${gdprTcfEnabled}`)}
      />

      <RelatedList>
        <RelatedListItem
          content={t('gdprTcfTimeout')}
          value={gdprTcfTimeout}
        />
      </RelatedList>
    </RelatedList>
  )

  const UsPrivacyCcpaTimeoutEnabled = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('usPrivacyCcpaEnabled')}
        value={t(`settingEnabled.${usPrivacyCcpaEnabled}`)}
      />

      <RelatedList>
        <RelatedListItem
          content={t('usPrivacyCcpaTimeout')}
          value={usPrivacyCcpaTimeout}
        />
      </RelatedList>
    </RelatedList>
  )

  const GppTimeoutEnabled = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('gppEnabled')}
        value={t(`settingEnabled.${gppEnabled}`)}
      />

      <RelatedList>
        <RelatedListItem
          content={t('gppTimeout')}
          value={gppTimeout}
        />
        <RelatedListItem
          content={t('gppControlUsNat')}
          value={t(`settingEnabled.${gppControlUsNat}`)}
        />
        <RelatedListItem
          content={t('gppControlUsStates')}
          value={t(`settingEnabled.${gppControlUsStates}`)}
        />
      </RelatedList>
    </RelatedList>
  )

  return (
    <RelatedSettings title={t('title')}>
      <GdprTcFTimeout />
      <UsPrivacyCcpaTimeoutEnabled />
      <GppTimeoutEnabled />
    </RelatedSettings>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitBidderDTO } from 'features/globalSetup/api/types/adUnitBidderDTO'

export const AD_UNIT_BIDDER_FRAGMENT: TypedDocumentNode<AdUnitBidderDTO> = gql`
  fragment AdUnitBidderFragment on AdUnitBidder {
    id
    adUnit {
      id
      divId
      path
    }
    csEnabled
    csParams
    s2sEnabled
    s2sParams
    updatedAt
  }
`

import _ from 'lodash'
import { useState } from 'react'

import { TransferListItem } from '../types'
import { UseTransferList, UseTransferListProps } from './types'

export const useTransferList = ({ leftList, rightList }: UseTransferListProps): UseTransferList => {
  const [checked, setChecked] = useState<TransferListItem[]>([])
  const [left, setLeft] = useState<TransferListItem[]>(leftList)
  const [right, setRight] = useState<TransferListItem[]>(rightList)

  const ITERATEE = 'value'

  const numberOfChecked = (items: TransferListItem[]): number =>
    _.intersectionBy(checked, items, ITERATEE).length

  const areAllChecked = (items: TransferListItem[]): boolean =>
    numberOfChecked(items) === items.length && items.length > 0

  const indeterminateChecked = (items: TransferListItem[]): boolean =>
    numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0

  const select = (item: TransferListItem) => (): void => {
    const tempChecked = [...checked]
    const shouldRemove = tempChecked.some(
      (checkedItem: TransferListItem): boolean => checkedItem.value === item.value,
    )

    if (shouldRemove) {
      _.remove(tempChecked, item)
    } else {
      tempChecked.push(item)
    }

    setChecked(tempChecked)
  }

  const selectAll = (items: TransferListItem[]) => (): void => {
    if (numberOfChecked(items) === items.length) {
      setChecked(_.differenceBy(checked, items, ITERATEE))
    } else {
      setChecked(_.unionBy(checked, items, ITERATEE))
    }
  }

  const leftChecked: TransferListItem[] = _.intersectionBy(checked, left, ITERATEE)

  const moveLeft = (): void => {
    setLeft(left.concat(rightChecked))
    setRight(_.differenceBy(right, rightChecked, ITERATEE))
    setChecked(_.differenceBy(checked, rightChecked, ITERATEE))
  }

  const rightChecked: TransferListItem[] = _.intersectionBy(checked, right, ITERATEE)

  const moveRight = (): void => {
    setRight(right.concat(leftChecked))
    setLeft(_.differenceBy(left, leftChecked, ITERATEE))
    setChecked(_.differenceBy(checked, leftChecked, ITERATEE))
  }

  return {
    areAllChecked,
    checked,
    indeterminateChecked,
    left,
    leftChecked,
    moveLeft,
    moveRight,
    numberOfChecked,
    right,
    rightChecked,
    select,
    selectAll,
  }
}

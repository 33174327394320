import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import {
  InventoryConnectionContext,
  InventoryConnectionContextType,
} from 'features/globalSetup/forms/Prebid/Modules/contexts/InventoryConnectionContext'
import {
  DomainsTable,
  InventoryConnectionForm,
} from 'features/globalSetup/forms/Prebid/Modules/InventoryConnectionForm'

export const InventoryConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.edit.inventoryConnection',
  })
  const [filters, setFilters] = useState<FilterValue[]>([])
  const UserIdDomainsTableFilter = (
    <Filter
      id='userIdDomainsTableFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )
  const contextData: InventoryConnectionContextType = {
    moduleType: 'userId',
  }

  return (
    <Paper>
      <TableHeader
        bottomSpacing
        description={t('header.subtitle')}
        filter={UserIdDomainsTableFilter}
        title={t('header.title')}
      />

      <InventoryConnectionContext.Provider value={contextData}>
        <InventoryConnectionForm>
          <DomainsTable filters={filters} />
        </InventoryConnectionForm>
      </InventoryConnectionContext.Provider>
    </Paper>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { PAGE_CREATE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const CREATE_PAGE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${PAGE_CREATE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation createPage($input: CreatePageInput!) {
    createPage(input: $input) {
      page {
        ...PageCreateFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

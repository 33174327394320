import { z } from 'zod'

export const commonSchema = z.object({
  crmId: z.string(),
  csManagerId: z.string().optional(),
  name: z.string(),
  networkCode: z.string(),
  pipedriveLink: z.string(),
})

export type CommonSchema = z.infer<typeof commonSchema>

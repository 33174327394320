import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { BoxForm, NumberField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { RelatedList, RelatedListItem, RelatedSettings } from 'components/RelatedSettings'
import { useGetWorkspacePrebidConfiguration } from 'features/globalSetup'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { URL } from '../../constants'
import { Schema } from '../../schema'

export const FailsafeTimeout = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { workspacePrebidConfiguration } = useGetWorkspacePrebidConfiguration({
    workspaceId,
  })
  const { control } = useFormContext<Schema>()

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('general.workspace.failsafeTimeout.description')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: URL.FAILSAFE_TIMEOUT,
            }}
          />
        }
        title={t('general.workspace.failsafeTimeout.title')}
      />

      <Stack
        alignItems='flex-start'
        direction='row'
        spacing={2}
      >
        <BoxForm>
          <NumberField
            control={control}
            label={t('general.workspace.form.failsafeTimeout.label')}
            name='failsafeTimeout'
            tooltip={{
              content: [
                t('general.workspace.form.failsafeTimeout.tooltip.line1'),
                t('general.workspace.form.failsafeTimeout.tooltip.line2'),
                t('general.workspace.form.failsafeTimeout.tooltip.line3'),
              ],
              link: {
                title: t('common:learnMore'),
                url: URL.FAILSAFE_TIMEOUT,
              },
            }}
          />
        </BoxForm>

        {ability.can('access', 'Product-prebidStack') && workspacePrebidConfiguration && (
          <RelatedSettings>
            <RelatedList>
              <RelatedListItem
                content={t('relatedSettings.prebidTimeout')}
                url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, {
                  workspaceId,
                })}
                value={workspacePrebidConfiguration.prebidTimeout}
              />
            </RelatedList>
          </RelatedSettings>
        )}
      </Stack>
    </Paper>
  )
}

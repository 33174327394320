import { DomainBidderSchemaDTO } from 'features/globalSetup/api/types/domainBidderDTO'
import { DomainBidderParameter } from 'features/globalSetup/types/domainBidder'
import { isPrimitive } from 'utils/primitive'

export const mapParametersSchema = (
  parametersSchema: DomainBidderSchemaDTO[],
): DomainBidderParameter[] =>
  parametersSchema.map((parameterDTO: DomainBidderSchemaDTO): DomainBidderParameter => {
    const { tooltip, type } = parameterDTO

    if (!isPrimitive(type)) {
      throw Error('Type of input does not match to primitive types')
    }

    return {
      ...parameterDTO,
      tooltip: tooltip ? { content: tooltip } : undefined,
      type,
    }
  })

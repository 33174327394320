import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const REQUEST_WORKSPACE_PRODUCT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation requestProduct($input: RequestProductInput!) {
    requestProduct(input: $input) {
      workspaceProduct {
        id
        product
        status
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`

import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import { ROUTES } from 'routes'
import {
  UserManagementFormContext,
  UserManagementFormContextType,
} from '../../contexts/UserManagementFormContext'
import { UserEditEmployeeForm } from '../../forms/UserEmployeeForm'
import { useGetEmployee } from './hooks/useGetEmployee'
import { UserEditEmployeePageParams } from './types'

export const UserEditEmployeePage = (): JSX.Element => {
  const { id } = useParams<UserEditEmployeePageParams>()
  const { t } = useTranslation('features/user')
  const { user } = useGetEmployee({ id })

  const contextProps: UserManagementFormContextType = useCommonFormContextProps({
    formId: 'employee-form-edit',
  })

  if (!id) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!user) {
    return <Loading />
  }

  return (
    <UserManagementFormContext.Provider value={contextProps}>
      <UserManagementFormContext.Consumer>
        {(ctxProps: UserManagementFormContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.USER.LIST, { tab: 'employees' })}
            title={t('form.employee.edit.title', { email: user.email })}
          />
        )}
      </UserManagementFormContext.Consumer>

      <UserEditEmployeeForm user={user} />
    </UserManagementFormContext.Provider>
  )
}

import { SortInput } from 'components/Table'
import { WorkspaceBidderForTableSortBy } from './hooks/useGetBidders/types'

export const DEFAULT_SORT: SortInput<WorkspaceBidderForTableSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: WorkspaceBidderForTableSortBy[] = [
  'code',
  'name',
  'sid',
  'updatedAt',
]

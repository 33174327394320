import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { binaryStringToBlob, downloadFile, XLSX_MIME_TYPE } from 'utils/file'
import { DOWNLOAD_AD_UNITS_CONFIGURATION } from './api/mutation'
import { UseDownloadAdUnitsConfiguration, UseDownloadAdUnitsConfigurationProps } from './types'

export const useDownloadAdUnitsConfiguration = ({
  domainName,
}: UseDownloadAdUnitsConfigurationProps): UseDownloadAdUnitsConfiguration => {
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()

  const FILENAME: string = `${domainName} - Ad units configuration.xlsx`

  const [mutate, { loading }] = useMutation(DOWNLOAD_AD_UNITS_CONFIGURATION, {
    onCompleted: ({ downloadAdUnitsXls: { errors, fileData } }): void => {
      if (errors && errors.length > 0) {
        enqueueSnackbar(t('form.general.bulkManagement.download.error', { domainName }), {
          variant: 'error',
        })
      } else if (fileData) {
        const decodedBase64: string = atob(fileData)
        const arrayBuffer: Uint8Array = binaryStringToBlob(decodedBase64)
        const blob = new Blob([arrayBuffer], { type: XLSX_MIME_TYPE })

        downloadFile(blob, FILENAME)
      }
    },
  })

  const download: UseDownloadAdUnitsConfiguration['download'] = id =>
    mutate({ variables: { input: { id } } })

  return { download, loading }
}

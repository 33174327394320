import { gql, TypedDocumentNode } from '@apollo/client'

import { PrebidModuleDTO } from 'features/globalSetup/api/types/prebidModule'

export const PREBID_MODULE_PARAMETERS: TypedDocumentNode<PrebidModuleDTO> = gql`
  fragment PrebidModuleParameter on PrebidModuleParamsSchema {
    label
    name
    required
    tooltip
    type
  }
`

import { SortInput } from 'components/Table'
import { WorkspacePrebidModuleSortBy } from 'features/globalSetup/types/workspacePrebidModule'

export const DEFAULT_SORT: SortInput<WorkspacePrebidModuleSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: WorkspacePrebidModuleSortBy[] = [
  'prebidModule.name',
  'prebidModule.account',
  'enabled',
  'updatedAt',
]

import { Button } from '@mui/material'

import { useAuthUser } from 'features/user'
import WorkspaceSelect, { Option } from 'features/workspace/components/WorkspaceSelect'
import { useAbility } from 'providers/casl'

export const WorkspaceSelectContainer = (): JSX.Element => {
  const ability = useAbility()
  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (ability.can('use', 'WorkspaceFeature-select')) {
    return <WorkspaceSelect />
  } else if (currentWorkspace) {
    const { alerts, name, networkCode } = currentWorkspace

    return (
      <Button>
        <Option {...{ alerts, name, networkCode }} />
      </Button>
    )
  } else return <></>
}

import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { ROUTES } from 'routes'
import {
  UserManagementFormContext,
  UserManagementFormContextType,
} from '../../contexts/UserManagementFormContext'
import { UserAddEmployeeForm } from '../../forms/UserEmployeeForm/UserAddEmployeeForm'

export const UserAddEmployeePage = (): JSX.Element => {
  const { t } = useTranslation('features/user')

  const contextProps: UserManagementFormContextType = useCommonFormContextProps({
    formId: 'employee-form-add',
  })

  return (
    <UserManagementFormContext.Provider value={contextProps}>
      <UserManagementFormContext.Consumer>
        {(ctxProps: UserManagementFormContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                text={t('form.common.action.sendInvitation')}
              />
            }
            backTo={generatePath(ROUTES.USER.LIST, { tab: 'employees' })}
            title={t('form.employee.add.title')}
          />
        )}
      </UserManagementFormContext.Consumer>

      <UserAddEmployeeForm />
    </UserManagementFormContext.Provider>
  )
}

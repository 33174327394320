import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainBidderSlice } from '../types'
import { DomainBidderSliceDTO } from './types'

export const fromDTO = (from: DomainBidderSliceDTO) =>
  mapDTO<DomainBidderSliceDTO, DomainBidderSlice>({ from }).transform(
    (domainBidderDTO: DomainBidderSliceDTO): DomainBidderSlice => {
      const {
        csEnabled,
        id,
        priority,
        s2sEnabled,
        updatedAt,
        workspaceBidder: {
          bidder: { name, s2sAvailable },
          bidderCode: code,
          id: workspaceBidderId,
          priority: workspaceBidderPriority,
          status,
        },
      } = domainBidderDTO

      return {
        code,
        csEnabled,
        id,
        name,
        priority,
        s2sAvailable: s2sAvailable,
        s2sEnabled,
        status,
        updatedAt: new Date(updatedAt),
        workspaceBidderId,
        workspaceBidderPriority,
      }
    },
  )

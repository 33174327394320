import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { DomainsForTable, DomainSortBy } from '../../types'
import { DomainsForTableDTO, DomainSortByDTO } from './types'

export const domainsFromDTO = (from: DomainsForTableDTO) =>
  mapDTO<DomainsForTableDTO, DomainsForTable>({ from }).transform(domainForTable => ({
    ...domainForTable,
    updatedAt: new Date(domainForTable.updatedAt),
  }))

export const sortToDTO = (sort: SortInput<DomainSortBy>): SortInput<DomainSortByDTO> => {
  const { by } = sort

  if (by === 'name') {
    return { ...sort, by: 'domainName' }
  } else {
    return { ...sort, by }
  }
}

import { Theme, ThemeOptions } from '@mui/material'
import { default as cratePaletteMui } from '@mui/material/styles/createPalette'

export const createPalette = (theme: Theme): ThemeOptions['palette'] =>
  cratePaletteMui({
    background: { default: theme.palette.grey[100] },

    black: { main: '#222222' },
    contrastThreshold: 4.5, // https://mui.com/material-ui/customization/palette/#accessibility
    cream: { main: '#faf3de' },

    error: { main: '#F33308' },
    info: { main: '#1681FF' },
    movement: { main: '#3E71FF' }, // blue
    primary: { main: '#222222' }, // black

    secondary: { main: '#FF234F' }, // Yieldbird
    success: { main: '#22C55E' },
    warning: { main: '#FFAB00' },
    white: { main: theme.palette.common.white },
  })

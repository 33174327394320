import { Button, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { TargetingActionTypeDialogProps } from './types'

export const TargetingActionTypeDialog = ({
  dialog: { isOpen, setOpen },
}: TargetingActionTypeDialogProps): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { setValue } = useFormContext()

  const Content: JSX.Element = (
    <Stack spacing={2}>
      <Typography>
        {t('form.general.advancedSettings.targetingActionType.dialog.subtitle')}
      </Typography>

      <Typography
        sx={{ display: 'flex' }}
        variant='subtitle2'
      >
        {t('form.general.advancedSettings.targetingActionType.dialog.description')}
      </Typography>
    </Stack>
  )

  const Actions: JSX.Element = (
    <Button
      onClick={(): void => {
        setValue('targetingActionType', 'targetAll', { shouldDirty: true })
        setOpen(false)
      }}
      variant='contained'
    >
      {t('form.general.advancedSettings.targetingActionType.dialog.confirm')}
    </Button>
  )

  return (
    <Dialog
      actions={Actions}
      content={Content}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t('form.general.advancedSettings.targetingActionType.dialog.title')}
    />
  )
}

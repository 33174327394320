import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { DocumentationLink } from 'components/Link'
import Loading from 'components/Loading'
import WorkspaceAlerts from 'features/workspace/components/WorkspaceAlerts'
import {
  WorkspaceFormContext,
  WorkspaceFormContextType,
} from 'features/workspace/contexts/WorkspaceFormContext'
import { WorkspaceEditForm } from 'features/workspace/forms/WorkspaceForm'
import { useGetWorkspace } from 'features/workspace/forms/WorkspaceForm/hooks/useGetWorkspace'
import { ROUTES } from 'routes'
import { DOCUMENTATION_URL } from '../constants'
import { WorkspaceEditUrlParams } from './types'

export const WorkspaceEditPage = (): JSX.Element => {
  const { id } = useParams<WorkspaceEditUrlParams>()
  const { t } = useTranslation('features/workspace')
  const { workspace } = useGetWorkspace({ id })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'workspace-form-edit',
  })

  if (!id) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!workspace) {
    return <Loading />
  }

  const contextProps: WorkspaceFormContextType = {
    workspace,
    ...commonContextProps,
  }

  return (
    <WorkspaceFormContext.Provider value={contextProps}>
      <WorkspaceFormContext.Consumer>
        {(ctxProps: WorkspaceFormContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={ROUTES.WORKSPACE.LIST}
            title={t('form.edit.title')}
            titleAdornment={{
              end: (
                <Stack
                  direction='row'
                  gap={2}
                >
                  <DocumentationLink url={DOCUMENTATION_URL} />
                  <WorkspaceAlerts alerts={workspace.alerts} />
                </Stack>
              ),
            }}
          />
        )}
      </WorkspaceFormContext.Consumer>

      <WorkspaceEditForm />
    </WorkspaceFormContext.Provider>
  )
}

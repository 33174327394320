import { CellContext } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'

import { TextField } from 'components/Form'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { Schema } from '../../form/schema'

export const CustomDomainSidInputCell = ({
  cell: { getValue },
  row: {
    original: { id },
  },
}: CellContext<DomainSupplyChain, unknown>) => {
  const { control } = useFormContext<Schema>()

  return (
    <TextField
      control={control}
      fullWidth
      name={`domainSupplyChainsSids.${id}`}
      value={getValue()}
    />
  )
}

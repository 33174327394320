export const XLSX_MIME_TYPE: string =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const downloadFile = (blob: Blob, filename: string): void => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
  link.remove()
}

export const binaryStringToBlob = (data: string): Uint8Array =>
  Uint8Array.from(data, c => c.charCodeAt(0))

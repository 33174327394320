import { z } from 'zod'

import { SchemaDefaultProps } from './types'
import { adUnitBiddersToAttributes } from './utils'

export const schema = z.object({
  adUnitAmazonEnabled: z.boolean(),
  adUnitBiddersAttributes: z.record(
    z.string(),
    z.object({
      csEnabled: z.boolean(),
      s2sEnabled: z.boolean(),
    }),
  ),
  id: z.string(),
  prebidStackEnabled: z.boolean(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({
  adUnit: { adUnitAmazonEnabled, id, prebidStackEnabled },
  adUnitBidders,
}: SchemaDefaultProps): Schema => ({
  adUnitAmazonEnabled: adUnitAmazonEnabled ?? false,
  adUnitBiddersAttributes: adUnitBiddersToAttributes(adUnitBidders),
  id,
  prebidStackEnabled,
})

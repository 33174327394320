import { z } from 'zod'

import { commonSchema } from './commonSchema'
import { mediaTypesDefault } from './mediaTypesSchema'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof commonSchema>

export const addSchemaDefault: AddSchema = {
  divId: undefined,
  domainId: '',
  mediaTypes: mediaTypesDefault,
  name: undefined,
  outOfPage: false,
  outOfPageType: 'standard',
  pageIds: [],
  path: '',
}

import { Domain } from 'features/inventory/types/domain'

export type ApplicableDomain = (typeof applicableDomains)[number]

export const applicableDomains = ['all', 'specified'] as const

export type FilteringRule = {
  applicableDomains: ApplicableDomain
  blocked: string[]
  blockedCount: number
  createdAt: Date
  domainIds: Domain['id'][]
  enabled: boolean
  id: string
  name: string
  updatedAt: Date
}

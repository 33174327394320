import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_AMAZON_FRAGMENT_FOR_GET } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACE_AMAZON: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_AMAZON_FRAGMENT_FOR_GET}

  query getWorkspaceAmazon($workspaceId: ID!) {
    workspaceAmazon(workspaceId: $workspaceId) {
      ...WorkspaceAmazonFragmentForGet
    }
  }
`

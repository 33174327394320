import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_PUBLISHER_FOR_TABLE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation UpdatePublisherForTable($input: UpdatePublisherWorkspaceRoleInput!) {
    updatePublisherWorkspaceRole(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

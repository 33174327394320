import { useTranslation } from 'react-i18next'

import { Link } from 'components/Link'
import { Workspace } from 'features/workspace'
import { usePipedriveLink } from '../../hooks/usePipedriveLink'

export const DialogContent = (): JSX.Element => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })

  const pipedriveLink: Workspace['pipedriveLink'] = usePipedriveLink()

  return (
    <>
      {t('dataSampling.modal.content.text')}

      <Link
        newTab
        to={pipedriveLink}
      >
        {t('dataSampling.modal.content.url')}
      </Link>
    </>
  )
}

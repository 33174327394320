import { createSlice } from '@reduxjs/toolkit'

import { User } from '..'
import { removeTokenCookies, setTokenCookies } from '../api/resolvers/refreshToken'
import { initialState } from './constants'
import { AuthenticatePayload } from './types'

import type { PayloadAction } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  initialState,
  name: 'user',

  reducers: {
    authenticate: (state, action: PayloadAction<AuthenticatePayload>) => {
      const {
        payload: { accessToken, refreshToken, user },
      } = action

      setTokenCookies({ accessToken, refreshToken })
      state.user = user
    },

    reset: state => {
      removeTokenCookies()
      state.user = null
    },

    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      const { user } = state
      const { payload } = action

      if (user) {
        state.user = {
          ...user,
          ...payload,
        }
      }
    },
  },
})

export const { authenticate, reset, updateUser } = userSlice.actions

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AmazonPageContext } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { Data, fromDTO, toDTO, UPDATE_DOMAIN_AMAZON } from './api'
import { UseUpdateDomainAmazon } from './types'

export const useUpdateDomainAmazon = (): UseUpdateDomainAmazon => {
  const { t } = useTranslation('features/globalSetup/amazon', {
    keyPrefix: 'pages.inventoryConnection.form',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AmazonPageContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(UPDATE_DOMAIN_AMAZON, {
    onCompleted: ({ updateDomainAmazon }: Data): void => {
      setLoading(false)

      if (updateDomainAmazon) {
        const { domainAmazon, errors } = updateDomainAmazon

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else if (domainAmazon) {
          setErrors({})
          enqueueSnackbar(
            t('success', {
              domainName: fromDTO(domainAmazon).domain.name,
            }),
            { variant: 'success' },
          )
        }
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema): void => {
    setLoading(true)

    mutate({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return { errors, update }
}

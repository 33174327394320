import { mapDTO } from '@twistezo/ts-dto-mapper'

import {
  UserSlice,
  UserSliceDTO,
  UserWorkspaceForForm,
  UserWorkspacePayloadDTO,
} from 'features/user/forms/UserPublisherForm/types'

export const userFromDTO = (from: UserSliceDTO): UserSlice =>
  mapDTO<UserSliceDTO, UserSlice>({ from }).transform(
    (user: UserSliceDTO): UserSlice => ({
      ...user,
      userWorkspaces: user.userWorkspaces.map(
        (userWorkspace: UserWorkspacePayloadDTO): UserWorkspaceForForm => ({
          role: userWorkspace.role,
          userId: user.id,
          workspaceId: userWorkspace.id,
        }),
      ),
    }),
  )

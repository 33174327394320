import { Typography } from '@mui/material'

export const Line = ({ text }: { text: string }): JSX.Element => (
  <Typography
    sx={{ whiteSpace: 'pre-wrap' }}
    variant='body2'
  >
    {text}
  </Typography>
)

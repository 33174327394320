import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { Link } from 'components/Link'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  AddEditAdUnitContext,
  AddEditAdUnitContextType,
} from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useGetAdUnit } from 'features/inventory/ad-unit/forms/GeneralForm/hooks/useGetAdUnit'
import { DomainAlerts } from 'features/inventory/domain'
import { ROUTES } from 'routes'
import { ProductTab } from 'types/productTab'
import { EditAdUnitUrlParams } from './types'
import { useProductTabs } from './useProductTabs'

export const EditAdUnit = (): JSX.Element => {
  const { id: urlAdUnitId, tab: urlCurrentTab, workspaceId } = useParams<EditAdUnitUrlParams>()
  const { t } = useTranslation('features/adUnit')
  const tabs: Tab<ProductTab>[] = useProductTabs()
  const { adUnit } = useGetAdUnit({ id: urlAdUnitId })

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'ad-unit-form-edit',
  })

  if (!workspaceId || !urlAdUnitId) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!adUnit) {
    return <Loading />
  }

  const { path } = adUnit

  const contextProps: AddEditAdUnitContextType = {
    adUnit,
    ...commonContextProps,
  }

  const Actions = (ctxProps: AddEditAdUnitContextType): JSX.Element => (
    <>
      <Link
        to={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
          id: adUnit.domain.id,
          tab: urlCurrentTab,
          workspaceId,
        })}
      >
        <Button data-cy='edit-domain-button'>{t('edit.pageHeader.actions.editDomain')}</Button>
      </Link>

      <SaveButton {...ctxProps} />
    </>
  )

  return (
    <AddEditAdUnitContext.Provider value={contextProps}>
      <StickyBox>
        <AddEditAdUnitContext.Consumer>
          {(ctxProps: AddEditAdUnitContextType): JSX.Element => (
            <PageHeader
              actions={<Actions {...ctxProps} />}
              backTo={generatePath(ROUTES.INVENTORY.AD_UNIT.LIST, { workspaceId })}
              title={path}
              titleAdornment={{ end: <DomainAlerts alerts={adUnit.domain.alerts} /> }}
            />
          )}
        </AddEditAdUnitContext.Consumer>

        <Tabs
          path={ROUTES.INVENTORY.AD_UNIT.EDIT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<ProductTab>(tabs, urlCurrentTab, 'general')}
    </AddEditAdUnitContext.Provider>
  )
}

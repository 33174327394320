import _ from 'lodash'
import { FieldErrors } from 'react-hook-form'

import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { MutationInputError } from 'providers/graphql'
import { AdUnitBidderError, DomainBidderErrors } from './types'

export const parsedAdUnitBiddersErrors = (
  errors: DomainBidderErrors['adUnitBidders'],
): FieldErrors<Schema> => ({
  adUnitBidders: _.chain(errors)
    .mapKeys(
      (bidderError: AdUnitBidderError): AdUnitBidderError['adUnitBidderId'] =>
        bidderError.adUnitBidderId,
    )
    .mapValues(
      ({
        errors,
      }: AdUnitBidderError): Record<string, Record<string, Pick<MutationInputError, 'message'>>> =>
        _.chain(errors)
          .groupBy(
            (err: MutationInputError): MutationInputError['attribute'] =>
              err.attribute.split('.')[0],
          )
          .mapValues(
            (errors: MutationInputError[]): Record<string, Pick<MutationInputError, 'message'>> =>
              _.chain(errors)
                .mapKeys(
                  (err: MutationInputError): MutationInputError['attribute'] =>
                    err.attribute.split('.')[1],
                )
                .mapValues(
                  (err: MutationInputError): Pick<MutationInputError, 'message'> => ({
                    message: err.message,
                  }),
                )
                .value(),
          )
          .value(),
    )
    .value(),
})

import { generatePath, To } from 'react-router-dom'

import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { UserTableProps } from 'features/user/components/Table/types'
import { useAbility } from 'providers/casl'
import { calculatePagination } from 'providers/graphql'
import { ROUTES } from 'routes'
import { useGetEmployees } from './hooks/useGetEmployees'
import { EmployeeForTable } from './types'
import { useColumns } from './useColumns'

export const EmployeeTable = ({ filters }: UserTableProps): JSX.Element => {
  const ability = useAbility()
  const columns = useColumns()
  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })

  const { count, loading, users } = useGetEmployees({
    filters,
    ...calculatePagination({ page, rowsPerPage }),
  })

  const canManage: boolean = ability.can('update', 'Employee') && ability.can('delete', 'Employee')

  const handleRowClick = (row: EmployeeForTable): To =>
    generatePath(ROUTES.USER.EMPLOYEE.EDIT, { id: row.id })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<EmployeeForTable>
      columns={columns}
      columnVisibility={{ actions: canManage }}
      data={users}
      loading={loading}
      onClickRow={canManage ? handleRowClick : undefined}
      pagination={paginationProps}
    />
  )
}

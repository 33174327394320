import { z } from 'zod'

export const consentManagementSchema = z.object({
  gdprTcfEnabled: z.boolean(),
  gdprTcfTimeout: z.number().min(0),
  gppControlUsNat: z.boolean(),
  gppControlUsStates: z.boolean(),
  gppEnabled: z.boolean(),
  gppTimeout: z.number().min(0),
  prebidTimeout: z.number().min(0),
  usPrivacyCcpaEnabled: z.boolean(),
  usPrivacyCcpaTimeout: z.number().min(0),
})

export type ConsentManagementSchema = z.infer<typeof consentManagementSchema>

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { useCreatePrebidModule } from '../../hooks/useCreatePrebidModule'
import { AddSchema, addSchema, addSchemaDefault } from '../../schemas/addSchema'
import { AnalyticsAdaptersForm } from './AnalyticsAdaptersForm'

export const AddContainer = (): JSX.Element => {
  const { formId, loading, setLoading, setTouched, setValid } = useContext(PrebidModulesContext)
  const { create, errors: apiErrors } = useCreatePrebidModule({
    moduleType: 'analytics',
    setLoading,
  })

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => (loading ? <Loading /> : <AnalyticsAdaptersForm variant='add' />)}
    </Form>
  )
}

import { z } from 'zod'

import { mediaTypesKindValues } from 'features/inventory/ad-unit/types/mediaTypes'
import { viewportSchema } from 'features/inventory/viewport'
import { validateJSONSchema } from 'utils/json'

const mediaTypeSchema = z
  .object({
    mediaTypeId: z.string().optional(),
    updatedAt: z.date(),
  })
  .merge(
    viewportSchema.pick({
      bannerEnabled: true,
      bannerSizes: true,
      nativeEnabled: true,
      outstreamEnabled: true,
      playerSize: true,
      viewportId: true,
    }),
  )

const mediaTypeViewportsSchema = mediaTypeSchema.merge(z.object({ viewport: viewportSchema }))

export const mediaTypesSchema = z.object({
  defaultAttribute: mediaTypeSchema,
  kind: z.enum(mediaTypesKindValues),
  nativeConfiguration: z.string().superRefine(validateJSONSchema),
  newViewportId: z.string().optional(),
  outstreamConfiguration: z.string().superRefine(validateJSONSchema),
  viewportAttributes: mediaTypeViewportsSchema.array(),
})

export const mediaTypesDefault: MediaTypesSchema = {
  defaultAttribute: {
    bannerEnabled: false,
    bannerSizes: [],
    nativeEnabled: false,
    outstreamEnabled: false,
    playerSize: [],
    updatedAt: new Date(),
  },
  kind: 'default',
  nativeConfiguration: '{}',
  outstreamConfiguration: '{}',
  viewportAttributes: [],
}

export type MediaTypeViewportsSchema = z.infer<typeof mediaTypeViewportsSchema>
export type MediaTypeSchema = z.infer<typeof mediaTypeSchema>
export type MediaTypesSchema = z.infer<typeof mediaTypesSchema>

import { mapDTO } from '@twistezo/ts-dto-mapper'

import { UserWorkspacePayloadDTO } from 'features/user/forms/UserPublisherForm/types'
import { UserWorkspaceForFormSlice } from '../../types'

export const userWorkspaceFromDTO = (from: UserWorkspacePayloadDTO): UserWorkspaceForFormSlice =>
  mapDTO<UserWorkspacePayloadDTO, UserWorkspaceForFormSlice>({ from }).transform(
    (userWorkspaceDTO: UserWorkspacePayloadDTO): UserWorkspaceForFormSlice => ({
      role: userWorkspaceDTO.role,
      workspaceId: userWorkspaceDTO.id,
    }),
  )

import { gql, TypedDocumentNode } from '@apollo/client'

import { EMPLOYEE_FRAGMENT } from 'features/user/pages/UserEditEmployeePage/hooks/useGetEmployee'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_EMPLOYEE: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${EMPLOYEE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      user {
        ...EmployeeFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

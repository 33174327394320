import { RouteObject } from 'react-router-dom'

import { DownloadTagsPage } from 'features/tag/pages/DownloadTagsPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const tagRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='TagPage-download'
        page={<DownloadTagsPage />}
      />
    ),
    path: ROUTES.TAG,
  },
]

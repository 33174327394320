import { useContext } from 'react'

import { useMergeFormData } from 'components/Form'
import { Table } from 'components/Table'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { mapToFormField } from '../../form/mapper'
import { Schema } from '../../form/schema'
import { WorkspaceBidderForTable } from '../../hooks/useGetBidders/types'
import { TableProps } from './types'
import { useColumns } from './useColumns'

export const BidderSidsTable = ({ data, pagination, sort }: TableProps): JSX.Element => {
  const { loading } = useContext(SupplyChainContext)
  const columns = useColumns()

  useMergeFormData<Schema>({
    data: mapToFormField(data),
    path: 'workspaceBidderSids',
  })

  return (
    <Table<WorkspaceBidderForTable>
      columns={columns}
      data={data}
      loading={loading}
      meta={{ verticalBorders: true }}
      pagination={pagination}
      sort={sort}
    />
  )
}

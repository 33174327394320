import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_FRAGMENT } from 'features/workspace'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, VariablesDTO } from './types'

export const UPDATE_CURRENT_WORKSPACE: TypedDocumentNode<Data, { input: VariablesDTO }> = gql`
  ${WORKSPACE_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation updateCurrentWorkspace($input: UpdateCurrentWorkspaceInput!) {
    updateCurrentWorkspace(input: $input) {
      user {
        id
        currentWorkspace {
          ...WorkspaceFragment
        }
        currentWorkspaceRole
        workspaceProducts {
          product
          status
        }
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { Box, CSSObject } from '@mui/material'

import { FlexBoxProps } from './types'

export const FlexBox = ({ axis, ...muiBoxProps }: FlexBoxProps): JSX.Element => {
  const axisProps = (): CSSObject => {
    switch (axis) {
      case 'x': {
        return {
          flexDirection: 'row',
        }
      }
      case 'y': {
        return {
          flexDirection: 'column',
        }
      }
      case 'xy': {
        return {
          flexDirection: 'column',
          justifyContent: 'center',
        }
      }
    }
  }

  return (
    <Box
      {...muiBoxProps}
      sx={{
        alignItems: 'center',
        display: 'flex',
        ...axisProps(),
        ...muiBoxProps.sx,
      }}
    />
  )
}

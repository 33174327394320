import Alerts from 'components/Alerts'
import { DomainAlert } from '../../types/DomainAlert'
import { Props } from './types'
import { useAlerts } from './useAlerts'

export const DomainAlerts = ({ alerts }: Props): JSX.Element => {
  const { switcher } = useAlerts()

  return <Alerts<DomainAlert> {...{ alerts, switcher }} />
}

import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SaveButtonProps } from './types'

export const SaveButton = ({
  formId,
  loading,
  text,
  touched,
  valid,
}: SaveButtonProps): JSX.Element => {
  const { t: tCommon } = useTranslation('common')

  const buttonId: string = `${formId}-save-button`

  return (
    <Button
      data-cy={buttonId}
      disabled={!touched || loading || !valid}
      form={formId}
      id={buttonId}
      type='submit'
      variant='contained'
    >
      {text || tCommon('form.action.save')}
    </Button>
  )
}

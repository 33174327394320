import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_BIDDER: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspaceBidder($id: ID!) {
    node(id: $id) {
      ... on WorkspaceBidder {
        accountType
        id
        enabled
        consentMode
        priority
        bidderCode
        bidCpmAdjustment
        bidderSid
        supplyChain {
          id
        }
        bidderCode
        bidder {
          id
          name
        }
        status
      }
    }
  }
`

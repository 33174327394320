import { z } from 'zod'

import { adUnitsAttributesSchema } from 'features/inventory/domain/components/ProductAdUnitsTable'
import { matchTypeValues } from 'features/inventory/domain/types/matchType'
import { Domain } from 'features/inventory/types/domain'

export const schema = z
  .object({
    adThreshold: z.number().min(0).max(100).multipleOf(0.01),
    advertiserIds: z.string().array(),
    domainId: z.string(),
    lineItemIds: z.string().array(),
    matchType: z.enum(matchTypeValues),
    monitorUserScrollEnabled: z.boolean(),
    orderIds: z.string().array(),
    refresherEnabled: z.boolean(),
    refreshInterval: z.number().min(1).max(300),
    targetingEnabled: z.boolean(),
  })
  .merge(adUnitsAttributesSchema)

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({ domainRefresher }: Domain): Schema => ({
  ...domainRefresher,
  adUnitsAttributes: {},
})

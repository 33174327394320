import Adquery from 'images/logos/prebidModule/Adquery QID.svg?react'
import ATS from 'images/logos/prebidModule/ATS Analytics.svg?react'
import Criteo from 'images/logos/prebidModule/Criteo ID.svg?react'
import Geoedge from 'images/logos/prebidModule/Geoedge.svg?react'
import Greenbids from 'images/logos/prebidModule/Greenbids.svg?react'
import Id5 from 'images/logos/prebidModule/Id5.svg?react'
import Just from 'images/logos/prebidModule/Just ID.svg?react'
import Live from 'images/logos/prebidModule/Live Intent Id.svg?react'
import Lotame from 'images/logos/prebidModule/Lotame Panorama Id.svg?react'
import Pubstack from 'images/logos/prebidModule/Pubstack Analytics.svg?react'
import Quantcast from 'images/logos/prebidModule/Quantcast ID.svg?react'
import Shared from 'images/logos/prebidModule/Shared Id.svg?react'
import Teads from 'images/logos/prebidModule/Teads ID.svg?react'
import Unified from 'images/logos/prebidModule/Unified Id.svg?react'
import Weborama from 'images/logos/prebidModule/Weborama.svg?react'
import { Logos } from 'types/logos'

export const prebidModuleLogos: Logos = {
  'Adquery QID': Adquery,
  'ATS Analytics': ATS,
  'Criteo ID': Criteo,
  Geoedge: Geoedge,
  'Greenbids Analytics': Greenbids,
  'Greenbids RTD Provider': Greenbids,
  Id5: Id5,
  'Just ID': Just,
  'Live Intent Id': Live,
  'Lotame Panorama Id': Lotame,
  'Pubstack Analytics': Pubstack,
  'Quantcast ID': Quantcast,
  'Shared Id': Shared,
  'Teads ID': Teads,
  'Unified Id': Unified,
  Weborama: Weborama,
} as const

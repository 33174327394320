import { useTranslation } from 'react-i18next'

import { AlertBox } from 'components/Box'
import { RelatedSettingsProps } from './types'

export const RelatedSettings = ({ children, title }: RelatedSettingsProps): JSX.Element => {
  const { t } = useTranslation('components')

  return (
    <AlertBox
      severity='info'
      title={title ?? t('relatedSettings')}
    >
      {children}
    </AlertBox>
  )
}

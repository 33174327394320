import { useTranslation } from 'react-i18next'

import { SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { DocumentationLink } from 'components/Link'
import {
  WorkspaceFormContext,
  WorkspaceFormContextType,
} from 'features/workspace/contexts/WorkspaceFormContext'
import { WorkspaceAddForm } from 'features/workspace/forms/WorkspaceForm'
import { ROUTES } from 'routes'
import { DOCUMENTATION_URL } from '../constants'

export const WorkspaceAddPage = (): JSX.Element => {
  const { t } = useTranslation('features/workspace')

  const contextProps: WorkspaceFormContextType = useCommonFormContextProps({
    formId: 'workspace-form-add',
  })

  return (
    <WorkspaceFormContext.Provider value={contextProps}>
      <WorkspaceFormContext.Consumer>
        {(ctxProps: WorkspaceFormContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={ROUTES.WORKSPACE.LIST}
            title={t('form.add.title')}
            titleAdornment={{ end: <DocumentationLink url={DOCUMENTATION_URL} /> }}
          />
        )}
      </WorkspaceFormContext.Consumer>

      <WorkspaceAddForm />
    </WorkspaceFormContext.Provider>
  )
}

import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SwitchProps } from 'components/Form/Switch'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { KONWLEDGE_BASE_URL } from 'features/globalSetup/features/amazon/constants'
import { AmazonPageContext } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext'
import { AmazonPageContextType } from 'features/globalSetup/features/amazon/contexts/AmazonPageContext/types'
import { Schema } from 'features/globalSetup/features/amazon/forms/GeneralPageForm'
import { useSwitchProps } from './hooks/useSwitchProps'

export const AmazonOnOffSection = () => {
  const { t } = useTranslation(['features/globalSetup/amazon', 'common'])
  const { setAccountType, workspaceAmazon } = useContext<AmazonPageContextType>(AmazonPageContext)

  const switchProps: SwitchProps<Schema> = useSwitchProps({ workspaceAmazon })

  const { watch } = useFormContext<Schema>()
  const { accountType } = watch()

  useEffect(() => {
    setAccountType(accountType)
  }, [accountType]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper>
      <SectionHeader
        subtitle={t('pages.general.amazonOnOff.subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KONWLEDGE_BASE_URL.AMAZON_UAM_TAM,
            }}
          />
        }
        title={t('pages.general.amazonOnOff.title')}
        titleEndAdornment={<Switch {...switchProps} />}
      />
    </Paper>
  )
}

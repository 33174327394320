import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GET_FILTERING_RULE } from './api/query'
import { FilteringRuleSlice, UseGetFilteringRule, UseGetFilteringRuleProps } from './types'

export const useGetFilteringRule = ({ id }: UseGetFilteringRuleProps): UseGetFilteringRule => {
  const [filteringRule, setFilteringRule] = useState<FilteringRuleSlice>()

  useEffect(() => {
    if (id) {
      get({ variables: { id } })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [get] = useLazyQuery(GET_FILTERING_RULE, {
    onCompleted: ({ node: filteringRule }) => {
      if (filteringRule) {
        setFilteringRule(filteringRule)
      }
    },
  })

  return { filteringRule }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_SUPPLY_CHAIN: TypedDocumentNode<Data, Variables> = gql`
  ${ERROR_FRAGMENT}

  mutation updateSupplyChain($name: String, $id: ID!, $asi: String, $defaultSid: String) {
    updateSupplyChain(input: { name: $name, id: $id, asi: $asi, defaultSid: $defaultSid }) {
      supplyChain {
        name
        id
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const DELETE_DOMAIN: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation deleteDomain($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
`

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModuleParameter } from 'features/globalSetup/types/workspacePrebidModule'

export const prepareValue = (
  name: PrebidModuleParameter['name'],
  parameters: WorkspacePrebidModuleParameter[],
): WorkspacePrebidModuleParameter['value'] => {
  let value: string = ''

  if (parameters.length > 0) {
    const parameter: undefined | WorkspacePrebidModuleParameter = parameters.find(
      (parameter: WorkspacePrebidModuleParameter) => parameter.name === name,
    )

    if (!parameter) {
      throw Error('Mismatched parameters between WorkspacePrebidModule and PrebidModule')
    }

    value = parameter.value
  }

  return value
}

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { Schema } from '../../schema'
import { ConsentManagementCommonFields } from './components/ConsentManagementCommonFields'

export const ConsentManagementSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.consentManagement',
  })
  const { t: tC } = useTranslation('common')
  const { control, watch } = useFormContext<Schema>()

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tC('learnMore'),
              url: KNOWLEDGE_BASE_URL.CONSENT_MANAGEMENT,
            }}
          />
        }
        title={t('title')}
      />

      <ConsentManagementCommonFields
        control={control}
        watch={watch}
      />
    </Paper>
  )
}

import { RouteObject } from 'react-router-dom'

import { ViewportPage } from 'features/inventory/viewport/pages/ViewportPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const viewportRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='ViewportPage'
        page={<ViewportPage />}
      />
    ),
    path: ROUTES.INVENTORY.VIEWPORT,
  },
]

import { useMutation } from '@apollo/client'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { DELETE_WORKSPACE } from './mutation'
import { UseDeleteWorkspace, UseDeleteWorkspaceProps } from './types'

export const useDeleteWorkspace = ({ name }: UseDeleteWorkspaceProps): UseDeleteWorkspace => {
  const { t } = useTranslation('features/workspace')
  const { enqueueSnackbar } = useSnackbar()

  const enqueueErrorSnackbar = (): SnackbarKey =>
    enqueueSnackbar(t('delete.action.error', { workspaceName: name }), { variant: 'error' })

  const [mutateDeleteWorkspace, { loading }] = useMutation(DELETE_WORKSPACE, {
    onCompleted: ({ deleteWorkspace }): void => {
      if (deleteWorkspace) {
        const { errors } = deleteWorkspace

        if (errors && errors.length > 0) {
          enqueueErrorSnackbar()
        }
      } else {
        enqueueSnackbar(t('delete.action.success', { workspaceName: name }), { variant: 'success' })
      }
    },
    onError: (): void => {
      enqueueErrorSnackbar()
    },
    refetchQueries: ['workspacesForTable'],
  })

  const deleteWorkspace: UseDeleteWorkspace['deleteWorkspace'] = id =>
    mutateDeleteWorkspace({ variables: { input: { id } } })

  return { deleteWorkspace, loading }
}

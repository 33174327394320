import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { WorkspacePrebidModule } from 'features/globalSetup/types/workspacePrebidModule'
import { DELETE_WORKSPACE_PREBID_MODULE } from './api/mutation'
import { UseDeleteWorkspacePrebidModule, useDeleteWorkspacePrebidModuleProps } from './types'

export const useDeleteWorkspacePrebidModule = ({
  prebidModule,
}: useDeleteWorkspacePrebidModuleProps): UseDeleteWorkspacePrebidModule => {
  const { t } = useTranslation('features/globalSetup')
  const { enqueueSnackbar } = useSnackbar()

  const [mutateDeleteWorkspacePrebidModule, { loading }] = useMutation(
    DELETE_WORKSPACE_PREBID_MODULE,
    {
      onCompleted: ({ deleteWorkspacePrebidModule }): void => {
        const errors = deleteWorkspacePrebidModule?.errors
        const prebidModuleName = prebidModule.name

        if (errors && errors.length > 0) {
          enqueueSnackbar(
            t('prebid.modules.common.list.delete.action.error', {
              prebidModuleName,
            }),
            { variant: 'error' },
          )
        } else {
          enqueueSnackbar(
            t('prebid.modules.common.list.delete.action.success', {
              prebidModuleName,
            }),
            { variant: 'success' },
          )
        }
      },
      refetchQueries: ['workspacePrebidModules'],
    },
  )

  const destroy: UseDeleteWorkspacePrebidModule['destroy'] = (id: WorkspacePrebidModule['id']) =>
    mutateDeleteWorkspacePrebidModule({ variables: { input: { id } } })

  return { destroy, loading }
}

import { Box } from '@mui/material'
import { To, useNavigate } from 'react-router-dom'

import { cellWrapperStyles } from './cellWrapperStyles'
import { LinkCellProps } from './types'

export const LinkCell = <T,>({
  children,
  columnMeta,
  onClick,
  row,
}: LinkCellProps<T>): JSX.Element => {
  const navigate = useNavigate()

  const handleOnClick = (): void => {
    if (onClick) {
      Promise.resolve(onClick(row.original)).then((to: To | void): void => {
        if (to) navigate(to)
      })
    }
  }

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        ...cellWrapperStyles<T>(columnMeta),
        cursor: onClick === undefined ? 'arrow' : 'pointer',
      }}
    >
      {children}
    </Box>
  )
}

import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditPageContext } from 'features/inventory/page/contexts/AddEditPageContext'
import { CommonForm } from './CommonForm'
import { useCreatePage } from './hooks/useCreatePage'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/addSchema'

export const GeneralFormAdd = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(AddEditPageContext)
  const { createPage, errors: apiErrors } = useCreatePage()

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={data => createPage(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => (loading ? <Loading /> : <CommonForm variant='add' />)}
    </Form>
  )
}

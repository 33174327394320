import { useTranslation } from 'react-i18next'

import { CellCentered } from './CellCentered'
import { NotFoundCellProps } from './types'

export const NotFoundCell = ({ columnsCount }: NotFoundCellProps): JSX.Element => {
  const { t } = useTranslation('components')

  return <CellCentered columnsCount={columnsCount}>{t('table.nothingFound')}</CellCentered>
}

import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Dialog from 'components/Dialog'
import { Form as FormProvider } from 'components/Form'
import { useUpdateProduct } from '../../hooks/useUpdateProduct'
import { DialogProps } from '../../types'
import { Form } from './Form'
import { Schema, schema, schemaDefault } from './schema'

export const TrialDialog = ({
  isOpen,
  newStatus,
  setOpen,
  workspaceProduct,
}: DialogProps): JSX.Element => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/product')

  const { from, id, product, to } = workspaceProduct
  const { errors: apiErrors, update } = useUpdateProduct(workspaceProduct)

  const formId = `${product}-trial-dialog-form`

  const handleSubmit = ({ from, to }: Schema): void => {
    update({ from, id, status: newStatus, to })
    setOpen(false)
  }

  const Actions = (): JSX.Element => (
    <Button
      form={formId}
      type='submit'
      variant='contained'
    >
      {tC('form.action.save')}
    </Button>
  )

  return (
    <FormProvider<Schema>
      externalErrors={apiErrors}
      id={formId}
      onSubmit={handleSubmit}
      schema={schema}
      schemaDefaults={schemaDefault({ from, to })}
    >
      {() => (
        <Dialog
          actions={<Actions />}
          content={<Form />}
          isOpen={isOpen}
          maxWidth={false}
          setOpen={setOpen}
          title={t('dialog.status.trial.title', { product: t(`product.${product}`) })}
        />
      )}
    </FormProvider>
  )
}

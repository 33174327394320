import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainPrebidStackDTO } from '../types/domainPrebidStackDTO'

export const DOMAIN_PREBID_STACK_FRAGMENT: TypedDocumentNode<DomainPrebidStackDTO> = gql`
  fragment DomainPrebidStackFragment on DomainPrebidStack {
    createdAt
    customConsentManagementEnabled
    customPrebidTimeoutEnabled
    domainAmazonEnabled
    domainId
    gdprTcfTimeout
    gdprTcfEnabled
    gppControlUsNat
    gppControlUsStates
    gppTimeout
    gppEnabled
    id
    prebidStackEnabled
    prebidTimeout
    updatedAt
    usPrivacyCcpaTimeout
    usPrivacyCcpaEnabled
  }
`

import { CSSObject, useTheme } from '@mui/material'
import { CellContext, flexRender } from '@tanstack/react-table'

import { UseCell, UseCellProps } from './types'

export const useCell = <T>({ cell }: UseCellProps<T>): UseCell<T> => {
  const {
    column: {
      columnDef: { cell: cellDef, meta: columnMeta },
      getIsPinned,
      getSize,
      getStart,
    },
    getContext,
  } = cell

  const theme = useTheme()
  const {
    palette: { background, white },
    zIndex: { tableCellPinned },
  } = theme

  const context: CellContext<T, unknown> = getContext()
  const {
    row,
    table: {
      getLeftTotalSize,
      options: { meta: tableMeta },
    },
  } = context

  const Content = flexRender(cellDef, context)
  const isParentRow: boolean = row.depth === 0
  const isClickable = Boolean(!columnMeta?.nonClickable) && isParentRow

  const isPinned: boolean = getIsPinned() === 'left'
  const leftStart: number = getStart()
  const width: number = getSize()
  const isPinnedAtEnd: boolean = isPinned && leftStart + width === getLeftTotalSize()

  const withVerticalBorders = Boolean(tableMeta?.verticalBorders)
  const verticalBorders: CSSObject = {
    borderRight: '1px solid',
    // eslint-disable-next-line perfectionist/sort-objects
    '&:last-of-type': {
      borderRight: 'none',
    },
    borderColor: 'divider',
  }

  const styledCell: CSSObject | undefined = tableMeta?.styleRow?.(row, theme)

  const styles: CSSObject = {
    ...(withVerticalBorders ? verticalBorders : {}),
    backgroundColor: isPinned ? background.default : white.main,
    boxShadow: isPinnedAtEnd ? '-5px 0 5px -5px grey inset' : undefined,
    left: isPinned ? `${leftStart}px` : undefined,
    minWidth: width,
    p: 0,
    position: isPinned ? 'sticky' : 'relative',
    verticalAlign: 'top',
    zIndex: isPinned ? tableCellPinned : 'unset',
    ...styledCell,
  }

  return {
    Content,
    isClickable,
    row,
    styles,
  }
}

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { theme } from './theme'

export const MaterialUiProvider = ({ children }: { children: JSX.Element }): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

import { gql, TypedDocumentNode } from '@apollo/client'

import { WORKSPACE_USER_FOR_TABLE_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_WORKSPACE_USERS_FOR_TABLE: TypedDocumentNode<Data, Variables> = gql`
  ${WORKSPACE_USER_FOR_TABLE_FRAGMENT}

  query getWorkspaceUsersForTable($first: Int, $last: Int, $terms: [String!], $workspaceId: ID!) {
    usersInWorkspace(first: $first, last: $last, terms: $terms, workspaceId: $workspaceId) {
      nodes {
        ...WorkspaceUserForTableFragment
      }

      totalCount
    }
  }
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_BIDDERS_FOR_SUPPLY_CHAIN_TABLE: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspaceBiddersForSupplyChainTable(
    $supplyChainId: ID!
    $workspaceId: ID!
    $first: Int
    $last: Int
    $sort: WorkspaceBidderSortInput
    $terms: [String!]
  ) {
    workspaceBidders(
      supplyChainId: $supplyChainId
      workspaceId: $workspaceId
      first: $first
      last: $last
      sort: $sort
      terms: $terms
    ) {
      nodes {
        bidder {
          id
          name
        }
        bidderCode
        bidderSid
        id
        updatedAt
      }

      totalCount
    }
  }
`

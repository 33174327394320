import { mapDTO } from '@twistezo/ts-dto-mapper'

import { CommonSchema, Schema } from '../../../forms/GeneralPageForm'
import { Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((schema: From): To => {
    const { accountType, enabled, id } = schema

    const commonProps: CommonSchema = {
      enabled: id ? enabled : undefined,
    }

    let variables: Schema | undefined = undefined
    switch (accountType) {
      case 'own': {
        const { pubId } = schema
        variables = {
          accountType: 'own',
          pubId,
          ...commonProps,
        }

        break
      }
      case 'yieldbird': {
        const { status } = schema
        variables = {
          accountType: 'yieldbird',
          status: status ?? undefined,
          ...commonProps,
        }

        break
      }
      case 'thirdPartyProvider': {
        const { pubId, supplyChainId } = schema
        variables = {
          accountType: 'thirdPartyProvider',
          pubId,
          supplyChainId,
          ...commonProps,
        }

        break
      }
    }

    if (variables === undefined) {
      throw Error('Something went wrong with additional props related to accountType')
    }

    return variables
  })

import { Theme, ThemeOptions } from '@mui/material'

export const createZIndex = (theme: Theme): ThemeOptions['zIndex'] => {
  const { drawer } = theme.zIndex

  return {
    appBar: drawer + 1,
    stickyBox: drawer + 1,
    tableCell: 2,
    tableCellPinned: 4,
    tableFilter: 4,
    tableHeaderPinned: 4,
  }
}

import { Account, WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'

export const isDisabledByYieldbirdRequested = (
  accountType: Account,
  workspaceBidderStatus: WorkspaceBidder['status'],
) => accountType === 'yieldbird' && workspaceBidderStatus === 'requested'

export const isDisabledByYieldbirdDenied = (
  accountType: Account,
  workspaceBidderStatus: WorkspaceBidder['status'],
) => accountType === 'yieldbird' && workspaceBidderStatus === 'denied'

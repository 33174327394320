import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { DomainBidderOption } from '../types'
import { DomainBidderSliceDTO } from './types'

type From = DomainBidderSliceDTO
type To = SelectOption<DomainBidderOption>

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((domainBidder: From): To => {
    const {
      domain: { id: domainId, name },
      id: domainBidderId,
    } = domainBidder

    return {
      label: name,
      rawData: {
        domain: {
          id: domainId,
          name,
        },
        domainBidderId,
      },
      value: domainBidderId,
    }
  })

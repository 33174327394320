import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralFormEdit } from 'features/inventory/domain/forms/GeneralForm'
import PrebidStackForm from 'features/inventory/domain/forms/PrebidStackForm'
import { PriceGeniusForm } from 'features/inventory/domain/forms/PriceGeniusForm'
import RefresherForm from 'features/inventory/domain/forms/RefresherForm'
import UnfilledRecoveryForm from 'features/inventory/domain/forms/UnfilledRecoveryForm'
import ViewabilityToolsForm from 'features/inventory/domain/forms/ViewabilityToolsForm'
import { useAbility } from 'providers/casl'
import { ProductTab } from 'types/productTab'

export const useProductTabs = (): Tab<ProductTab>[] => {
  const { t } = useTranslation('features/domain')
  const { t: tProduct } = useTranslation('features/product')
  const ability = useAbility()

  const canUpdate = ability.can('update', 'Domain')

  return [
    {
      disabled: !canUpdate,
      element: 'general',
      label: t('edit.tabs.general'),
      page: <GeneralFormEdit />,
    },
    {
      authorized: 'ProductForm-prebidStack',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-prebidStack'),
      element: 'prebid-stack',
      label: tProduct('product.prebidStack'),
      page: <PrebidStackForm />,
    },
    {
      authorized: 'ProductForm-refresher',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-refresher'),
      element: 'refresher',
      label: tProduct('product.refresher'),
      page: <RefresherForm />,
    },
    {
      authorized: 'ProductForm-viewabilityTools',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-viewabilityTools'),
      element: 'viewability-tools',
      label: tProduct('product.viewabilityTools'),
      page: <ViewabilityToolsForm />,
    },
    {
      authorized: 'ProductForm-priceGenius',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-priceGenius'),
      element: 'price-genius',
      label: tProduct('product.priceGenius'),
      page: <PriceGeniusForm />,
    },
    {
      authorized: 'ProductForm-unfilledRecovery',
      disabled: !canUpdate || !ability.can('access', 'ProductForm-unfilledRecovery'),
      element: 'unfilled-recovery',
      label: tProduct('product.unfilledRecovery'),
      page: <UnfilledRecoveryForm />,
    },
  ]
}
